<template>
  <div class="py-4">
    <div class="w-full flex items-center justify-between mb-4">
      <p class="font-bold text-grey-2">{{ $t('contracts.filters.title') }}</p>
      <p
        class="font-semibold text-sm text-orange-1 cursor-pointer"
        @click="clear"
      >
        {{ $t('contracts.filters.clear') }}
      </p>
    </div>
    <p class="text-left text-sm font-semibold text-grey-3 mb-3">
      {{ $t('contracts.filters.risk_title') }}
    </p>
    <div class="flex">
      <div
        v-for="(value, key) in $tm('contracts.filters.risk_options')"
        :key="key"
      >
        <GeneralInput
          v-if="value !== ''"
          v-model:data="risk[key]"
          bold
          greenBg
          noBoxes
          type="checkbox"
          name="risk"
          class="cursor-pointer"
        >
          <template #label>
            <div class="w-4 flex items-center justify-center no-highlight ">
              {{ $tm('contracts.filters.risk_options.' + key) }}
            </div>
          </template>
        </GeneralInput>
      </div>
    </div>
    <p class="text-left text-sm font-semibold text-grey-3 mb-3">
      {{ $t('contracts.filters.payment_status_title') }}
    </p>
    <GeneralInput
      v-model:data="paymentStatus"
      separatedRadio
      bgColor="bg-grey-ghost"
      type="radio"
      name="status"
      :options="$tm('contracts.filters.payment_status_options')"
    />
    <p class="text-left text-sm font-semibold text-grey-3 mb-3">
      {{ $t('contracts.filters.category_title') }}
    </p>
    <GeneralInput
      v-for="(value, key) in $tm('projects.category_options')"
      :key="key"
      v-model:data="categories[key]"
      bold
      type="checkbox"
      name="category"
      greenBg
      :label="value"
      class="p-4 rounded-2xl bg-grey-ghost mb-3"
    />
    <GpButton @click="filter" class=" lg:fixed lg:bottom-6 lg:left-1/2 lg:transform lg:-translate-x-1/2">
      {{ $t('contracts.filters.apply') }}
    </GpButton>
  </div>
</template>

<script>
import { ref, computed } from '@vue/reactivity'
import GeneralInput from '@/components/general/Input.vue'
import GpButton from '@/components/general/GpButton.vue'
import { useStore } from 'vuex'
export default {
  emits: ['filter'],
  components: {
    GeneralInput,
    GpButton
  },
  setup(props, { emit }) {
    const store = useStore()

    const previousFilters = computed(() => {
      return store.state.contracts.filters
    })

    const paymentStatus = ref(
      previousFilters.value && previousFilters.value.paymentStatus
        ? previousFilters.value.paymentStatus
        : null
    )

    const risk = ref(
      previousFilters.value && previousFilters.value.risk
        ? previousFilters.value.risk
        : {
            0: null,
            4: null,
            5: null,
            6: null,
            7: null,
            8: null,
            9: null,
            10: null
          }
    )

    const categories = ref(
      previousFilters.value && previousFilters.value.categories
        ? previousFilters.value.categories
        : {
            sustainable_energy: null,
            blue_economy: null,
            social_economy: null,
            business_in_transition: null,
            green_use_of_land: null
          }
    )

    const wallets = ref(
      previousFilters.value && previousFilters.value.wallets
        ? previousFilters.value.wallets
        : null
    )
    const walletsLabel = ref(null)
    if (store.state.wallets.wallets) {
      if (!wallets.value) wallets.value = store.getters.getWalletsOptions()
      walletsLabel.value = store.getters.getWalletsLabels()
    }

    const filter = () => {
      const filters = {
        paymentStatus: paymentStatus.value,
        risk: risk.value,
        categories: categories.value,
        wallets: wallets.value,
      }
      store.dispatch('setContractFilters', filters)
      emit('filter')
      store.dispatch('setShowPopup', false)
    }

    const clear = () => {
      store.dispatch('setContractFilters', null)
      paymentStatus.value = null
      risk.value = {
        0: null,
        4: null,
        5: null,
        6: null,
        7: null,
        8: null,
        9: null,
        10: null
      }
      categories.value = {
        sustainable_energy: null,
        blue_economy: null,
        social_economy: null,
        business_in_transition: null,
        green_use_of_land: null
      }
      wallets.value = store.getters.getWalletsOptions()
      emit('filter')
      store.dispatch('setShowPopup', false)
    }

    return {
      paymentStatus,
      filter,
      risk,
      clear,
      categories,
      wallets,
      walletsLabel
    }
  }
}
</script>

<style lang="scss" scoped></style>
