<template>
  <div class="p-6 rounded-3xl overflow-hidden relative transition-all duration-300" :class="{'shadow-2xl' : !noShadow, 'bg-green-base': template == 'christmas', 'bg-grey-giftcard': template !== 'christmas'}">
    <transition name="page">
      <GiftcardChristmasWhite style="transform: scale(1.8); position:absolute; right:0" v-if="template == 'christmas'"/>
      <div v-else>
        <img src="@/assets/svg/yellow-ball-2.svg" alt="" class="absolute -right-4 -top-4 w-16">
        <img src="@/assets/svg/giftcard-illustration.svg" alt="" class="absolute w-36 top-1/2 right-6 -translate-y-1/2 transform">
      </div>
    </transition>
    <!-- <div
      v-if="physical"
      class="bg-bordeaux-light text-white px-3 py-1 rounded-lg absolute top-6 left-16 w-max mb-2 text-xs"
    >
      Physical Card
    </div> -->
    <div class="mb-1 text-2xl">
      <div
        class="mb-2 font-semibold transition-all duration-300"
        :class="name ? template == 'christmas' ? 'text-white' : 'text-blue-base' :  template == 'christmas' ? 'text-green-holiday' : 'text-grey-giftcard-template'"
      >
        {{ name ? name : $t('giftcards.card.name') }}
      </div>
      <div class="font-bold flex transition-all duration-300" :class="amount ? template == 'christmas' ? 'text-white' : 'text-orange-giftcard' :  template == 'christmas' ? 'text-green-holiday' : 'text-grey-giftcard-template'">
        $ {{ amount ? amount : $t('giftcards.card.amount') + ' ' }}
      </div>
    </div>
    <div class="text-xs w-3/4 font-medium transition-all duration-300" :class="template == 'christmas' ? 'text-white' : 'text-blue-base'">
      {{ $t('giftcards.card.title') }}
      <div>{{ $t('giftcards.card.subtitle') }}</div>
    </div>
  </div>
</template>
<script>
import GiftcardChristmasWhite from "@/components/giftcards/GiftcardChristmasWhite";
export default {
  components:{
    GiftcardChristmasWhite,
  },
	props: {
		name: {
			type: String,
			default: ''
		},
		amount: {
			type: String,
			default: ''
		},
    noShadow: {
      type: Boolean,
      default: false
    },
    template: {
      type: String,
      default: null
    },
		physical: {
			type: Boolean,
			default: false
		}
	},
	setup () {}
}
</script>
