<template>
  <div 
    class="min-h-screen md:h-auto px-0 lg:px-24 container overflow-y-hidden relative" 
    style="padding-bottom: 0px"
  >
    <div>
      <FormPage 
        name="onboarding"
      />
    </div>
  </div>
</template>

<script>
import FormPage from "@/components/general/FormPage";
import form from "@/assets/ts/onboarding/base";
import companyForm from "@/assets/ts/onboarding/company";
import { defineComponent, computed, watch } from "vue";
import store from "@/store";

export default defineComponent({
  components: {
    FormPage
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      store.dispatch('setForm', form)
    });
  },

  setup(){
    const storeData = computed(() => {
      return store.state.onboarding.data
    })

    watch(
      () => storeData.value.type,
      () => {
        if (storeData.value.type === 'particular') {
          store.dispatch('setForm', form )
        } else {
          store.dispatch('setForm', companyForm )
        }
      }
    )
  }
})
</script>