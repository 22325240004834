<template>
  <div class="page flex">
    <div v-if="wallets && wallets.length" class="lg:pt-20 container flex-grow">
      <div class="flex">
        <div>
          <Back :to="'/settings'" :title="$t('settings.wallets.title')" />
        </div>
        <img
          class="-mt-8 lg:mt-4 ml-2 cursor-pointer"
          src="@/assets/svg/info.svg"
          alt="info"
          @click="openPopup"
        />
      </div>

      <!-- Popup -->
      <div class="hidden">
        <div class="text-left" id="popup-wallets-details">
          <p class="font-bold text-base lg:text-lg text-grey-2 mb-4">
            {{ $t('settings.wallets.popup.title') }}
          </p>

          <p class="text-13 text-grey-2 mb-4">
            {{ $t('settings.wallets.popup.subtitle') }}
          </p>

          <div class="flex">
            <img
              class="self-start mr-1"
              src="@/assets/svg/small_arrow.svg"
              alt="arrow icon"
            />
            <p class="text-13 text-grey-2 lg:text-grey-4 mb-4">
              {{ $t('settings.wallets.popup.about_project_term') }}
            </p>
          </div>

          <div class="flex">
            <img
              class="self-start mr-1"
              src="@/assets/svg/small_coin.svg"
              alt="coin icon"
            />
            <p class="text-13 text-grey-2 lg:text-grey-4 mb-4">
              {{ $t('settings.wallets.popup.about_fees') }}
            </p>
          </div>

          <div class="flex">
            <img
              class="self-start mr-1"
              src="@/assets/svg/small_waiting.svg"
              alt="waiting icon"
            />
            <p class="text-13 text-grey-2 lg:text-grey-4">
              {{ $t('settings.wallets.popup.about_transfers') }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="wallets">
        <SettingsWalletElement
          v-for="(wallet, index) of wallets"
          :key="wallet.id"
          :wallet="wallet"
          :index="index"
          @click="getWalletDetails(wallet)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Back from '@/components/general/Back.vue'
import SettingsWalletElement from '@/components/settings/WalletElement.vue'

export default {
  components: {
    Back,
    SettingsWalletElement
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const loading = computed(() => {
      return store.state.general.loading
    })

    //For now, promoter just see projects' wallets
    const wallets = computed(() => {
      return store.state.wallets.promoterWallets
    })

    //To uncomment when promoter is able to access the investment wallet
    // const cookies = inject('vue-cookie')
    // const profile = ref(cookies.getCookie('profile'))
    // const wallets = computed(() => {
    //   if (profile.value.promoter_id) {
    //     return store.state.wallets.wallets.concat(
    //       store.state.wallets.promoterWallets
    //     )
    //   }
    //   return store.state.wallets.wallets
    // })

    onMounted(async () => {
      if (!wallets.value || (wallets.value && !wallets.value.length)) {
        store.dispatch('setLoading', true)
        await store.dispatch('fetchWallets')
        store.dispatch('setLoading', false)
      }
    })

    const getWalletDetails = (wallet) => {
      store.dispatch('setLoading', true)
      router.push({
        name: 'settings-wallet-details',
        params: { wallet: JSON.stringify(wallet) }
      })
      store.dispatch('setLoading', false)
    }

    const openPopup = () => {
      const popup = {
        align: 'center',
        side: true,
        content: document.getElementById('popup-wallets-details').outerHTML
      }
      store.dispatch('setPopup', popup)
    }

    return {
      wallets,
      getWalletDetails,
      openPopup,
      loading
    }
  }
}
</script>

<style lang="postcss" scoped></style>
