<template>
  <div class="steps">
    <p class="steps__title--mobile ml-5 md:ml-0">{{$t('auto_investment.setup.interest_rate.title')}}</p>
    <p class="text-sm text-grey-2 font-semibold lg:font-normal text-opacity-80 text-left mb-12">{{$t('auto_investment.setup.interest_rate.subtitle')}}</p>
    <Slider
      v-model="interestRate"
      :min="2"
      :max="8"
      :step="1"
      :tooltips="true"
      showTooltip="drag"
      :format="formatTooltip"
      class="slider-auto-investment"
    />
    <div class="flex justify-between mt-6">
      <p class="font-bold text-grey-2 text-base">2%</p>
      <p class="font-bold text-grey-2 text-base">8%</p>
    </div>
    <p class="text-sm text-grey-3 bg-grey-7 p-4 mt-8 rounded-3xl">
      {{ $t('auto_investment.setup.interest_rate.info_box') }} {{roundToOneDecimal(avgInterestRate, 1)}}%
    </p>
    <GpButton
      mobileWidth="w-4/5"
      :disabled="!interestRate"
      class="absolute bottom-20 lg:bottom-8 lg:right-24 mx-auto lg:m-0"
      @click="next"
    >
      {{$t('onboarding.continue')}}
    </GpButton>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import GpButton from '@/components/general/GpButton.vue'
import Slider from '@vueform/slider'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'

export default {
  components: {
    GpButton,
    Slider
  },
  setup(props, { emit }) {
    const store = useStore()
    const interestRate = ref([2, 8])
    const { roundToOneDecimal } = useGlobalHelpers()

    const openPopup = () => {
      const popup = {
        align: 'center',
        side: true,
        content: document.getElementById('popup-content-auto-investment-details')
          .outerHTML
      }
      store.dispatch('setPopup', popup)
	  }

    const avgInterestRate = computed(() => {
			return store.state.metrics.metrics ? store.state.metrics.metrics.avg_interest_rate : 0
		})

    async function next() {
      store.dispatch('updateNewAutoInvestment', {
        min_interest: interestRate.value[0],
        max_interest: interestRate.value[1],
      })
      emit('next')
    }

    const formatTooltip = (value) => {
      return `${Math.round(value)}%`
    }

    return {
      openPopup,
      next,
      formatTooltip,
      interestRate,
      avgInterestRate,
      roundToOneDecimal
    }
  }
}
</script>
