<template>
  <div class="page flex">
    <SuccessCard
      v-if="hasChanged"
      :title="$t('settings.discard_changes.title')"
      :text="$t('settings.discard_changes.content')"
      :closeable="false"
    >
      <template #icon>
        <img
          src="@/assets/svg/exclamation_grey_border.svg"
          alt="exclamation icon"
        />
      </template>
      <template #button>
        <div class="lg:grid lg:grid-cols-2 lg:gap-5">
          <GpButton
            color="white"
            class="mb-4 mx-auto"
            desktopWidth="w-full"
            @click="discard"
          >
            {{ $t('wording.discard_changes') }}
          </GpButton>
          <GpButton
            @click="() => (hasChanged = false)"
            class="mx-auto"
            desktopWidth="w-full"
          >
            {{ $t('wording.no_go_back') }}
          </GpButton>
        </div>
      </template>
    </SuccessCard>
    <div
      v-else
      class="lg:pt-20 container flex-grow relative min-p-button flex flex-col"
    >
      <div @click="checkChanges">
        <Back :to="null" :title="$t('settings.suitability.title')" />
      </div>
      <!-- v-slot="{ errors, meta }" -->
      <Form
        v-if="suitability"
        class="form flex flex-col flex-grow justify-between"
        ref="form"
      >
        <p class="text-left mb-2 text-grey-3">
          {{ $t('settings.suitability.sub_types.' + profile.sub_type) }}
        </p>
        <p
          class="text-left mb-6 text-grey-3 text-sm"
          v-html="$t('settings.suitability.headings.' + profile.sub_type)"
        />
        <div v-if="suitability.is_expired" class="flex items-start">
          <div class="h-2 w-2 relative mr-2 mt-1.5 flex items-start">
            <div
              class="
                animate-ping
                absolute
                h-full
                w-full
                rounded-full
                bg-red-primary
                opacity-75
              "
            />
            <div class="relative rounded-full h-2 w-2 bg-red-primary" />
          </div>
          <p class="text-left mb-6 text-red-primary text-sm">
            {{ $t('settings.suitability.expiration_warning') }}
          </p>
        </div>
        <div>
          <div class="grid grid-cols-2 gap-3 mb-6">
            <div
              v-for="(value, key) of previousInvestments"
              :key="key"
              class="w-full flex items-center rounded-2xl p-4"
              :class="previousInvestments[key] ? 'bg-green-5' : 'bg-white'"
            >
              <GeneralInput
                v-model:data="previousInvestments[key]"
                type="checkbox"
                name="previous_investments"
                noMargin
                greenBg
                bold
                :label="$t('onboarding.fields.previous_investments.' + key)"
              />
            </div>
          </div>
          <GeneralInput
            v-model:data="suitability.employment"
            type="text"
            name="employment"
            :label="$t('settings.suitability.steps.fields.employment')"
          />
          <GeneralInput
            v-model:data="suitability.employment_status"
            type="multiselect"
            name="employment_status"
            :label="$t('settings.suitability.steps.fields.job_status')"
            :options="$tm('onboarding.fields.statuses')"
          />
          <GeneralInput
            v-model:data="suitability.investing_exp"
            type="multiselect"
            name="investing_exp"
            :label="$t('settings.suitability.steps.fields.experience')"
            :options="$tm('onboarding.fields.exp_levels')"
          />
          <GeneralInput
            v-model:data="suitability.goal"
            type="multiselect"
            name="goal"
            :label="$t('settings.suitability.steps.fields.goal')"
            :options="$tm('onboarding.fields.goals_indv')"
          />
          <!-- risk -->
          <GeneralInput
            v-model:data="suitability.risk_level"
            type="multiselect"
            :options="$tm('ca_legislation.suitability.risk_profile.options')"
            name="risk_level"
            :label="$t('settings.suitability.steps.fields.risk')"
          />
          <GeneralInput
            v-model:data="suitability.net_worth"
            type="dollar"
            name="net_worth"
            rules="required|minValue:0|maxValue:1000000000"
            :label="$t('settings.suitability.steps.fields.worth')"
          />
          <GeneralInput
            v-model:data="suitability.assets_value"
            type="dollar"
            name="assets_value"
            rules="required|minValue:0|maxValue:1000000000"
            :label="$t('settings.suitability.steps.fields.assets')"
          />
          <GeneralInput
            v-model:data="suitability.personal_income"
            type="dollar"
            name="personal_income"
            rules="required|minValue:0|maxValue:1000000000"
            :label="$t('settings.suitability.steps.fields.income')"
          />
          <GeneralInput
            v-model:data="suitability.save_monthly"
            type="dollar"
            name="save_monthly"
            rules="required|minValue:0|maxValue:1000000000"
            :label="$t('settings.suitability.steps.fields.save')"
          />
          <GeneralInput
            v-model:data="suitability.investment_term"
            type="multiselect"
            name="investment_term"
            :options="$tm('ca_legislation.suitability.investment_term.options')"
            :label="$t('settings.suitability.steps.fields.length')"
          />
          <GeneralInput
            v-model:data="suitability.portfolio_percentage"
            type="multiselect"
            name="portfolio_percentage"
            :options="$tm('ca_legislation.suitability.percent.options')"
            :label="$t('settings.suitability.steps.fields.percent')"
          />
          <GeneralInput
            v-model:data="suitability.investment_reason"
            type="multiselect"
            name="investment_reason"
            :options="$tm('ca_legislation.suitability.reason.options')"
            :label="$t('settings.suitability.steps.fields.reason')"
          />
          <GeneralInput
            v-model:data="suitability.other_primary_income"
            type="checkbox"
            name="other_primary_income"
            :label="$t('settings.suitability.steps.fields.p_income')"
          />
          <GeneralInput
            v-model:data="suitability.not_insider"
            type="checkbox"
            name="not_insider"
            :label="$t('settings.suitability.steps.fields.insider')"
          />
          <GeneralInput
            v-model:data="suitability.no_borrowed"
            type="checkbox"
            name="no_borrowed"
            :label="$t('settings.suitability.steps.fields.borrowed')"
          />
          <GeneralInput
            v-model:data="suitability.self_info"
            type="checkbox"
            name="self_info"
            :label="$t('settings.suitability.steps.fields.self')"
          />
          <GeneralInput
            v-model:data="suitability.tcp_form"
            type="checkbox"
            name="tcp_form"
            :label="$t('settings.suitability.steps.fields.tcp')"
          />
        </div>
        <!-- :disabled="/*!(isDirty || meta.dirty) || !meta.valid || loading*/" -->
        <GpButton
          v-if="suitability.is_expired && !isDirty"
          @click="updateSuitability(true)"
          class="ml-auto"
        >
          {{ $t('wording.confirm') }}
        </GpButton>
        <GpButton
          v-else
          @click="updateSuitability()"
          class="ml-auto"
          :disabled="!isDirty"
        >
          {{ $t('settings.personal_details.success_btn') }}
        </GpButton>
        <!-- <GpButton
                :disabled="!(isDirty || meta.dirty) || !meta.valid || loading"
                @click="updateDetails"
                class="absolute bottom-8 right-0"
              >
                {{ $t('settings.personal_details.success_btn') }}
              </GpButton> -->
      </Form>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import SuccessCard from '@/components/general/SuccessCard.vue'
import GeneralInput from '@/components/general/Input.vue'
import useSuitability from '@/mixins/useSuitability.js'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import useProfile from "@/mixins/useProfile";

export default {
  components: {
    GeneralInput,
    Back,
    GpButton,
    SuccessCard
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { updateProfile } = useProfile()
    const settingsConfig = require("@/config/settings.config.js");

    let previousInvestments = ref({
      etfs: false,
      bonds: false,
      mutual_funds: false,
      real_estate: false,
      stocks: false,
      term_deposits: false,
      alternative_investments:false
    })
    const suitability = ref(null)
    const hasChanged = ref(false)
    const { suitabilityLevel } = useSuitability()
    const { dollarToNumberFormatting, dollarFormatting } =
      useGlobalHelpers()

    onMounted(async () => {
      await store.dispatch('setRequestSettings', settingsConfig.toString())
      await store.dispatch('fetchProfile')
      await store.dispatch('fetchSuitability')

      storedSuitability.value.net_worth = dollarFormatting(storedSuitability.value.net_worth)
      storedSuitability.value.assets_value = dollarFormatting(storedSuitability.value.assets_value)
      storedSuitability.value.personal_income = dollarFormatting(storedSuitability.value.personal_income)
      storedSuitability.value.save_monthly = dollarFormatting(storedSuitability.value.save_monthly)
      suitability.value = JSON.parse(JSON.stringify(storedSuitability.value))

      // Set previous_investments
      storedSuitability.value.previous_investments?.split(',').map((i) => {
        if(i != '')
        previousInvestments.value[i] = true
      })
    })

    watch(previousInvestments.value, async () => {
      let previous_investments_array = []
      for (const investment in previousInvestments.value) {
        if (previousInvestments.value[investment]) {
          previous_investments_array.push(investment)
        }
      }
      suitability.value.previous_investments =
        previous_investments_array.toString()
    })

    const storedSuitability = computed(() => {
      return store.state.suitability.suitability
    })

    const settings = computed(() => {
      return store.state.general.settings
    })

    const updateSuitability = (confirmation = false) => {
      store.dispatch('setLoading', true)
      delete suitability.value.is_expired
      suitability.value.net_worth = dollarToNumberFormatting(suitability.value.net_worth, true)
      suitability.value.assets_value = dollarToNumberFormatting(suitability.value.assets_value, true)
      suitability.value.personal_income = dollarToNumberFormatting(suitability.value.personal_income, true)
      suitability.value.save_monthly = dollarToNumberFormatting(suitability.value.save_monthly, true)

      store
        .dispatch('updateSuitability', suitability.value)
        .then(async () => {store.dispatch('fetchProfile').then(() => {
            store.dispatch('setLoading', false)
            window.location.reload()
          })
        })
        .catch(() => {
          store.dispatch('setLoading', false)
        })
    }

    const checkChanges = () => {
      if (isDirty.value === true) {
        hasChanged.value = true
      } else {
        router.go(-1)
      }
    }

    const isDirty = computed(() => {
      return (
        JSON.stringify(suitability.value) !==
        JSON.stringify(storedSuitability.value)
      )
    })

    const discard = () => {
      router.go(-1)
    }

    const profile = computed(() => {
      return store.getters.getProfile()
    })

    return {
      suitability,
      previousInvestments,
      updateSuitability,
      checkChanges,
      discard,
      hasChanged,
      isDirty,
      profile
    }
  }
}
</script>

<style lang="sass" scoped></style>
