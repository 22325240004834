<template>
  <div class="page flex flex-col items-end">
    <SideBar v-if="!isEdit">
      <FlowProgress step="3.3" copy="strategies.progress" flow="strategies" />
    </SideBar>
    <img
      v-if="!isEdit"
      src="/img/signup/loved_one.png"
      alt="loved one picture"
      class="hidden lg:block fixed bottom-0 left-40 w-72 z-0 h-72"
    />
    <div
      v-if="!changed"
      :class="
        !isEdit
          ? 'strategies__desktop-container justify-between'
          : 'container flex-grow flex flex-col justify-between lg:pt-20'
      "
    >
      <div :class="{ 'lg:w-3/4 xl:w-2/3 2xl:w-1/2': !isEdit }">
        <div class="mt-2 mb-4">
          <Back
            :to="isEdit ? -1 : '/strategies/investment'"
            :title="$t('strategies.setup.max_amount.title')"
          />
        </div>
        <ProgressBar class="lg:hidden" v-if="!isEdit" :step="5" :total="7" />
        <p v-if="!isEdit" class="text-sm text-grey-2 text-left opacity-80 mt-6">
          {{ $t('strategies.setup.max_amount.text') }}
          <span class="orange-link" @click="openPopup">
            {{ $t('wording.here') }}.</span
          >
        </p>
        <!-- Comment -->
        <div class="hidden">
          <div class="text-left" id="popup-content-max-amount">
            <p
              v-for="(value, key) in $tm('strategies.setup.max_amount.popup')"
              :key="key"
              class="
                text-grey-2 text-sm
                odd:font-bold odd:mb-4
                even:text-opacity-50 even:mb-9
                last:mb-0
                first:text-base
              "
            >
              {{ value }}
            </p>
          </div>
        </div>
        <GeneralInput
          class="mt-6"
          v-model:data="max"
          type="dollar"
          name="amount"
          :label="$t('strategies.setup.max_amount.placeholder')"
          :locked="noMax"
        />
        <div class="flex mb-4">
          <div
            v-for="(el, i) in maxAmounts"
            v-bind:key="el"
          >
            <div
              class="
                text-13 text-orange-1
                flex-initial
                font-bold
                px-4
                py-2
                bg-white
                rounded-xl
                mr-3
                cursor-pointer
                mt-3
                lg:mt-0
              "
              :class="{ 'cursor-not-allowed': noMax, 'hidden lg:flex': i === maxAmounts.length - 1 }"
              @click="
                () => {
                  if (!noMax) max = dollarFormatting(el)
                }
              "
            >
              ${{ numberFormatting(dollarFormatting(el)) }} 
            </div>
          </div>
        </div>
        <GeneralInput
          v-model:data="noMax"
          class="custom-checkbox"
          type="checkbox"
          name="noProjectMax"
        >
          <template #label>
            <span class="ml-2">
              {{ $t('strategies.setup.max_amount.no_limit') }}
            </span>
          </template>
        </GeneralInput>
      </div>
      <div
        v-if="!isEdit"
        class="lg:flex lg:justify-evenly lg:items-center lg:w-3/4 xl:w-2/3 2xl:w-1/2"
      >
        <a @click="skip" color="white" class="text-orange-1 font-semibold text-base uppercase mb-4 lg:mb-0">
          {{ $t('strategies.setup.max_amount.skip_btn') }}
        </a>
        <GpButton :disabled="!max && !noMax" @click="selectMaxAmount">
          {{ $t('wording.next') }}
        </GpButton>
      </div>
      <div v-else>
        <p class="text-sm text-grey-2 opacity-60 mb-4">
          {{ $t('strategies.edit.project_max.text') }}
        </p>
        <div class="lg:flex lg:justify-between">
          <a @click="confirm(true)" color="white" class="text-orange-1 font-semibold text-base uppercase">
            {{ $t('strategies.edit.project_max.skip_btn') }}
          </a>
          <GpButton :disabled="!max && !noMax" @click="confirm(false)">
            {{ $t('wording.change') }}
          </GpButton>
        </div>
      </div>
    </div>
    <ChangesConfirmation
      v-else
      @discard="discard"
      :displayData="displayData"
      :data="confirmationData"
      :text="$t('strategies.edit.direct_debit.text')"
      icon="project_max"
      :strategyId="strategy.id"
    />
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import SideBar from '@/components/general/SideBar.vue'
import GeneralInput from '@/components/general/Input.vue'
import ProgressBar from '@/components/general/ProgressBar.vue'
import FlowProgress from '@/components/general/FlowProgress.vue'
import { ref, computed, watch, onBeforeMount } from 'vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import ChangesConfirmation from '@/components/strategies/ChangesConfirmation.vue'
import { useStore } from 'vuex'
import useFlows from '@/mixins/useFlows.js'
export default {
  components: {
    Back,
    GeneralInput,
    ProgressBar,
    ChangesConfirmation,
    GpButton,
    FlowProgress,
    SideBar
  },
  setup() {
    const i18n = useI18n({ useScope: 'global' })
    const max = ref(null)
    const router = useRouter()
    const store = useStore()
    const route = useRoute()
    const isEdit = ref(route.query.edit)
    const changed = ref(false)
    const confirmationData = ref({})
    const displayData = ref([])
    const { dollarFormatting, numberFormatting } = useGlobalHelpers()
    const maxAmounts = ref([5000, 7500, 15000, 25000, 50000])
    const noMax = ref(false)

    const { noNewStrategy } = useFlows()

    const strategy = computed(() => {
      return store.state.strategies.strategy
    })

    if (!isEdit.value) noNewStrategy()
    else {
      if (!strategy.value) {
        router.go(-1)
      }
    }

    onBeforeMount(() => {
      // Set amount
      if (
        isEdit.value &&
        strategy.value &&
        store.state.strategies.strategy.auto_investments.length > 0
      ) {
        strategy.value.auto_investments[0].project_max
          ? (max.value = dollarFormatting(
              strategy.value.auto_investments[0].project_max
            ))
          : (noMax.value = true)
      }
    })

    const selectMaxAmount = () => {
      store.dispatch('updateNewStrategy', {
        max_amount_per_project: noMax.value
          ? null
          : parseInt(max.value.replace(/\D/g, ''))
      })
      nextStep()
    }

    const skip = () => {
      store.dispatch('updateNewStrategy', {
        max_amount_per_project: null
      })
      nextStep()
    }

    const nextStep = () => {
      router.push({
        path: '/settings/bank-accounts',
        query: { strategies: 1 }
      })
    }

    const confirm = (clear) => {
      changed.value = true
      confirmationData.value = {
        project_max: clear ? null : parseInt(max.value) * 100
      }
      if (clear) {
        displayData.value.push({
          label: i18n.t('strategies.edit.project_max.no_amount'),
          value: ''
        })
      } else {
        let value = '$' + noMax.value ? 'N/A' : max.value 

        displayData.value.push({
          label: i18n.t('strategies.edit.menu.project_max'),
          value: value
        })
      }
    }

    const discard = () => {
      changed.value = false
      confirmationData.value = {}
      displayData.value = []
      max.value = null
    }

    const openPopup = () => {
      const popup = {
        align: 'center',
        side: true,
        content: document.getElementById('popup-content-max-amount').outerHTML
      }
      store.dispatch('setPopup', popup)
    }

    watch(
      () => noMax.value,
      () => {
        if (noMax.value) max.value = null
      }
    )

    return {
      max,
      skip,
      selectMaxAmount,
      isEdit,
      confirm,
      changed,
      confirmationData,
      displayData,
      discard,
      openPopup,
      strategy,
      maxAmounts,
      dollarFormatting,
      numberFormatting,
      noMax
    }
  }
}
</script>

<style lang="scss" scoped></style>
