<template>
  <div>
    <demography-map class="-mx-6 md:mx-auto" />
    <div
      class="
        bg-white
        md:bg-transparent
        -mx-6
        md:mx-auto
        px-6
        md:px-0
        -mt-6
        md:mt-0
        pt-6
        md:pt-0
        pb-4
        md:pb-0
      "
    >
      <div
        class="text-left mt-6 text-grey-2 font-medium"
        v-html="
          $t(
            'impact.demography.invested_in',
            {
              countries: numberOfCountries
            },
            numberOfCountries
          )
        "
      ></div>
      <template v-if="numberOfCountries > 2">
        <span class="text-left text-grey-2 font-medium hidden md:block">{{
          $t('impact.demography.top_3')
        }}</span>
        <div class="text-13 text-grey-4 text-left mt-1 mb-6 block md:hidden">
          {{ $t('impact.demography.top_3_mobile') }}
        </div>
      </template>

      <div class="md:grid grid-cols-3 gap-4 mt-4" v-if="impact.project_countries">
        <div
          v-for="(el, key) in impact.project_countries.slice(0, 3)"
          v-bind:key="key"
          class=""
        >
          <country-card :projects="el.value" :country_code="el.code" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DemographyMap from '@/components/impact/demography/Map'
import CountryCard from '@/components/impact/demography/CountryCard'
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  components: { DemographyMap, CountryCard },

  setup() {
    const store = useStore()

    const impact = computed(() => {
      return store.state.impact.impact
    })

    const numberOfCountries = computed(() => {
      return impact.value.project_countries && Object.keys(impact.value.project_countries) ? Object.keys(impact.value.project_countries).length : 0
    })

    return { impact, numberOfCountries }
  }
}
</script>

<style lang="css" scoped></style>
