<template>
  <div class="page flex">
    <SuccessCard
      v-if="hasChanges"
      :title="$t('settings.discard_changes.title')"
      :text="$t('settings.discard_changes.content')"
      :closeable="false"
    >
      <template #icon>
        <img
          src="@/assets/svg/exclamation_grey_border.svg"
          alt="exclamation icon"
        />
      </template>
      <template #button>
        <div class="lg:grid lg:grid-cols-2 lg:gap-5">
          <GpButton
            color="white"
            class="mb-4 mx-auto"
            desktopWidth="w-full"
            @click="discard"
          >
            {{ $t('wording.discard_changes') }}
          </GpButton>
          <GpButton @click="cancel" class="mx-auto" desktopWidth="w-full">
            {{ $t('wording.no_go_back') }}
          </GpButton>
        </div>
      </template>
    </SuccessCard>
    <div v-else class="lg:pt-20 container flex-grow min-p-button relative">
      <div @click="checkChanges">
        <Back :to="null" :title="$t('settings.email_notifications.title')" />
      </div>
      <div v-if="notifications">
        <div
          v-for="notification_group in notificationsSchema"
          v-bind:key="notification_group"
          class="pt-9 lg:pt-6"
        >
          <div
            class="
              font-semibold
              text-grey-2 text-sm
              lg:text-base
              text-left
              mb-8
            "
          >
            {{
              $t(
                'settings.email_notifications.groups.' +
                  notification_group.group +
                  '.title'
              )
            }}
          </div>
          <div
            v-for="setting in notification_group.settings"
            v-bind:key="setting"
            class="mb-6 text-sm text-grey-3"
          >
            <div class="flex justify-between">
              <div class="mr-4 text-left">
                {{
                  $t(
                    'settings.email_notifications.groups.' +
                      notification_group.group +
                      '.' +
                      setting
                  )
                }}
                <div
                  v-if="
                    typeof $tm(
                      'settings.email_notifications.subtext.' +
                        notification_group.group +
                        '.' +
                        setting
                    ) === 'string'
                  "
                  class="text-grey-4 text-xs mb-4 mt-1"
                >
                  {{
                    $t(
                      'settings.email_notifications.subtext.' +
                        notification_group.group +
                        '.' +
                        setting
                    )
                  }}
                </div>
              </div>
              <general-input
                v-model:data="notifications[setting]"
                type="toggle"
                class="align-middle"
                :name="setting"
                v-if="notification_group.group !== 'legal'"
              />
            </div>
            <hr class="mt-1" />
          </div>
        </div>
        <div
          class="font-semibold text-grey-2 text-sm lg:text-base text-left mb-4"
        >
          {{ $t('settings.email_notifications.groups.legal.title') }}
        </div>
        <div class="text-left text-grey-3 sm:text-13 text-sm mb-8 md:w-3/4">
          {{ $t('settings.email_notifications.groups.legal.legal') }}
        </div>

        <div
          class="
            absolute
            bottom-0
            w-screen
            container
            left-1/2
            -translate-x-1/2
            transform
          "
        >
          <div class="w-full relative">
            <GpButton
              :disabled="!hadChanges || loading"
              @click="submit"
              class="absolute bottom-8 right-0"
            >
              {{ $t('settings.email_notifications.submit') }}
            </GpButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import GeneralInput from '@/components/general/Input.vue'
import SuccessCard from '@/components/general/SuccessCard.vue'

export default {
  components: {
    GeneralInput,
    Back,
    GpButton,
    SuccessCard
  },
  setup() {
    const store = useStore()
    const i18n = useI18n()
    const router = useRouter()
    const hadChanges = ref(false)
    const hasChanges = ref(null)
    const loaded = ref(false)
    let notifications = ref({})

    const notificationsSchema = ref([
      {
        group: 'news',
        settings: [
          'promotional_new_investment',
          'promotional_new_offer',
          'promotional_newsletter',
          'promotional_discounts'
        ]
      },
      {
        group: 'transactions',
        settings: [
          'transactions_monthly_payments',
          'transactions_payments_received',
          'transactions_proof',
          // 'transactions_autoinvestment',
          // 'transactions_savings'
        ]
      }
    ])

    const loading = computed(() => {
      return store.state.general.loading
    })

    onMounted(async () => {
      await store.dispatch('getNotifications')
      notifications.value = store.state.notifications.notifications
    })

    const submit = async function () {
      store.dispatch('setLoading', true)
      await store.dispatch('updateNotifications', notifications.value)
      store.dispatch('setLoading', false)
      const popup = {
        title: i18n.t('settings.email_notifications.success_popup.title'),
        align: 'center',
        side: false,
        iconBg: 'bg-green-5',
        icon: '/img/done_step.svg',
        primaryButton: i18n.t('settings.email_notifications.success_popup.btn')
      }
      store.dispatch('setPopup', popup)
      router.push({name: 'settings'})
      hadChanges.value = false
    }

    watch(
      () => notifications,
      () => {
        if (loaded.value) {
          hadChanges.value = true
        } else {
          loaded.value = true
        }
      },
      { deep: true }
    )

    const checkChanges = () => {
      if (hadChanges.value === true) {
        hasChanges.value = true
      } else {
        router.go(-1)
      }
    }

    const discard = () => {
      router.go(-1)
    }

    const cancel = () => {
      hasChanges.value = false
    }

    return {
      notificationsSchema,
      notifications,
      loading,
      hadChanges,
      submit,
      checkChanges,
      hasChanges,
      discard,
      cancel
    }
  }
}
</script>

<style lang="sass" scoped></style>
