import i18n from "@/i18n";
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import { Types } from "@/assets/ts/enums/form/types";
import handle_suitability from "@/assets/ts/investor_type/callbacks/handle_suitability";
const order_income = ['_0', '_2500000', '_5000000', '_7500000', '_12500000', '_20000000', '_30000000'];
const order_worth = ['_0', '_500000', '_2000000', '_5000000', '_10000000', '_40000000', '_100000000', '_500000000'];
const order_assets = ['_10000', '_100000', '_250000', '_500000', '_1500000', '_2500000', '_5000000', '_10000000', '_50000000', '_100000000'];
const order_save = ['_0', '_100', '_25000', '_50000', '_100000', '_200000'];

const questions:PageForm =
  {
    title: i18n.global.t('ca_legislation.investor_classification.sidebar_title'),
    image: 'img/lamp-sidebar.png',
    form: [
      {
        title: i18n.global.t("ca_legislation.investor_classification.questions.title"),
        fields: [
            {
              key: "personal_income",
              title: i18n.global.t("ca_legislation.investor_classification.questions.personal_income_title"),
              subtitle: i18n.global.t("ca_legislation.investor_classification.questions.personal_income_text"),
              options: order_income.reduce((obj, key) => {
                // @ts-ignore
                return {...obj, [key]: i18n.global.tm("ca_legislation.investor_classification.questions.personal_income_options")[key]};
              }, {}),
              type: Types.RADIO,
              rules: 'required'
            },
            {
              key: "net_worth",
              title: i18n.global.t("ca_legislation.investor_classification.questions.net_worth_title"),
              subtitle: i18n.global.t("ca_legislation.investor_classification.questions.net_worth_text"),
              options: order_worth.reduce((obj, key) => {
                // @ts-ignore
                return {...obj, [key]: i18n.global.tm("ca_legislation.investor_classification.questions.net_worth_options")[key]};
              }, {}),
              type: Types.RADIO,
              rules: 'required'
            },
            {
              key: "assets_value",
              title: i18n.global.t("ca_legislation.investor_classification.questions.assets_value_title"),
              subtitle: i18n.global.t("ca_legislation.investor_classification.questions.assets_value_text"),
              options: order_assets.reduce((obj, key) => {
                // @ts-ignore
                return {...obj, [key]: i18n.global.tm("ca_legislation.investor_classification.questions.assets_value_options")[key]};
              }, {}),
              type: Types.RADIO,
              rules: 'required'
            },
            {
              key: "save_monthly",
              title: i18n.global.t("ca_legislation.investor_classification.questions.save_monthly_title"),
              subtitle: i18n.global.t("ca_legislation.investor_classification.questions.save_monthly_text"),             
              options: order_save.reduce((obj, key) => {
                // @ts-ignore
                return {...obj, [key]: i18n.global.tm("ca_legislation.investor_classification.questions.save_monthly_options")[key]};
              }, {}),type: Types.RADIO,
              rules: 'required'
            },
            {
              key: "employment_status",
              title: i18n.global.t("ca_legislation.investor_classification.questions.employment_status_title"),
              subtitle: i18n.global.t("ca_legislation.investor_classification.questions.employment_status_text"),
              type: Types.RADIO,
              options: i18n.global.tm("ca_legislation.investor_classification.questions.options_status"),
              rules: 'required'
            },
            {
              key: "employment",
              title: i18n.global.t("ca_legislation.investor_classification.questions.employment_title"),
              subtitle: i18n.global.t("ca_legislation.investor_classification.questions.employment_text"),
              type: Types.STRING,
              rules: 'required'
            }
        ],
        next: true,
        nextCallback: handle_suitability,
        back: true
      }
    ]
  }

export default questions
