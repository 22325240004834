<template>
  <div v-if="profile">
    <div
      @click="isBorrower
          ? ''
          : profile.phone
          ? goToWithdraw(wallet)
          : phoneNumberPopup()
      "
      class="flex items-center justify-between text-grey-2  text-sm
            font-semibold  px-6 pt-3
            rounded-t-2xl
            lg:text-opacity-80 lg:font-normal hover:bg-yellow-6 hover:text-grey-1"
      :class="isBorrower
          ? 'cursor-not-allowed opacity-50'
          : 'cursor-pointer'
      "
    >
      <div>
        <div class="flex my-6 lg:my-3">
          <img
            class="mr-4"
            src="@/assets/svg/home/withdraw.svg"
            alt="withdraw"
          />
          <p

          >
            {{ $t('home.drawer.withdraw') }}
          </p>
        </div>
      </div>

      <img
        src="@/assets/svg/arrows/right_grey_arrow.svg"
        alt="right"
        class="cursor-pointer lg:hidden"
      />
    </div>
    <div class="lg:hidden h-px w-full bg-grey-2 bg-opacity-10" />
    <!-- Autoinvestment -->
    <!-- <router-link
      v-if="!wallet.saving_id"
      to="auto-investment"
      class="flex items-center justify-between text-grey-2  text-sm
            font-semibold
            px-6
            lg:text-opacity-80 lg:font-normal hover:bg-yellow-6 hover:text-grey-1 px-6"
      :class="'opacity-50 cursor-not-allowed'"
    >
      <div class="flex my-6 lg:my-3">
        <img
          class="mr-4"
          src="@/assets/svg/home/autoinvestment.svg"
          alt="autoinvestment"
        />
        <p>
          <span v-if="autoinvestmentsCount <= 0">{{ $t('home.drawer.autoinvestment_create') }}</span>
          <span v-else>{{ $t('home.drawer.autoinvestment_edit') }}</span>
          
        </p>
        <p
                class="
                  ml-2
                  text-grey-2
                  font-semibold
                  text-xs
                  px-2
                  py-0.5
                  rounded
                  bg-orange-3
                "
              >
                {{ $t('wording.soon') }}
              </p>
      </div>
      <img
        src="@/assets/svg/arrows/right_grey_arrow.svg"
        alt="right"
        class="lg:hidden"
      />
    </router-link> -->
    <!-- <div class="lg:hidden h-px w-full bg-grey-2 bg-opacity-10" /> -->
    <!-- Strategy -->
    <!-- <router-link
      v-if="!isStrategy"
      :to="!isRegular || strategiesCount > 0 ? '' : '/strategies/add-strategy'"
      class="flex items-center justify-between text-grey-2  text-sm
            font-semibold
            px-6 pb-3
            rounded-b-2xl
            lg:text-opacity-80 lg:font-normal hover:bg-yellow-6 hover:text-grey-1"
      :class="!isRegular || strategiesCount > 0 ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'"
    >
      <div class="flex my-6 lg:my-3">
        <img class="mr-4" src="@/assets/svg/home/strategy.svg" alt="strategy" />
        <p
        >
          {{ $t('home.drawer.strategy_create') }}
        </p>
      </div>
      <img
        src="@/assets/svg/arrows/right_grey_arrow.svg"
        alt="right"
        class="lg:hidden"
      />
    </router-link> -->
    <!-- <router-link
      v-else
      :to="!isRegular || strategiesCount <= 0 || !activeStrategy ? '' : '/strategies/edit/' + slug"
      class="flex items-center justify-between text-grey-2  text-sm
            font-semibold
            px-6 pb-3
            rounded-b-2xl
            lg:text-opacity-80 lg:font-normal hover:bg-yellow-6 hover:text-grey-1"
      :class="!isRegular || strategiesCount <= 0 || !activeStrategy ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'"
    >
      <div class="flex my-6 lg:my-3">
        <img class="mr-4" src="@/assets/svg/home/strategy.svg" alt="strategy" />
        <p
        >
          {{ $t('home.drawer.strategy_edit') }}
        </p>
      </div>
      <img
        src="@/assets/svg/arrows/right_grey_arrow.svg"
        alt="right"
        class="lg:hidden"
      />
    </router-link> -->
  </div>
</template>

<script>
import { computed } from 'vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    hasMoney: {
      type: Boolean,
      default: false
    },
    isStrategy: {
      type: Boolean,
      default: false
    },
    // isRegular: {
    //   type: Boolean,
    //   default: false
    // },
    strategyId: {
      type: Number,
      default: -1
    },
    strategyName: {
      type: String,
      default: ''
    },
    wallet: {
      type: Object,
      default: () => {}
    },
    strategiesCount: {
      type: Number,
      default: 0
    },
    autoinvestmentsCount: {
      type: Number,
      default: 0
    },
    activeStrategy: {
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const router = useRouter()
    const { slugIt } = useGlobalHelpers()
    const slug =
      props.strategyName && props.strategyName !== ''
        ? slugIt(props.strategyName) + '-' + props.strategyId
        : props.strategyId
    const store = useStore()
    const i18n = useI18n({ useScope: 'global' })
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const isBorrower = computed(() => {
      if (profile.value && profile.value.sub_type === 'borrower')
        return true
      return false
    })

    function goToWithdraw(wallet) {
      router.push({
        name: 'withdraw',
        params: {
          wallet: JSON.stringify(wallet)
        }
      })
    }

    function goToAddPhoneNumber() {
      router.push({
        path: '/settings/add-phone-number'
      })
    }

    function phoneNumberPopup() {
      const popup = {
        title: i18n.t('withdraw.popup.title'),
        align: 'center',
        side: false,
        icon: '/img/popup/clock-grey.svg',
        content: i18n.t('withdraw.popup.content'),
        secondaryButton: i18n.t('wording.not_now'),
        primaryButton: i18n.t('withdraw.popup.btn'),
        secondaryLink: '/dashboard',
        primaryLink: '/settings/add-phone-number'
      }
      store.dispatch('setPopup', popup)
    }

    return {
      slug,
      profile,
      isBorrower,
      goToWithdraw,
      goToAddPhoneNumber,
      phoneNumberPopup
    }
  }
}
</script>

<style lang="scss" scoped></style>
