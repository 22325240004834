<template>
  <div>
    <div class="flex items-center">
      <div
        class="w-24 h-24 bg-no-repeat bg-center bg-cover rounded-3xl mr-6"
        :style="'background-image: url(\'' + `/img/${manager.photo}` + '\')'"
      ></div>
      <div class="text-left">
        <div
          v-html="$t('promoter.account_manager.title', { name: manager.name })"
          class="text-grey-3 w-56 mb-1"
        ></div>
        <a
          href="mailto:operations-ca@goparity.com"
          class="uppercase text-orange-1 font-bold text-sm cursor-pointer"
        >
          {{ $t('promoter.account_manager.help') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    manager: Object
  },
  setup() {
    return {}
  }
}
</script>
