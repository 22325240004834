import axios from 'axios'
import { useStore } from 'vuex'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
export default {
  state: {
    projects: [],
    promoter_projects: [],
    has_next_page: true,
    next_page: 1,
    fetched: [],
    order: null,
    fetching: false,
    isCampaign: false,
    type: 'projects',
    project: null
  },
  mutations: {
    SET_PROJECTS(state, projects) {
      state.projects = state.projects.concat(projects)
    },
    SET_IS_CAMPAIGN(state, isCampaign) {
      state.isCampaign = isCampaign
    },
    SET_FETCHING(state, fetching) {
      state.fetching = fetching
    },
    SET_PROMOTER_PROJECTS(state, promoter_projects) {
      state.promoter_projects =
        state.promoter_projects.concat(promoter_projects)
    },
    SET_PROJECT(state, project) {
      state.project = project
    },
    SET_TYPE(state, type) {
      state.type = type
    },
    CLEAR_PROJECTS(state) {
      state.projects = []
    },
    CLEAR_FETCHED(state) {
      state.fetched = []
    },
    SET_FETCHED(state, page) {
      state.fetched.push(page)
    },
    SET_NEXT_PAGE(state, next_page) {
      state.next_page = next_page
    },
    SET_HAS_NEXT_PAGE(state, has_next_page) {
      state.has_next_page = has_next_page
    },
    SET_ORDER(state, order) {
      state.order = order
    }
  },
  actions: {
    getPromoterProjects({ commit, state }, promoter_id) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/' + state.type, {
          params: {
            'filter[promoter_id]': promoter_id
          }
        })
        .then((projects) => {
          commit('SET_PROMOTER_PROJECTS', projects.data.data)
          return projects.data.data
        })
    },
    getProjects({ commit, state }) {
      if (!state.fetched.includes(state.next_page)) {
        commit('SET_FETCHING', true)
        commit('SET_FETCHED', state.next_page)
        return axios
          .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/' + state.type , {
            params: {
              page: state.next_page,
              sort: state.order ?? 'created_at',
              'filter[state]': state.isCampaign
                ? ''
                : 'campaign,comingsoon,reserved'
            }
          })
          .then((projects) => {
            commit('SET_PROJECTS', projects.data.data)
            commit('SET_HAS_NEXT_PAGE', projects.data.next_page_url)
            commit('SET_NEXT_PAGE', state.next_page + 1)
            commit('SET_FETCHING', false)
            return projects.data.data
          })
          .catch((e) => {
            commit('SET_ERROR', e)
          })
      }
    },
    getCampaign({ commit }, id) {
      const { isInt } = useGlobalHelpers()
      const store = useStore()
      if (isInt(id))
        return axios
          .get(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/campaigns/' +
              parseInt(id)
          )
          .then((data) => {
            commit('SET_PROJECT', data.data)
          }).catch((e)=>{
            if(e.response){
              if(e.response.status === 404)
                window.location.href = '/404'
            }
          })
      else
        store.dispatch('setError', {
          response: {
            custom: true,
            status: 500,
            data: {
              code: 'invalid_project'
            }
          }
        })
    },
    getProject({ commit }, id) {
      const { isInt } = useGlobalHelpers()
      const store = useStore()
      if (isInt(id))
        return axios
          .get(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/projects/' +
              parseInt(id)
          )
          .then((data) => {
            commit('SET_PROJECT', data.data)
          }).catch((e)=>{
            if(e.response){
              if(e.response.status === 404)
                window.location.href = '/404'
            }
          })
      else
        store.dispatch('setError', {
          response: {
            custom: true,
            status: 500,
            data: {
              code: 'invalid_project'
            }
          }
        })
    },
    resetProjects({ commit, dispatch }) {
      commit('CLEAR_PROJECTS')
      commit('SET_HAS_NEXT_PAGE', true)
      commit('SET_NEXT_PAGE', 1)
      commit('CLEAR_FETCHED')
      dispatch('getProjects')
    },
    setIsCampaign({ commit }, isCampaign) {
      commit('SET_IS_CAMPAIGN', isCampaign)
    },
    setOrder({ commit, dispatch }, order) {
      commit('SET_ORDER', order)
      dispatch('resetProjects')
    },
    setType({ commit, dispatch }, type) {
      commit('SET_TYPE', type)
      dispatch('resetProjects')
    },
    setFetching({ commit }, fetching) {
      commit('SET_FETCHING', fetching)
    }
  },
  getter: {}
}
