<template>
  <div class="md:page min-h-screen">
    <div class="md:pt-24">
      <div class="md:container">
        <div class="relative">
          <ImpactCard v-if="!isLoading" />
          <div class="project-card project-card--skeleton h-80" v-else>
            <div
              class="
                project-card__header project-card__header--skeleton
                h-full
                rounded-3xl
              "
            ></div>
          </div>
          <!--div class="hidden" ref="image">
            <ImpactCard no-animation />
          </div-->
          <!--div
            class="
              p-3
              bg-white
              absolute
              right-8
              z-50
              rounded-xl
              cursor-pointer
              md:top-8
              bottom-8
              md:bottom-auto
            "
            @click="shareImage"
          >
            <img
              src="@/assets/svg/orange_share.svg"
              alt=""
              class="hidden md:block"
            />
            <div
              class="
                block
                md:hidden
                text-13
                font-bold
                text-orange-1
                uppercase
                px-3
                tracking-widest
              "
            >
              {{ $t('wording.share') }}
            </div>
          </div-->
        </div>
      </div>
      <div class="mt-12 container">
        <div class="flex">
          <div
            v-for="(section, index) in sections"
            v-bind:key="section"
            class="mr-6 md:mr-12 cursor-pointer text-sm md:text-base"
            :class="{
              'font-bold': section === activeSection,
              'text-grey-4': section !== activeSection
            }"
            @click="changeSection(section)"
            :id="section"
          >
            {{ sectionsTitle[index] }}
          </div>
        </div>
        <div class="relative" ref="barParent">
          <div
            class="w-6 h-1 bg-orange-3 absolute bottom-0 transition-all"
            :style="'left:' + left + 'px'"
          ></div>
          <div class="h-0.5 w-full bg-grey-6 mt-4"></div>
        </div>
      </div>

      <div class="mt-8 container pb-24 md:pb-8">
        <component :is="'Impact' + activeSection" />
      </div>
      <!--div class="block lg:hidden"><MobileSubNavbar /></div-->
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'

import ImpactCard from '@/components/impact/Card.vue'
import ImpactContribution from '@/components/impact/Contribution'
import ImpactDemography from '@/components/impact/Demography'
import ImpactImpact from '@/components/impact/Impact'
import MobileSubNavbar from '@/components/general/MobileSubNavbar'
import { useI18n } from "vue-i18n";
export default {
  components: {
    ImpactCard,
    MobileSubNavbar,
    ImpactContribution,
    ImpactDemography,
    ImpactImpact
  },
  setup() {
    const i18n = useI18n({ useScope: 'global' })

    const image = ref(null)
    const showPopup = ref(true)
    const store = useStore()
    const sectionsTitle = ref(i18n.tm('impact.sections'))
    const sections = ref([
      "Contribution",
      "Impact",
      "Demography"
    ])
    const activeSection = ref(sections.value[0])
    const isLoading = ref(true)
    const left = ref(0)
    const barParent = ref(null)
    const type = computed(() => {
      return store.state.projects?.type
    })

    function shareImage() {
      store.dispatch('setShare', image.value)
    }

    function changeType(type) {
      store.dispatch('setType', type)
    }

    async function changeSection(section) {
      activeSection.value = section
      const timer = (ms) => new Promise((res) => setTimeout(res, ms))
      await timer(1)
      left.value =
        document.getElementById(section).offsetLeft - barParent.value.offsetLeft
    }

    onMounted(async () => {
      await store.dispatch('fetchImpact')
      isLoading.value = false

      if (location.hash) {
        const val = location.hash.replace('#', '')
        if (sections.value.includes(val)) changeSection(val)
      }
    })

    return {
      type,
      changeType,
      showPopup,
      image,
      shareImage,
      sections,
      sectionsTitle,
      activeSection,
      left,
      barParent,
      isLoading,
      changeSection
    }
  }
}
</script>
