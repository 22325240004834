<template>
  <div class="page flex flex-col justify-between px-6">
    <div class="container flex-grow flex flex-col justify-between lg:pt-20">
      <div class="text-left">
        <img
          class="mb-4 mt-8"
          src="@/assets/svg/error-page-icon.svg"
          alt="error"
        />
        <p class="text-grey-2 font-medium mb-8 text-lg">
          {{ $t('errors.something_wrong') }}
        </p>
        <p class="text-grey-2 text-opacity-80 text-sm mb-4">
          {{ $t('errors.sorry') }}
        </p>
        <p class="text-grey-2 text-opacity-80 text-sm">
          {{ isCustom ? $t('errors.custom.' + message) : ($t('errors.api.' + message + '.title') + ': ' + $t('errors.api.' + message + '.description')) }}
        </p>
      </div>
      <GpButton @click="close" class="ml-auto">
        {{ $t('errors.try_again') }}
      </GpButton>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import useError from '@/mixins/useError.js'
import { useStore } from 'vuex'
import GpButton from '@/components/general/GpButton.vue'
export default {
  components: {
    GpButton
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const { errorMessage } = useError()

    if (!store.state.error.error) router.go(-1)

    const error = computed(() => {
      return store.state.error.error
    })

    const close = () => {
      router.push({name: 'dashboard'})
    }

    const message = computed(() => {
      return errorMessage()
    })

    const isCustom = computed(() => {
      return store.state.error.error.custom
    })

    return {
      close,
      error,
      message,
      isCustom
    }
  }
}
</script>

<style lang="scss" scoped></style>
