<template>
  <div
    class="
      w-full
      bg-white
      rounded-2xl
      my-4
      p-4
      lg:max-w-3/4
      xl:max-w-2/3
      2xl:max-w-1/2
    "
    :class="{
      'cursor-pointer transition ease-in-out duration-200 transform hover:shadow-light hover:-translate-y-1': !locked
    }"
    @click="selectTopupMethod"
  >
    <div class="flex justify-between">
      <div class="flex">
        <img
          class="self-start mt-1 lg:hidden"
          :src="require('@/assets/svg/strategies/' + method + iconColor)"
          alt=""
        />
        <img
          class="self-start mt-1 hidden lg:block"
          :src="require('@/assets/svg/strategies/' + method + iconColorDesktop)"
          alt=""
        />
        <div class="ml-2 lg:ml-6">
          <div
            class="text-grey-2 text-sm lg:text-base font-bold text-left mb-1"
            :class="
              locked ? 'text-opacity-50' : 'text-opacity-90 lg:text-opacity-100'
            "
          >
            {{ $t('strategies.setup.topup_method.' + method + '.title') }}
          </div>
          <div
            class="text-left text-grey-2 text-xs lg:text-sm"
            :class="locked ? 'opacity-20' : 'opacity-60'"
          >
            {{ $t('strategies.setup.topup_method.' + method + '.text') }}
          </div>
        </div>
      </div>
      <img
        v-if="!locked"
        src="@/assets/svg/arrows/right_grey_arrow.svg"
        alt="right arrow"
        class="ml-2"
      />
      <div v-else>
        <p
          class="
            text-grey-2
            font-semibold
            text-xs
            px-2
            py-0.5
            rounded
            bg-orange-3
          "
        >
          {{ $t('wording.soon') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  props: {
    method: {
      type: String,
      default: ''
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const iconColor = ref(props.locked ? '_grey.svg' : '_orange.svg')
    const iconColorDesktop = ref(
      props.locked ? '_grey_desktop.svg' : '_green.svg'
    )
    const store = useStore()
    const router = useRouter()

    const selectTopupMethod = () => {
      if (!props.locked) {
        store.dispatch('updateNewStrategy', {
          topupMethod: props.method
        })
        router.push('/strategies/why-saving')
      }
    }

    return {
      iconColor,
      iconColorDesktop,
      selectTopupMethod
    }
  }
}
</script>

<style lang="scss" scoped></style>
