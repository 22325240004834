import { VueCookieNext } from 'vue-cookie-next'
// import useSecurity from '@/mixins/useSecurity'

export default function profileType({ to, next, router }) {
  if (!VueCookieNext.isCookieAvailable('profile')) {
    return router.push({name: 'login'})
  }
  // const { decrypt } = useSecurity()
  let profile = VueCookieNext.getCookie('profile')
  // if (profile) profile = decrypt(profile)
  // profile = JSON.parse(profile)
  if (to.meta.type !== profile.type) {
    return router.push({ name: to.meta.fallback })
  } else if (to.meta.particular === 'true' && profile.promoter_id) {
    return router.push({ name: to.meta.fallback })
  }
  return next()
}
