
import axios from 'axios'
export default function activate({ to, router }) {
  const code = to.params.code
  axios
    .post(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/activate/' + code)
    .then(() => {
      router.replace('/login?activated=true')
    })
    .catch(() => {
      router.replace('/login?activated=false')
    })
}
