import { useStore } from 'vuex'

export default function () {
  const serverError = (error) => {
    if (error) {
      const status = error.response ? error.response.status : null
      if (status === 500 || status === 429) {
        return true
      }
    }
    return false
  }

  const errorMessage = () => {
    const store = useStore()
    const error = store.state.error.error

    if (error) {
      const status = error.response ? error.response.status : null
      if (status === 401) {
        if (error.config.url.search(/api\/login/g) !== -1)
          return 'invalid_credentials'
        else if (error.config.url.search(/api\/signup/g) !== -1)
          return 'taken_email'
        else return 'failed_auth'
      } else {
        if (error.response && error.response.data && error.response.data.errors) {
          if (error.response.data.errors.length > 1)
            return error.response.data.errors
          else if (Array.isArray(error.response.data.errors))
            return error.response.data.errors[0]
          else return error.response.data.errors.code
        }
        if(error.response && error.response.data)
          return error.response.data.code
        return error.response
      }
    }
    return false
  }

  return {
    serverError,
    errorMessage
  }
}
