import axios from 'axios'
import { VueCookieNext } from 'vue-cookie-next'
import { useStore } from 'vuex'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'

export default {
  state: {
    newStrategy: {},
    strategies: null,
    strategy: null
  },
  mutations: {
    RESET_NEW_STRATEGY(state) {
      state.newStrategy = {}
    },
    UPDATE_NEW_STRATEGY(state, data) {
      state.newStrategy = {
        ...state.newStrategy,
        ...data
      }
    },
    SET_STRATEGIES(state, data) {
      state.strategies = data
    },
    COOKIE_STORE_NEW_STRATEGY(state) {
      VueCookieNext.setCookie('newStrategy', state.newStrategy, {
        expire: '2m',
        path: '/',
        domain: '',
        secure: true
      })
    },
    SET_STRATEGY(state, data) {
      state.strategy = data
    }
  },
  actions: {
    resetNewStrategy({ commit }) {
      commit('RESET_NEW_STRATEGY')
    },
    updateNewStrategy({ commit }, data) {
      commit('UPDATE_NEW_STRATEGY', data)
    },
    cookieStoreNewStrategy({ commit }) {
      commit('COOKIE_STORE_NEW_STRATEGY')
    },
    // eslint-disable-next-line no-empty-pattern
    createStrategy({}, newStrategy) {
      return axios.post(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/strategies',
        newStrategy
      )
    },
    fetchStrategies({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/strategies', {
          params: {
            'filter[status]': 1
          }
        })
        .then((data) => {
          commit('SET_STRATEGIES', data.data)
        })
    },
    fetchStrategy({ commit, getters }, strategy) {
      const store = useStore()
      const { isInt } = useGlobalHelpers()

      if (isInt(strategy)) {
        return axios
          .get(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/strategies/' +
              strategy
          )
          .then((data) => {
            if (data.data.profile_id === getters.getProfile().profile_id)
              commit('SET_STRATEGY', data.data)
            else
              store.dispatch('setError', {
                response: {
                  custom: true,
                  status: 500,
                  data: {
                    code: 'unauthorized_strategy'
                  }
                }
              })
          })
          .catch((err) => {
            store.dispatch('setError', err)
            store.dispatch('forceRedirect', '/dashboard')
          })
      } else
        store.dispatch('setError', {
          response: {
            custom: true,
            status: 500,
            data: {
              code: 'unauthorized_strategy'
            }
          }
        })
    },
    updateStrategy({ commit }, strategy) {
      return axios
        .put(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/strategies/' +
            strategy.id,
          strategy.data
        )
        .then((data) => {
          commit('SET_STRATEGY', data.data)
        })
    }
  },
  getter: {}
}
