import store from '@/store/index'
import i18n from "@/i18n";
import { subYears } from 'date-fns'

const handle_onboarding = () =>
{
  return new Promise((resolve, reject) =>{

    const obj = {
      ...store.state.general.formData,
    }
    // to avoid auto complete rename vars
    obj.country = obj.countryLocation

    store.dispatch('setLoading', true)
    obj.date_of_birth = obj.date_of_birth.toISOString().slice(0, 10)
    store.dispatch('add', obj)
    store.dispatch('submitProfile')
      .then(() => {
        store.dispatch('setForm', null)
        store.dispatch('clearFormData')
        store.dispatch('setLoading', false)
        if(obj.type == 'particular'){
          resolve('done')
          window.location.href = '/suitability'
        }else{
          window.location.href = '/onboarding'
        }
      })
      .catch((e) => {
        store.dispatch('setLoading', false)
        store.dispatch('setError', e)
        window.location.href = '/onboarding'
      })
  })
}

const set_type = () =>
{
  return new Promise((resolve, reject) =>{

    store.dispatch('setLoading', true)

    const obj = {
      ...store.state.general.formData,
    }
    store.dispatch('add', {type: obj.type})
    store.dispatch('setLoading', false)

  })

}

const handle_age = () =>
{
  return new Promise((resolve, reject) =>{

    const obj = {
      ...store.state.general.formData,
    }

    if(!obj.date_of_birth){
      const popup = {
        title: i18n.global.t('ca_legislation.onboarding.age.title'),
        align: 'center',
        side: false,
        icon: '/img/popup/clock-grey.svg',
        content: i18n.global.t('ca_legislation.onboarding.age.content')
      } 
      store.dispatch('setPopup', popup)
    }
    else if(obj.date_of_birth < subYears(new Date(), 65)){
      const popup = {
        align: 'center',
        side: false,
        content: i18n.global.t('errors.age.description'),
        title: i18n.global.t('errors.age.title'),
        icon: '/img/popup/clock-grey.svg',
        primaryButton: i18n.global.t('onboarding.confirm')
      } 
      store.dispatch('setPopup', popup)
    }
    obj.date_of_birth = obj.date_of_birth.toISOString().slice(0, 10)
  })

}

export { handle_onboarding, set_type, handle_age}
