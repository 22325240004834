<template>
  <div>
    <div class="grid mb-4 text-grey-4 text-xs grid-cols-3 lg:grid-cols-4">
      <p class="text-left hidden lg:flex">
        {{ $t('contracts.details.payment_plan.payment_n') }}
      </p>
      <p class="text-left">
        {{ $t('contracts.details.payment_plan.date') }}
      </p>
      <p class="text-right">
        {{ $t('contracts.details.payment_plan.payment') }}
      </p>
      <p class="text-right">
        {{ $t('contracts.details.payment_plan.outstanding') }}
      </p>
    </div>
    <div v-for="(payment, index) in payments" :key="index">
      <div
        class="grid py-6 grid-cols-3 lg:grid-cols-4"
        :class="{
          'lg:bg-white lg:rounded-3xl lg:-mx-6 lg:px-6':
            full &&
            payment.payment_n ===
              contract.upcoming_payments.next_payment.payment_n
        }"
      >
        <div class="flex items-start">
          <img
            v-if="
              payment.state === 'paid' ||
              contract.contract_label === 'marketplace_sell'
            "
            src="@/assets/svg/contracts/tick-green.svg"
            alt="success"
            class="mt-1 mr-2"
          />
          <div
            v-else-if="
              payment.payment_n ===
              contract.upcoming_payments.next_payment.payment_n
            "
            class="h-2 w-2 relative mr-2 mt-2"
          >
            <div
              class="
                animate-ping
                absolute
                h-full
                w-full
                rounded-full
                bg-green-2
                opacity-75
              "
            />
            <div class="relative rounded-full h-2 w-2 bg-green-2" />
          </div>
          <div>
            <p
              class="text-sm text-left"
              :class="
                payment.state === 'paid'
                  ? 'text-grey-4 font-medium'
                  : payment.payment_n ===
                    contract.upcoming_payments.next_payment.payment_n
                  ? 'text-grey-2 font-semibold'
                  : 'text-grey-3'
              "
            >
              {{
                $t('contracts.details.payment_plan.payment_n') +
                payment.payment_n
              }}
            </p>
            <p class="text-xs text-grey-4 text-left lg:hidden">
              {{
                dateFormatting(
                  new Date(payment.schedule_date.replace(/-/g, '/')),
                  '/',
                  true
                )
              }}
            </p>
          </div>
        </div>
        <p class="text-xs text-grey-4 text-left hidden lg:flex">
          {{
            dateFormatting(
              new Date(payment.schedule_date.replace(/-/g, '/')),
              '/',
              true
            )
          }}
        </p>
        <div
          class="text-right text-sm"
          :class="
            payment.state === 'paid'
              ? 'text-grey-4'
              : payment.payment_n ===
                contract.upcoming_payments.next_payment.payment_n
              ? 'text-green-1 font-bold'
              : 'text-grey-1'
          "
        >
          $ {{ dollarFormatting(payment.instalment_investor_net) }}
        </div>
        <div
          class="text-right text-sm text-grey-2"
          :class="
            payment.state === 'paid'
              ? 'text-grey-4'
              : payment.payment_n ===
                contract.upcoming_payments.next_payment.payment_n
              ? 'font-bold'
              : ''
          "
        >
          + $ {{ dollarFormatting(payment.capital_outstanding) }}
        </div>
      </div>
      <div :class="{ 'lg:px-6': full }">
        <div
          v-if="index !== payments.length - 1"
          class="hidden lg:block w-full h-px bg-grey-6"
        />
      </div>
    </div>
    <GpButton
      v-if="full"
      color="white"
      class="ml-auto mt-8"
      @click="downloadXLS"
      :disabled="!downloadable"
    >
      {{ $t('contracts.details.payment_plan.download') }}
    </GpButton>
  </div>
</template>

<script>
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import axios from 'axios'
import GpButton from '@/components/general/GpButton.vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  components: {
    GpButton
  },
  props: {
    payments: {
      type: Array,
      default: () => []
    },
    contract: {
      type: Array,
      default: () => {}
    },
    full: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { dollarFormatting, dateFormatting } = useGlobalHelpers()
    const downloadable = ref(true)
    const store = useStore()
    const i18n = useI18n()

    const downloadXLS = () => {
      downloadable.value = false
      axios
        .get(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/contracts/' +
            parseInt(props.contract.id) +
            '/files',
          {
            params: { file_type: 'payment_plan' }
          }
        )
        .then(() => {
          const popup = {
            title: i18n.t('contracts.full_payment_plan.export_popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            content: i18n.t('contracts.full_payment_plan.export_popup.description'),
            primaryButton: i18n.t('contracts.full_payment_plan.export_popup.btn')
          }
          store.dispatch('setPopup', popup)
          downloadable.value = true
          store.dispatch('setLoading', false)
        })
        .catch((e) => {
          store.dispatch('setError', e)
          downloadable.value = true
          store.dispatch('setLoading', false)
        })
    }

    return {
      dollarFormatting,
      dateFormatting,
      downloadXLS,
      downloadable
    }
  }
}
</script>

<style lang="scss" scoped></style>
