<template>
  <div class="bg-white status-banner rounded-3xl">
    <div class="status__header py-5 px-6 overflow-hidden">
      <div class="lg:flex relative justify-between items-center">
        <div class="text-grey-2 text-left w-3/4 md:w-1/2">
          {{ $t('invite.invitations.banner.title') }}
        </div>
        <div
          class="text-grey-2 font-bold text-left text-4xl lg:text-center z-10"
        >
          <div
            class="
              md:absolute md:right-0 md:top-1/2
              transform
              md:-translate-y-1/2
              z-10
            "
          >
            ${{ dollarFormatting(vouchers.total) }} 
          </div>

          <div
            class="absolute balloon -bottom-32 z-0"
            :class="
              'right-' + right[Math.floor(Math.random() * right.length - 1)]
            "
            :style="
              '--delay:' +
              Math.floor(Math.random() * 2000) +
              'ms; --duration: ' +
              (Math.floor(Math.random() * 6000) + 3000) +
              'ms; --height: ' +
              (Math.floor(Math.random() * 6) + 2) +
              'rem'
            "
            v-for="index in 4"
            v-bind:key="'balloon-' + index"
          >
            <img src="@/assets/svg/balloon.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { format } from 'date-fns'
import useGlobalHelpers from "@/mixins/useGlobalHelpers";

export default {
  components: {},
  setup() {
    const store = useStore()
    const vouchers = ref([])
    const { dollarFormatting } = useGlobalHelpers()

    const right = ref([
      '4',
      '8',
      '12',
      '20',
      '16',
      '14',
      '24',
      '32',
      '56',
      '64',
      '72',
      '80',
      '96'
    ])
    onMounted(async () => {
      if (!store.state.vouchers.vouchersSample)
        await store.dispatch('getVouchersSample')
      vouchers.value = store.state.vouchers.vouchersSample
    })

    function formatDate(date) {
      return format(date, 'dd/MM/Y')
    }

    return {
      vouchers,
      right,
      dollarFormatting,
      formatDate
    }
  }
}
</script>
<style scoped>
.status-banner {
  background: rgba(79, 89, 99, 0.08);
}

.status-banner__share {
  width: fit-content;
}

.balloon {
  animation: animate-balloon ease-in both infinite;
  animation-delay: var(--delay);
  animation-duration: var(--duration);
  z-index: 0;
}
.balloon img {
  height: var(--height);
}

@keyframes animate-balloon {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateY(-300px);
  }
}
</style>
