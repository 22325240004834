<template>
  <div class="steps">
    <p class="steps__title--mobile ml-5 md:ml-0">{{$t('auto_investment.setup.risk_rating.title')}}</p>
    <p class="text-sm text-grey-2 font-semibold lg:font-normal text-opacity-80 text-left mb-12">{{$t('auto_investment.setup.risk_rating.subtitle')}}</p>
    <Slider
      v-model="riskRating"
      :min="0"
      :max="66.6"
      :step="11.1"
      :tooltips="true"
      :format="formatTooltip"
      class="slider-auto-investment"
    />
    <div class="flex justify-between mt-6">
      <p class="font-bold text-grey-2 text-base">A+</p>
      <p class="font-bold text-grey-2 text-base">C+</p>
    </div>
    <p class="text-sm text-grey-3 bg-grey-7 p-4 mt-8 rounded-3xl" v-html="$t('auto_investment.setup.risk_rating.info_box'+extra)" />
    <GpButton
      mobileWidth="w-4/5"
      class="absolute bottom-20 lg:bottom-8 lg:right-24 mx-auto lg:m-0"
      @click="next"
    >
      {{$t('onboarding.continue')}}
    </GpButton>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, watch } from 'vue'
import GpButton from '@/components/general/GpButton.vue'
import Slider from '@vueform/slider'

export default {
  components: {
    GpButton,
    Slider
  },
  setup(props, { emit }) {
    const store = useStore()
    const riskRating = ref(55.5)
    const extra = ref('')

    const riskLevel = (value) => {
      if (value === 0) return 'A+'
      else if (value === 11.1) return 'A'
      else if (value === 22.2) return 'A-'
      else if (value === 33.3) return 'B+'
      else if (value === 44.4) return 'B'
      else if (value === 55.5) return 'B-'
      else if (value === 66.6) return 'C+'
      else return 'D'
    }

    const riskConversion = (value) => {
      const rates = {
        0: 10,
        11.1: 9,
        22.2: 8,
        33.3: 7, 
        44.4: 6,
        55.5: 5,
        66.6: 4
      }
      return rates[value] ? rates[value] : null
    }

    async function next() {
      store.dispatch('updateNewAutoInvestment', {
        max_risk: riskConversion(riskRating.value)
      })
      emit('next')
    }

    const formatTooltip = (value) => {
      return riskLevel(value)
    }

    watch(
      () => riskRating,
      () => {
        if (riskRating.value == 0) {
          extra.value = '_a_plus'
        } else if (riskRating.value == 66.6){
          extra.value = '_c_plus'
        } else {
          extra.value = ''
        }
      },
      { deep: true }
    )

    return {
      next,
      formatTooltip,
      riskRating,
      riskLevel,
      riskConversion,
      extra
    }
  }
}
</script>

