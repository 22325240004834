<template>
  <div class="page h-screen lg:flex items-center container overflow-hidden">
    <GreenBall
      class="opacity-10 w-full lg:block hidden"
      start="bottom"
      offset="4rem"
      cutout="0"
    />
    <a href="https://goparity.ca" >

    <img
      src="@/assets/img/logo/goparity.svg"
      alt=""
      class="
        hidden
        lg:block
        absolute
        top-0
        left-0
        ml-4
        mt-4
        w-28
        sm:ml-10 sm:mt-10 sm:w-40
        z-50
      "
    />
    </a>
    <div
      class="
        max-w-xl
        mx-auto
        lg:py-12 lg:px-16
        lg:flex lg:flex-col
        h-full
        relative
        lg:h-5/6 lg:bg-white
        rounded-3xl
        max-h-160 lg:bg-opacity-60
      "
    >
      <div class="h-full flex flex-col justify-between">
        <div>
          <router-link class="lg:hidden absolute left-0 top-1" to="login">
            <img
              src="@/assets/svg/arrows/left_grey_arrow.svg"
              alt="left_arrow"
            />
          </router-link>
          <div
            class="
              mb-4
              text-lg
              lg:text-xl
              text-grey-2
              font-bold
              text-left
              ml-5
              lg:ml-0
            "
          >
            {{ $t('passwordForgot.title_underline') }}
          </div>
          <div class="w-full text-left text-grey-2 mb-6 text-sm">
            {{ $t('passwordForgot.text') }}
          </div>
          <Form v-slot="{ errors, meta }">
            <GeneralInput
              v-model:data="email"
              type="email"
              name="email"
              label="Email"
              rules="email|required"
              :error="errors.email"
            />
            <div class="absolute bottom-0 lg:bottom-12 width-button">
              <GpButton
                :disabled="!meta.valid"
                desktopWidth="w-full"
                class="flex lg:mb-12"
                @click="recoverPassword"
              >
                {{ $t('passwordForgot.btn_text') }}
              </GpButton>
              <span class="hidden lg:block text-grey-4 text-sm">
                {{ $t('wording.back_to') }}
                <router-link to="login" class="orange-link">
                  {{ $t('wording.login') }}
                </router-link>
              </span>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GpButton from '@/components/general/GpButton.vue'
import { ref, computed } from 'vue'
import { Form } from 'vee-validate'
import GeneralInput from '@/components/general/Input.vue'
import GreenBall from '@/components/general/GreenBall.vue'
import { useStore } from 'vuex'
import { useI18n } from "vue-i18n";
export default {
  components: {
    GpButton,
    Form,
    GeneralInput,
    GreenBall
  },
  setup() {
    const email = ref('')
    const done = ref(false)
    const store = useStore()
    const i18n = useI18n({ useScope: 'global' })

    const maskedEmail = computed(() => {
      return (
        email.value.substring(0, 2) +
        '******' +
        email.value.substring(email.value.indexOf('@'))
      )
    })

    const recoverPassword = () => {
      store.dispatch('setLoading', true)
      store
        .dispatch('forgotPassword', {
          email: email.value
        })
        .then(() => {
          store.dispatch('setLoading', false)
          const popup = {
            title: i18n.t('passwordForgot.success_title'),
            align: 'center',
            side: false,
            icon: '/img/popup/email-no-border.svg',
            content: i18n.t('passwordForgot.success_text',{email: maskedEmail.value}),
            primaryButton: i18n.t('passwordForgot.success_btn'),
            primaryLink: '/login',
            iconBg: 'bg-grey-8'
          }
          store.dispatch('setPopup', popup)
        })
        .catch((err) => {
          store.dispatch('setError', err)
        })
    }

    return {
      email,
      recoverPassword,
      maskedEmail,
      done
    }
  }
}
</script>

<style>
.width-button {
  width: calc(100% - 8rem);
}
@media (max-width: 1023px) {
  .width-button {
    width: 100%;
  }
}
</style>
