import info from "@/assets/ts/investor_type/steps/info";
import questions_company from "@/assets/ts/investor_type/steps/questions_company";

const form =
  {
      info,
      questions_company
  }

export default form
