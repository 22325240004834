<template>
  <div class="page flex flex-col items-end">
    <SideBar>
      <FlowProgress step="5" copy="strategies.progress" flow="strategies" />
    </SideBar>
    <img
      src="/img/signup/loved_one.png"
      alt="loved one picture"
      class="hidden lg:block fixed bottom-0 left-40 w-72 z-0 h-72"
    />
    <div class="strategies__desktop-container justify-between">
      <div v-if="!created" class="lg:w-3/4 xl:w-2/3 2xl:w-1/2">
        <div class="mt-2 mb-4">
          <Back
            :to="{
              path: '/settings/bank-accounts',
               
            }"
            :title="$t('strategies.setup.confirmation.title')"
          />
        </div>
        <ProgressBar class="lg:hidden" :step="7" :total="7" />
        <p class="hidden lg:block mt-4 text-left text-grey-2 text-opacity-80">
          {{ $t('strategies.setup.confirmation.description') }}
        </p>
        <!-- White box -->
        <div class="text-sm bg-white rounded-t-2xl mt-6 mb-0.5 lg:mb-0 p-4">
          <div class="flex justify-between mb-3 lg:mb-4">
            <div class="flex items-center">
              <p class="text-sm lg:text-base text-grey-3">
                {{ $t('strategies.setup.confirmation.dd_label') }}
              </p>
              <p class="ml-1 hidden lg:block text-grey-3">{{ '(' }}</p>
              <p class="text-11 lg:text-base text-grey-4 lg:text-grey-3 ml-1 lg:ml-0">
                {{ $t('wording.day') + ' ' + strategy.day_of_month }}
              </p>
              <p class="hidden lg:block text-grey-3">{{ ')' }}</p>
            </div>
            <p class="text-grey-2 font-semibold text-sm lg:text-base">
              ${{ dollarFormatting(strategy.amount) }}
            </p>
          </div>
          <div
            class="hidden lg:block w-full bg-grey-2 bg-opacity-10 h-px mb-4"
          />
          <div class="flex justify-between mb-3 lg:mb-4">
            <p class="text-sm lg:text-base text-grey-3">
              {{ $t('strategies.setup.confirmation.wallet_label') }}
            </p>
            <p class="text-grey-2 font-semibold text-sm lg:text-base">
              {{ strategy.wallet_percentage }}%
            </p>
          </div>
          <div
            class="hidden lg:block w-full bg-grey-2 bg-opacity-10 h-px mb-4"
          />
          <div class="flex justify-between">
            <p class="text-sm lg:text-base text-grey-3">
              {{ $t('strategies.setup.confirmation.risk_label') }}
            </p>
            <p class="text-grey-2 font-semibold text-sm lg:text-base">
              {{ levelRiskFinal(strategy.max_risk) }}
            </p>
          </div>
        </div>
        <!-- Grey box -->
        <div class="bg-white bg-opacity-60 rounded-b-2xl p-4 text-sm">
          <p
            class="
              text-grey-4
              text-xs text-left
              mb-3
              lg:mb-4
            "
          >
            {{ $t('strategies.setup.confirmation.estimate_title') }}
          </p>
          <div class="flex justify-between mb-3">
            <p class="text-sm lg:text-base text-grey-3 font-semibold lg:font-normal">
              {{ $t('strategies.setup.confirmation.interest_label') }}
            </p>
            <p class="text-grey-2 font-bold lg:font-semibold text-sm lg:text-base">
              ${{ dollarFormatting(interestRate) }}
            </p>
          </div>
          <div
            class="hidden lg:block w-full bg-grey-2 bg-opacity-10 h-px mb-4"
          />
          <div class="flex justify-between">
            <p class="text-sm lg:text-base text-grey-3 font-semibold lg:font-normal">
              {{ $t('strategies.setup.confirmation.assets_label') }}
            </p>
            <p class="text-grey-2 font-bold lg:font-semibold text-sm lg:text-base">
              ${{ dollarFormatting(totalAssets) }}
            </p>
          </div>
        </div>
        <GeneralInput
          class="mt-7"
          v-model:data="riskDisclaimer"
          type="checkbox"
          name="riskDisclaimer"
          :label="$t('strategies.setup.confirmation.risk_disclaimer_checkbox')"
        />
        <GeneralInput
          class="mt-7"
          v-model:data="ddDisclaimer"
          type="checkbox"
          name="ddDisclaimer"
          :label="$t('strategies.setup.confirmation.dd_disclaimer_checkbox')"
        />
      </div>
      <div v-else>
        <p class="text-3xl text-grey-2 text-opacity-50 ml-5 mt-24 text-left">
          {{ $t('strategies.setup.confirmation.success_text') }}
        </p>
      </div>
      <div class="lg:flex lg:justify-end lg:w-3/4 xl:w-2/3 2xl:w-1/2">
        <GpButton v-if="!created" :disabled="!accepted" @click="createStrategy">
          {{ $t('strategies.setup.confirmation.btn') }}
        </GpButton>
        <router-link to="/dashboard" v-else>
          <GpButton>
            {{ $t('wording.confirm') }}
          </GpButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import SideBar from '@/components/general/SideBar.vue'
import GeneralInput from '@/components/general/Input.vue'
import ProgressBar from '@/components/general/ProgressBar.vue'
import FlowProgress from '@/components/general/FlowProgress.vue'
import useFlows from '@/mixins/useFlows.js'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import useStrategies from '@/mixins/useStrategies.js'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { ref, computed, onBeforeMount } from 'vue'
export default {
  components: {
    Back,
    GeneralInput,
    ProgressBar,
    GpButton,
    FlowProgress,
    SideBar
  },
  setup() {
    const store = useStore()
    const strategy = computed(() => {
      return store.state.strategies.newStrategy
    })
    const riskDisclaimer = ref(false)
    const ddDisclaimer = ref(false)
    const created = ref(false)
    const submitted = ref(false)
    const error = ref(null)
    const { dollarFormatting } = useGlobalHelpers()
    const { levelRiskFinal } = useStrategies()
    const router = useRouter()
    const i18n = useI18n({ useScope: 'global' })

    const { noNewStrategy } = useFlows()
    onBeforeMount(() => {
      noNewStrategy()
    })

    const interestRate = computed(() => {
      return store.state.strategies.newStrategy.forecast ? store.state.strategies.newStrategy.forecast.interest : 0
    })

    const totalAssets = computed(() => {
      return store.state.strategies.newStrategy.forecast ? store.state.strategies.newStrategy.forecast.total : 0
    })

    const accepted = computed(() => {
      return riskDisclaimer.value && ddDisclaimer.value && !submitted.value
    })

    const createStrategy = () => {
      // disable button after first click
      submitted.value = true
      store.dispatch('setLoading', true)
      let data = {
        name: store.state.strategies.newStrategy.name,
        monthly_amount: store.state.strategies.newStrategy.amount,
        month_day: store.state.strategies.newStrategy.day_of_month,
        vp_bank_account_id: store.state.strategies.newStrategy.bank_account.id,
        wallet_percentage: store.state.strategies.newStrategy.wallet_percentage,
        project_max: store.state.strategies.newStrategy.project_max,
        max_risk: store.state.strategies.newStrategy.max_risk
      }

      if (store.state.strategies.newStrategy.other_beneficiary) {
        data.beneficiary_name =
          store.state.strategies.newStrategy.other_beneficiary.name
        data.beneficiary_birthdate =
          store.state.strategies.newStrategy.other_beneficiary.birthdate
      }

      if(store.state.strategies.newStrategy.end_date) {
        data.end_date = store.state.strategies.newStrategy.end_date
      }

      store
        .dispatch('createStrategy', data)
        .then(async () => {
          created.value = true
          const popup = {
            title: i18n.t('strategies.setup.confirmation.popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            primaryButton: i18n.t('strategies.setup.confirmation.popup.btn'),
            primaryLink: '/dashboard'
          }
          store.dispatch('setLoading', false)
          await store.dispatch('setPopup', popup)
          router.push({name: 'dashboard'})
        })
        .catch((err) => {
          error.value = err
          submitted.value = false
          store.dispatch('setError', err)
        })
    }

    return {
      strategy,
      interestRate,
      totalAssets,
      createStrategy,
      riskDisclaimer,
      ddDisclaimer,
      accepted,
      created,
      error,
      dollarFormatting,
      levelRiskFinal
    }
  }
}
</script>

<style lang="scss" scoped></style>
