<template>
  <div class="page flex">
    <div
      class="
        lg:pt-20
        container
        flex-grow flex flex-col
        justify-between
        relative
      "
    >
      <transition name="fade" mode="out-in">
        <div>
          <Back
            :to="'/settings'"
            :title="$t('settings.add_phone_number.title')"
          />
          <div class="pt-9 lg:pt-6">
            <Form class="form" ref="form" v-slot="{ errors, meta }">
              <GeneralInput
                ref="phoneInput"
                v-model:data="phone"
                v-model:phoneCode="phoneCode"
                type="number"
                name="phone"
                :label="$tm('settings.add_phone_number.phone_number')"
                rules="required|exactLength:10"
                :error="errors.phone"
              />

              <div class="absolute bottom-0 w-screen left-0 container">
                <div class="w-full relative">
                  <GpButton
                    :disabled="!meta.valid || !meta.dirty"
                    @click="createPhone"
                    class="absolute bottom-8 right-0"
                  >
                    {{ $t('wording.confirm') }}
                  </GpButton>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { Form } from 'vee-validate'
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import GeneralInput from '@/components/general/Input'

export default {
  components: {
    Back,
    GpButton,
    Form,
    GeneralInput
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const error = ref(null)
    let phoneCode = ref('1')
    let phone = ref('')
    const fromPayout = ref(route.query.payout)
    const profile = ref(null)

    onMounted(() => {
      profile.value = store.getters.getProfile()
      if (profile.value.phone) {
        router.push({
          path: '/settings/verification-code'
        })
      }
    })

    const createPhone = () => {
      store.dispatch('setLoading', true)
      store
        .dispatch('createPhone', {
          country_code: phoneCode.value,
          number: phone.value
        })
        .then(() => {
          store.dispatch('setLoading', false)
          router.push({
            path: '/settings/verification-code'
          })
        })
        .catch((err) => {
          if (err.response.data.errors.code === 'phone_already_validated') {
            store.dispatch('setLoading', false)
            router.push({
              path: '/settings/verification-code'
            })
          } else {
            store.dispatch('setError', err)
            store.dispatch('setLoading', false)
          }
        })
    }

    return {
      createPhone,
      phone,
      phoneCode,
      error,
      fromPayout,
      profile
    }
  }
}
</script>

<style lang="sass" scoped></style>
