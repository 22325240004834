<template>
  <div class="page flex flex-col">
    <SuccessCard
      v-if="cancelling"
      :title="$t('projects.details.reservation_details.cancel_title')"
      :text="$t('projects.details.reservation_details.cancel_text')"
      :closeable="false"
    >
      <template #icon>
        <img src="@/assets/svg/exclamation_grey_border.svg" alt="topup" />
      </template>
      <template #button>
        <GpButton color="white" class="mb-4 mx-auto" @click="keep">
          {{ $t('projects.details.reservation_details.keep_btn') }}
        </GpButton>
        <GpButton @click="cancel" class="mx-auto">
          {{ $t('projects.details.reservation_details.cancel_btn') }}
        </GpButton>
      </template>
    </SuccessCard>
    <div
      v-else-if="reservation"
      class="container flex-grow flex flex-col lg:pt-20 justify-between"
    >
      <div>
        <Back :to="-1" title="" />
        <div class="flex w-full items-center justify-between mb-4">
          <p class="text-left text-4xl font-bold text-grey-2 text-opacity-50">
            - ${{ dollarFormatting(reservation.total) }}
          </p>
          <img
            src="@/assets/svg/transactions/investment_details_failed.svg"
            alt="invest"
          />
        </div>
        <p class="text-left text-xl font-bold text-grey-2 mb-1">
          {{ $t('projects.details.reservation_details.type') }}
        </p>
        <p class="text-left font-medium text-grey-2 text-opacity-80 mb-8">
          {{ dateTimeFormat(reservation.transaction.date) }}
        </p>
        <!-- <TopupProofPopup
          :reserved="reservation.transaction"
          :hours="reservation.investment_expiration_warning.hours_to_expire"
        /> -->
        <div class="bg-white mb-px rounded-t-2xl p-4 mt-8">
          <div class="flex items-center justify-between mb-4">
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ $t('projects.details.reservation_details.status') }}
            </p>
            <div
              class="
                px-2
                py-0.5
                bg-grey-2 bg-opacity-20
                flex
                items-center
                justify-center
                rounded-lg
              "
            >
              <p class="text-13 font-semibold text-grey-2">
                {{ $t('projects.details.reservation_details.pending') }}
              </p>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ $t('projects.details.reservation_details.origin') }}
            </p>
            <p>{{ $t('projects.details.reservation_details.main_wallet') }}</p>
          </div>
        </div>
        <div class="bg-white bg-opacity-60 rounded-b-2xl p-4">
          <div class="flex items-center justify-between">
            <p class="text-sm text-grey-2 text-opacity-80">
              {{ $t('projects.details.reservation_details.amount') }}
            </p>
            <p>${{ dollarFormatting(reservation.total) }}</p>
          </div>
        </div>
      </div>
      <!-- Buttons -->
      <div class="flex">
        <button
          class="
            w-14
            h-14
            bg-white
            rounded-2xl
            flex
            items-center
            justify-center
            mr-4
          "
          @click="cancel"
        >
          <img src="@/assets/svg/trash_orange.svg" alt="trash" />
        </button>
        <GpButton color="grey" class="flex-grow w-auto" @click="edit">
          {{ $t('projects.details.reservation_details.edit_btn') }}
        </GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import Back from '@/components/general/Back.vue'
import SuccessCard from '@/components/general/SuccessCard.vue'
import GpButton from '@/components/general/GpButton.vue'
// import TopupProofPopup from '@/components/general/TopupProofPopup.vue'
import axios from 'axios'
export default {
  components: {
    Back,
    GpButton,
    // TopupProofPopup,
    SuccessCard
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { dollarFormatting, dateTimeFormat } = useGlobalHelpers()
    const cancelling = ref(null)

    const reservation = computed(() => {
      return store.state.invest.reservation
    })

    if (!reservation.value) router.go(-1)

    const cancel = () => {
      if (!cancelling.value) cancelling.value = true
      else
        axios
          .delete(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/investments/' +
              reservation.value.id
          )
          .then(() => {
            router.go(-1)
          }).catch((e) => {
          store.dispatch('setLoading', false);
          store.dispatch('setError', e)
      })
    }

    const keep = () => {
      router.go(-1)
    }

    const edit = () => {
      router.push({
        name: 'invest-wallet',
        params: {
          project: reservation.value.project_id,
          editReservation: reservation.value.id
        }
      })
    }

    return {
      reservation,
      dollarFormatting,
      dateTimeFormat,
      keep,
      cancel,
      cancelling,
      edit
    }
  }
}
</script>

<style lang="scss" scoped></style>
