<template>
  <div>
    <div class="h-full flex flex-col justify-between">
      <div>
        <div class="flex items-center justify-between pb-5">
          <p class="text-grey-2 font-bold">
            {{ $t('transactions.filters.title') }}
          </p>
          <p class="text-xs orange-link" @click="clearFilters">
            {{ $t('wording.clear') }}
          </p>
        </div>
        <!-- Categories -->
        <p class="text-left text-grey-2 font-semibold text-sm mt-6 mb-3">
          {{ $t('transactions.filters.category_title') }}
        </p>
        <div class="grid grid-cols-2 gap-3 mb-6">
          <div
            v-for="category of Object.keys(categories)"
            :key="category"
            class="w-full flex items-center rounded-2xl p-4"
            :class="categories[category] ? 'bg-green-5' : 'bg-grey-ghost'"
          >
            <GeneralInput
              v-model:data="categories[category]"
              noMargin
              bold
              type="checkbox"
              greenBg
              name="topup"
              :label="$t('transactions.filters.' + category)"
            />
          </div>
        </div>
        <!-- Date -->
        <p class="text-left text-grey-2 font-semibold text-sm mt-6 mb-3">
          {{ $t('transactions.filters.period_title') }}
        </p>
        <div class="grid grid-cols-2 gap-3 mb-6">
          <GeneralInput
            v-model:data="fromDate"
            noMargin
            type="date"
            name="from"
            :label="$t('wording.from')"
            :fromDate="false"
            :toDate="toLimit"
          />
          <GeneralInput
            v-model:data="toDate"
            noMargin
            type="date"
            name="to"
            :label="$t('wording.to')"
            :toDate="false"
            :fromDate="fromLimit"
          />
        </div>
      </div>
    </div>
    <div
      class="lg:fixed lg:bottom-6 lg:left-1/2 lg:transform lg:-translate-x-1/2"
    >
      <GpButton @click="applyFilters">
        {{ $t('transactions.filters.btn') }}
      </GpButton>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, ref, computed, onMounted } from 'vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { useI18n } from 'vue-i18n'
import GeneralInput from '@/components/general/Input.vue'
import GpButton from '@/components/general/GpButton.vue'
import { useStore } from 'vuex'
export default {
  components: {
    GeneralInput,
    GpButton
  },
  setup() {
    const { emit } = getCurrentInstance()
    const store = useStore()
    const { dateTimeFormatting } = useGlobalHelpers()
    const wallet = ref('null')
    const walletOptions = ref(null)
    const filteredWallet = computed(() => {
      let wlt = null
      if (store.state.transactions.filters) {
        wlt = store.state.wallets.wallet
        if (!wlt && store.state.wallets.promoterWallets)
          wlt = store.state.wallets.promoterWallets.find((el) => {
            return el.id == store.state.transactions.filters.wallet
          })
      }
      return wlt
    })
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const isPromoter = computed(() => {
      if (profile.value) {
        return profile.value.promoter_id
      }
      return false
    })
    const i18n = useI18n({ useScope: 'global' })

    if (isPromoter.value && store.state.wallets.promoterWallets.length) {
      store.state.wallets.promoterWallets.map((w) => {
        if (w.name) {
          walletOptions.value[w.id] = w.name
        } else {
          walletOptions.value[w.id] = i18n.t('transactions.filters.main_wallet')
        }
      })
    }

    const categories = ref(
      Object.keys(store.state.transactions.filters.categories).length !== 0
        ? store.state.transactions.filters.categories
        : {
            topup: false,
            withdrawal: false,
            investments: false,
            payments: false,
            vouchers: false
          }
    )

    onMounted(() => {
      if (filteredWallet.value)
        wallet.value = filteredWallet.value.id.toString()
    })

    const toDate = ref(store.state.transactions.filters.date.to ?? null)
    const fromDate = ref(store.state.transactions.filters.date.from ?? null)

    const buildCategoryFilters = () => {
      let categoryFilter = []
      for (let category in categories.value) {
        if (categories.value[category]) {
          if (category === 'investments') {
            categoryFilter.push(
              'project_investment',
              'autoinvestment',
              'saving_investment'
            )
          } else if (category === 'payments') {
            categoryFilter.push('payment_received')
          } else {
            categoryFilter.push(category)
          }
        }
      }
      return categoryFilter
    }

    const applyFilters = async () => {
      await store.dispatch('addFilters', {
        filters: {
          categories: categories.value,
          toDate: toDate.value,
          fromDate: fromDate.value,
          wallet: wallet.value
        },
        requestFilters: {
          categories: buildCategoryFilters(),
          ...(toDate.value
            ? { toDate: dateTimeFormatting(toDate.value) }
            : undefined),
          ...(fromDate.value
            ? { fromDate: dateTimeFormatting(fromDate.value) }
            : undefined),
          ...(wallet.value === 'null' ? undefined : { wallet: wallet.value })
        }
      })
      await store.dispatch('resetNextPage')
      await store.dispatch('fetchTransactions', {
        page: store.state.transactions.nextPage,
        total: 3,
        group: true
      })
      emit('close')
    }

    const clearFilters = () => {
      categories.value = {
        topup: false,
        withdrawal: false,
        investments: false,
        payments: false
      }
      toDate.value = null
      fromDate.value = null
    }

    const fromLimit = computed(() => {
      return fromDate.value !== null ? fromDate.value : false
    })

    const toLimit = computed(() => {
      return toDate.value !== null ? toDate.value : false
    })

    return {
      applyFilters,
      categories,
      clearFilters,
      toDate,
      fromDate,
      fromLimit,
      toLimit,
      wallet,
      walletOptions
    }
  }
}
</script>

<style lang="scss" scoped></style>
