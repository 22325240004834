<template>
  <div class="page-no-nav flex">
    <SuccessCard
      v-if="hasChanges"
      :title="$t('settings.discard_changes.title')"
      :text="$t('settings.discard_changes.content')"
      :closeable="false"
    >
      <template #icon>
        <img
          src="@/assets/svg/exclamation_grey_border.svg"
          alt="exclamation icon"
        />
      </template>
      <template #button>
        <div class="lg:grid lg:grid-cols-2 lg:gap-5">
          <GpButton
            color="white"
            class="mb-4 mx-auto"
            desktopWidth="w-full"
            @click="discard"
          >
            {{ $t('wording.discard_changes') }}
          </GpButton>
          <GpButton @click="cancel" class="mx-auto" desktopWidth="w-full">
            {{ $t('wording.no_go_back') }}
          </GpButton>
        </div>
      </template>
    </SuccessCard>
    <div v-else class="lg:pt-20 container flex-grow min-p-button relative">
      <div v-if="profile" class="flex flex-col justify-between">
        <div @click="checkChanges">
          <Back :to="null" :title="$t('settings.organization_details.title')" />
        </div>
        <div
          v-if="profile && kycsConditions"
          class="flex flex-col justify-between"
        >
          <div class="flex text-left text-grey-3 mb-6 text-sm">
            {{
              $t(
                'settings.personal_details.status.' +
                  kycsConditions.localesPath +
                  '.text'
              )
            }}
            <router-link
              v-if="
                kycsConditions.localesPath !== 'validation_asked' &&
                kycsConditions.localesPath !== 'validated'
              "
              to="/dashboard/kyc"
              class="orange-link text-xs cursor-pointer ml-1"
            >
              {{
                $t(
                  'settings.personal_details.status.' +
                    kycsConditions.localesPath +
                    '.btn'
                )
              }}
            </router-link>
          </div>
        </div>
        <Form class="form" ref="form" v-slot="{ errors, meta }">
          <!--  General Info -->
          <div
            class="
              text-left text-grey-3
              mb-6
              text-lg
              font-bold
              lg:font-semibold
            "
          >
            {{ $t('settings.organization_details.general_information') }}
          </div>
          <GeneralInput
            v-model:data="profile.company_name"
            type="string"
            name="company_name"
            :label="$t('settings.organization_details.organization_name')"
            :locked="kycSubmitted"
            :noNumbersOrSymbols="true"
            rules="required|maxLength:40"
            :error="errors.company_name"
          />
          <!-- <GeneralInput
            v-model:data="profile.sub_type"
            type="multiselect"
            name="subtype_multiselect"
            :label="
              $t('settings.organization_details.organization_type.input_label')
            "
            :options="organizationSubType"
            :multiselectLabel="'name'"
            :multiselectValueProp="'value'"
            :multiselectTrackBy="'name'"
            :searchable="false"
            :required="true"
            :locked="kycSubmitted"
          /> -->

          <GeneralInput
            v-model:data="profile.email"
            type="email"
            name="email"
            :label="$t('settings.organization_details.user_email')"
            :required="true"
            rules="email|required"
            :error="errors.email"
          />

          <GeneralInput
            v-if="profile.phone"
            v-model:data="profile.phone"
            v-model:phoneCode="profile.country_code"
            type="number"
            name="phone"
            :label="$t('settings.organization_details.phone_number')"
            rules="exactLength:10"
            :error="errors.phone"
          />

          <GeneralInput
            v-model:data="profile.country"
            type="countries"
            name="country"
            :label="$t('settings.organization_details.country')"
            :locked="kycSubmitted"
            rules="required"
          />

          <GeneralInput
            v-model:data="profile.region"
            type="multiselect"
            :options="isBorrower ? $tm('onboarding.fields.provsBorrower') : $tm('onboarding.fields.provsLender')"
            name="region"
            rules="required"
            :label="$t('onboarding.steps.0.fields.region')"
          />
          
          <GeneralInput
            v-model:data="profile.address"
            type="text"
            name="address"
            :label="$t('settings.organization_details.address')"
            :addressSymbols="true"
            rules="required|maxLength:40"
            :error="errors.address"
            :autocomplete="true"
          />

          <GeneralInput
            v-model:data="profile.post_code"
            type="tel"
            name="post_code"
            :label="$t('settings.organization_details.zip_code')"
            :zipCode="true"
            :country="profile.country"
            rules="required|exactLength:6"
            :error="errors.post_code"
          />

          <GeneralInput
            v-model:data="profile.city"
            type="text"
            name="city"
            :label="$t('settings.organization_details.city')"
            rules="required|maxLength:25"
            :error="errors.city"
          />

          <GeneralInput
            v-if="profile.business_number"
            v-model:data="profile.business_number"
            type="text"
            name="business_number"
            :label="$t('settings.organization_details.business_number')"
            :locked="kycSubmitted"
            rules="required"
            :error="errors.business_number"
          />
          <GeneralInput
            v-model:data="profile.business_website"
            type="text"
            name="business_website"
            :label="$t('settings.organization_details.business_website')"
            :locked="kycRegular"
            rules="required|maxLength:30"
          />
          <div
            class="
              absolute
              bottom-0
              w-screen
              container
              left-1/2
              -translate-x-1/2
              transform
            "
          >
            <div class="w-full relative">
              <GpButton
                :disabled="!(isDirty || meta.dirty) || !meta.valid"
                @click="updateDetails"
                class="ml-auto"
              >
                {{ $t('wording.save') }}
              </GpButton>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { Form } from 'vee-validate'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import useRules from '@/assets/js/rules'
import useProfile from '@/mixins/useProfile.js'
import GeneralInput from '@/components/general/Input.vue'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import SuccessCard from '@/components/general/SuccessCard.vue'
import _ from 'lodash'

export default {
  components: {
    GeneralInput,
    Back,
    Form,
    GpButton,
    SuccessCard
  },
  setup() {
    const store = useStore()
    const i18n = useI18n({ useScope: 'global' })
    const { updateProfile } = useProfile()
    const router = useRouter()
    const { isRequired } = useRules()
    const isBorrower = computed(() => {
      return profile.value && profile.value.sub_type === 'borrower'
    })

    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const { prettierDate } = useGlobalHelpers()
    const loading = computed(() => {
      return store.state.general.loading
    })
    const regionCountries = ref(['CA', 'MX', 'US'])
    const form = ref(null)
    const initialProfile = ref(null)
    const hasChanges = ref(null)

    let organizationSubType = [
      {
        name: i18n.t('settings.organization_details.organization_type.company'),
        value: 'business'
      },
      {
        name: i18n.t('settings.organization_details.organization_type.other'),
        value: 'organization'
      }
    ]

    const kycsConditions = computed(() => {
      return store.state.kycs.conditions
    })

    let kycStatusCopy = computed(() => {
      if (kycsConditions.value.localesPath === 'validated') {
        return null
      }
      return kycsConditions.value ? kycsConditions.value.localesPath : ''
    })

    const kycSubmitted = computed(() => {
      if (kycsConditions.value) {
        return ['validation_asked', 'validated', 'document_failed'].includes(
          kycsConditions.value.localesPath
        )
      } else {
        return false
      }
    })

    onMounted(async () => {
      initialProfile.value = JSON.parse(JSON.stringify(profile.value))
      if (!store.state.kycs.kycs) {
        store.dispatch('setLoading', true)

        await store.dispatch('getKycs')
      }
      if (!kycsConditions.value) {
        store.dispatch('setLoading', true)

        const conditions = await store.getters.getKycsConditions()
        await store.dispatch('setKycsConditions', conditions)
      }
      store.dispatch('setLoading', false)
    })

    const updateDetails = () => {
      store.dispatch('setLoading', true)

      if (!regionCountries.value.includes(profile.value.country)) {
        profile.value.region = null
      }
      profile.value.date_of_birth = prettierDate(profile.value.date_of_birth)
      updateProfile(profile.value).then(() => {
        form.value.resetForm()
        store.dispatch('setLoading', false)

        const popup = {
          title: i18n.t('settings.organization_details.success_popup.title'),
          align: 'center',
          side: false,
          iconBg: 'bg-green-5',
          icon: '/img/done_step.svg',
          primaryButton: i18n.t(
            'settings.organization_details.success_popup.btn'
          )
        }
        store.dispatch('setPopup', popup)
        router.push({name: 'settings'})
      })
    }

    const isDirty = computed(() => {
      return (
        JSON.stringify(profile.value) !== JSON.stringify(initialProfile.value)
      )
    })

    const validate = () => {
      //TODO
    }

    const checkChanges = () => {
      if (isDirty.value === true) {
        hasChanges.value = true
      } else {
        router.go(-1)
      }
    }

    const discard = () => {
      router.go(-1)
    }

    const cancel = () => {
      hasChanges.value = false
    }

    watch(
      () => _.cloneDeep(profile.value),
      (val, oldVal) => {
        if (val && oldVal) {
          if (val.country !== oldVal.country) {
            profile.value.address = ''
            profile.value.post_code = ''
            profile.value.city = ''
          }
        }
      },
      { deep: true }
    )

    return {
      profile,
      isBorrower,
      kycsConditions,
      i18n,
      organizationSubType,
      regionCountries,
      updateDetails,
      isRequired,
      kycSubmitted,
      kycStatusCopy,
      form,
      isDirty,
      loading,
      validate,
      checkChanges,
      hasChanges,
      discard,
      cancel
    }
  }
}
</script>

<style lang="sass" scoped></style>
