<template>
  <div class="relative" id="tabs">
    <div class="flex items-center justify-between lg:justify-start mb-2">
      <div v-for="index in nTabs" :key="index" class="lg:mr-8">
        <p
          class="text-sm text-grey-2 cursor-pointer"
          :class="index === activeTab ? 'font-semibold' : 'text-opacity-50'"
          @click="changeTab(index)"
        >
          {{ $t(data + '.' + index) }}
        </p>
        <div
          class="mt-2 w-6 h-1 transition-all"
          :class="index === activeTab ? 'bg-orange-2' : 'bg-none'"
        />
      </div>
    </div>
    <div class="h-px w-full bg-grey-2 bg-opacity-10 absolute bottom-1" />
  </div>
</template>

<script>
import { useVModel } from 'vue-composable'
// import { ref } from 'vue'
export default {
  props: {
    data: {
      type: String,
      default: ''
    },
    nTabs: {
      type: Number,
      default: 0
    },
    current: {
      type: Number,
      default: 1
    },
    anchors: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const activeTab = useVModel(props, 'current')

    const changeTab = (newTab) => {
      // activeTab.value = newTab
      // aprox. height of nav bar + back btn + project img + tabs
      // for mobile and desktop
      let extraOffset = window.innerWidth < 1024 ? 128 : 256
      let offset =
        document.getElementById(props.anchors[newTab - 1]).offsetTop +
        extraOffset
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      })
    }

    return {
      activeTab,
      changeTab
    }
  }
}
</script>

<style lang="scss" scoped></style>
