<template>
  <div
    class="
      text-left text-sm text-grey-3
      p-4
      rounded-3xl
      bg-white
      flex
      items-start
      mt-4
    "
  >
    <div class="p-4 rounded-2xl mr-4 bg-gradient-yellow">
      <img src="@/assets/svg/toast/warning.svg" alt="" />
    </div>
    <div>
      <div class="text-grey-3 font-bold mb-2">
        {{ $t('promoter.overdue.title') }}
      </div>
      <div class="text-sm text-grey-4 mb-2">
        {{ $t('promoter.overdue.content') }}
      </div>
      <div class="orange-link">
        <router-link to="/loans"
          >{{ $t('promoter.overdue.link') }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  setup() {}
}
</script>

<style>
.bg-gradient-yellow {
  background-image: linear-gradient(
    114deg,
    rgba(255, 182, 0, 0.1) -18%,
    rgba(255, 182, 0, 0) 90%
  );
}
</style>
