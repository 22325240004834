<template>
  <div class="
        absolute
        w-1/5
        right-0
        -mr-14
        transform
        translate-x-full
        -mt-36
        translate-y-2/3
        bg-green-mint
        text-center
        rounded-lg
      "
  >
    <p class="p-4">
      {{ $t(copy) }}
    </p>  
  </div>
</template>
<script>

export default {
  props: {
    copy: {
      type: String,
      default: ''
    }
  }
}
</script>