<template>
  <div class="">
    <div class="px-6 sm:px-0">
      <div class="flex justify-between mb-5">
        <div class="text-xl text-grey-2 font-semibold">
          {{ $t('promoter.loan_carousel.title') }}
          <span class="font-normal text-grey-4">({{ total }})</span>
        </div>
        <div class="orange-link">
          <router-link to="loans">
            {{ $t('wording.see_all') }}
          </router-link>
        </div>
      </div>
    </div>
    <swiper
      class="w-full loan-slide"
      :loop="false"
      :slides-per-view="1.2"
      :space-between="16"
      :pagination="false"
      :allowTouchMove="true"
      :centered-slides="true"
      :speed="1000"
      :autoplay="false"
      :breakpoints="{
        // when window width is >= 320px
        640: {
          slidesPerView: 2,
          centeredSlides: false
        },
        1800: {
          slidesPerView: 3,
          centeredSlides: false
        }
      }"
    >
      <template v-if="!skeleton">
        <swiper-slide
          v-for="project in projects"
          v-bind:key="'project-' + project.id"
        >
          <LoanCard :project="project" />
        </swiper-slide>
      </template>

      <template v-else>
        <swiper-slide
          v-for="index in 4"
          v-bind:key="'project-skeleton-' + index"
        >
          <ContractSkeleton />
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import LoanCard from './LoanCard'
import ContractSkeleton from '@/components/contracts/ContractSkeleton'

export default {
  components: {
    Swiper,
    LoanCard,
    SwiperSlide,
    ContractSkeleton
  },
  props: {
    projects: Array,
    total: Number,
    skeleton: {
      type: Boolean,
      default: false
    }
  },
  setup() {}
}
</script>
<style>
.loan-slide .swiper-slide {
  min-height: 0px;
}
</style>
