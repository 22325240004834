<template>
  <div class="text-left bg-orange-5 p-4 rounded-3xl">
    <div class="text-grey-2 font-semibold mb-2">
      {{
        $t('promoter.fee_warning.title', {
          date: date,
          amount: dollarFormatting(amount)
        })
      }}
    </div>
    <div class="text-grey-3 text-sm">
      {{ $t('promoter.fee_warning.content') }}
    </div>
  </div>
</template>
<script>
import useGlobalHelpers from '@/mixins/useGlobalHelpers'

export default {
  props: {
    date: String,
    amount: Number
  },
  setup() {
    const { dollarFormatting } = useGlobalHelpers()
    return {
      dollarFormatting
    }
  }
}
</script>
