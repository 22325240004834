<template>
  <div class="steps overflow-y-auto h-full">
    <p class="steps__title--mobile ml-5 md:ml-0">{{$t('auto_investment.setup.confirmation.title')}}</p>
    <p class="text-sm text-grey-2 font-semibold lg:font-normal text-opacity-80 text-left mb-8 lg:mb-12">{{$t('auto_investment.setup.confirmation.subtitle')}}</p>
    <div class="p-4 bg-white rounded-2xl">
      <div class="flex items-center justify-between text-sm mb-4">
        <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.interest_rate')}}</p>
        <p class="text-grey-2 font-semibold">
          {{ interestRate }}
        </p>
      </div>
      <div class="flex items-center justify-between text-sm mb-4">
        <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.max_term')}}</p>
        <p class="text-grey-2 font-semibold">
          {{ term }}
        </p>
      </div>
      <div class="flex items-center justify-between text-sm mb-4">
        <p class="text-grey-3">
          <span class="lg:hidden">{{$t('auto_investment.setup.confirmation.wallet_balance_mobile')}}%</span> 
          <span class="hidden lg:block">{{$t('auto_investment.setup.confirmation.wallet_balance')}}</span>
        </p>
        <p class="text-grey-2 font-semibold">
          {{ walletBalance }} {{$t('auto_investment.setup.confirmation.available_funds')}}
        </p>
      </div>
      <div class="flex items-center justify-between text-sm mb-4 ">
        <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.risk')}}</p>
        <p class="text-grey-2 font-semibold">
          {{ risk }}
        </p>
      </div>
      <div class="flex items-center justify-between text-sm mb-4 ">
        <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.project_max')}}</p>
        <p class="text-grey-2 font-semibold">
          ${{ projectMax }}
        </p>
      </div>
      <div class="flex items-center justify-between text-sm">
        <p class="text-grey-3">{{$t('auto_investment.setup.confirmation.project_min')}}</p>
        <p class="text-grey-2 font-semibold">
          ${{ projectMin }}
        </p>
      </div>
    </div> 
    <GeneralInput
      v-model:data="loanAgreement"
      type="checkbox"
      name="loanAgreement"
      class="mt-8 lg:mt-10"
    >
      <template #label>
        <span v-html="$t('auto_investment.setup.confirmation.checkbox', { server_url: serverUrl })" />
      </template>
    </GeneralInput>
    <div class="flex flex-col lg:flex-row lg:items-center my-10 text-center lg:text-left">
      <a @click="editAutoInvestment()"  class="text-orange-1 font-semibold text-base uppercase lg:flex-grow lg:pl-48 mb-8 lg:mb-0">{{$t('auto_investment.setup.confirmation.edit')}}</a>
      <GpButton
        @click="createAutoInvestment()"
        :disabled="!loanAgreement">
        {{ $t('wording.confirm') }}
      </GpButton>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import GeneralInput from '@/components/general/Input.vue'
import GpButton from '@/components/general/GpButton.vue'
import { useRouter } from 'vue-router'

export default {
  components: {
    GeneralInput,
    GpButton
  },
  setup() {
    const loading = ref(false)
    const router = useRouter()
    const loanAgreement = ref(false)
    const store = useStore()
    const i18n = useI18n({ useScope: 'global' })
    const { riskConversion, dollarFormatting } = useGlobalHelpers()

    const serverUrl = computed(() => {
			return process.env.VUE_APP_SERVER_BASE_URL ?? null
		})

    const interestRate = computed(() => {
			return store.state.autoInvestment.autoInvestment ? `${store.state.autoInvestment.autoInvestment.min_interest}% - ${store.state.autoInvestment.autoInvestment.max_interest}%`: 0
		})

    const term = computed(() => {
			return store.state.autoInvestment.autoInvestment ? `< ${i18n.t('wording.year', store.state.autoInvestment.autoInvestment.max_term)} `: 0
		})

    const walletBalance = computed(() => {
			return store.state.autoInvestment.autoInvestment ? `<${store.state.autoInvestment.autoInvestment.balance_percentage} %`: 0
		})

    const risk = computed(() => {
			return store.state.autoInvestment.autoInvestment ? riskConversion(store.state.autoInvestment.autoInvestment.max_risk) : 0
		})

    const projectMax = computed(() => {
			return store.state.autoInvestment.autoInvestment.project_max ? dollarFormatting(store.state.autoInvestment.autoInvestment.project_max) : 'N/A'
		})

    const projectMin = computed(() => {
			return store.state.autoInvestment.autoInvestment.project_min ? dollarFormatting(store.state.autoInvestment.autoInvestment.project_min) : 'N/A'
		})

    const editAutoInvestment = () => {
      if(store.state.autoInvestment.autoInvestment) {
      router.push({
        name: 'edit-auto-investment',
        params: { autoInvestment: JSON.stringify(store.state.autoInvestment.autoInvestment) }
      })
      } else {
        router.push('setup-auto-investment')
      }
    }

    const createAutoInvestment = () => {
      if(store.state.autoInvestment.autoInvestment) {
        store.dispatch('setLoading', true)
        loanAgreement.value = false
        store.dispatch('createAutoInvestment', store.state.autoInvestment.autoInvestment)
        .then(async () => {
          const popup = {
            title: i18n.t('auto_investment.setup.confirmation.popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            primaryButton: i18n.t('auto_investment.setup.confirmation.popup.btn'),
            primaryLink: '/auto-investment'
          }
          store.dispatch('setLoading', false)
          await store.dispatch('setPopup', popup)
          router.push('/auto-investment')
        })
        .catch((e) => {
          store.dispatch('setError', e)
          store.dispatch('forceRedirect', '/auto-investment')
        })
      } else {
        router.push('/auto-investment')
      }
    }

    return {
      loanAgreement,
      interestRate,
      walletBalance,
      term,
      serverUrl,
      risk,
      projectMin,
      projectMax,
      createAutoInvestment,
      editAutoInvestment,
      loading
    }
  }
}
</script>
