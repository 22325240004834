<template>
  <div class="w-full">
    <div class="flex justify-between mb-2" :class="{ 'px-6': !contain }">
      <div>
        <p class="text-11 text-white text-opacity-60 text-left" v-if="details">
          {{ $t('wording.raised') }}
        </p>
        <div class="font-bold text-green-1 text-sm" v-if="current !== null && (state === 'campaign' || state === 'reserved')">
          $ {{ dollarFormatting(current) }} 
        </div>
        <div class="text-grey-7 text-sm font-semibold" v-else>
          {{ state === 'funded' ? $t(
          'projects.state_options.funded',
          { investorCount: investors },
          investors
        ) : $t('projects.details.goal') }}
        </div>
      </div>
      <div v-if="reserved && details">
        <p class="text-11 text-white text-opacity-60 text-left">
          {{ $t('projects.state_options.reserved') }}
        </p>
        <div class="font-bold text-green-1 text-sm">
          $ {{ dollarFormatting(reserved) }} 
        </div>
      </div>
      <div>
        <p class="text-11 text-white text-opacity-60 text-left" v-if="details">
          {{ $t('wording.goal') }}
        </p>
        <div
          class="text-white text-sm"
          :class="{ 'font-bold': current === null }"
        >
          $ {{ dollarFormatting(goal) }} 
        </div>
      </div>
    </div>
    <div class="h-2">
      <template v-if="current !== null && state !== 'funded'">
        <div class="progress-bar opacity-30 bg-white w-full" v-if="state === 'campaign' || state === 'reserved'"></div>
        <div
          class="progress-bar w-24 bg-green-1"
          :style="'width: ' + ((current + reserved) / goal) * 100 + '%;'"
        ></div>
        <div
          class="progress-bar w-24 bg-green-2"
          :style="'width: ' + (current / goal) * 100 + '%;'"
        ></div>
      </template>
    </div>
  </div>
</template>

<script>
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'

export default {
  props: {
    goal: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: 'Investment goal'
    },
    current: {
      type: Number,
      default: null
    },
    reserved: {
      type: Number,
      default: 0
    },
    investors: {
      type: Number,
      default: 0
    },
    labels: {
      type: Boolean,
      default: false
    },
    details: {
      type: Boolean,
      default: false
    },
    contain: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      default: 'campaign'
    },
  },
  setup() {
    const { dollarFormatting } = useGlobalHelpers()

    return {
      dollarFormatting
    }
  }
}
</script>
<style lang="postcss" scoped>
.progress-bar {
  @apply h-2 absolute bottom-0;
}
</style>
