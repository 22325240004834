<template>
	<div class="home">
		<SideBar>
			<div class="h-screen relative">
				<GreenBall
					class="steps__ball w-full"
					start="bottom"
					offset="-2rem"
					cutout="0"
				/>
				<img
					src="@/assets/img/windmill-sidebar.png"
					alt=""
					class="absolute bottom-28 h-96 2xl:h-2/3"
				/>
				<div class="text-left w-3/4 mx-auto absolute z-10 left-24">
					<div class="text-3xl">
						<div class="text-grey-3 font-normal">
							{{$t('auto_investment.setup.title_sidebar_1')}}
						</div>
						<div class="text-grey-3 font-medium">
							{{
								$t(
									'auto_investment.self'
								)
							}}
						</div>
					</div>
					<div class="text-grey-3 font-normal text-3xl" v-if="activeIndex === 0">
						{{
							$t(
								'auto_investment.setup.title_sidebar_2'
							)
						}}
					</div>
					<ul class="ml-8 mt-8 text-grey-2" v-else>
						<transition name="fade" 
							v-for="(item, index) in stepsData"
            	v-bind:key="item">
							<li class="mb-4">
								<span v-if="activeIndex <= index + 1" class="steps__bullet" :class="index + 1 === activeIndex ? 'steps__bullet--active' : ''">
									{{ index + 1 }}
								</span>
								 <img v-else
                    src="@/assets/img/step_done.png"
                    alt=""
                    class="w-6 absolute"
                  />
								<span class="ml-9 text-sm" :class="activeIndex === index + 1 ? 'font-bold' : ''">
									{{ item }}
								</span>
							</li>
						</transition>
					</ul>
				
						<div>
							<span
								class="steps__bullet"
								:class="activeIndex === index + 1 ? 'steps__bullet--active' : ''"
							>
								{{ item }}
							</span
							>
						</div>
				</div>
			</div>
		</SideBar>
		<div
			class="
				w-full
				lg:w-2/3
				ml-auto
				min-h-screen
				relative
				text-left
				lg:pt-28
				z-0
			"
		>	
			<div
				class="
					md:w-3/4
					w-4/5
					m-auto
					absolute
					md:relative md:left-0
					left-9
					top-2
					z-50
					mb-8
				"
			>
				<transition name="toast-fade">
					<div
						class="
							back
							text-left text-grey-2
							md:flex
							absolute
							top-5
							items-center
							cursor-pointer
							md:mb-12
						"
						@click="previousSlide"
					>
						<img
							src="@/assets/svg/chev-left.svg"
							alt=""
							class="w-5 md:w-auto"
						/>

						<span class="hidden md:block">{{ $t('wording.back') }}</span>
					</div>
				</transition>
			</div>
			<swiper
				class="w-full auto-investment-swiper"
				:loop="false"
				@swiper="setSwiper"
				:slides-per-view="1"
				:space-between="50"
				:pagination="false"
				:allowTouchMove="false"
				:autoHeight="true"
				:speed="1000"
				:autoplay="false"
			>
				<swiper-slide>
					<Setup 	
						@next="nextSlide"
						:loading="loading" 
						class="w-4/5 md:w-3/4 mx-auto mt-16 md:mt-0"
					/>
				</swiper-slide>
				<swiper-slide>
					<InterestRate
						@next="nextSlide"
						:loading="loading" 
						class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
					/>
				</swiper-slide>
				<swiper-slide>
					<MaxTerm
						@next="nextSlide"
						:loading="loading" 
						class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
					/>
				</swiper-slide>
				<swiper-slide>
					<RiskRating
						@next="nextSlide"
						:loading="loading" 
						class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
					/>
				</swiper-slide>
				<swiper-slide>
					<WalletPercentage
						@next="nextSlide"
						:loading="loading" 
						class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
					/>
				</swiper-slide>
				<swiper-slide class="lg:hidden">
					<ProjectMinMobile
						@next="nextSlide"
						:loading="loading" 
						class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
					/>
				</swiper-slide>
				<swiper-slide class="lg:hidden">
					<ProjectMaxMobile
						@next="nextSlide"
						:loading="loading" 
						class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
					/>
				</swiper-slide>
				<swiper-slide class="hidden lg:block">
					<InvestmentAmountDesktop
						@next="nextSlide"
						:loading="loading" 
						
						class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
					/>
				</swiper-slide>
				<swiper-slide>
					<Confirmation
						@next="nextSlide"
						:loading="loading" 
						class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
					/>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>

<script>
import SideBar from '@/components/general/SideBar.vue'
import InterestRate from '@/components/auto-investment/InterestRate.vue'
import MaxTerm from '@/components/auto-investment/MaxTerm.vue'
import WalletPercentage from '@/components/auto-investment/WalletPercentage.vue'
import RiskRating from '@/components/auto-investment/RiskRating.vue'
import ProjectMinMobile from '@/components/auto-investment/ProjectMinMobile.vue'
import ProjectMaxMobile from '@/components/auto-investment/ProjectMaxMobile.vue'
import InvestmentAmountDesktop from '@/components/auto-investment/InvestmentAmountDesktop.vue'
import Confirmation from '@/components/auto-investment/Confirmation.vue'
import GreenBall from '@/components/general/GreenBall.vue'
import Setup from '@/components/auto-investment/Setup.vue'

import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
	components: {
		SideBar,
		Setup,
		Swiper,
		MaxTerm,
		RiskRating,
		ProjectMinMobile,
		ProjectMaxMobile,
		InvestmentAmountDesktop,
		Confirmation,
		SwiperSlide,
		InterestRate,
		WalletPercentage,
		GreenBall
	},
	name: 'setup-auto-investment',
	setup() {
		const swiper = ref(null)
		const loading = ref(false)
		const i18n = useI18n({ useScope: 'global' })
		const { dollarFormatting } = useGlobalHelpers()
		const store = useStore()
		const router = useRouter()

		const activeIndex = computed(() => {
			return swiper.value ? swiper.value.activeIndex : 0
		})

		const stepsData = computed(() => {
			return i18n.tm('auto_investment.steps')
		})

		// Swipe Next
		async function nextSlide() {
			swiper.value.slideTo(swiper.value.activeIndex + 1)
		}

		// Swipe Previous
		function previousSlide() {
			if (swiper.value.activeIndex > 0) {
				swiper.value.slideTo(swiper.value.activeIndex - 1)
			} else router.push({name: 'dashboard'})
		}

		// Initialize Swiper
		function setSwiper(value) {
			swiper.value = value
		}

		// Get Metrics
		onMounted(async () => {
      if (!store.state.metrics.metrics)
        await store.dispatch('fetchMetrics')
		})

		return {
			stepsData,
			dollarFormatting,
			setSwiper,
			nextSlide,
			loading,
			previousSlide,
			activeIndex
		}
	}
}
</script>

<style>
.slider-auto-investment {
  --slider-connect-bg: #b3dabb;
  --slider-bg: #4f59631a;
  --slider-handle-ring-color: #ffffff;
  --slider-handle-bg: #b3dabb;
  --slider-handle-ring-width: 6px;
  --slider-handle-radius: 1000px;
	--slider-tooltip-bg: #b3dabb;
	--slider-tooltip-py: 0.25rem;
	--slider-tooltip-px: 0.25rem;
}

.slider-horizontal .slider-handle {
  right: -16px;
}
.auto-investment-swiper{
.swiper-wrapper {
  height: 100vh !important;
}
}
</style>