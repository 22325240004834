<template>
  <div v-if="!hasLoanArrears" class="page flex">
    <div v-if="nextDD" class="lg:pt-20 container flex-grow">
      <Back :to="'/dashboard'" :title="$t('promoter.dd_associated.title')" />
      <div class="pt-4 lg:pt-6">
        <Table :data="DirectDebitData" />
      </div>
      <div class="pt-4" v-if="nextDD.status === 'FAILED'">
        <div class="flex border border-orange-3 rounded-2xl">
          <div class="pr-2 pl-4">
            <img
              :src="require('@/assets/svg/toast/warning.svg')"
              alt=""
              class="pt-6 w-8 lg:w-4"
            />
          </div>
          <div>
            <p
              class="text-grey-3 text-sm pt-6 mr-4 text-left font-bold"
              v-html="
                $t('promoter.dd_associated.dd_failed_warning_penalty', {
                  fee: dollarFormatting(nextDD.failed_dd_fee)
                })
              "
            ></p>
            <p
              class="
                text-grey-4
                lg:text-grey-3
                text-sm
                pt-2
                pb-6
                mr-4
                text-left
              "
              v-html="$t('promoter.dd_associated.dd_failed_warning')"
            ></p>
          </div>
        </div>
      </div>

      <div
        class="pt-4"
        v-if="nextDD.status === 'TO_BE_ORDERED' || nextDD.status === 'CREATED'"
      >
        <div
          class="
            rounded-2xl
            pl-4
            py-5
            font-semibold
            text-grey-3 text-sm
            bg-grey-7
            text-left
          "
          v-html="
            $t('promoter.dd_associated.fee_warning', {
              fee: dollarFormatting(nextDD.failed_dd_fee)
            })
          "
        ></div>
        <div>
          <p class="pt-20 text-left text-xs text-grey-3 mb-6">
            {{ $t('promoter.dd_associated.ordered_warning') }}
          </p>
        </div>
      </div>

      <div v-if="nextDD.status === 'FAILED'" class="pt-12 pb-4">
        <div class="flex lg:justify-end">
          <GpButton
            color="grey"
            desktopWidth="w-80"
            mobileWidth="w-full"
            @click="goToTopup"
          >
            {{ '+ ' + $t('promoter.dd_associated.top_up_now') }}
          </GpButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import Table from '@/components/general/Table.vue'
import Back from '@/components/general/Back.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import GpButton from '@/components/general/GpButton.vue'

export default {
  components: {
    Back,
    Table,
    GpButton
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const i18n = useI18n({ useScope: 'global' })
    const { dollarFormatting } = useGlobalHelpers()
    const nextDD = ref(null)
    const wallet = ref(null)
    const loan = ref(null)
    const projectId = ref(null)
    const DirectDebitData = ref({
      top: [],
      bottom: []
    })
    const stateBg = ref({
      SUCCEEDED: 'bg-green-1',
      CREATED: 'bg-grey-1',
      FAILED: 'pulse bg-failed'
    })

    const loansNextDD = computed(() => {
      return store.getters.getNextDD()
    })

    const maskStatus = computed(() => {
      return nextDD.value.status 
        ? nextDD.value.status !== 'TO_BE_ORDERED'
          ? nextDD.value.status.toLowerCase()
          : 'to_be_ordered'
        : nextDD.value.vp_payin_id
          ? 'to_be_ordered'
          : 'succeeded'
    })

    const loanId = computed(() => {
      return parseInt(route.params.id)
    })

    const hasLoanArrears = computed(() => {
      if (loan.value) {
        return loan.value && loan.value.loan_status.in_arrears
      } else {
        return loansNextDD.value && loansNextDD.value.loan_status.in_arrears
      }
    })

    onBeforeMount(async () => {
      if (!store.state.loans) await store.dispatch('fetchLoans')
      if (loanId.value) {
        loan.value = await store.dispatch('fetchLoan', loanId.value)
      }
      if (hasLoanArrears.value) await router.push({name: 'dashboard'})

      nextDD.value = loan.value
        ? loan.value.next_direct_debit
        : store.getters.getNextDD().next_direct_debit

      DirectDebitData.value.top = [
        {
          title: i18n.t('promoter.dd_associated.status.title'),
          value: i18n.t('promoter.dd_associated.status.' + maskStatus.value)
        },
        {
          title: i18n.t('promoter.dd_associated.amount'),
          value: '$' + dollarFormatting(nextDD.value.amount)
        },
        {
          title: i18n.t('promoter.dd_associated.day'),
          value:
            i18n.t('promoter.dd_associated.execution_date') +
            nextDD.value.execution_date
        }
      ]
      DirectDebitData.value.bottom = [
        {
          title: i18n.t('promoter.dd_associated.owner'),
          value: nextDD.value.bank_account_owner
        },
        {
          title: i18n.t('promoter.dd_associated.name_bank_account'),
          value: nextDD.value.bank_account_name
        }
      ]
      if (nextDD.value.bank_account_bic) {
        DirectDebitData.value.bottom.push({
          title: i18n.t('promoter.dd_associated.bic'),
          value: nextDD.value.bank_account_bic
        })
      }
      if (loansNextDD.value) {
        projectId.value = loansNextDD.value.id
      }
      if (store.state.wallets.promoterWallets) {
        wallet.value = store.getters.getWalletByProject(projectId.value)
      } else {
        await store.dispatch('fetchWallets')
        wallet.value = store.getters.getWalletByProject(projectId.value)
      }
    })

    function goToTopup() {
      router.push({
        name: 'topup',
        params: {
          propWallet: JSON.stringify(wallet.value)
        }
      })
    }

    return {
      nextDD,
      DirectDebitData,
      stateBg,
      goToTopup,
      dollarFormatting,
      hasLoanArrears
    }
  }
}
</script>

<style lang="postcss" scoped></style>
