<template>
  <div>
    <div>
      <div class="pt-8 px-6 text-left">
        <router-link
          v-for="(el, index) in $tm('navigation.dropdown')"
          v-bind:key="el.link"
          :to="{ name: el.active === 'false' ? 'dashboard' : el.link }"
        >
          <div
            class="py-6 cursor-pointer font-medium text-sm relative text-grey-3"
            :class="{
              'border-b': index < $tm('navigation.dropdown').length - 1
            }"
            v-if="index !== 0"
          >
            <div class="flex">
              <img
                :src="require('@/assets/svg/navigation/' + el.link + '.svg')"
                alt=""
              />
              <div class="absolute left-8 top-1/2 transform -translate-y-1/2">
                {{ el.name }}
              </div>
              <img
                src="@/assets/svg/chev-right-2.svg"
                alt=""
                class="absolute right-0 top-1/2 transform -translate-y-1/2"
              />
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {}
  }
}
</script>
