<template>
  <div class="relative">
    <img
      src="@/assets/svg/info.svg"
      alt="info"
      class="cursor-pointer"
      @mouseover="showInfoPopup = true"
      @mouseleave="showInfoPopup = false"
      @click="showInfoPopup = true"
    />
    <div class="absolute">
      <div v-if="showInfoPopup" class="arrow-up relative">
        <div  
          class="absolute top-2 lg:-inset-x-36 bg-blue-ocean-2 text-white text-xs w-72 rounded-xl p-2 flex items-center justify-center ml-4 lg:ml-0"
          :class="positionXAxisMobile"
        >
          <div class="flex items-start">
            <p>{{$t(copy) }} {{ value }}</p>
            <div
              class="flex items-start w-8 cursor-pointer"
              @click="showInfoPopup = false"
            >
              <!-- <img class="w-4 absolute top-2 right-2" src="@/assets/svg/cross-white.svg" alt="close" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    copy: {
      type: String,
      default: ''
    },
    positionXAxisMobile: {
      type: String,
      default: '-inset-x-36'
    },
    value:{
      type: String,
      default: ''
    }
  },
  setup() {
    const showInfoPopup = ref(false)
    return {
      showInfoPopup
    }
  }
}
</script>

<style lang="css" scoped>
.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #154B64;
}

</style>







