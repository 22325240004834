import axios from 'axios'
import randPwd from '@/mixins/useGenPassword.js'
import { VueCookieNext } from 'vue-cookie-next'

export default {
  state: {
    step: 0,
    seen: [],
    data: { },
    kyc: [],
    kyc_type: null,
    beneficiary_id: null,
    success: false,
    is_ubo: null,
    config: {
      company: [
        'back',
        'next|back',
        'next|back',
        'next|back|later',
        'next|back|later',
        'back|later'
      ],
      particular: [
        'next|back', 
        'back']
    }
  },
  mutations: {
    SET_STEP(state, step) {
      state.step = step
    },
    SET_IS_UBO(state, value) {
      state.is_ubo = value
    },
    SET_KYC(state, data) {
      state.kyc.push({ type: data.kyc_type, state: data.kyc_state })
    },

    SET_KYC_TYPE(state, data) {
      state.kyc_type = data
    },

    SET_BENEFICIARY_ID(state, data) {
      state.beneficiary_id = data
    },

    SET_SEEN(state, seen) {
      state.seen.push(seen)
    },

    SET_SUCCESS(state) {
      state.success = true
    },

    REMOVE_SEEN(state, seen) {
      state.seen = state.seen.filter(function (item) {
        return item !== seen
      })
    },

    SET_DATA(state, data) {
        state.data = {
          ...state.data,
          ...data
        }
    }
  },
  actions: {
    externalSignup({ commit }, data) {
      const pwd = randPwd
      data = {
        ...data,
        password: pwd,
        password_confirmation: pwd
      }
      return axios
        .post(process.env.VUE_APP_SERVER_BASE_URL + 'api/signup', data)
        .then(({ data }) => {
          VueCookieNext.setCookie('profile', null)
          commit('SET_USER_DATA', data)
          VueCookieNext.setCookie('token', data.token)
          return data
        })
    },
    next({ commit, state }) {
      commit('SET_STEP', state.step + 1)
    },
    doneKyc({ commit }, data) {
      commit('SET_KYC', data)
    },
    // eslint-disable-next-line no-unused-vars
    sms({ commit }, data) {
      return axios.get(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/profiles/sms',
        data
      )
      .catch((e) => {
        commit('SET_ERROR', e)
      })
    },
    // eslint-disable-next-line no-unused-vars
    submitProfile({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.data.date_of_birth) {
          if(state.data.date_of_birth instanceof Date)
            state.data.date_of_birth = state.data.date_of_birth
              .toISOString()
              .slice(0, 10);
          if(typeof state.data.date_of_birth === 'string' && state.data.date_of_birth.includes('/')) {
            state.data.date_of_birth = state.data.date_of_birth.replace(/ /g, "").replace(/\//g, "-").split('-').reverse().join('-');
          }
        }
        if (state.data.legal_rep && state.data.legal_rep.date_of_birth ) {
          if(state.data.date_of_birth instanceof Date)
            state.data.legal_rep.date_of_birth = state.data.legal_rep.date_of_birth
            .toISOString()
            .slice(0, 10);
          if(typeof state.data.legal_rep.date_of_birth === 'string' && state.data.legal_rep.date_of_birth.includes('/')) {
            state.data.legal_rep.date_of_birth = state.data.legal_rep.date_of_birth.replace(/ /g, "").replace(/\//g, "-").split('-').reverse().join('-');
          }
        }
        if (state.data.post_code.length > 6) {
          if (state.data.post_code.includes(' ')) {
            state.data.post_code = (state.data.post_code).replace(/\s/g, '')
          }
        }
        state.data.kyc_level = 'light'
        state.data.sub_type = 'unsuitable_pending'

        return axios.post(
            process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/profiles',
            state.data
          )
        .then((data) => {
          VueCookieNext.setCookie('profile', data.data, {
            path: '/',
            domain: '',
            secure: true
          })
          resolve(data)
        })
        .catch((e) => {
          commit('SET_ERROR', e)
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    submitKyc({ commit, state }, data) {
      var formData = new FormData()
      formData.append('kycFirst', data.kycFirst, data.kycFirst.name.replace(/ /g, '-'))
      if (data.kycSecond) formData.append('kycSecond', data.kycSecond, data.kycSecond.name.replace(/ /g, '-'))
      if (data.kycThird)  formData.append('kycThird', data.kycThird, data.kycThird.name.replace(/ /g, '-'))
      if (data.kycFourth) formData.append('kycFourth', data.kycFourth, data.kycFourth.name.replace(/ /g, '-'))
      if (data.kycFifth)  formData.append('kycFifth', data.kycFifth, data.kycFifth.name.replace(/ /g, '-'))

      formData.append('kyc_type', state.data.kyc_type)


      return axios.post(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/profiles/kyc',
        formData
      )   
    },

    previous({ commit, state }) {
      commit('SET_STEP', state.step - 1)
    },

    setStep({ commit }, step) {
      commit('SET_STEP', step)
    },
    setKycType({ commit }, kyc_type) {
      commit('SET_KYC_TYPE', kyc_type)
    },
    finish({ commit }) {
      commit('SET_SUCCESS')
    },
    addSeen({ commit }, data) {
      commit('SET_SEEN', data)
    },
    removeSeen({ commit }, data) {
      commit('REMOVE_SEEN', data)
    },
    add({ commit }, data) {
      commit('SET_DATA', data)
    }
  },
  getters: { 
    step1: (state) => (type) => {
      if(type == 'particular') return Boolean(!state.data.city ||
        !state.data.name || 
        !state.data.surname || 
        !state.data.address || 
        !state.data.post_code)
      else return !state.data.company_name
    },
    step2: (state, getters) => (type) => {
      if(type == 'particular'){
        if (getters.step1(type) || 
        !state.data.country ||
        !state.data.region ||
        !state.data.date_of_birth ||
        !state.data.sin ||
        !state.data.phone)
          return true
        return false
      }else{
        if(getters.step1(type) || 
        !state.data.address ||
        !state.data.post_code ||
        !state.data.region ||
        !state.data.city ||
        !state.data.country ||
        !state.data.phone ||
        !state.data.business_number ||
        !state.data.business_website ||
        !state.data.business_type_id ||
        !state.data.business_type_category_id)
          return true
        return false
      }
    },
    step3: (state, getters) => (type) => {
      if(type == 'particular'){
        if(getters.step2(type) || 
          !state.data.employment_status || 
          !state.data.employment)
          return true
        return false
      }else{
        if (getters.step2(type) || 
        !state.data.name ||
        !state.data.surname ||
        !state.data.date_of_birth ||
        !state.data.address ||
        !state.data.post_code ||
        !state.data.city ||
        !state.data.legal_country)
          return true
        return false
      }
    },
    step4: (state, getters) => (type) => {
      if(type == 'particular'){
        if(getters.step3(type) || 
          !state.data.investment_term ||
          !state.data.portfolio_percentage ||
          !state.data.goal ||
          !state.data.investment_reason)
          return true
        return false
      }else{
        if (getters.step3(type) || 
        !state.data.investment_term ||
        !state.data.investing_exp ||
        !state.data.investment_reason ||
        !state.data.portfolio_percentage ||
        !state.data.goal ||
        !state.data.risk_level)
          return true
        return false
      }
    },
    step5: (state, getters) => (type) => {
      if(type != 'particular'){
        if(getters.step4(type))
          return true
        return false
      }
      return false
    }
  }
}
