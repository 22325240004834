<template>
  <div class="project-card project-card--skeleton h-80">
    <div
      class="project-card__header project-card__header--skeleton h-1/2"
    ></div>
    <div class="project-card__body project-card__body--skeleton"></div>
  </div>
</template>

<script>
export default {
  components: {},
  setup() {}
}
</script>
