import i18n from "@/i18n";
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import { Types } from "@/assets/ts/enums/form/types";
import {set_type} from "@/assets/ts/onboarding/callbacks/handle_onboarding";

const questions:PageForm =
  {
    title: i18n.global.t('ca_legislation.onboarding.sidebar_title'),
    image: 'img/lamp-sidebar.png',
    form: [
      {
        title: i18n.global.t("ca_legislation.onboarding.questions.title_types"),
        fields: [
            {
              key: "type",
              subtitle: i18n.global.t("ca_legislation.onboarding.questions.type_text"),
              options: i18n.global.tm("ca_legislation.onboarding.questions.types"),
              type: Types.RADIO,
              rules: 'required'
            }
        ],
        next: true,
        nextCallback: set_type,
        back: true,
      }
    ]
  }

export default questions
