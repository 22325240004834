<template>
  <div class="page">
    <div class="container lg:pt-20">
      <back :to="'/loan/' + id + '/details'" />
      <div
        class="
          text-xl
          font-grey-1 font-bold
          my-8
          text-left
          absolute
          lg:relative
          -top-3
          left-12
        "
      >
        {{ $t('promoter.loan.payment.title') }}

        <span v-if="total_payments" class="text-sm text-grey-3 font-medium ml-1"
          >{{ total_payments.total_paid }}/{{ total_payments.total }}</span
        >
      </div>
      <List
        :payments="payments"
        page
        @next="handle"
        :has_next_page="has_next_page"
      />
      <Link
        :text="$t('promoter.loan.payment.send')"
        textStyle="text-sm font-semibold"
        image-first
        class="mt-8"
        @click="sendExcel"
      >
        <template #image>
          <div>
            <img
              src="@/assets/svg/arrows/down_orange_arrow.svg"
              alt="right"
              class="transform -rotate-180"
            />
          </div>
        </template>
        <template #hoverImage>
          <div>
            <img
              src="@/assets/svg/arrows/down_orange_3_arrow.svg"
              alt="right"
              class="transform -rotate-180"
            />
          </div>
        </template>
      </Link>
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import List from '@/components/promoter/List'
import Link from '@/components/general/Link'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { onMounted, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  components: { Back, List, Link },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const loading = computed(() => {
      return store.state.general.loading
    })
    const id = ref(route.params.id)
    const i18n = useI18n({ useScope: 'global' })

    const payments = computed(() => {
      return store.state.promoters.payments
    })

    const sendExcel = () => {
      store
        .dispatch('sendPaymentsExcel', id.value)
        .then(() => {
          const popup = {
            title: i18n.t('promoter.loan.payment.excel.success.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            content: i18n.t('promoter.loan.payment.excel.success.description'),
            primaryButton: i18n.t('promoter.loan.payment.excel.success.button')
          }
          store.dispatch('setPopup', popup)
        })
        .catch((e) => {
          store.dispatch('setError', e)
        })
    }

    const total_payments = computed(() => {
      return store.state.promoters.total_payments
    })

    const has_next_page = computed(() => {
      return store.state.promoters.has_next_page
    })

    const handle = async () => {
      if (has_next_page.value && !loading.value) {
        store.dispatch('setLoading', true)

        await store.dispatch('fetchPayments', route.params.id)
        store.dispatch('setLoading', false)
      }
    }

    onMounted(() => {
      if (route.params.id) {
        store.dispatch('clearPayments')
        store.dispatch('fetchPayments', route.params.id)
      } else router.push('loans')
    })
    return {
      payments,
      handle,
      loading,
      has_next_page,
      total_payments,
      id,
      sendExcel
    }
  }
}
</script>
