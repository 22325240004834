import axios from 'axios'
export default {
  state: {
    method: null,
    amount: null,
    bankAccount: null,
    error: null,
    details: null,
    amountNotEnoughConfirmation: false
  },
  mutations: {
    SET_METHOD(state, data) {
      state.method = data
    },
    SET_AMOUNT(state, data) {
      state.amount = data
    },    
    SET_BANK_ACCOUNT(state, data) {
      state.bankAccount = data
    },
    SET_DETAILS(state, data) {
      state.details = data
    }, 
    SET_CONFIRMATION(state, data) {
      state.amountNotEnoughConfirmation = data
    }
  },
  actions: {
    setMethod({ commit }, value) {
      commit('SET_METHOD', value)
    },
    setBankAccount({ commit }, value) {
      commit('SET_BANK_ACCOUNT', value)
    },
    topup({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const method = data.method ?? state.method
        axios
          .post(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/wallets/topup', {
            payin_type: method,
            amount: data.amount ?? state.amount,
            wallet_id: data.wallet_id,
            token: state.bankAccount?.token ?? null,
            return_url:
              data.return_url ??
              process.env.VUE_APP_FE_BASE_URL + 'transactions?type=topup'
          })
          .then((response) => {
            if (method === 'bankwire') commit('SET_DETAILS', response.data)
            resolve(response.data)
          })
          .catch((e) => {
            commit('SET_ERROR', e)
            reject(e)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    sendDetails({}, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/wallets/topup/send',
            { params: data }
          )
          .then((response) => {
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    setAmount({ commit }, value) {
      commit('SET_AMOUNT', value)
    },
    setConfirmation({ commit }, value) {
      commit('SET_CONFIRMATION', value)
    }
  }
}
