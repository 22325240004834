<template>
  <div>
    <div class="flex py-6 relative md:w-80" @click="sendDetails('email')">
      <img src="@/assets/svg/email.svg" alt="" class="mr-4" />
      <div>
        <div class="text-sm text-grey-2 font-semibold">
          {{ $t('settings.wallets.details.send_email') }}
        </div>
        <div v-if="profile" class="text-13 text-grey-4">
          {{ profile.email }}
        </div>
      </div>
      <img
        src="@/assets/svg/chev-right-2.svg"
        alt=""
        class="absolute right-0 top-1/2 -translate-y-1/2 transform"
      />
      <transition name="toast-fade">
        <div class="absolute right-0" v-if="show === 'email'">
          <div class="flex bg-grey-1 text-white px-4 py-2 rounded-xl">
            <img src="@/assets/svg/copy_inverted.svg" alt="" />
            <div class="ml-3 opacity-80 font-semibold text-13">
              {{ $t('wording.sent') }}
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div class="flex py-6 relative" @click="sendDetails('phone')">
      <img src="@/assets/svg/message.svg" alt="" class="mr-4" />
      <div>
        <div class="text-sm text-grey-2 font-semibold">
          {{ $t('settings.wallets.details.send_phone') }}
        </div>
        <div v-if="profile" class="text-13 text-grey-4">
          +{{ profile.country_code }} {{ profile.phone }}
        </div>
      </div>
      <img
        src="@/assets/svg/chev-right-2.svg"
        alt=""
        class="absolute right-0 top-1/2 -translate-y-1/2 transform"
      />
      <transition name="toast-fade">
        <div class="absolute right-0" v-if="show === 'phone'">
          <div class="flex bg-grey-1 text-white px-4 py-2 rounded-xl">
            <img src="@/assets/svg/copy_inverted.svg" alt="" />
            <div class="ml-3 opacity-80 font-semibold text-13">
              {{ $t('wording.sent') }}
            </div>
          </div>
        </div>
      </transition>
    </div>
    <transition name="toast-fade">
      <div class="absolute" v-if="error">
        <div class="flex bg-red-light text-red-primary px-4 py-2 rounded-xl">
          <div class="ml-3 opacity-80 font-semibold text-13">
            {{ error }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
export default {
  props: {
    profile: {
      type: Object,
      default: () => {}
    },
    walletId: String
  },
  setup(props) {
    const store = useStore()
    const show = ref(null)
    const error = ref(null)

    function sendDetails(method) {
      store
        .dispatch('sendDetails', {
          method: method,
          id: props.walletId,
          type: 'wallet'
        })
        .then(() => {
          show.value = method
          setTimeout(function () {
            show.value = null
          }, 500)
        })
        .catch((e) => {
          error.value = e.response.data
          setTimeout(function () {
            error.value = null
          }, 1000)
        })
    }

    return {
      sendDetails,
      show,
      error
    }
  }
}
</script>
