<template>
  <div class="flex-grow lg:justify-center lg:items-center flex flex-col w-full">
    <div
      class="
        flex-grow
        lg:flex-grow-0
        flex flex-col
        justify-between
        p-6
        lg:p-16
        rounded-3xl
        lg:bg-white lg:bg-opacity-60
        w-full
        lg:w-2/5 lg:mx-auto
      "
    >
      <div>
        <router-link
          v-if="closeable"
          class="flex justify-end absolute right-0"
          :class="{ 'right-8 top-8': drawer }"
          :to="primaryLink"
        >
          <img src="@/assets/svg/grey_cross.svg" alt="left_arrow" />
        </router-link>
        <div class="mt-4 lg:mx-auto w-16">
          <slot name="icon">
            <img src="@/assets/svg/success.svg" alt="email_grey_border" />
          </slot>
        </div>
        <slot name="title">
          <div
            class="
              w-full
              text-left
              lg:text-center
              text-grey-2
              font-medium
              text-xl
              my-6
            "
          >
            {{ title }}
          </div>
        </slot>
        <slot name="text">
          <div class="w-full text-left text-sm lg:text-center text-grey-3">
            {{ text }}
          </div>
        </slot>
      </div>
      <div class="mt-16">
        <slot name="button">
          <router-link :to="primaryLink">
            <GpButton desktopWidth="w-full">
              {{ primaryButton }}
            </GpButton>
          </router-link>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import GpButton from '@/components/general/GpButton.vue'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    primaryButton: {
      type: String,
      default: ''
    },
    primaryLink: {
      type: String,
      default: ''
    },
    closeable: {
      type: Boolean,
      default: true
    },
    drawer: {
      type: Boolean,
      default: false
    }
  },
  components: {
    GpButton
  },
  setup() {}
}
</script>
