<template>
  <div class="mb-12" :class="{ relative: isMobile }">
    <div class="flex justify-between mb-6">
      <div class="text-grey-2 text-xl font-semibold">
        {{ $t('promoter.loan_status.title') }}
      </div>
      <div>
        <router-link :to="'/loan/' + loanId + '/details'" class="orange-link">
          {{ $t('wording.details') }}
        </router-link>
      </div>
    </div>
    <div
      class="circle-big w-3/4 md:w-1/2 bg-white rounded-full shadow-green4"
      :style="
        '--percentage: ' +
        circlePercentage +
        '% 262%; --arrearsPercentage: ' +
        arrearsPercentage +
        '% 262%; --arrearsDegree: rotate(' +
        (-90 + arrearsDegree) +
        'deg)'
      "
      @click="show = !show"
    >
      <div
        class="
          absolute
          left-1/2
          top-1/2
          transform
          -translate-x-1/2 -translate-y-1/2
          z-20
        "
      >
        <div class="text-grey-1 font-bold text-lg md:text-2xl">
          $ {{ dollarFormatting(total) }} 
        </div>
        <div class="text-xs md:text-lg">
          {{ $t('promoter.loan_status.amount') }}
        </div>
      </div>
      <svg>
        <circle
          class="bg"
          cx="50%"
          cy="50%"
          r="42%"
          @mouseover="addHover($event, 'outstanding')"
          @mouseout="hover = ''"
        ></circle>
        <circle
          class="progress"
          cx="50%"
          cy="50%"
          r="42%"
          @mouseover="addHover($event, 'paid')"
          @mouseout="hover = ''"
          v-if="amount > 0"
        ></circle>
        <circle
          class="progress progress--light"
          cx="50%"
          cy="50%"
          r="42%"
          @mouseover="addHover($event, 'paid')"
          @mouseout="hover = ''"
          v-show="hover === 'paid'"
          v-if="amount > 0"
        ></circle>
        <circle
          class="progress progress--orange"
          cx="50%"
          cy="50%"
          r="42%"
          @mouseover="addHover($event, 'arrears')"
          @mouseout="hover = ''"
          v-if="arrears > 0"
        ></circle>
        <circle
          class="progress progress--orange progress--light-orange"
          cx="50%"
          cy="50%"
          r="42%"
          @mouseover="addHover($event, 'arrears')"
          @mouseout="hover = ''"
          v-show="hover === 'arrears'"
        ></circle>
      </svg>
    </div>
    <hr class="hidden md:block mt-16" />
    <transition name="fade">
      <div class="block md:hidden" v-if="show">
        <div
          class="
            absolute
            bg-white
            shadow-tooltip
            py-3
            px-5
            text-left
            rounded-2xl
            z-50
            left-0
            top-8
            max-w-1/2
          "
        >
          <div class="mb-1 font-semibold text-base text-grey-1">
            $ {{ dollarFormatting(amount) }} 
          </div>
          <div class="text-grey-1 text-sm">
            {{ $t('promoter.loan_status.outstanding') }}
          </div>
        </div>
        <div
          class="
            absolute
            bg-white
            shadow-tooltip
            py-3
            px-5
            text-left
            rounded-2xl
            z-50
            right-0
            top-8
            max-w-1/2
          "
        >
          <div class="mb-1 font-semibold text-base text-green-1">
            $ {{ dollarFormatting(amount) }} 
          </div>
          <div class="text-grey-1 text-sm">
            {{ $t('promoter.loan_status.paid_back') }}
          </div>
        </div>
        <div
          class="
            absolute
            bg-white
            shadow-tooltip
            py-3
            px-5
            text-left
            rounded-2xl
            z-50
            right-0
            -bottom-8
          "
        >
          <div class="mb-1 font-semibold text-base text-orange-3">
            $ {{ dollarFormatting(arrears) }} 
          </div>
          <div class="text-grey-1 text-sm">
            {{ $t('promoter.loan_status.arrears') }}
          </div>
        </div>
      </div>
    </transition>
    <div
      class="
        absolute
        bg-white
        shadow-tooltip
        py-3
        px-5
        text-left
        rounded-2xl
        z-50
      "
      :style="'left: ' + tooltip.x + '; top: ' + tooltip.y"
      v-if="hover"
    >
      <div
        class="mb-1 font-semibold text-xl"
        :class="
          hover === 'paid'
            ? 'text-green-1'
            : hover === 'outstanding'
            ? 'text-grey-1'
            : 'text-orange-3'
        "
      >
        $ {{
          dollarFormatting(
            hover === 'paid'
              ? amount
              : hover === 'outstanding'
              ? outstanding
              : arrears
          )
        }}
        
      </div>
      <div class="text-grey-1 text-lg">
        {{
          hover === 'paid'
            ? $t('promoter.loan_status.paid_back')
            : hover === 'outstanding'
            ? $t('promoter.loan_status.outstanding')
            : $t('promoter.loan_status.arrears')
        }}
      </div>
    </div>
    <div class="mt-6 hidden md:flex mb-12">
      <div class="flex items-start mr-8" v-if="amount > 0">
        <div class="h-4 w-4 bg-green-1 rounded-full mr-4 mt-1"></div>
        <div class="text-left">
          <div class="text-xl font-medium text-grey-2">
            {{ $t('promoter.loan_status.paid_back') }}
          </div>
          <div class="text-lg text-grey-1">{{ percentage }}%</div>
        </div>
      </div>
      <div class="flex items-start mr-8">
        <div class="h-4 w-4 bg-blue-dark rounded-full mr-4 mt-1"></div>
        <div class="text-left">
          <div class="text-xl font-medium text-grey-2">
            {{ $t('promoter.loan_status.outstanding') }}
          </div>
          <div class="text-lg text-grey-1">{{ outstandingPercentage }}%</div>
        </div>
      </div>
      <div class="flex items-start" v-if="arrears > 0">
        <div class="h-4 w-4 bg-orange-3 rounded-full mr-4 mt-1"></div>
        <div class="text-left">
          <div class="text-xl font-medium text-grey-2">
            {{ $t('promoter.loan_status.arrears') }}
          </div>
          <div class="text-lg text-grey-1">{{ arrearsPercentage }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useGlobalHelpers from '@/mixins/useGlobalHelpers'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  props: {
    amount: {
      type: Number,
      default: 0
    },
    arrears: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
    outstanding: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const hover = ref('')
    const lastHover = ref('')
    const show = ref(false)
    const innerWidth = ref(window.innerWidth)
    const route = useRoute()
    const store = useStore()
    const tooltip = ref({ x: '0px', y: '0px' })
    const { dollarFormatting } = useGlobalHelpers()
    const isMobile = computed(() => {
      return innerWidth.value < 768
    })

    const loans = computed(() => {
      return store.state.promoters.loans
    })
    const loanId = computed(() => {
      return route.params.id
        ? route.params.id
        : loans.value && loans.value.length > 0
        ? loans.value[0].id
        : 0
    })
    const outstandingPercentage = computed(() => {
      return Math.round(
        ((props.outstanding - props.arrears) / props.total) * 100
      )
    })
    const percentage = computed(() => {
      return Math.round((props.amount / props.total) * 100)
    })
    const circlePercentage = computed(() => {
      return Math.round((props.amount / props.total) * 261)
    })
    const arrearsPercentage = computed(() => {
      return Math.round((props.arrears / props.total) * 100)
    })
    const arrearsDegree = computed(() => {
      return Math.round((percentage.value * 180) / 50)
    })
    const arrearsCirclePercentage = computed(() => {
      return Math.round((props.arrears / props.total) * 261)
    })
    const addHover = (evt, el) => {
      tooltip.value.x = evt.pageX - 10 + 'px'
      tooltip.value.y = evt.pageY + 40 + 'px'
      hover.value = el
    }
    return {
      dollarFormatting,
      percentage,
      route,
      addHover,
      arrearsDegree,
      circlePercentage,
      loanId,
      hover,
      isMobile,
      lastHover,
      tooltip,
      arrearsPercentage,
      outstandingPercentage,
      arrearsCirclePercentage,
      show
    }
  }
}
</script>
<style scoped>
.circle-big {
  position: relative;
  height: 0px;
  max-width: 25rem;
  padding-bottom: min(50%, 25rem);
  margin: 30px auto 25px auto;
}

@media (max-width: 768px) {
  .circle-big {
    padding-bottom: min(75%, 25rem);
  }
}

.circle-big svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.circle-big .bg {
  fill: white;
  stroke-width: 13px;
  stroke: #263340;
}

.circle-big .progress {
  cursor: pointer;
  fill: none;
  stroke-width: 14px;
  stroke: #38c867;
  stroke-linecap: round;
  animation: big 1s ease-in-out;
  stroke-dasharray: var(--percentage);
  transform: rotate(-90deg);
  transform-origin: center;
}

.circle-big .progress--light {
  stroke-width: 22px;
  opacity: 0.5;
  animation: none;
}

.circle-big .progress--light-orange {
  stroke-width: 22px;
  opacity: 0.5;
  animation: none !important;
}

.circle-big .progress--orange {
  stroke: #ffb600;
  transform: var(--arrearsDegree);
  stroke-dasharray: var(--arrearsPercentage);
  animation: big-arrears 1s ease-in-out;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes big-arrears {
  from {
    stroke-dasharray: 0 262%;
  }
  to {
    stroke-dasharray: var(--arrearsPercentage);
  }
}

@keyframes big {
  from {
    stroke-dasharray: 0 262%;
  }
  to {
    stroke-dasharray: var(--percentage);
  }
}

@keyframes appear {
  0%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
