<template>
  <div class="page">
    <div class="container lg:pt-20">
      <Back :to="-1" :title="$t('projects.details.updates.title')" />
      <ul class="mt-8">
        <li
          v-for="update in updates"
          :key="update.id"
          class="
            w-full
            grid grid-cols-updatesMobile
            md:grid-cols-updatesDesktop
          "
        >
          <div class="mt-1">
            <div class="h-2 w-2 relative">
              <div
                class="
                  animate-ping
                  absolute
                  h-full
                  w-full
                  rounded-full
                  bg-green-2
                  opacity-75
                "
              />
              <div class="relative rounded-full h-2 w-2 bg-green-2" />
            </div>
            <div v-if="update !== updates.at(-1)" class="green-line ml-1" />
          </div>
          <div class="mb-8">
            <div
              :class="
                update.type === 'news'
                  ? 'bg-white px-4 py-6 rounded-2xl'
                  : 'place-items-start text-left'
              "
            >
              <div class="flex justify-between">
                <p class="text-13 text-grey-1 font-semibold text-left">
                  {{ update.title }}
                </p>
                <p
                  class="
                    text-grey-2 text-opacity-50 text-11 text-right
                    min-w-max
                  "
                >
                  {{ update.date }}
                </p>
              </div>
              <p
                class="text-grey-2 text-sm text-opacity-80 mt-2 text-left"
                v-html="update.description"
              ></p>
              <div class="flex justify-between items-end mt-6">
                <img
                  v-if="update.timeline_images.length > 0"
                  :src="update.timeline_images[0].path"
                  :title="update.timeline_images[0].title"
                  :alt="update.timeline_images[0].description"
                  :class="
                    update.type === 'news'
                      ? 'w-20 h-auto max-h-12'
                      : 'rounded-2xl w-full md:max-w-md mt-2 opacity-80 md:h-60'
                  "
                />
                <Link
                  v-if="update.link"
                  :link="update.link"
                  :text="$t('wording.read_more')"
                  textStyle="text-xs"
                >
                  <template #image>
                    <div class="transform -rotate-45">
                      <img
                        src="@/assets/svg/arrows/down_orange_arrow.svg"
                        alt="right"
                        class="transform -rotate-90"
                      />
                    </div>
                  </template>
                  <template #hoverImage>
                    <div class="transform -rotate-45">
                      <img
                        src="@/assets/svg/arrows/down_orange_3_arrow.svg"
                        alt="right"
                        class="transform -rotate-90"
                      />
                    </div>
                  </template>
                </Link>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed } from 'vue'
import Back from '@/components/general/Back.vue'
import Link from '@/components/general/Link.vue'

export default {
  components: {
    Back,
    Link
  },
  async setup() {
    const route = useRoute()
    const store = useStore()
    await store.dispatch('getProject', route.params.id)

    const project = computed(() => {
      return store.state.projects.project
    })

    const updates = computed(() => {
      return project.value.timelines
    })

    return {
      project,
      updates
    }
  }
}
</script>

<style lang="postcss" scoped>
.green-line {
  width: 0.063rem;
  @apply bg-green-2 h-full opacity-20;
}
</style>
