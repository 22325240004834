<template>
  <div class="relative">
    <div
      class="flex items-center justify-start mb-2"
      v-if="typeof nTabs === 'number'"
    >
      <div v-for="index in nTabs" :key="index" class="mr-10 lg:mr-8">
        <p
          class="text-sm text-left text-grey-2 cursor-pointer"
          :class="index === activeTab ? 'font-semibold' : 'text-opacity-50'"
          @click="changeTab(index)"
        >
          {{
            $t(labels + '.' + index, {
              labelData: labelsData[index - 1]
            })
          }}
        </p>
        <div
          class="mt-2 w-6 h-1"
          :class="index === activeTab ? 'bg-orange-2' : 'bg-none'"
        />
      </div>
    </div>
    <div class="flex items-center justify-start mb-2" v-else>
      <div v-for="(tab, index) in nTabs" :key="index" class="mr-10 lg:mr-8">
        <p
          class="text-sm text-left text-grey-2 cursor-pointer"
          :class="index + 1 === activeTab ? 'font-semibold' : 'text-opacity-50'"
          @click="changeTab(index + 1)"
        >
          {{ tab }}
        </p>
        <div
          class="mt-2 w-6 h-1"
          :class="index + 1 === activeTab ? 'bg-orange-2' : 'bg-none'"
        />
      </div>
    </div>
    <div class="h-px w-full bg-grey-2 bg-opacity-10 absolute bottom-0" />
  </div>
</template>

<script>
import { useVModel } from 'vue-composable'
// import { ref } from 'vue'
export default {
  props: {
    labels: {
      type: String,
      default: ''
    },
    labelsData: {
      type: Array,
      default: () => []
    },
    nTabs: {
      type: [Number, Array]
    },
    current: {
      type: Number,
      default: 1
    },
    anchors: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const activeTab = useVModel(props, 'current')

    const changeTab = (newTab) => {
      activeTab.value = newTab
    }

    return {
      activeTab,
      changeTab
    }
  }
}
</script>

<style lang="scss" scoped></style>
