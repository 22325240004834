<template>
  <div class="md:page ">
    <transition name="fade">
      <SideBar v-if="hasSide" class="absolute left">
        <div class="h-screen relative">
          <div class="text-left w-3/4 mx-auto absolute z-10 left-10 xl:left-20">
            <div class="text-grey-2 font-medium text-3xl">
              {{$t('giftcards.sidebar') }}
            </div>
            <div v-if="giftcards.data.length === 1" class="mt-8">
              <div style="max-width: 350px" class="mx-auto">
                <Card :name="giftcards.data[0].name" :amount="giftcards.data[0].amount" :template="giftcards.data[0].template" :message="giftcards.data[0].message" />
              </div>
            </div>
            <div v-else style="max-width: 370px" class="mx-auto mt-8">
              <div>
                <Card :name="giftcard.name" :amount="giftcard.amount" :template="giftcard.template" :message="giftcard.message" v-for="giftcard in giftcards.data" :key="giftcard.name" class="mb-4"/>
              </div>

            </div>
          </div>
        </div>
      </SideBar>
    </transition>
    <div class="md:pt-4">
      <div class="w-full actions lg:w-2/3 relative md:pt-24 min-h-screen" :class="hasSide ? 'ml-auto' : 'mx-auto lg:max-w-2/3'">
          <swiper
            class="w-full"
            :loop="false"
            @swiper="setSwiper"
            :slides-per-view="1"
            :space-between="50"
            :pagination="false"
            :allowTouchMove="false"
            :speed="400"
            :autoplay="false"
            :autoHeight="true"
            :observer="true"
            :observeParents="true"
            @slideChange="slideChange"
          >
            <swiper-slide v-slot="{ isActive }">
              <GiftcardForm
                class="w-4/5 pr-4 mx-auto mt-0 pl-4 md:pl-0"
                :class="hasSide ? 'w-4/5' : 'w-full'"
                @next="next"
                :isActive="isActive"
              />
            </swiper-slide>
            <swiper-slide v-if="isMobile()">
              <GiftcardFinalize
                class="w-4/5 pr-4 mx-auto mt-0 pl-4 md:pl-0"
                :class="hasSide ? 'w-4/5' : 'w-full'"
                @next="next"
                @previous="previous"
              />
            </swiper-slide>
            <swiper-slide >
              <GiftcardPaymentMethod
                class="w-4/5 pr-4 mx-auto mt-0 pl-4 md:pl-0"
                :class="hasSide ? 'w-4/5' : 'w-full'"
                @next="next"
                @previous="previous"
              />
            </swiper-slide>
            <swiper-slide v-slot="{ isActive }">
              <GiftcardConfirm
                class="w-4/5 pr-4 mt-0 pl-4 md:pl-0"
                :class="hasSide ? 'w-4/5 mx-auto' : 'w-full mx-auto'"
                :isActive="isActive"
                @previous="previous"
              />
            </swiper-slide>
          </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { ref, computed, onMounted, watch } from "vue";
import GiftcardForm from "@/components/giftcards/GiftcardForm";
import GiftcardPaymentMethod from "@/components/giftcards/GiftcardPaymentMethod";
import { useStore } from "vuex";
import GiftcardConfirm from "@/components/giftcards/GiftcardConfirm";
import SideBar from '@/components/general/SideBar.vue'
import Card from '@/components/giftcards/Card'
import GiftcardFinalize from "@/components/giftcards/GiftcardFinalize";
export default {
  components: {
    GiftcardFinalize,
    GiftcardConfirm,
    GiftcardPaymentMethod,
    GiftcardForm,
    Swiper,
    SideBar,
    Card,
    SwiperSlide,
  },
  setup() {
    const swiper = ref(null)
    const store = useStore()

    const step = computed(() => {
      return store.state.onboarding.step
    })

    const giftcards = computed(() => {
      return store.state.general.giftcards
    })

    const activeIndex = computed(()=>{
      return swiper.value?.activeIndex
    })

    const hasSide = computed(()=>{
      return !isMobile() && (activeIndex.value === 3 || (activeIndex.value === 2 && ((wallets.value && wallets.value.length === 1) || giftcards.value && giftcards.value.method !== 'wallet')))
    })

    function previous(){
      swiper.value.slideTo(swiper.value.activeIndex - 1)
    }

    // Triggered when a slide change occurs, get the configs of slide (if has back button, next button, etc...)
    function slideChange() {
      var ro = new ResizeObserver(() => {
        swiper.value.update()
      });
      // Observe one or multiple elements
      ro.observe(swiper.value.slides[swiper.value.activeIndex]);
    }

    const wallets = computed(() => {
      return [store.state.wallets.wallet]
    })

    function next(){
      store.dispatch('setStep', swiper.value.activeIndex + 1)
      swiper.value.slideTo(swiper.value.activeIndex + 1)
    }

    function isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    }

    watch(
      () => step.value,
      () =>{
        swiper.value?.slideTo(step.value)
      }
    )

    onMounted(async ()=> {
      await store.dispatch('fetchWallets')
    })

    // Initialize swiper instance (Add swiper component to swiper reference)
    function setSwiper(value) {
      swiper.value = value
      var ro = new ResizeObserver(() => {
        swiper.value.update()
      });
      // Observe one or multiple elements
      ro.observe(swiper.value.slides[0]);
    }

    return {
      slideChange,
      swiper,
      wallets,
      next,
      activeIndex,
      isMobile,
      hasSide,
      previous,
      setSwiper,
      giftcards
    }
  }
}
</script>
