<template>
  <div class="dropdown inline-block relative text-sm">
    <span class="text-grey-2 opacity-50">{{ $t('promoter.loans.order') }}</span>
    <button
      class="text-grey-2 py-2 px-2 rounded inline-flex items-center"
      @click="show = !show"
      @blur="hide"
    >
      <span class="mr-1 font-semibold">{{
        $t('promoter.loans.tabs.' + state + '.orders.' + order)
      }}</span>
      <svg
        class="fill-current h-4 w-4 opacity-50"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
        />
      </svg>
    </button>
    <ul
      class="
        dropdown-menu
        absolute
        text-gray-700
        z-50
        rounded-2xl
        bg-white
        right-0
        py-3
      "
      :class="{ hidden: !show }"
    >
      <li
        class=""
        v-for="(option, index) in options"
        v-bind:key="index"
        @click="setOrder(index)"
      >
        <div class="flex items-center">
          <div
            class="ml-4 w-4 h-4 rounded-full relative"
            :class="index === order ? 'bg-green-2' : 'bg-grey-6'"
          >
            <div
              class="
                bg-white
                w-2
                h-2
                absolute
                left-1/2
                top-1/2
                transform
                z-40
                rounded-full
                -translate-y-1/2 -translate-x-1/2
              "
              v-if="index === order"
            ></div>
          </div>
          <div
            class="
              text-sm
              py-3
              px-4
              block
              whitespace-no-wrap
              cursor-pointer
              text-left
              hover:font-semibold
              text-grey-2
            "
            :class="{ 'font-semibold': index === order }"
          >
            {{ option }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { watch } from 'vue'

export default {
  components: {},
  props: {
    state: String
  },
  setup(props) {
    const show = ref(false)
    const store = useStore()
    const i18n = useI18n({ useScope: 'global' })

    const options = ref(
      i18n.tm('promoter.loans.tabs.' + props.state + '.orders')
    )

    store.dispatch('setLoanOrder', Object.keys(options.value)[0])

    const order = computed(() => {
      return store.state.promoters.order
    })

    watch(
      () => props.state,
      () => {
        options.value = i18n.tm(
          'promoter.loans.tabs.' + props.state + '.orders'
        )
        store.dispatch('setLoanOrder', Object.keys(options.value)[0])
      }
    )

    function hide() {
      setTimeout(function () {
        show.value = false
      }, 200)
    }

    function setOrder(order) {
      store.dispatch('setLoanOrder', order)
    }

    return { show, options, setOrder, order, hide }
  }
}
</script>
