<template>
  <div
    class="
      z-50
      bg-opacity-50
      h-screen
      w-full
      fixed
      top-0
      left-0
      lg:relative lg:h-auto lg:bg-opacity-0
    "
    :class="{ 'bg-grey-2': background }"
  >
    <div
      @click="close"
      class="absolute top-0 w-full z-30 h-screen"
      v-if="background"
    />

    <div
      class="
        absolute
        lg:relative
        z-50
        w-full
        rounded-t-2xl
        lg:rounded-2xl
        bottom-0
        left-0
        pb-12
      "
      :class="[
        expanded ? maxHeight : minHeight + ' lg:h-auto',
        grey ? 'bg-grey-7' : 'bg-white'
      ]"
    >
      <div class="flex flex-col w-full h-full px-6 pb-4 lg:py-6">
        <div class="w-full py-2 lg:hidden">
          <img
            src="@/assets/svg/arrows/left_grey_arrow.svg"
            alt="left arrow"
            class="transform w-4 h-4 mx-auto"
            :class="expanded ? '-rotate-90' : 'rotate-90'"
            @click="() => (expanded = !expanded)"
          />
        </div>
        <!-- For draggable -->
        <div
          v-if="false"
          class="h-1 w-8 my-4 bg-grey-2 bg-opacity-20 mx-auto lg:hidden"
          @click="() => (expanded = !expanded)"
        />
        <div class="overflow-y-auto">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, ref } from 'vue'
export default {
  props: {
    maxHeight: {
      type: String,
      default: 'h-full'
    },
    minHeight: {
      type: String,
      default: 'h-1/2'
    },
    blur: {
      type: Boolean,
      default: true
    },
    background: {
      type: Boolean,
      default: true
    },
    grey: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const expanded = ref(false)
    const { emit } = getCurrentInstance()
    const close = () => {
      emit('close')
    }
    return { expanded, close }
  }
}
</script>
