<template>
  <div class="page">
    <div class="container lg:pt-20">
      <div
        class="absolute lg:relative lg:top-auto lg:left-auto top-8 left-6 z-50"
      >
        <back white />
      </div>
      <template v-if="loan">
        <div
          class="
            relative
            px-6
            lg:px-10
            mb-8
            lg:rounded-3xl lg:pt-7
            bg-cover bg-center
            pb-8
          "
          :style="
            loan.image
              ? 'box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.4); background-image: url(\'' +
                loan.image.path +
                '\')'
              : 'box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.2);'
          "
        >
          <div class="text-white text-3xl font-medium text-left">
            {{ loan.name }}
          </div>
          <div class="mt-6 text-left">
            <div v-if="loan.project_wallet_balance">
              <p class="font-medium mb-2 text-white">
                {{ $t('home.balance') }}
              </p>
              <p class="text-5xl mb-12 text-white">
                <span class="font-bold">${{
                  dollarFormatting(loan.project_wallet_balance).split('.')[0]
                }}</span>
                <span
                  >.{{
                    dollarFormatting(loan.project_wallet_balance).split('.')[1] ??
                    '00'
                  }}</span
                >
              </p>
            </div>
          </div>
          <NextPaymentCard
            :daysLeft="loan.next_payment.days_to_next_payment"
            :amount="loan.next_payment.amount"
            :type="
              isArrears
                ? 'arrears'
                : !loan.next_direct_debit
                ? 'default'
                : loan.next_direct_debit.status ?? 'default'
            "
            :arrearsDate="loan.loan_status.in_arrears_since"
          />
        </div>
        <div class="container sm:px-6 lg:px-0 sm:max-w-none">
          <LoanCircle
            class="mb-6"
            :total="loan.loan_status.loan_amount"
            :arrears="loan.loan_status.in_arrears"
            :amount="loan.loan_status.capital_paid"
            :outstanding="loan.loan_status.capital_outstanding"
          />
        </div>
        <div class="container sm:px-6 lg:px-0 sm:max-w-none">
          <SingleOverdueWarning v-if="isArrears" class="mb-4" :loan="loan" />
          <div
            v-if="!isArrears"
            @click="goToDirectDebitDetails()"
            class="cursor-pointer"
          >
            <NextDD
              class="mb-4"
              :state="nextDD.status"
              :amount="nextDD.amount"
              :fee="dollarFormatting(nextDD.failed_dd_fee)"
              :days="nextDD.status != 'SUCCEEDED' ? nextDD.days_left : '8'"
              :date="nextDD.execution_date"
              v-if="nextDD"
            />
          </div>

          <NextDDWarning
            v-if="nextDD && (!nextDD.status || nextDD.status === 'CREATED')"
            class="mb-4"
          />

          <FeeWarning
            :amount="loan.next_payment.amount - loan.project_wallet_balance"
            :date="loan.next_payment.next_payment_date"
            v-if="
              loan.project_wallet_balance < loan.next_payment.amount &&
              !nextDD &&
              !isArrears
            "
            class="mb-4"
          />
          <GpButton
            color="grey"
            class="mt-4 mx-auto"
            desktopWidth="w-64"
            mobileWidth="w-full"
            @click="goToTopup"
            v-if="
              loan.next_payment.amount > 0 &&
              !isArrears &&
              (!nextDD ||
                (loan.project_wallet_balance < loan.next_payment.amount &&
                  !nextDD) ||
                (nextDD && nextDD.status === 'FAILED'))
            "
          >
            {{ '+ ' + $t('home.topup_long') }}
          </GpButton>
        </div>
        <div class="w-full flex items-center justify-between mb-6 mt-12">
          <p class="text-grey-2 text-left text-opacity-50">
            {{ $t('home.latest_activity') }}
          </p>
          <router-link
            v-if="transactions && transactions.length"
            class="grey-link"
            :to="'/transactions'"
            @click="setWallet"
          >
            {{ capitalize($t('wording.see_all')) }}
            <img
              src="@/assets/svg/arrows/right_dark_grey_arrow.svg"
              alt="right"
              class="ml-1"
            />
          </router-link>
        </div>

        <div
          v-if="!transactions || !transactions.length"
          class="
            bg-white bg-opacity-60
            lg:bg-transparent
            rounded-2xl
            py-4
            lg:p-0
            mb-8
          "
        >
          <TransactionsListElement :transaction="{ type: 'empty' }" isLast />
        </div>
        <div
          v-else
          class="bg-white lg:bg-transparent rounded-2xl py-4 lg:p-0 mb-8"
        >
          <TransactionsListElement
            v-for="(transaction, index) of transactions"
            :key="transaction.id"
            :transaction="transaction"
            :isLast="index + 1 === transactions.length"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton'
import NextPaymentCard from '@/components/promoter/NextPaymentCard'
import LoanCircle from '@/components/promoter/LoanCircle'
import SingleOverdueWarning from '@/components/promoter/SingleOverdueWarning'
import NextDD from '@/components/promoter/NextDD'
import NextDDWarning from '@/components/promoter/NextDDWarning'
import FeeWarning from '@/components/promoter/FeeWarning'
import TransactionsListElement from '@/components/transactions/TransactionListElement.vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { onMounted, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import useGlobalHelpers from '@/mixins/useGlobalHelpers'
export default {
  components: {
    Back,
    NextPaymentCard,
    LoanCircle,
    SingleOverdueWarning,
    NextDD,
    NextDDWarning,
    FeeWarning,
    TransactionsListElement,
    GpButton
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const i18n = useI18n({ useScope: 'global' })
    const { dollarFormatting, dateFormatting, capitalize, slugIt } =
      useGlobalHelpers()
    const id = ref(route.params.id)
    const projectWallet = computed(()=>{
      return store.state.wallets.promoterWallets ? store.state.wallets.promoterWallets.find((el)=>{return el.project_id == route.params.id}) : null
    })

    const loan = computed(() => {
      return store.state.promoters.loan
    })

    const nextDD = computed(() => {
      return loan.value ? loan.value.next_direct_debit : null
    })

    const isArrears = computed(() => {
      return (
        loan.value &&
        loan.value.loan_status &&
        loan.value.loan_status.in_arrears > 0
      )
    })

    const transactions = computed(() => {
      return store.state.transactions.transactions
    })

    const tableData = computed(() => {
      let data = {}
      data.top = []
      data.bottom = []
      if (loan.value) {
        i18n.tm('promoter.loan.table.top').forEach((el) => {
          if (
            el.value !== 'extra_payments' ||
            (el.value === 'extra_payments' &&
              loan.value.loan_status.extra_payments > 0)
          ) {
            let helper = {
              title: el.title,
              value: '$' + dollarFormatting(loan.value.loan_status[el.value]),
              subtitle: []
            }
            el.subitems.forEach((subitem) => {
              helper.subtitle.push({
                title: subitem.title,
                value:
                  '$' + dollarFormatting(loan.value.loan_status[subitem.value]),
                underline: subitem.underline
              })
            })
            data.top.push(helper)
          }
        })
        i18n.tm('promoter.loan.table.bottom').forEach((el) => {
          let helper = {
            title: el.title,
            value: dateFormatting(new Date(loan.value[el.value].slice(0, 10).replace(/-/g, "/"))),
            subtitle: []
          }

          data.bottom.push(helper)
        })
      }
      return data
    })

    function goToDirectDebitDetails() {
      if (loan.value)
        router.push({
          name: 'dd-details',
          params: { id: loan.value.id }
        })
      else router.push({name: 'dashboard'})
    }

    function setWallet(){
      store.dispatch('addFilters', {
        filters: {
          categories: {},
          date: {from: null, to: null},
          wallet: projectWallet.value.id
        },
        requestFilters: {
          categories: [],
          date: {from: null, to: null},
          wallet: projectWallet.value.id
        }
      })
    }

    const projectSlug = computed(() => {
      return loan.value.name
        ? slugIt(loan.value.name) + '-' + loan.value.id
        : loan.value.id
    })

    const goToTopup = () => {
      if (loan.value)
        router.push({
          path: '/topup/' + projectSlug.value
        })
      else router.push({name: 'dashboard'})
    }

    onMounted(async () => {
      if (route.params.id) store.dispatch('fetchLoan', route.params.id)
      else router.push('loans')
      store.dispatch('fetchWallets')
      await store.dispatch('resetNextPage')
      store.dispatch('fetchTransactions', {
        page: 1,
        total: 4,
        group: false,
        project_id: route.params.id
      })
    })

    return {
      loan,
      tableData,
      id,
      setWallet,
      dollarFormatting,
      nextDD,
      isArrears,
      transactions,
      capitalize,
      goToTopup,
      goToDirectDebitDetails
    }
  }
}
</script>
