import axios from 'axios'

export default {
  state: {
    suitability: null
  },
  mutations: {
    SET_SUITABILITY(state, data) {
      state.suitability = data
    }
  },
  actions: {
    fetchSuitability({ commit }) {

        return axios
          .get(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/suitability'
          )
          .then((data) => {
            commit('SET_SUITABILITY', data.data)
          })
          .catch((e) => {
            commit('SET_ERROR', e)
          })
    },
    updateSuitability({ commit }, suitability) {
      return axios
        .put(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/suitability/' +
            suitability.id,
            suitability
        )
        .then((data) => {
          commit('SET_SUITABILITY', data.data)
        })
        .catch((e) => {
          commit('SET_ERROR', e)
        })
    }
  },
  getter: {}
}
