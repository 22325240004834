<template>
  <div class="home">
    <SideBar>
      <div class="h-screen relative">
        <img
          src="@/assets/img/mountain-sidebar.png"
          alt=""
          class="absolute bottom-28 opacity-80 z-negative"
        />
        <div
          v-if="investment && investment.transaction && wallet"
          class="px-16 text-grey-3"
        >
          <div class="text-3xl text-grey-3 font-medium text-left mb-10">
            {{ $t('invest.proof.sidebar.title') }}
            <span class="text-grey-2">{{
              investment.transaction.description
            }}</span>
          </div>
          <div class="flex justify-between mb-3">
            <div>{{ $t('wording.wallet') }}</div>
            <div class="font-semibold">{{ wallet_name }}</div>
          </div>
          <div class="flex justify-between mb-3">
            <div>{{ $t('topup.sidebar.wallet_balance') }}</div>
            <div class="font-semibold">
              ${{ dollarFormatting(wallet.balance ?? 0) }} 
            </div>
          </div>
          <div class="flex justify-between text-orange-1">
            <div>{{ $t('invest.proof.sidebar.need') }}</div>
            <div class="font-semibold">
              ${{
                dollarFormatting(
                  Math.max(
                    investment.transaction.amount - (wallet.balance ?? 0),
                    0
                  )
                )
              }}
              </div>
          </div>
        </div>
      </div>
    </SideBar>
    <div
      class="
        w-full
        lg:w-2/3
        ml-auto
        min-h-screen
        relative
        text-left
        lg:pt-28
        z-0
      "
    >
      <router-link to="/dashboard">
        <div class="block md:hidden absolute right-9 top-6 z-50">
          <img src="@/assets/svg/cross.svg" alt="" />
        </div>
      </router-link>
      <proof-upload
        v-if="investment"
        class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
        :investment="investment.transaction.transfer_investment_id"
        :hours="investment.investment_expiration_warning.hours_to_expire"
      />
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/general/SideBar.vue'
import ProofUpload from '@/components/invest/ProofUpload.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    SideBar,
    ProofUpload
  },
  props: {
    reserved: String,
    projectName: String,
    hours: String
  },
  name: 'home',
  setup() {
    const reservedData = ref(null)
    const loading = ref(false)
    const { dollarFormatting } = useGlobalHelpers()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const i18n = useI18n()
    const wallet = computed(() => {
      return store.state.wallets.wallet
    })
    const investment = computed(() => {
      return store.state.invest.investment
    })
    const wallet_name = computed(() => {
      if (wallet.value && wallet.value.saving_model)
        return wallet.value.saving_model.name
      return i18n.t('transactions.filters.main_wallet')
    })

    onMounted(async () => {
      if (parseInt(route.query.id))
        store
          .dispatch('fetchInvestment', route.query.id)
          .then(() => {
            store.dispatch(
              'fetchWallet',
              investment.value.transaction.vp_wallet_id
            )
          })
          .catch((e) => {
            console.log(e)
            router.push({name: 'dashboard'})
          })
      else router.push({name: 'dashboard'})
    })

    return {
      name,
      dollarFormatting,
      investment,
      loading,
      wallet,
      reservedData,
      wallet_name
    }
  }
}
</script>
