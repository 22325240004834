<template>
  <div class="page flex">
    <div
      class="container flex-grow flex flex-col lg:pt-20 justify-between"
      v-if="strategy"
    >
      <div>
        <Back :to="'/dashboard'" :title="$t('strategies.edit.title')" />
        <EditStrategiesListElement
          :title="$t('strategies.edit.menu.plan_name')"
          :description="strategy.name"
          :icon="'plan_name'"
          :link="'edit-name'"
        />
        <EditStrategiesListElement
          :title="$t('strategies.edit.menu.plan_owner')"
          :description="
            strategy.beneficiary_name
              ? strategy.beneficiary_name
              : $t('wording.you')
          "
          :icon="'owner'"
          :link="'edit-owner'"
        />
        <EditStrategiesListElement
          :title="$t('strategies.edit.menu.direct_debit')"
          :description="
            '$' + dollarFormatting(strategy.monthly_amount) + 
            $t('strategies.edit.direct_debit.on_day') + ' ' +
            strategy.month_day +
            ' ' +
            $t('strategies.setup.direct_debit.daypicker.periodicity')
          "
          :icon="'dd'"
          :link="'direct-debit'"
        />
        <EditStrategiesListElement
          :title="$t('strategies.edit.menu.investment')"
          :description="
            strategy.auto_investments.length
              ? strategy.auto_investments[0].balance_percentage +
                $t('strategies.edit.menu.investment_description') +
                strategy.auto_investments[0].max_risk +
                ' ' +
                $t('wording.risk')
              : ''
          "
          :icon="'investment'"
          :link="'investment'"
        />
        <EditStrategiesListElement
          :title="$t('strategies.edit.menu.project_max')"
          :description="
            strategy.auto_investments.length
              ? strategy.auto_investments[0].project_max ? strategy.auto_investments[0].project_max / 100 +
                $t('strategies.edit.menu.project_max_description') : $t('strategies.edit.menu.no_project_max_description')
              : ''
          "
          :icon="'project_max'"
          :link="'max-amount'"
        />
      </div>
      <p
        @click="deleteStrategy"
        class="
          text-orange-1
          font-bold
          text-center
          lg:text-right
          cursor-pointer
          hover:underline
        "
      >
        {{ $t('strategies.edit.remove').toUpperCase() }}
      </p>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount } from 'vue'
import Back from '@/components/general/Back.vue'
import EditStrategiesListElement from '@/components/strategies/EditStrategiesListElement.vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import axios from 'axios'
export default {
  components: {
    Back,
    EditStrategiesListElement
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { dollarFormatting } = useGlobalHelpers()

    onBeforeMount(async () => {
      if (route.params.id !== -1) {
        await store.dispatch('setLoading', true)
        await store.dispatch('fetchStrategy', route.params.id.split('-').pop())
        await store.dispatch('setLoading', false)
      }
      if (!strategy.value.status) router.push({name: 'dashboard'})
    })

    const strategy = computed(() => {
      return store.state.strategies.strategy
    })

    const deleteStrategy = () => {
      store.dispatch('setLoading', true)
      axios
        .delete(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/strategies/' +
            strategy.value.id
        )
        .then(() => {
          store.dispatch('setLoading', false)
          router.push({name: 'dashboard'})
        })
        .catch((e) => {
          store.dispatch('setLoading', false)
          store.dispatch('setError', e)
        })
    }

    return {
      strategy,
      deleteStrategy,
      dollarFormatting
    }
  }
}
</script>

<style lang="scss" scoped></style>
