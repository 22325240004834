<template>
  <div class="page flex">
    <SuccessCard
      v-if="hasChanges"
      :title="$t('settings.discard_changes.title')"
      :text="$t('settings.discard_changes.content')"
      :closeable="false"
    >
      <template #icon>
        <img
          src="@/assets/svg/exclamation_grey_border.svg"
          alt="exclamation icon"
        />
      </template>
      <template #button>
        <div class="lg:grid lg:grid-cols-2 lg:gap-5">
          <GpButton
            color="white"
            class="mb-4 mx-auto"
            desktopWidth="w-full"
            @click="discard"
          >
            {{ $t('wording.discard_changes') }}
          </GpButton>
          <GpButton @click="cancel" class="mx-auto" desktopWidth="w-full">
            {{ $t('wording.no_go_back') }}
          </GpButton>
        </div>
      </template>
    </SuccessCard>
    <div v-else class="lg:pt-20 container flex-grow min-p-button relative">
      <div v-if="beneficiaryData" class="flex flex-col justify-between">
        <div @click="checkChanges">
          <Back
            :to="null"
            :title="$t('settings.beneficiary_information.details_title')"
          />
        </div>
        <div v-if="kycsConditions" class="flex flex-col justify-between">
          <div class="flex text-left text-grey-3 mb-6 text-sm">
            {{
              $t(
                'settings.personal_details.status.' +
                  kycsConditions.localesPath +
                  '.text'
              )
            }}
            <p
              v-if="
                kycsConditions.localesPath !== 'validation_asked' &&
                kycsConditions.localesPath !== 'validated'
              "
              @click="validate"
              class="flex orange-link text-xs cursor-pointer ml-1"
            >
              {{
                $t(
                  'settings.personal_details.status.' +
                    kycsConditions.localesPath +
                    '.btn'
                )
              }}
            </p>
          </div>
        </div>

        <Form class="form" ref="form" v-slot="{ errors, meta }">
          <GeneralInput
            v-model:data="beneficiaryData.name"
            type="string"
            name="names"
            :label="$t('settings.beneficiary_information.first_name')"
            :locked="validationAsked || kycSubmitted"
            :noNumbersOrSymbols="true"
            rules="required|alphabet|maxLength:20"
            :error="errors.names"
          />
          <GeneralInput
            v-model:data="beneficiaryData.surname"
            type="string"
            name="surnames"
            :label="$t('settings.beneficiary_information.last_name')"
            :locked="validationAsked || kycSubmitted"
            :noNumbersOrSymbols="true"
            rules="required|alphabet|maxLength:30"
            :error="errors.surnames"
          />

          <GeneralInput
            ref="dateInput"
            v-model:data="beneficiaryData.date_of_birth"
            type="date"
            name="date_of_birth"
            :label="$t('settings.beneficiary_information.date_of_birth')"
            :locked="validationAsked || kycSubmitted"
            :required="true"
          />

          <GeneralInput
            v-model:data="beneficiaryData.legal_country"
            type="countries"
            name="country_of_residence"
            :label="$t('settings.beneficiary_information.country_of_residence')"
            :locked="validationAsked || kycSubmitted"
            :required="true"
          />

          <GeneralInput
            v-if="regionCountries.includes(beneficiaryData.legal_country)"
            v-model:data="beneficiaryData.region"
            type="text"
            name="region"
            :label="$t('settings.beneficiary_information.region')"
            :locked="validationAsked ? true : false"
            rules="required|alphabet|maxLength:15"
            :error="errors.region"
          />

          <GeneralInput
            v-model:data="beneficiaryData.address"
            type="text"
            name="address"
            :label="$t('settings.beneficiary_information.address')"
            :addressSymbols="true"
            :locked="validationAsked ? true : false"
            rules="required|alphabet|maxLength:40"
            :error="errors.address"
            :autocomplete="true"
          />

          <GeneralInput
            v-model:data="beneficiaryData.city"
            type="text"
            name="city"
            :label="$t('settings.beneficiary_information.city')"
            :locked="validationAsked ? true : false"
            rules="required|alphabet|maxLength:25"
            :error="errors.city"
          />

          <GeneralInput
            v-model:data="beneficiaryData.post_code"
            type="tel"
            name="post_code"
            :label="$t('settings.beneficiary_information.zipCode')"
            :zipCode="true"
            :country="beneficiaryData.legal_country"
            :pattern="beneficiaryData.legal_country === 'PT' ? '[0-9\-]+' : '*'"
            :maxLength="beneficiaryData.legal_country === 'PT' ? '8' : '32'"
            :locked="validationAsked ? true : false"
            rules="required|zip|maxLength:20"
            :error="errors.post_code"
          />

          <GeneralInput
            v-model:data="beneficiaryData.country_of_birth"
            type="countries"
            name="nationality"
            :label="$t('settings.beneficiary_information.nationality')"
            :locked="validationAsked || kycSubmitted"
            :required="true"
          />

          <GeneralInput
            v-model:data="beneficiaryData.city_of_birth"
            type="text"
            name="city_of_birth"
            :label="$t('settings.beneficiary_information.city_of_birth')"
            :locked="validationAsked || kycSubmitted"
            rules="required|alphabet|maxLength:25"
            :error="errors.city_of_birth"
          />

          <div
            class="
              absolute
              bottom-0
              w-screen
              container
              left-1/2
              -translate-x-1/2
              transform
            "
          >
            <div class="w-full relative">
              <GpButton
                :disabled="!(isDirty || meta.dirty) || !meta.valid"
                @click="updateDetails"
                class="absolute bottom-8 right-0"
              >
                {{ $t('wording.save') }}
              </GpButton>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { Form } from 'vee-validate'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import useRules from '@/assets/js/rules'
import GeneralInput from '@/components/general/Input.vue'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import SuccessCard from '@/components/general/SuccessCard.vue'

export default {
  components: {
    GeneralInput,
    Back,
    Form,
    GpButton,
    SuccessCard
  },
  props: {
    beneficiary: { type: String, default: '' }
  },

  setup(props) {
    const router = useRouter()
    const store = useStore()
    const i18n = useI18n()
    const { isRequired } = useRules()
    const { prettierDate } = useGlobalHelpers()
    const beneficiaryData = ref(null)
    const regionCountries = ref(['CA', 'MX', 'US'])
    const form = ref(null)
    const dateOfBirth = ref(null)
    const citizenship = ref(null)
    const legalCountry = ref(null)
    const loading = computed(() => {
      return store.state.general.loading
    })
    const initialProfile = ref(null)
    const hasChanges = ref(null)

    const kycsConditions = computed(() => {
      return store.state.kycs.conditions
    })

    const kycSubmitted = computed(() => {
      if (kycsConditions.value) {
        return ['validated', 'document_failed'].includes(
          kycsConditions.value.localesPath
        )
      } else {
        return false
      }
    })

    const validationAsked = computed(() => {
      if (kycsConditions.value) {
        return ['validation_asked'].includes(kycsConditions.value.localesPath)
      } else {
        return false
      }
    })

    onMounted(async () => {
      if (!props.beneficiary) {
        router.push({
          name: 'settings-beneficiary-information'
        })
      } else {
        beneficiaryData.value = JSON.parse(props.beneficiary)
        if (!store.state.kycs.kycs) {
          store.dispatch('setLoading', true)
          await store.dispatch('getKycs')
        }
        if (!kycsConditions.value) {
          store.dispatch('setLoading', true)
          const conditions = await store.getters.getKycsConditions()
          await store.dispatch('setKycsConditions', conditions)
        }
        dateOfBirth.value = beneficiaryData.value.date_of_birth
        citizenship.value = beneficiaryData.value.country_of_birth
        legalCountry.value = beneficiaryData.value.legal_country
        initialProfile.value = JSON.parse(JSON.stringify(beneficiaryData.value))
        store.dispatch('setLoading', false)
      }
    })

    const updateDetails = async () => {
      store.dispatch('setLoading', true)
      if (
        !regionCountries.value.includes(beneficiaryData.value.legal_country)
      ) {
        beneficiaryData.value.region = null
      }
      beneficiaryData.value.date_of_birth = prettierDate(
        beneficiaryData.value.date_of_birth
      )
      await store.dispatch('updateRepresentative', beneficiaryData.value)
      await store.dispatch('fetchRepresentatives')
      form.value.resetForm()
      store.dispatch('setLoading', false)
      const popup = {
        title: i18n.t('settings.add_beneficiary.success_popup.title'),
        align: 'center',
        side: false,
        iconBg: 'bg-green-5',
        icon: '/img/done_step.svg',
        primaryButton: i18n.t('settings.add_beneficiary.success_popup.btn')
      }
      store.dispatch('setPopup', popup)
      router.push('/settings/beneficiary-information')
    }

    const isDirty = computed(() => {
      return (
        JSON.stringify(beneficiaryData.value) !==
        JSON.stringify(initialProfile.value)
      )
    })

    const validate = () => {
      //TODO
    }

    const checkChanges = () => {
      if (isDirty.value === true) {
        hasChanges.value = true
      } else {
        router.go(-1)
      }
    }

    const discard = () => {
      router.go(-1)
    }

    const cancel = () => {
      hasChanges.value = false
    }

    return {
      kycsConditions,
      isRequired,
      regionCountries,
      updateDetails,
      beneficiaryData,
      kycSubmitted,
      validationAsked,
      form,
      prettierDate,
      isDirty,
      loading,
      validate,
      checkChanges,
      hasChanges,
      discard,
      cancel
    }
  }
}
</script>

<style lang="sass" scoped></style>
