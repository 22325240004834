<template>
  <div v-if="timeElapsed">
    <div class="text-center">
      <div class="flex justify-center">
        <img
          src="@/assets/svg/timer.svg"
          alt="timer"
          class="bg-grey-6 p-6 w-16 rounded-3xl"
        />
      </div>
      <p class="font-bold text-lg text-grey-2 mb-9 mt-6">
        {{ $t('errors.auto_logout.title') }} {{ timeElapsed }}
        {{ $t('wording.seconds') }}
      </p>
    </div>
    <div class="text-left" id="popup-auto-logout">
      <p class="text-sm text-grey-2 text-opacity-60">
        {{ $t('errors.auto_logout.description') }}
        <strong> {{ timeElapsed }} </strong>
        {{ $t('wording.seconds') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    timeElapsed: {
      type: Number,
      default: 0
    }
  }
}
</script>
