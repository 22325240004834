<template>
  <div class="page-no-nav flex">
    <div class="lg:pt-20 container flex-grow relative min-p-button">
      <div class="mb-6" @click="checkChanges">
        <Back :to="null" :title="$t('settings.fiscal_information.title')" />
      </div>
      <div
        v-if="profile && kycsConditions"
        class="flex flex-col justify-between"
      >
        <div
          v-if="kycsConditions.localesPath === 'kyc_not_submitted'"
          class="flex text-left text-grey-3 mb-6 text-sm">
          {{
            $t(
              'settings.personal_details.status.' +
                kycsConditions.localesPath +
                '.text'
            )
          }}
          <router-link
            to="/dashboard/kyc"
            class="flex orange-link text-xs cursor-pointer ml-1"
          >
            {{
              $t(
                'settings.personal_details.status.' +
                  kycsConditions.localesPath +
                  '.btn'
              )
            }}
          </router-link>
        </div>
        <Form class="form" ref="form" v-slot="{ errors, meta }">
          <GeneralInput
            ref="countryInput"
            v-model:data="profile.country"
            type="countries"
            name="country"
            :label="$t('settings.fiscal_information.country')"
            :locked="kycRegular"
            rules="required"
          />

          <GeneralInput
            v-model:data="profile.region"
            type="multiselect"
            :options="$tm('onboarding.fields.provsLender')"
            name="region"
            rules="required"
            :locked="kycRegular"
            :label="$t('onboarding.steps.0.fields.region')"
          />
          
          <GeneralInput
            v-model:data="profile.address"
            type="text"
            name="address"
            :label="$t('settings.fiscal_information.address')"
            :addressSymbols="true"
            rules="required|address|maxLength:80"
            :error="errors.address"
            :autocomplete="true"
            :locked="kycRegular"
          />

          <GeneralInput
            v-model:data="profile.post_code"
            type="text"
            name="post_code"
            :label="$t('settings.fiscal_information.post_code')"
            rules="required|exactLength:6"
            :error="errors.post_code"
            :locked="kycRegular"
          />

          <GeneralInput
            v-model:data="profile.city"
            type="text"
            name="city"
            :label="$t('settings.fiscal_information.city')"
            rules="required|maxLength:25"
            :error="errors.city"
            :locked="kycRegular"
          />
          <div
            class="
              w-full
              text-left text-grey-3
              lg:text-grey-4
              p-4
              mt-8
              mb-10
              text-13
              border-2
              rounded-2xl
            "
          >
            {{ $t('settings.fiscal_information.update_info_text') }}
            <div
              class="
                inline
                text-left
                mt-9
                lg:mt-4
                mb-6
                text-orange-1
                text-sm
                font-bold
              "
              >
              <a :href="`mailto:${$t('settings.fiscal_information.update_info_link')}`">
                {{ $t('settings.fiscal_information.update_info_link') }}
              </a> 
            </div>
          </div>
          <div>
            <div class="w-full relative">
              <GpButton
                :disabled="!(isDirty || meta.dirty) || !meta.valid || loading"
                @click="updateDetails"
                class="ml-auto"
              >
                {{ $t('settings.fiscal_information.success_btn') }}
              </GpButton>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import useProfile from '@/mixins/useProfile.js'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Form } from 'vee-validate'
import useRules from '@/assets/js/rules'
import GeneralInput from '@/components/general/Input.vue'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import _ from 'lodash'

export default {
  components: {
    GeneralInput,
    Back,
    Form,
    GpButton
  },
  setup() {
    const i18n = useI18n()
    const store = useStore()
    const router = useRouter()
    const { updateProfile } = useProfile()
    const { isRequired } = useRules()
    const form = ref(null)
    const hasChanges = ref(null)
    const initialProfile = ref(null)
    const isBorrower = computed(() => {
      return profile.value && profile.value.sub_type === 'borrower'
    })

    const profile = computed(() => {
      return store.getters.getProfile()
    })

    const kycRegular = computed(() => {
      return profile.value.kyc_level === 'regular'
    })

    const kycsConditions = computed(() => {
      return store.state.kycs.conditions
    })
    
    const loading = computed(() => {
      return store.state.general.loading
    })

    onMounted(async () => {
      if (!store.state.kycs.kycs) {
        store.dispatch('setLoading', true)
        await store.dispatch('getKycs')
      }
      if (!kycsConditions.value) {
        store.dispatch('setLoading', true)
        const conditions = await store.getters.getKycsConditions()
        await store.dispatch('setKycsConditions', conditions)
      }
      store.dispatch('setLoading', false)
      initialProfile.value = JSON.parse(JSON.stringify(profile.value))
    })

    const isDirty = computed(() => {
      return (
        JSON.stringify(profile.value) !== JSON.stringify(initialProfile.value)
      )
    })

    const updateDetails = () => {
      store.dispatch('setLoading', true)
      updateProfile({
        country: profile.value.country,
        region: profile.value.region,
        address: profile.value.address,
        post_code: profile.value.post_code,
        city: profile.value.city
      })
        .then(() => {
          store.dispatch('setLoading', false)
          const popup = {
            title: i18n.t('settings.fiscal_information.success_popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            primaryButton: i18n.t(
              'settings.fiscal_information.success_popup.btn'
            )
          }
          store.dispatch('setPopup', popup)
          router.push({name: 'settings'})
        })
        .catch((e) => {
          store.dispatch('setLoading', false)
          store.dispatch('setError', e)
        })
    }

    const checkChanges = () => {
      if (isDirty.value === true) {
        hasChanges.value = true
      } else {
        router.push({ name: 'settings' })
      }
    }

    return {
      profile,
      updateDetails,
      kycRegular,
      isRequired,
      form,
      loading,
      kycsConditions,
      hasChanges,
      checkChanges,
      isBorrower,
      isDirty
    }
  }
}
</script>

<style lang="sass" scoped></style>
