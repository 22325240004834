<template>
  <div>
    <!-- unsuitable -->
    <!-- title -->
    <div class="text-center">
      <p class="font-bold text-lg text-grey-2 mb-9 mt-6">
        {{ $t('errors.unsuitable_reviewed.title') }} 
      </p>
    </div>
    <!-- description -->
    <div class="text-left" id="popup-auto-logout">
      <p class="text-sm text-grey-2 text-opacity-60 space-y-1">
        {{ $t('errors.unsuitable_reviewed.description') }}
      </p>
      <p class="text-sm text-grey-2 text-opacity-60 space-y-1">
        {{ $t('errors.unsuitable_reviewed.description2') }}
      </p>
      <GpButton
        @click="next()" >
          {{ $t('signup.popup.continue') }}
    </GpButton>
    </div><br/><br/><br/>.
  </div>
</template>
<script>
import GpButton from '@/components/general/GpButton.vue'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    GpButton
  },
  setup(){
    const i18n = useI18n({ useScope: 'global' })

    function next(){
      window.open(i18n.t('settings.suitability.meetDR'))
    }
    return {
      i18n,
      next
      }
  }
}
</script>
