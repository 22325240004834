var chars = [
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  '0123456789',
  '#$!-_&%',
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
]
var randPwd = [10, 3, 2, 4]
  .map(function (len, i) {
    return Array(len)
      .fill(chars[i])
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)]
      })
      .join('')
  })
  .concat()
  .join('')
  .split('')
  .sort(function () {
    return 0.5 - Math.random()
  })
  .join('')

export default randPwd
