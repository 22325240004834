import axios from 'axios'

export default {
  state: {
    metrics: null
  },
  mutations: {
    SET_METRICS(state, metrics) {
      state.metrics = metrics
    }
  },
  actions: {
    fetchMetrics({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/metrics')
        .then((response) => {
          commit('SET_METRICS', response.data)
        })
    }
  },
  getters: {}
}
