import i18n from "@/i18n";
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import { Types } from "@/assets/ts/enums/form/types";
import {get_type} from "@/assets/ts/suitability/callbacks/update_suitability";
const order = ['impact_creation', 'portfolio_diversity', 'save_for_child','save_for_future','save_for_purchase','save_for_retirement'];
const risk_profile_order = ['1_high', '2_medium', '3_low'];

const investment_reason:PageForm =
  {
    name: i18n.global.t('ca_legislation.suitability.side_panel.names.investment_reason'),
    title: i18n.global.t('ca_legislation.suitability.side_panel.titles.investment_reason'),
    form: [
      {
        title: i18n.global.t('ca_legislation.suitability.reason_risk.title'),
        fields: [
          {
            key: "risk_level",
            value: "",        title: i18n.global.t('ca_legislation.suitability.risk_profile.title'),
            subtitle:  i18n.global.t('ca_legislation.suitability.risk_profile.subtitle'),
            options: risk_profile_order.reduce((obj, key) => {
              // @ts-ignore
              return {...obj, [key]: i18n.global.tm("ca_legislation.suitability.risk_profile.options")[key]};
            }, {}),
            rules: "required",
            type: Types.RADIO
          },
          {
            key: "investment_reason",
            value: "",        title: i18n.global.t('ca_legislation.suitability.reason.title'),
            subtitle:  i18n.global.t('ca_legislation.suitability.reason.subtitle'),
            options: order.reduce((obj, key) => {
              // @ts-ignore
              return {...obj, [key]: i18n.global.tm("ca_legislation.suitability.reason.options")[key]};
            }, {}),
            type: Types.RADIO,
            condition: {
              key: 'type',
              value: 'particular'
            }
          },
        ],
        next: true,
        mountedCallback: get_type,
        back: true
      }
    ]
  }
export default investment_reason
