<template>
  <div
    class="
      bg-white
      md:p-4
      rounded-3xl
      relative
      text-left
      md:h-20
      mb-6
      md:mb-0
      text-grey-3 text-13
    "
  >
    <div class="hidden md:block">
      <div>{{ country.country }}</div>
      <div class="font-semibold">
        {{ projects }} {{ projects > 1 ? $t('wording.projects') : $t('wording.project') }}
      </div>
      <div class="absolute right-4 bottom-4">
        <div
          class="w-6 h-6 bg-cover bg-center rounded-lg"
          :style="
            'background-image: url(\'' +
            `/img/flags/${country_code.toLowerCase()}.png` +
            '\')'
          "
        ></div>
      </div>
    </div>
    <div class="flex justify-between md:hidden">
      <div class="flex items-center">
        <div
          class="w-6 h-6 bg-cover bg-center rounded-lg mr-4"
          :style="
            'background-image: url(\'' +
            `/img/flags/${country_code.toLowerCase()}.png` +
            '\')'
          "
        ></div>
        <div>{{ country.country }}</div>
      </div>
      <div>
        <div class="font-semibold">
          {{ projects }} {{ projects > 1 ? $t('wording.projects') : $t('wording.project') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCountries from '@/assets/js/countries'
import { computed } from 'vue'

export default {
  components: {},
  props: {
    country_code: String,
    projects: Number
  },
  setup(props) {
    const { getCountries } = useCountries()
    const country = computed(() => {
      return getCountries().find((el) => {
        return el.key.toLowerCase() === props.country_code.toLowerCase()
      })
    })
    return { country }
  }
}
</script>

<style lang="css" scoped></style>
