<template>
  <div
    class="page flex px-0 lg:px-0 container overflow-y-hidden"
    style="padding-bottom: 0"
  >
    <SignupVoucher
      class="block md:hidden"
      :show="showSignupVoucher"
      @hide="showSignupVoucher = false"
    />

    <div class="absolute sm:ml-0 pt-4 items-center md:items-baseline  lg:ml-16 sm:pt-14 z-50 flex w-full sm:w-auto justify-between md:justify-start">
      <a :href="url" >
        <img
          src="@/assets/img/logo/goparity.svg"
          alt=""
          class="w-28 sm:w-40 ml-4 sm:ml-0"
        />
      </a>
    </div>
    <div class="sm:relative lg:flex overflow-hidden flex-grow">
      <img
        src="@/assets/img/signup_mobile.png"
        alt=""
        class="absolute left-0 mt-10 w-full block sm:hidden"
      />
      <GeneralSlide
        class="
          lg:w-6/12 lg:m-auto lg:h-screen
          text-left
          hidden
          lg:block
          items-end
          lg:items-start

        "
        :dir="'/img/signup/'"
        :data="data"
      />
      <SignupCard
        class="
          w-full
          bottom-0
          min-h-screen-2/3
          sm:h-auto
          lg:min-h-3/4 lg:min-h-auto
          sm:relative
          absolute
          lg:w-5/12
          top-card
          sm:top-0
          h-fit
          max-w-3xl
          lg:mt-0 lg:mt-auto
          m-auto
          lg:scale-90
          transform
        "
        company
        noPw
      />
    </div>
  </div>
</template>

<script>
import SignupCard from '@/components/signup/Card.vue'
import GeneralSlide from '@/components/general/Slide.vue'
import SignupVoucher from '@/components/signup/SignupVoucher.vue'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    SignupCard,
    SignupVoucher,
    GeneralSlide
  },
  setup() {
    const route = useRoute()
    const i18n = useI18n()
    const url = computed(() => {
      return process.env.VUE_APP_FE_BASE_URL
    })
    const showSignupVoucher = ref(true)
    const voucher = computed(() => {
      return route.params.voucher
    })
    const data = computed(() => {
      const arr = voucher.value
        ? i18n.tm('signup.slider_voucher')
        : i18n.tm('signup.slider_company')
      if (voucher.value) {
        arr.forEach((el) => {
          el.subtitle = el.subtitle.replace('{voucher}', voucher.value)
        })
      }
      return arr
    })
    return { voucher, data, showSignupVoucher, url }
  }
}
</script>
