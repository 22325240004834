<template>
  <div class="page max-h-screen text-left">
    <img
      src="@/assets/img/logo/goparity.svg"
      alt=""
      class="ml-12 pt-12 absolute w-40 z-50"
    />
    <div class="md:flex h-screen overflow-hidden w-screen">
      <div class="mx-auto">
        <div class="mt-28 mb-8">
          <div
            class="
              mx-auto
              w-3/4
              mb-6
              text-grey-2
              opacity-80
              font-medium
              text-13
            "
          >
            Upload front and back of your identity document or one file with
            both.
          </div>
          <div class="text-center">
            <file-upload
              ref="upload"
              accept="image/png,image/jpeg,image/jpg,pdf"
              :extensions="['jpg', 'jpeg', 'png', 'pdf']"
              :size="1024 * 1024 * 7"
              v-model="files"
              input-id="file1"
              post-action="/upload/post"
              :multiple="true"
              :maximum="2"
              :thread="2"
              :drop="'.drop-area'"
              class="steps__upload relative px-6 w-3/4"
              :class="{
                'opacity-50 cursor-not-allowed pointer-events-none':
                  files.length > 1
              }"
            >
              <div
                class="drop-area w-full h-full z-50 absolute"
                @click.prevent="addFile(false)"
              ></div>
              <div
                class="
                  relative
                  top-2/4
                  right-1/4
                  transform
                  w-full
                  -translate-y-1/2
                "
              >
                <div class="flex justify-center">
                  <div class="w-4 mr-3">
                    <img
                      src="@/assets/ico/onboarding/upload.svg"
                      alt=""
                      class="w-full"
                    />
                  </div>
                  <div class="text-sm">UPLOAD FILES</div>
                </div>
              </div>
            </file-upload>
            <hr class="mx-auto w-3/4 my-5" />
            <div
              v-for="(file, index) in files"
              v-bind:key="file"
              class="
                md:py-2 md:px-4
                px-6
                py-5
                flex
                justify-between
                font-bold
                mt-2
                w-3/4
                mx-auto
              "
              :class="
                validImg(file)
                  ? 'steps__upload--success'
                  : 'steps__upload--error'
              "
            >
              <div class="w-3/4">{{ file.name }}</div>
              <div
                class="w-4 mt-1 cursor-pointer"
                @click="files.splice(index, 1)"
              >
                <img src="@/assets/svg/garbage.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        
        <GpButton
          mobileWidth="w-3/4"
          desktopWidth="w-3/4"
          @click="submit"
          class="mx-auto"
        >
          Upload
        </GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import { ref } from 'vue'
import GpButton from '@/components/general/GpButton.vue'

export default {
  components: {
    FileUpload,
    GpButton
  },
  data() {
    return {
      socket: null,
      files: [],
      files_back: [],
      validTypes: ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']
    }
  },
  created() {
    /*this.socket = new WebSocket(process.env.VUE_APP_SERVER_WEBSOCKET)
    // Ao estabelecer a conexão enviamos uma mensagem pro servidor~
    this.socket.addEventListener('open', () => {
      this.socket.send('Submiting KYC ' + this.$route.params.id)
    })

    // Callback disparado sempre que o servidor retornar uma mensagem
    this.socket.addEventListener('message', function (event) {
      console.log(event)
    })*/
  },

  methods: {
    validImg(file) {
      if (file) {
        return (
          this.validTypes.includes(file.type) &&
          file.size < 1024 * 1024 * 7 &&
          file.size > 1024 * 3
        )
      }
      return false
    },

    sendFiles() {
      for (let index = 0; index < this.files.length; index++) {
        const file = this.files[index]
        this.socket.binaryType = 'arraybuffer'

        var reader = new FileReader()
        var rawData = new ArrayBuffer()

        reader.onload = (e) => {
          rawData = e.target.result
          const bufferData = Buffer.from(rawData)
          const bsonData = JSON.stringify({
            // whatever js Object you need
            extra_fields: JSON.stringify({
              name: file.name,
              back: index === 1,
              type: file.type
            }),
            content: bufferData,
            to: this.$route.params.id
          })
          this.socket.send(bsonData)
        }
        reader.readAsArrayBuffer(file.file)
      }
    },
    submit() {
      this.sendFiles()
    }
  },
  setup() {
    const upload = ref(null)

    function addFile() {
      document.getElementById('file1').click()
    }

    return { upload, addFile }
  }
}
</script>
