<template>
  <div>
    <contribution-chart />
    <div class="text-left mt-12 md:mt-0">
      <div class="font-semibold text-grey-2 mb-4">
        {{ $t('impact.contribution.goals.title') }}
      </div>
      <div class="mb-4 text-sm text-grey-3">
        {{
          $t('impact.contribution.goals.content', {
            amount: impact.sdgs ? impact.sdgs.length : 0
          })
        }}
        <a
          :href="`https://goparity.ca/${locale}/faqs/103`"
          class="orange-link"
          target="_blank"
        >
          {{ $t('impact.contribution.goals.link') }}
        </a>
      </div>
    </div>
    <contribution-goals />
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import ContributionChart from '@/components/impact/contribution/Chart'
import ContributionGoals from '@/components/impact/contribution/Goals'
export default {
  components: { ContributionChart, ContributionGoals },
  setup() {
    const store = useStore()
    const { locale } = useI18n({ useScope: 'global' })
    const impact = computed(() => {
      return store.state.impact.impact
    })
    return { impact, locale }
  }
}
</script>
