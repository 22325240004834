<template>
  <div class="page">
    <div class="md:pt-24" v-if="profile && profile.beta_tester">
      <div class="container">
        <div>
          <InviteLink />
        </div>
        <div class="mt-10 lg:mt-8">
           <InviteStatus class="mb-8 lg:mb-4" :vouchers="vouchers" v-if="vouchers && vouchers.total > 0" />
            <!-- <InviteRank /> -->
        </div>
      </div>
    </div>
    <div class="md:pt-24" v-else>
      <div class="container">
        Feature not Available
      </div>
    </div>
  </div>
</template>

<script>
import InviteLink from '@/components/invite/Link.vue'
import InviteStatus from '@/components/invite/Status.vue'
// import InviteRank from '@/components/invite/Rank.vue'
import { computed, onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  components: {
    InviteLink,
    InviteStatus,
    // InviteRank
  },
  setup() {
    const store = useStore()
    const vouchers = ref([])

    onMounted(async () => {
      if (!store.state.vouchers.vouchersSample)
        await store.dispatch('getVouchersSample')
      vouchers.value = store.state.vouchers.vouchersSample
    })

    const profile = computed(() => {
      return store.getters.getProfile()
    })

    return{
      vouchers,
      profile
    }
  }
}
</script>
