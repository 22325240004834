<template>
  <div
    class="flex p-2 text-center bg-white bg-opacity-20 rounded-2xl"
    :class="horizontal ? 'items-center w-16 justify-around' : 'flex-col w-11'"
  >
    <div class="text-base text-white">
      {{ riskConversion(risk) }}
    </div>
    <img
      :class="horizontal ? '' : 'mx-auto'"
      :src="require('@/assets/svg/project_categories/' + category + '.svg')"
      :alt="category + ' svg'"
      v-if="category"
    />
  </div>
</template>

<script>
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
export default {
  props: {
    risk: {
      type: String,
      default: '0'
    },
    category: {
      type: String,
      default: ''
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { riskConversion } = useGlobalHelpers()
    return {
      riskConversion
    }
  }
}
</script>

<style lang="scss" scoped></style>
