<template>
  <div class="page flex flex-col items-end">
    <SideBar v-if="!isEdit">
      <FlowProgress step="3.2" copy="strategies.progress" flow="strategies" />
    </SideBar>
    <img
      v-if="!isEdit"
      src="/img/signup/loved_one.png"
      alt="loved one picture"
      class="hidden lg:block fixed bottom-0 left-40 w-72 z-0 h-72"
    />
    <div
      v-if="!changed && updates"
      :class="
        !isEdit
          ? 'strategies__desktop-container justify-between'
          : 'container flex-grow flex flex-col justify-between lg:pt-20'
      "
    >
      <div :class="{ 'lg:w-3/4 xl:w-2/3 2xl:w-1/2 lg:mx-auto': !isEdit }">
        <div class="mt-2 mb-4">
          <Back
            :to="isEdit ? -1 : '/strategies/direct-debit'"
            :title="$t('strategies.setup.investment.title')"
          />
        </div>
        <ProgressBar class="lg:hidden" v-if="!isEdit" :step="4" :total="7" />
        <div class="flex justify-between mb-8 mt-6">
          <p class="text-right text-grey-2 opacity-60">
            {{ $t('strategies.setup.investment.wallet_percentage_label') }}
          </p>
          <p class="text-right text-grey-2 font-bold">
            {{ walletPercentage }}%
          </p>
        </div>
        <Slider
          v-model="walletPercentage"
          :step="10"
          :tooltips="false"
          class="slider-strategies"
          :min="50"
        />
        <div class="flex justify-between my-8">
          <p class="text-right text-grey-2 opacity-60">
            {{ $t('strategies.setup.investment.risk_label') }}
          </p>
          <div class="flex">
            <p class="text-right text-grey-2 font-bold mr-2">
              {{ riskLevel }}
            </p>
            <p class="text-right text-grey-2 font-bold text-opacity-50">
              ~{{ riskInterest }}%
            </p>
          </div>
        </div>
        <Slider
          v-model="risk"
          :step="11.1"
          :tooltips="false"
          class="slider-strategies"
          :max="66.6"
        />
        <div
          class="
            absolute
            lg:relative
            z-40
            bottom-0
            left-0
            w-full
            lg:h-auto
            bg-white
            rounded-t-2xl
            lg:mt-6
          "
          :class="expanded ? 'h-4/5' : 'h-auto'"
        >
          <div
            class="
              flex flex-col
              w-full
              h-full
              justify-between
              px-6
              lg:pt-6
              pb-16
              lg:pb-6
            "
          >
            <div>
              <div
                class="
                  lg:hidden
                  mx-auto
                  my-2
                  rounded
                  w-8
                  h-1
                  bg-grey-2 bg-opacity-40
                "
                @click="() => (expanded = !expanded)"
              />
              <p class="lg:hidden text-grey-2 text-3xl font-bold">
                ${{ dollarFormatting(total) }} 
              </p>
              <p class="lg:hidden text-grey-2 text-sm text-opacity-80 mb-1">
                {{ $t('strategies.setup.investment.modal_text') }}
              </p>
              <div
                class="lg:hidden"
                :style="expanded ? 'height: 200px' : 'height: 100px'"
              >
                <vue3-chart-js
                  :id="chartData.id"
                  :type="chartData.type"
                  :data="chartData.data"
                  :options="chartData.options"
                  ref="chartRef"
                ></vue3-chart-js>
              </div>
              <div class="hidden lg:block" style="height: 150px">
                <vue3-chart-js
                  :id="chartData.id"
                  :type="chartData.type"
                  :data="chartData.data"
                  :options="chartData.options"
                  ref="chartRef"
                ></vue3-chart-js>
              </div>
              <div class="flex justify-around mt-2 mb-4">
                <div class="flex">
                  <div class="flex items-center mr-4">
                    <div
                      class="bg-grey-2 h-1 w-2 rounded-xl mr-1 bg-opacity-40"
                    />
                    <p class="text-grey-2 text-xs text-opacity-40">
                      {{ $t('strategies.setup.investment.money_transferred') }}
                    </p>
                  </div>
                  <div class="flex items-center">
                    <div class="bg-green-2 h-1 w-2 rounded-xl mr-1" />
                    <p class="text-grey-2 text-xs text-opacity-40">
                      {{ $t('strategies.setup.investment.total_assets') }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="expanded" class="mt-8">
                <div class="flex justify-between text-sm mb-4">
                  <p class="text-grey-2 text-opacity-80">
                    {{ $t('strategies.setup.investment.estimated_assets') }}
                  </p>
                  <p class="text-grey-2 font-semibold">
                    ${{ dollarFormatting(interest) }}
                  </p>
                </div>
                <div class="flex justify-between text-sm">
                  <p class="text-grey-2 text-opacity-80">
                    {{ $t('strategies.setup.investment.money_transferred') }}
                  </p>
                  <p class="text-grey-2 font-semibold">
                    ${{ dollarFormatting(transferred) }}
                  </p>
                </div>
              </div>
            </div>
            <GpButton
              v-if="!isEdit"
              @click="selectInvestment"
              class="lg:hidden"
            >
              {{ $t('wording.next') }}
            </GpButton>
            <GpButton v-else @click="confirm" class="lg:hidden">
              {{ $t('wording.confirm') }}
            </GpButton>
          </div>
        </div>
        <div
          class="
            hidden
            lg:flex
            rounded-b-2xl
            bg-orange-4
            p-6
            w-full
            justify-between
          "
        >
          <p class="text-grey-2 font-semibold text-sm">
            {{ $t('strategies.setup.investment.total_assets_desktop') }}
          </p>
          <p class="text-grey-2 font-bold text-sm">
            ${{ dollarFormatting(total) }}
          </p>
        </div>
      </div>
      <div
        class="lg:flex lg:justify-end lg:w-3/4 xl:w-2/3 2xl:w-1/2 pt-6"
        :class="{ 'ml-auto': isEdit }"
      >
        <GpButton
          v-if="!isEdit"
          @click="selectInvestment"
          class="hidden lg:flex"
        >
          {{ $t('wording.next') }}
        </GpButton>
        <GpButton v-else @click="confirm">
          {{ $t('wording.confirm') }}
        </GpButton>
      </div>
    </div>
    <ChangesConfirmation
      v-else-if="isEdit"
      @discard="() => (changed = false)"
      :displayData="displayData"
      :data="confirmationData"
      :text="$t('strategies.edit.direct_debit.text')"
      icon="investment"
      :strategyId="strategy.id"
    />
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import SideBar from '@/components/general/SideBar.vue'
import ProgressBar from '@/components/general/ProgressBar.vue'
import FlowProgress from '@/components/general/FlowProgress.vue'
import ChangesConfirmation from '@/components/strategies/ChangesConfirmation.vue'
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import Slider from '@vueform/slider'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import '@vueform/slider/themes/default.css'
import useFlows from '@/mixins/useFlows.js'
import useStrategies from '@/mixins/useStrategies.js'

import { computed, onBeforeMount, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
export default {
  components: {
    Back,
    Slider,
    ProgressBar,
    Vue3ChartJs,
    ChangesConfirmation,
    GpButton,
    FlowProgress,
    SideBar
  },
  setup() {
    const walletPercentage = ref(100)
    const risk = ref(44.4)
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const expanded = ref(false)
    const isEdit = ref(route.query.edit)
    const changed = ref(false)
    const confirmationData = ref(null)
    const displayData = ref(null)
    const { dollarFormatting, calculateInterestGross } = useGlobalHelpers()
    const { levelRisk, valueRisk, colorRisk, interestRisk, risktoSliderConversion } = useStrategies()

    const { noNewStrategy } = useFlows()

    const labels = ref([])
    const data = ref({
      transferred: [],
      transferredCents: [],
      total: [],
      totalCents: []
    })
    const amount = ref(store.state.strategies.newStrategy.amount)
    const updates = ref(0)
    const chartRef = ref(null)

    onBeforeMount(async () => {
      // Check if no refresh
      if (!isEdit.value) noNewStrategy()
      else {
        if (!strategy.value) {
          router.push({name: 'dashboard'})
        }
      }

      // Set labels
      const now = new Date()
      // if (isEdit.value || !store.state.strategies.newStrategy.endDate) {
      for (let i = 0; i < 4; i++) {
        labels.value.push(now.getFullYear() + i)
      }

      // Set amount
      if (isEdit.value && strategy.value && store.state.strategies.auto_investments.length) {
        amount.value = strategy.value.monthly_amount
        walletPercentage.value =  strategy.value.auto_investments[0].balance_percentage
        risk.value =  risktoSliderConversion(strategy.value.auto_investments[0].max_risk)
        riskLevel.value = levelRisk(risk.value)
        riskColor.value = colorRisk(risk.value)
        riskInterest.value = interestRisk(risk.value)
      }

      // Set chartdata
      updateData(true)
    })


    const strategy = computed(() => {
      return store.state.strategies.strategy
    })

    const riskLevel = computed(() => {
      return levelRisk(risk.value)
    })

    const riskColor = computed(() => {
      return colorRisk(risk.value)
    })

    const riskInterest = computed(() => {
      return interestRisk(risk.value)
    })

    const total = computed(() => {
      return data.value.totalCents[data.value.total.length - 1]
    })

    const transferred = computed(() => {
      return data.value.transferredCents[data.value.transferred.length - 1]
    })

    const selectInvestment = () => {
      store.dispatch('updateNewStrategy', {
        wallet_percentage: walletPercentage.value,
        max_risk: valueRisk(riskLevel.value),
        forecast: {
          total: total.value,
          transferred: transferred.value,
          interest: total.value - transferred.value
        }
      })
      router.push('/strategies/max-amount')
    }

    watch(
      [() => walletPercentage.value, () => risk.value],
      () => {
        updateData()
      }
    )

    const resetData = () => {
      data.value = {
        transferred: [],
        transferredCents: [],
        total: [],
        totalCents: []
      }
    }

    const updateData = (init = false) => {
      resetData()
      for (let i = 1; i <= labels.value.length; i++) {
        data.value.transferred.push((amount.value / 100) * (i - 1) * 12)
        data.value.total.push(
          (amount.value / 100 +
            calculateInterestGross(
              ((amount.value / 100) * 12 * walletPercentage.value) / 100,
              riskInterest.value,
              12
            )) *
            (i - 1) *
            12
        )
        data.value.transferredCents.push(amount.value * (i - 1) * 12)
        data.value.totalCents.push(
          (amount.value +
            calculateInterestGross(
              (amount.value * 12 * walletPercentage.value) / 100,
              riskInterest.value,
              12
            )) *
            (i - 1) *
            12
        )
      }

      updates.value = updates.value + 1

      chartData.value.options.plugins.title = {
        display: false,
        text: 'Updated Count: ' + updates.value
      }

      chartData.value.data.datasets[0].data = data.value.transferred
      chartData.value.data.datasets[1].data = data.value.total

      if (!init) chartRef.value.update(750)
    }

    const chartData = ref({
      id: 'line',
      type: 'line',
      data: {
        labels: labels.value,
        datasets: [
          {
            data: data.value.transferred,
            tension: 0.5,
            backgroundColor: '#4f596366',
            borderColor: '#4f596366'
          },
          {
            data: data.value.total,
            tension: 0.5,
            backgroundColor: '#00e763',
            borderColor: '#00e763'
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            labels: {
              font: {
                size: 10,
                family: 'Poppins'
              }
            }
          }
        },
        elements: {
          point: {
            radius: 0
          }
        },
        scales: {
          yAxis: {
            grid: {
              drawBorder: false
            },
            ticks: {
              color: '#4f596366'
            }
          },
          xAxis: {
            grid: {
              display: false
            },
            ticks: {
              color: '#4f596366'
            }
          }
        }
      }
    })

    const confirm = () => {
      confirmationData.value = {
        wallet_percentage: walletPercentage.value,
        max_risk: valueRisk(riskLevel.value)
      }
      displayData.value = [
        {
          label: 'Wallet balance to invest',
          value: walletPercentage.value + '%'
        },
        {
          label: 'Maximum risk',
          value: riskLevel.value
        }
      ]
      changed.value = !changed.value
    }

    const interest = computed(() => {
      return total.value - transferred.value
    })

    return {
      walletPercentage,
      risk,
      riskLevel,
      riskColor,
      riskInterest,
      selectInvestment,
      chartData,
      expanded,
      isEdit,
      changed,
      confirm,
      confirmationData,
      displayData,
      transferred,
      total,
      interest,
      strategy,
      dollarFormatting,
      updateData,
      updates,
      chartRef
    }
  }
}
</script>

<style>
.slider-strategies {
  --slider-connect-bg: #b3dabb;
  --slider-bg: #4f59631a;
  --slider-handle-ring-color: #ffffff;
  --slider-handle-bg: #b3dabb;
  --slider-handle-ring-width: 6px;
  --slider-handle-radius: 1000px;
}
</style>
