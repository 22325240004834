import info from "@/assets/ts/investor_type/steps/info";
import questions from "@/assets/ts/investor_type/steps/questions";

const form =
  {
      info,
      questions
  }

export default form
