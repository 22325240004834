<template>
  <div>
    <div
      class="
        hidden
        lg:block
        fixed
        top-0
        w-full
        py-4
        px-14
        z-50
        border-b
        animate-navbar
        transition
        duration-150
        ease-out
      "
      :class="{ 'bg-navbar': scroll }"
    >
      <div class="flex justify-between items-center">
        <router-link to="/dashboard">
          <img
            src="@/assets/img/logo/goparity.svg"
            alt=""
            class=""
          >
        </router-link>
        <div class="flex items-center">
          <router-link
            v-for="el in navigationLinks"
            :key="el.name"
            :to="{
              name: isPromoter && el.promoter_link ? el.promoter_link : el.link
            }"
            class="mr-10 text-sm"
            :class="
              (el.matches && el.matches.includes(currentRoute)) ||
                el.link === currentRoute
                ? 'text-grey-2 font-semibold'
                : 'text-grey-4 nav-link'
            "
          >
            {{ isPromoter && el.promoter_name ? el.promoter_name : el.name }}
          </router-link>
          <div
            id="navbar-drop"
            class="
              h-8
              w-8
              border-2
              rounded-xl
              relative
              cursor-pointer
            "
            @click="opened = !opened"
          >
            <div
              v-if="profile && profile.name && profile.surname"
              class="
                absolute
                top-1/2
                -translate-y-1/2
                left-1/2
                -translate-x-1/2
                transform
                uppercase
                text-13
                font-bold
                text-grey-4
                no-highlight
              "
            >
              {{ initials }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-clickoutside="opened"
        class="
          bg-white
          fixed
          right-14
          top-24
          pt-6
          rounded-3xl
          text-left
          transform
          transition-all
          scale-0
        "
        :class="{ 'scale-100': opened }"
      >
        <span
          v-for="el in $tm('navigation.dropdown')"
          :key="el.link"
        >
          <a
            v-if="el.external_link"
            :href="el.external_link"
            target="_blank"
          >
            <div
              class="
                py-3
                px-6
                pr-40
                cursor-pointer
                font-medium
                text-sm
                transition-all
                hover:bg-yellow-6 hover:font-bold hover:text-grey-2
                relative
              "
              :class="
                (el.matches && el.matches.includes(currentRoute)) ||
                  el.link === currentRoute
                  ? 'bg-yellow-6 font-bold text-grey-2'
                  : 'text-grey-3'
              "
              @click="opened = false"
            >
              <div class="flex">
                <img
                  v-if="el.link"
                  :src="require('@/assets/svg/navigation/' + el.link + '.svg')"
                  alt=""
                  class="mr-24"
                >
                <div
                  class="absolute left-14 top-1/2 transform -translate-y-1/2"
                >
                  {{ el.name }}
                </div>
              </div>
            </div>
          </a>
          <router-link
            v-else
            :to="{ name: el.active === 'false' ? 'dashboard' : el.link }"
          >
            <div
              class="
                py-3
                px-6
                pr-40
                cursor-pointer
                font-medium
                text-sm
                transition-all
                hover:bg-yellow-6 hover:font-bold hover:text-grey-2
                relative
                text-grey-3
              "
              :class="{'opacity-50 cursor-not-allowed': !isKycRegular && (el.link === 'giftcards' || el.link === 'account-details'), 'bg-yellow-6 font-bold text-grey-2' : (el.matches && el.matches.includes(currentRoute)) || el.link === currentRoute}"
              @click="opened = false"
            >
              <div class="flex">
                <img
                  v-if="el.link"
                  :src="require('@/assets/svg/navigation/' + el.link + '.svg')"
                  alt=""
                  class="mr-24"
                >
                <div
                  class="absolute left-14 top-1/2 transform -translate-y-1/2"
                >
                  {{ el.name }}
                </div>
              </div>
            </div>
          </router-link>
        </span>
        <div
          class="
            mt-1
            py-5
            px-6
            pr-24
            border-t
            cursor-pointer
            text-grey-4
            transition-all
            font-medium
            text-sm
            hover:font-bold hover:text-grey-2
            flex
          "
          @click="logout"
        >
          <img
            src="@/assets/svg/navigation/logout.svg"
            alt=""
            class="mr-4"
          >
          {{ $t('navigation.logout') }}
        </div>
      </div>
    </div>
    <div class="block lg:hidden">
      <!--router-link to="/settings" v-if="currentRoute === 'dashboard'">
        <div class="bg-white p-3 rounded-xl absolute top-6 right-6">
          <img src="@/assets/svg/navigation/mobile/settings.svg" alt="" /></div
      ></router-link-->
      <div
        class="
          w-full
          fixed
          bottom-0
          bg-white
          flex
          items-center
          justify-evenly
          z-50
          -mb-1
          pt-4
        "
        style="padding-bottom: calc(var(--sab) + 16px); box-shadow: 0 -20px 30px -16px rgba(39, 52, 65, 0.1);"
      >
        <div
          class="bg-orange-2 w-12 h-1 absolute top-0 transition-all"
          :style="'left:' + left + 'px'"
        />

        <div
          v-for="el in $tm('navigation.mobile')"
          :id="el.link + '-mobile'"
          :key="el.link"
          class="w-12"
        >
          <router-link :to="'/' + el.link">
            <img
              v-if="el.link"
              :src="
                require('@/assets/svg/navigation/mobile/' +
                  el.link +
                  (((el.matches && el.matches.includes(currentRoute)) ||
                    el.link === currentRoute) ? '-selected' : '') +
                  '.svg')
              "
              alt=""
              class="w-5 mx-auto"
            >
            <div
              class="text-grey-1 mt-1"
              :class="{'opacity-40': !((el.matches && el.matches.includes(currentRoute)) ||
                el.link === currentRoute)}"
              style="font-size:0.625rem"
            >
              {{ el.title }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { VueCookieNext } from 'vue-cookie-next'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { event } from "vue-gtag";

const clickoutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      const navbardrop = document.getElementById('navbar-drop')

      // here I check that click was outside the el and his children
      if (!el.contains(event.target) && !navbardrop.contains(event.target)) {
        // and if it did, call method provided in attribute value
        binding.instance.opened = false
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
  },

  unmounted: (el) => {
    document.removeEventListener('click', el.clickOutsideEvent)
  }
}

export default {
  directives: {
    clickoutside
  },
  setup() {
    const opened = ref(false)
    const route = useRoute()
    const store = useStore()
    const scroll = ref(false)
    const router = useRouter()
    const isIos = ref(false)
    const i18n = useI18n({ useScope: 'global' })

    const currentRoute = computed(() => {
      return route.name
    })

    const left = computed(()=>{
      return store.state.general.leftNav
    })
    function iOS() {
      var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
      var isAppleDevice = navigator.userAgent.includes('Macintosh')
      var isTouchScreen = navigator.maxTouchPoints >= 1

      return isIOS || (isAppleDevice && isTouchScreen)
    }

    /*
    // eslint-disable-next-line no-unused-vars
    function goTo(link){
      router.push(link)
    }

    const tabItems = []
    i18n.tm('navigation.mobile').forEach((el) =>{
      tabItems.push({
        "icon": el.icon, //optional
        "url": "javascript:goTo(" + el.link + ")"
      })
    })



    var tabs = {
      "enabled": !props.noNav.includes(window.location.pathname.replace('/','')), // set enabled to false to hide the tabs
      "items": tabItems
    };

    var json = JSON.stringify(tabs);
    window.location.href='gonative://tabs/setTabs?tabs=' + encodeURIComponent(json);
    */
    window.gonative_device_info = function (deviceInfo) {
      if (deviceInfo) {
        isIos.value = deviceInfo.platform === 'ios'
      }
    }

    const profile = computed(() => {
      return store.getters.getProfile()
    })

    const isKycRegular = computed(() => {
      return profile.value?.kyc_level === 'regular'
    })

    const initials = computed(()=>{
      const first = profile.value.name ? profile.value.name[0] : ''
      let second = profile.value.surname ? profile.value.surname.split(' ') : ''
      if(second)
        second = second.length > 1 ? second[second.length - 1] : second[0]
      return first + second[0]
    })


    onMounted(() => {
      document.addEventListener('scroll', () => {
        scroll.value = document.documentElement.scrollTop !== 0
      })
    })
    onUnmounted(() => {
      document.removeEventListener('scroll', () => {
        scroll.value = document.documentElement.scrollTop !== 0
      })
    })

    const isPromoter = computed(() => {
      if (profile.value) 
        return profile.value.promoter_id
      return false
    })

    const isBorrower = computed(() => {
      if (profile.value && profile.value.sub_type === 'borrower')
        return true
      return false
    })

    const navigationLinks = computed(() => {
      return i18n.tm('navigation.links').filter((el) => {
        return !el.type || (profile.value && el.type === profile.value.type)
      })
    })

    const selected = computed(() => {
      return route.name
    })

    const logout = () => {
      store.dispatch('setLoading', true)
      store.dispatch('logout', VueCookieNext.getCookie('token')).then(() => {
        event('logout', { method: 'navbar' })
        store.dispatch('resetToasts')
        window.localStorage.setItem('clicked_logout', true)
        router.push({ name: 'login' })
        store.dispatch('setLoading', false)
      })
      store.dispatch('setLoading', false)
    }

    return {
      profile,
      opened,
      isBorrower,
      route,
      currentRoute,
      isIos,
      logout,
      isPromoter,
      iOS,
      navigationLinks,
      left,
      scroll,
      initials,
      selected,
      isKycRegular
    }
  }
}
</script>

<style lang="css" scoped>
.animate-navbar {
  animation: appear-navbar ease-out 0.3s forwards;
}
@keyframes appear-navbar {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0%);
  }
}

.nav-link:hover {
  text-shadow: 0 0 0.65px #4f5963, 0 0 0.65px #4f5963;
}

.bg-navbar {
  background: #f4f6f2;
}
</style>
