<template>
  <div class="page flex">
    <div class="container flex-grow flex flex-col">
      <Back class="mb-6" :to="-1" title="" />
      <TransactionDetailsComponent :transaction="transaction" />
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import TransactionDetailsComponent from '@/components/transactions/TransactionDetailsComponent.vue'
import { onBeforeMount, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: {
    Back,
    TransactionDetailsComponent
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const transaction = computed(() => {
      return store.state.transactions.transaction
    })

    onBeforeMount(() => {
      if (!transaction.value) router.push('/transactions')
    })

    return {
      transaction
    }
  }
}
</script>

<style lang="scss" scoped></style>
