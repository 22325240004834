<template>
  <div class="relative">
    <transition name="toast-fade">
      <div class="absolute w-42 bottom-32 right-0" v-if="show">
        <div class="flex bg-grey-1 text-white px-4 py-2 rounded-xl">
          <img src="@/assets/svg/copy_inverted.svg" alt="" />
          <div class="ml-3 opacity-80 font-semibold text-13">
            {{ $t('wording.copied') }}
          </div>
        </div>
      </div>
    </transition>
    <div class="hidden">
      <div id="popup">
        <div class="text-grey-3">
          {{ $t('invite.code.popup.description', {value: mgmValue}) }}
        </div>
        <div class="indented-custom-bullet mt-4">
          <ul class="opacity-70 text-grey-2 text-left">
            <li
              class="pl-4"
              :class="{
                'mb-3': index < $tm('invite.code.popup.list').length - 1
              }"
              v-for="(item, index) in $tm('invite.code.popup.list')"
              v-bind:key="item"
            >
              {{ item.replace('{value}', mgmValue) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="w-20 h-20 square relative">
      <div
        class="
          absolute
          left-1/2
          top-1/2
          -translate-x-1/2 -translate-y-1/2
          transform
        "
      >
        <img src="@/assets/svg/heart.svg" alt="" />
      </div>
    </div>
    <div class="text-2xl lg:text-3xl font-medium mt-8 text-left text-grey-2">
      <div class="mt-3">
        {{ $t('invite.title_underline_mobile', {value: mgmValue}) }} {{ $t('invite.title') }}
      </div>
    </div>
    <div
      class="flex justify-between bg-white px-4 py-3 rounded-2xl mt-10"
      @click="copyCode"
    >
      <div class="text-grey-2 font-bold text-xl">{{ profile.mgm_code }}</div>
      <input type="hidden" id="user-code" :value="codeLink" />
      <div class="uppercase text-sm copy font-bold mt-1 cursor-pointer">
        {{ $t('wording.copy_link') }}
      </div>
    </div>
    <div class="text-left mt-4 text-grey-2">
      {{ $t('invite.code.description', {value: mgmValue}) }}
      <span class="orange-link font-bold cursor-pointer" @click="openPopup">
        {{ $t('invite.code.description_link') }}
      </span>
    </div>
  </div>
</template>
<script>
import { ref, computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import useGlobalHelpers from "@/mixins/useGlobalHelpers";
import { copyText } from 'vue3-clipboard'

export default {
  components: {},
  setup() {
    const store = useStore()
    const show = ref(null)
    const i18n = useI18n({ useScope: 'global' })
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const { dollarFormatting } = useGlobalHelpers()

    const mgmValue = computed(()=>{
      return store.getters.getMgmValue()
    })
    const codeLink = ref(
      process.env.VUE_APP_FE_BASE_URL +
        'signup/voucher/' +
        profile.value.mgm_code
    )

    function openPopup() {
      const content = document.getElementById('popup')
      const popup = {
        title: i18n.t('invite.code.popup.title'),
        align: 'center',
        icon: '/img/popup/clock-grey.svg',
        side: false,
        content: content.outerHTML
      }
      store.dispatch('setPopup', popup)
    }

    const copyCode = async function () {
      copyText(codeLink.value, undefined, (error) => {
        if (error) {
          console.log(error)
        } else {
          show.value = codeLink.value
          setTimeout(function () {
            show.value = null
          }, 500)
        }
      })

    }

    return {
      openPopup,
      copyCode,
      dollarFormatting,
      profile,
      show,
      codeLink,
      mgmValue
    }
  }
}
</script>
<style lang="postcss" scoped>
.square {
  border-radius: 2rem;
  background-image: linear-gradient(
    to top,
    rgba(0, 231, 99, 0.2),
    rgba(229, 253, 239, 0.2)
  );
}
.copy {
  color: rgba(185, 142, 89, 0.8);
}
</style>
