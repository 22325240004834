<template>
  <div
    class="
      w-full
      lg:rounded-3xl
      bg-center bg-cover
      text-left text-white
      lg:px-10
      px-6
      py-7
      absolute
      lg:relative
      top-0
      left-0
    "
    :style="
      project.image
        ? 'box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.4); background-image: url(\'' +
          project.image.path +
          '\')'
        : 'box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.2)'
    "
    v-if="project"
  >
    <div class="text-2xl lg:text-3xl font-medium pb-10 lg:pb-24 pt-20 lg:pt-0">
      {{ project.name }}
    </div>
    <div class="flex justify-between items-end">
      <div
        class="lg:w-1/2 text-sm lg:text-base"
        v-html="
          $t('promoter.loan.banner.funded', {
            particular: project.funded_by.particular,
            company: project.funded_by.company
          })
        "
      ></div>
      <div class="text-sm lg:text-xl font-bold">
        $ {{ dollarFormatting(project.crowdlending_value) }}
      </div>
    </div>
  </div>
</template>

<script>
import useGlobalHelpers from '@/mixins/useGlobalHelpers'

export default {
  components: {},
  props: {
    project: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const { dollarFormatting } = useGlobalHelpers()

    return { dollarFormatting }
  }
}
</script>
