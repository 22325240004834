<template>
  <div class="w-full flex">
    <div class="h-2 w-2 relative mr-4 mt-1.5">
      <div
        class="
          animate-ping
          absolute
          h-full
          w-full
          rounded-full
          bg-green-2
          opacity-75
        "
      />
      <div class="relative rounded-full h-2 w-2 bg-green-2" />
    </div>
    <div class="flex-grow">
      <div class="flex items-start lg:items-center justify-between">
        <p class="text-sm text-grey-2 text-opacity-80 text-left">
          {{ description }}
        </p>
        <div
          class="
            hidden
            lg:flex
            h-px
            mx-4
            bg-grey-2 bg-opacity-10
            w-24
            flex-grow
          "
        />
        <p
          class="font-semibold text-13"
          :class="isGreen ? 'text-green-1' : 'text-grey-2'"
        >
          {{ amount }}
        </p>
      </div>
      <p class="text-left text-11 text-grey-2 text-opacity-50">{{ date }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    amount: {
      type: String,
      default: ''
    },
    isGreen: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped></style>
