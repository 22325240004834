<template>
  <div class="page flex lg:flex-col lg:items-end">
    <SideBar>
      <FlowProgress step="1" copy="strategies.progress" flow="strategies" />
    </SideBar>
    <img
      src="/img/signup/loved_one.png"
      alt="loved one picture"
      class="hidden lg:block fixed bottom-0 left-40 w-72 z-0 h-72"
    />
    <div class="strategies__desktop-container">
      <div class="mt-2 mb-4 lg:w-3/4 xl:w-2/3 2xl:w-1/2">
        <Back
          to="/dashboard"
          :title="$t('strategies.setup.topup_method.title')"
        />
      </div>
      <ProgressBar class="lg:hidden" :step="0" :total="7" />
      <p class="text-grey-2 opacity-80 text-sm text-left my-6 lg:hidden">
        {{ $t('strategies.setup.topup_method.text') }}
      </p>
      <TopupMethod method="dd" class="mt-0" />
      <TopupMethod method="topup" locked />
      <TopupMethod method="roundup" locked />
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import TopupMethod from '@/components/strategies/TopupMethod.vue'
import ProgressBar from '@/components/general/ProgressBar.vue'
import FlowProgress from '@/components/general/FlowProgress.vue'
import SideBar from '@/components/general/SideBar.vue'

export default {
  components: {
    TopupMethod,
    ProgressBar,
    SideBar,
    Back,
    FlowProgress
  }
}
</script>

<style lang="scss" scoped></style>
