// import axios from 'axios'
import axios from 'axios'

export default {
  state: {
    representatives: []
  },
  mutations: {
    SET_REPRESENTATIVES(state, representatives) {
      state.representatives = representatives
    }
  },
  actions: {
    fetchRepresentatives({ commit }) {
      return axios
        .get(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/profiles/representatives'
        )
        .then(({ data }) => {
          commit('SET_REPRESENTATIVES', data)
          return data
        })
        .catch((e) => {
          commit('SET_ERROR', e)
        })
    },
    // eslint-disable-next-line no-empty-pattern
    updateRepresentative({commit}, representatives_data) {
      return axios
      .put(
        process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/profiles/representatives',
        representatives_data
      )
      .catch((e) => {
        commit('SET_ERROR', e)
      })
    }
  },
  getters: {
    getLegalRepresentative: (state) => () => {
      return state.representatives.length > 1 ? state.representatives.find((r) => {
        return r.type === 'legal'
      }) : state.representatives.length === 1 ? state.representatives[0] : []
    },
    getBeneficiaries: (state) => () => {
      return state.representatives.filter((b) => {
        return b.is_ubo === 1
      })
    }
  }
}
