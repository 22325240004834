<template>
  <div
    @click="goToDetails('card')"
    class="w-full rounded-2xl p-6 relative h-36 flex flex-col justify-between"
    :class="{
      'bg-grey-600': !contract.images,
      'cursor-pointer': !carousel
    }"
  >
    <div
      class="absolute w-full h-full top-0 left-0 rounded-2xl z-0 bg-cover"
      :class="{ 'filter grayscale': contract.state === 'settled' }"
      :style="
        contract.images
          ? 'background-image: url(\'' + contract.images.path + '\')'
          : ''
      "
    >
      <div
        class="
          absolute
          w-full
          h-full
          top-0
          left-0
          grey-image-filter
          rounded-2xl
        "
      />
    </div>
    <div
      class="relative flex items-start"
      :class="
        contract.contract_label !== '' && contract.state !== 'settled'
          ? 'justify-between'
          : 'justify-end'
      "
    >
      <MktTags :label="contract.contract_label" />
      <div class="flex items-start justify-end">
        <p
          v-if="contract.state === 'settled'"
          class="text-sm text-right text-white text-opacity-80"
        >
          $ {{ dollarFormatting(contract.contract_gross_value.total_paid) }}
        </p>
        <p v-else class="text-sm text-right">
          <span class="text-white">
            $ {{ dollarFormatting(contract.contract_gross_value.total_paid) }}
          </span>
          <span class="white-text-80">
            {{ ' / $' + dollarFormatting(contract.contract_gross_value.total) }}
          </span>
        </p>
        <div class="ml-2" style="height: 20px; width: 20px">
          <vue3-chart-js
            :id="chartData.id"
            :type="chartData.type"
            :data="chartData.data"
            :options="chartData.options"
            @before-render="() => {}"
          >
          </vue3-chart-js>
        </div>
      </div>
    </div>
    <div>
      <p
        class="
          font-bold
          text-white text-left
          mb-1
          relative
          whitespace-nowrap
          overflow-hidden overflow-ellipsis
        "
        @click="goToDetails('name')"
        :class="{ 'cursor-pointer': carousel }"
      >
        {{ contract.project_name }}
      </p>
      <div
        v-if="contract.state === 'settled'"
        class="flex text-white text-sm relative"
      >
        <img
          src="@/assets/svg/contracts/tick-white.svg"
          alt="clock"
          class="mr-1"
        />
        <p>{{ $t('contracts.settled') }}</p>
      </div>
      <div
        v-else-if="contract.payment_in_arrears"
        class="flex text-white text-sm relative"
      >
        <img
          src="@/assets/svg/contracts/clock-white.svg"
          alt="clock"
          class="mr-1"
        />
        <p>{{ $t('contracts.arrears') }}</p>
      </div>
      <div v-else class="flex text-white text-sm relative">
        <img
          src="@/assets/svg/contracts/money-white.svg"
          alt="money"
          class="mr-1"
        />
        <p v-if="contract.upcoming_payments.days_to_next_payment === 0">
          {{
            '$ ' +
            dollarFormatting(
              contract.upcoming_payments.next_payment.instalment_investor_net
            ) +
            $t(' wording.today')
          }}
        </p>
        <p v-else>
          {{
            '$ '+ dollarFormatting(
              contract.upcoming_payments.next_payment.instalment_investor_net
            ) +
            ' ' +
            $t('wording.in') +
            ' ' +
            $t(
              'wording.day',
              { n: contract.upcoming_payments.days_to_next_payment },
              contract.upcoming_payments.days_to_next_payment
            )
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import MktTags from '@/components/contracts/MktTags.vue'
export default {
  components: {
    Vue3ChartJs,
    MktTags
  },
  props: {
    contract: {
      type: Object,
      default: () => {}
    },
    carousel: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { dollarFormatting, slugIt } = useGlobalHelpers()
    const router = useRouter()

    const chartData = computed(() => {
      return {
        id: 'doughnut',
        type: 'doughnut',
        data: {
          datasets: [
            {
              data:
                props.contract.state === 'settled'
                  ? [props.contract.contract_gross_value.total_paid]
                  : [
                      props.contract.contract_gross_value.total_paid,
                      props.contract.contract_gross_value.total -
                        props.contract.contract_gross_value.total_paid
                    ],
              backgroundColor: ['#00e763', '#f4f6f5'],
              borderWidth: 0
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutout: '75%',
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          }
        }
      }
    })

    const goToDetails = (origin) => {
      if (
        (origin === 'card' && !props.carousel) ||
        (origin === 'name' && props.carousel)
      )
        if (props.contract) {
          const slug = props.contract.project_name
            ? slugIt(props.contract.project_name) + '-' + props.contract.id
            : props.contract.id
          router.push('/contract-details/' + slug)
        }
    }

    return {
      dollarFormatting,
      chartData,
      goToDetails
    }
  }
}
</script>

<style scoped>
.white-text-80 {
  color: rgba(255, 255, 255, 0.8);
}
</style>
