import CryptoJS from 'crypto-js'

export default function () {
  const encryptMethod = () => {
    return 'AES-256-CBC'
  }

  const encryptMethodLength = () => {
    var encryptMethodVar = encryptMethod()
    // get only number from string.
    // @link https://stackoverflow.com/a/10003709/128761 Reference.
    var aesNumber = encryptMethodVar.match(/\d+/)[0]
    return parseInt(aesNumber)
  }

  const encrypt = (value) => {
    var iv = CryptoJS.lib.WordArray.random(16) // the reason to be 16, please read on `encryptMethod` property.

    var salt = CryptoJS.lib.WordArray.random(256)
    var iterations = 999
    var encryptMethodLengthVar = encryptMethodLength() / 4
    var hashKey = CryptoJS.PBKDF2(process.env.VUE_APP_ENC_KEY, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLengthVar / 8,
      iterations: iterations
    })

    var encrypted = CryptoJS.AES.encrypt(value, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv: iv
    })

    var encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext)

    var output = {
      ciphertext: encryptedString,
      iv: CryptoJS.enc.Hex.stringify(iv),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iterations: iterations
    }

    return CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(JSON.stringify(output))
    )
  }

  const decrypt = (encryptedString) => {
    var json = JSON.parse(
      CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString))
    )

    var salt = CryptoJS.enc.Hex.parse(json.salt)
    var iv = CryptoJS.enc.Hex.parse(json.iv)

    var encrypted = json.ciphertext // no need to base64 decode.

    var iterations = parseInt(json.iterations)
    if (iterations <= 0) {
      iterations = 999
    }
    var encryptMethodLength = 64
    var hashKey = CryptoJS.PBKDF2(process.env.VUE_APP_ENC_KEY, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLength / 8,
      iterations: iterations
    })

    var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv: iv
    })

    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  const useSignature = (profile) => {
    const crypto = require('crypto')

    const hash = crypto.createHash('sha1')
    const prof_sig = profile.profile_id + profile.date_of_birth
    const data = hash.update(prof_sig, 'utf-8')
    return data.digest('hex')
  }

  return {
    encrypt,
    decrypt,
    useSignature
  }
}
