import axios from 'axios'
export default {
  state: {
    investments: null,
    investment: null,
    reservation: null,
    signatures: null
  },
  mutations: {
    SET_INVESTMENTS(state, data) {
      state.investments = data
    },
    SET_INVESTMENT(state, data) {
      state.investment = data
    },
    SET_RESERVATION(state, data) {
      state.reservation = data
    },
    SET_SIGNATURES(state, data) {
      state.signatures = data
    }
  },
  actions: {
    fetchInvestments({ commit }, projectId) {
      const params = {
        project_id: projectId
      }
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/investments', {
          params
        })
        .then((data) => {
          commit('SET_INVESTMENTS', data.data)
        })
    },
    fetchInvestment({ commit }, investmentId) {
      return axios
        .get(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/investments/' +
            investmentId
        )
        .then((data) => {
          commit('SET_INVESTMENT', data.data)
        })
    },
    setSignatures({ commit }, data) {
      commit('SET_SIGNATURES', data)
    },
    storeReservation({ commit }, reservation) {
      commit('SET_RESERVATION', reservation)
    },
    // eslint-disable-next-line no-unused-vars
    uploadProof({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        var formData = new FormData()

        formData.append('proof', data.proof)

        axios
          .post(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/investments/' +
              data.investment_id +
              '/upload-proof',
            formData
          )
          .then((data) => {
            resolve(data)
          })
          .catch((e) => {
            commit('SET_ERROR', e)
            reject(e)
          })
      })
    }
  },
  getters: {
    getPendingInvestments: (state) => {
      if (state.investments)
        return state.investments.filter((i) => {
          return i.state === 'pending'
        })
      else return null
    },
    getWaitingInvestments: (state) => {
      if (state.investments)
        return state.investments.filter((i) => {
          return i.state === 'waiting'
        })
      else return null
    },
    getWaitingListInvestments: (state) => {
      if (state.investments)
        return state.investments.filter((i) => {
          return i.state === 'waiting_list'
        })
      else return null
    }
  }
}
