<template>
  <div v-if="isMkt" class="relative">
    <div
      class="relative px-2 py-px rounded w-max rounded"
      :class="[bgColor, { pulse: label === 'for_sale' }]"
    >
      <p class="text-white text-sm">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const i18n = useI18n({ useScope: 'global' })

    const isMkt = computed(() => {
      if (props.label === 'marketplace_purchase' || props.label === 'for_sale')
        return true
      else return false
    })

    const bgColor = computed(() => {
      if (props.label === 'marketplace_purchase') return 'bg-orange-1'
      else if (
        props.label === 'for_sale' ||
        props.label === 'marketplace_purchase_sell' ||
        props.label === 'marketplace_sell'
      )
        return 'bg-orange-2'
      else return ''
    })

    const text = computed(() => {
      if (props.label === 'marketplace_purchase')
        return i18n.t('contracts.mkt_buy')
      else if (
        props.label === 'for_sale' ||
        props.label === 'marketplace_purchase_sell' ||
        props.label === 'marketplace_sell'
      )
        return i18n.t('contracts.mkt_sale')
      else return ''
    })

    return {
      text,
      bgColor,
      isMkt
    }
  }
}
</script>

<style scoped>
.pulse {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(232, 174, 28, 0.7);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(232, 174, 28, 0);
  }
}
</style>
