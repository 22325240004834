<template>
  <div class="min-h-screen md:h-auto px-0 lg:px-24 container overflow-y-hidden relative" style="padding-bottom: 0px">
    <div>
      <FormPage 
        name="investor-classification"/>
    </div>
  </div>
</template>

<script>
import FormPage from "@/components/general/FormPage";
import form from "@/assets/ts/investor_type/base";
import companyForm from "@/assets/ts/investor_type/company";
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  components: {
    FormPage
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      store.dispatch('setForm', store.getters.getProfile().type === 'particular' ? form : companyForm)
      console.log(vm)
    });
  },

  setup(){
  }
})

</script>
