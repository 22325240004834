import axios from 'axios'
import { useI18n } from 'vue-i18n'

export default {
  state: {
    promoterWallets: null,
    wallet: null
  },
  mutations: {
    SET_PROMOTER_WALLETS(state, data) {
      state.promoterWallets = data
    },
    SET_WALLET(state, data) {
      state.wallet = data
    }
  },
  actions: {
    fetchWallets({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/wallets')
        .then((data) => {
          if(data.data.profile) commit('SET_WALLET', data.data.profile)
          if(data.data.promoter) commit('SET_PROMOTER_WALLETS', data.data.promoter)
        })
        .catch((e) => {
          commit('SET_WALLET', null)
          commit('SET_PROMOTER_WALLETS', null)
          commit('SET_ERROR', e)
        })
    },
    fetchWallet({ commit }, walletId) {
      return axios
        .get(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/wallets/' +
            parseInt(walletId)
        )
        .then((data) => {
          commit('SET_WALLET', data.data)
        })
        .catch((e) => {
          commit('SET_ERROR', e)
        })
    },
    storeWallet({ commit }, wallet) {
      commit('SET_WALLET', wallet)
    }
  },
  getters: {
    getWalletById: (state) => (id) => {
      return state.wallets
        ? state.wallets.filter((w) => {
            return w.id === id
          })[0]
        : null
    },
    getMainWallet: (state) => () => {
      return state.wallets
        ? state.wallets.filter((w) => {
            return w.saving_id === null
          })[0]
        : null
    },
    getWalletsCount: (state) => () => {
      return state.wallets ? state.wallets.length : 0
    },
    getWalletsLabels: (state) => () => {
      const i18n = useI18n({ useScope: 'global' })

      let labels = {}
      state.wallets.map((w) => {
        if ((w.saving_id && w.name) || (w.project_id && w.name)) {
          labels[w.id] = w.name
        } else {
          labels[w.id] = i18n.t('transactions.filters.main_wallet')
        }
      })

      return labels
    },
    getWalletsOptions: (state) => () => {
      let wallets = {}
      state.wallets.map((w) => {
        wallets[w.id] = null
      })

      return wallets
    },
    getWalletByProject: (state) => (projectId) => {
      return state.promoterWallets.filter((w) => {
        return w.project_id === projectId
      })[0]
    }
  }
}
