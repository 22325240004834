<template>
  <div class="steps">
    <p class="steps__title--mobile ml-5 md:ml-0">{{$t('auto_investment.setup.project_max.title')}}</p>
    <p class="text-sm text-grey-2 font-semibold lg:font-normal text-opacity-80 text-left mb-12">{{$t('auto_investment.setup.project_max.subtitle')}}</p>
    <Form @submit="next" class="form" ref="form" v-slot="{ errors, meta }">
      <GeneralInput
        v-model:data="projectMax"
        type="dollar"
        name="projectMax"
        :error="errors.projectMax"
        :locked="checkProjectMaxCheck()"
        :label="checkProjectMaxCheck() ? '' : $t('invest.amount.title')"
        :rules="projectMaxCheck ? '' : projectMin ? 'minValue:'+projectMin+'|maxValue:2499900,1' + ',1' : 'minValue:500|maxValue:2499900,1'"
        prefix="$"
      />
      <div class="flex mb-4 justify-between items-start">
        <div
          v-for="el in amounts"
          v-bind:key="el"
          class="
            text-13 text-orange-1
            font-bold
            p-2
            bg-white
            rounded-xl
            mr-3
            cursor-pointer
          "
          @click="checkProjectMaxCheck()? null : projectMax = dollarFormatting(el)"
        >
          $ {{ dollarFormatting(el).split(".")[0] }} 
        </div>
      </div>
      <GeneralInput
        v-model:data="projectMaxCheck"
        type="checkbox"
        name="noProjectMax"
      >
        <template #label>
          {{ $t('auto_investment.setup.project_max.info_box') }}
        </template>
      </GeneralInput>
      <div class="absolute md:bottom-8 bottom-24 w-4/5 md:w-3/4">
        <GpButton
          mobileWidth="w-full"
          :disabled="!formFilled || !meta.valid"
          @click="next"
          class="md:absolute right-0 bottom-0"
        >
          {{ $t('wording.continue') }}
        </GpButton>
      </div>
    </Form>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import GpButton from '@/components/general/GpButton.vue'
import GeneralInput from '@/components/general/Input.vue'
import { Form } from 'vee-validate'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'

export default {
  components: {
    GpButton,
    GeneralInput,
    Form
  },
  setup(props, { emit }) {
    const store = useStore()
    const form = ref(null)
    const projectMax = ref(null)
    const projectMaxCheck = ref(false)
    const amounts = ref([10000, 50000, 100000, 200000])
    const { dollarFormatting, dollarToNumberFormatting } = useGlobalHelpers()
    const projectMaxToStore = ref(null)

    async function next() {
      if(projectMax.value || projectMaxCheck.value) {
        if(projectMaxCheck.value) projectMaxToStore.value = false 
        else projectMaxToStore.value = dollarToNumberFormatting(projectMax.value)
        store.dispatch('updateNewAutoInvestment', {
          project_max: projectMaxToStore.value,
        })
        emit('next')
      }
    }

    const projectMin = computed(() => {
      if(store.state.autoInvestment.autoInvestment.project_min) {
        return store.state.autoInvestment.autoInvestment.project_min
      } 
      return null
    })

     const formFilled = computed(() => {
      return projectMax.value || projectMaxCheck.value 
    })

    const checkProjectMaxCheck = () => {
      if(projectMaxCheck.value === true) {
        projectMax.value = null
        return true
      }
      return false;
    }

    return {
      next,
      projectMax,
      dollarFormatting,
      projectMaxCheck,
      amounts,
      form,
      checkProjectMaxCheck,
      formFilled,
      projectMin,
      projectMaxToStore,
      dollarToNumberFormatting
    }
  }
}
</script>
