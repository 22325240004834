<template>
  <div class="flip-card">
    <div class="flip-card-inner" :class="{'reverse': reverse}">
      <div class="flip-card-front">
        <CardFront
          :name="name"
          :amount="amount"
          :template="template"
          :physical="physical"
          :noShadow="noShadow"
        />
      </div>
      <div class="flip-card-back">
        <CardBack :message="message"
                  :template="template"
                  :noShadow="noShadow"

        />
      </div>
    </div>
    <div class="absolute bg-grey-1 rounded-full p-2 right-0 bottom-0 md:hidden block" @click="reverse = !reverse">
      <img src="@/assets/svg/cycle.svg" alt="">
    </div>
  </div>
</template>

<script>
import CardBack from "@/components/giftcards/CardBack";
import CardFront from "@/components/giftcards/CardFront";
import {ref} from 'vue'
export default {
  components:{
    CardBack,
    CardFront
  },
	props: {
		name: {
			type: String,
			default: ''
		},
		amount: {
			type: String,
			default: ''
		},
		physical: {
			type: Boolean,
			default: false
		},
    noShadow: {
      type: Boolean,
      default: false
    },
    template: {
      type: String,
      default: null
    },
		message: {
			type: String,
			default: null
		}
	},
	setup () {
    const reverse = ref(false)
    return{reverse}
  }
}
</script>

<style lang="postcss" scoped>
.flip-card {
	background-color: transparent;
	width: 100%;
	height: 11rem;
	perspective: 1000px;
	transform-origin: center center;
}

.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	transition: transform 0.6s;
	transform-style: preserve-3d;
}

@media (max-width: 1024px) {
  .reverse{
    transform: rotateY(180deg);
  }
}

.flip-card-front,
.flip-card-back {
	position: absolute;
	width: 100%;
	height: 95%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.flip-card-back {
	transform: rotateY(180deg);
}

@media (min-width: 1024px) {
		.flip-card:hover
		.flip-card-inner {
		transform: rotateY(180deg);
	}

	.flip-card:hover .flip-card-inner {
		transform: rotateY(180deg);
	}
}

</style>
