<template>
  <div class="page flex flex-col items-end">
    <SideBar>
      <FlowProgress step="2" copy="strategies.progress" flow="strategies" />
    </SideBar>
    <img
      src="/img/signup/loved_one.png"
      alt="loved one picture"
      class="hidden lg:block fixed bottom-0 left-40 w-72 z-0 h-72"
    />
    <div class="strategies__desktop-container justify-between z-0 h-96">
      <div class="lg:w-3/4 xl:w-2/3 2xl:w-1/2">
        <div class="mt-2 mb-4">
          <Back
            :to="'/strategies/why-saving'"
            :title="$t('strategies.setup.other_beneficiary.title')"
          />
        </div>
        <ProgressBar class="lg:hidden" :step="2" :total="7" />
        <GeneralInput
          class="mt-6"
          v-model:data="beneficiaryName"
          type="name"
          name="name"
          :label="$t('strategies.setup.other_beneficiary.name_placeholder')"
        />
        <GeneralInput
          class="mt-6"
          ref="dateInput"
          :required="true"
          v-model:data="birthdate"
          type="date"
          name="date"
          :toDate="new Date()"
          :fromDate="false"
          :label="$t('strategies.setup.other_beneficiary.birthday_placeholder')"
        />
        <div v-if="allFilled" class="pt-2">
          <p
            v-if="isUnderAged"
            class="text-sm text-grey-2 text-opacity-60 text-left"
            v-html="
              $t('strategies.setup.other_beneficiary.under_age_text', {
                firstName: beneficiaryName.split(' ')[0]
              })
            "
          />
          <p
            v-else
            class="text-sm text-grey-2 text-opacity-60 text-left"
            v-html="
              $t('strategies.setup.other_beneficiary.over_age_text', {
                firstName: beneficiaryName.split(' ')[0]
              })
            "
          />
        </div>
      </div>
      <div class="lg:flex lg:justify-end lg:w-3/4 xl:w-2/3 2xl:w-1/2">
        <GpButton :disabled="!allFilled" @click="selectOtherBeneficiary">
          {{ $t('wording.next') }}
        </GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import SideBar from '@/components/general/SideBar.vue'
import GeneralInput from '@/components/general/Input.vue'
import ProgressBar from '@/components/general/ProgressBar.vue'
import FlowProgress from '@/components/general/FlowProgress.vue'
import { ref, computed, onBeforeMount } from 'vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useFlows from '@/mixins/useFlows.js'

export default {
  components: {
    Back,
    GeneralInput,
    ProgressBar,
    GpButton,
    FlowProgress,
    SideBar
  },
  setup() {
    const beneficiaryName = ref(null)
    const birthdate = ref(null)
    const store = useStore()
    const router = useRouter()
    const { dateFormatting } = useGlobalHelpers()

    const { noNewStrategy } = useFlows()
    onBeforeMount(() => {
      noNewStrategy()
    })

    const allFilled = computed(() => {
      return beneficiaryName.value && birthdate.value
    })

    const isUnderAged = computed(() => {
      const now = new Date()
      let year = birthdate.value.getFullYear()
      let month = birthdate.value.getMonth()
      let day = birthdate.value.getDate()
      let legalAge = new Date(year + 18, month, day)
      return legalAge > now
    })

    const selectOtherBeneficiary = () => {
      store.dispatch('updateNewStrategy', {
        other_beneficiary: {
          name: beneficiaryName.value,
          birthdate: dateFormatting(birthdate.value) 
        }
      })
      router.push('/strategies/direct-debit')
    }

    return {
      beneficiaryName,
      birthdate,
      allFilled,
      selectOtherBeneficiary,
      isUnderAged
    }
  }
}
</script>

<style lang="scss" scoped></style>
