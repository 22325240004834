export default function () {

  function suitabilityLevel(suitability, rules, confirmation = false){
    // first check automatic manual
    if(!rules) return 'unsuitable_pending'
    if(
      !suitability.employment || 
      !suitability.employment_status ||
      !suitability.investing_exp ||
      !suitability.goal ||
      !suitability.risk_level ||
      !suitability.investment_term ||
      !suitability.portfolio_percentage ||
      !suitability.investment_reason ||
      !suitability.other_primary_income ||
      !suitability.not_insider ||
      !suitability.no_borrowed ||
      !suitability.self_info
    ) {
      return 'unsuitable_pending'
    }

      // If low risk
      if(suitability.risk_level === '3_low') return 'unsuitable_pending'
      
      // If invests more than 10%
      if(suitability.portfolio_percentage === 'more_10') return 'unsuitable_pending'
      
      // If big purchase or save for the future
      if(['save_for_child', 'save_for_retirement', 'save_for_purchase'].includes(suitability.investment_reason)) return 'unsuitable_pending'
    
      // If medium risk
      if(suitability.risk_level === '2_medium') {
        // If only one of bonds, mutual funds, term deposits or real estate
        if(!suitability.previous_investments || suitability.previous_investments.split(',').length === 1 && ['bonds', 'mutual_funds', 'term_deposits', 'real_estate'].includes(suitability.previous_investments)) return 'unsuitable_pending'      
        
        // If goal is earn passive income or save
        if(suitability.goal === 'save_money') return 'unsuitable_pending'
        
        // If new investor or little exp
        if(['new_investor', 'little_exp'].includes(suitability.investing_exp)) return 'unsuitable_pending'
        
        // If big purchase or save for the future
        if(['save_for_future'].includes(suitability.investment_reason)) return 'unsuitable_pending'
      }
  
    //lender qualification All investors are qualified as retail automatically, but when they hit 10k then they can schedule a meeting to increase to resulting category limit
    if(
      suitability.net_worth       >= rules.accredited_net_worth || 
      suitability.assets_value    >= rules.accredited_assets_value || 
      suitability.personal_income >= rules.accredited_personal_income 
    )
    {
      // Accredited
      return confirmation ? 'accredited' : 'accredited_pending'
    } 
    else if(
      suitability.net_worth       >= rules.eligible_net_worth || 
      suitability.personal_income >= rules.eligible_personal_income 
    )
    {
      // Eligible
      return confirmation ? 'eligible' : 'eligible_pending'
    }


    // Retail
    else return 'retail'
  }

  return {
    suitabilityLevel,
  }
}
