<template>
  <div
    class="
      left-0
      absolute
      w-full
      bottom-0
      md:rounded-b-2xl
      text-white text-left
      px-6
      py-4
      cursor-pointer
    "
    :class="bg[type]"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="goTo"
  >
    <div class="flex">
      <img src="@/assets/svg/clock.svg" alt="" class="mr-2" />
      <div
        v-html="
          $t(
            'home.next_payment.' +
              (type === 'SUCCEEDED' || type === 'CREATED' || type === 'FAILED'
                ? 'default'
                : type.toLowerCase()),
            {
              amount: dollarFormatting(amount),
              daysLeft: daysLeft,
              arrearsDate: dateFormatting(
                new Date(arrearsDate),
                '/',
                true,
                false
              )
            }
          )
        "
      ></div>
      <img
        src="@/assets/svg/chev-right-white.svg"
        alt=""
        class="w-4 ml-8 transform transition ease-out"
        :class="{ 'translate-x-1': hover }"
      />
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers'
import { useRoute, useRouter } from 'vue-router'

export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    daysLeft: Number,
    id: Number,
    amount: Number,
    arrearsDate: String
  },
  setup(props) {
    const { dollarFormatting, dateFormatting } = useGlobalHelpers()
    const hover = ref(false)
    const router = useRouter()
    const route = useRoute()
    const bg = ref({
      default: '',
      CREATED: '',
      SUCCEEDED: 'bg-green-1',
      arrears: 'bg-orange-3',
      FAILED: 'bg-orange-3'
    })
    function goTo() {
      router.push(
        '/loan/' + (route.params.id ?? props.id) + '/details/payments'
      )
    }
    return { bg, dollarFormatting, hover, dateFormatting, goTo }
  }
}
</script>
