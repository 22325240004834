<template>
  <div
    class="
      lg:absolute
      lg:top-0
      lg:w-full
      lg:h-screen
      lg:z-40
      flex
    "
  >
    <div
      class="
        lg:w-full lg:flex lg:justify-center lg:items-center lg:flex-grow
        flex
      "
    >
      <div
        class="
          container
          flex-grow
          lg:flex-grow-0 lg:h-auto
          flex flex-col
          justify-between
          lg:bg-white lg:w-2/5 lg:p-14 lg:rounded-3xl
        "
      >
        <div class="text-left lg:text-center">
          <img
            :src="
              require('@/assets/svg/strategies/' + icon + '_grey_border.svg')
            "
            class="w-18 my-6 lg:mx-auto"
            alt=""
          />
          <p class="text-grey-2 text-2xl mb-2 lg:mb-6">
            {{ $t('wording.confirm_changes') }}
          </p>
          <p class="text-grey-2 mb-8 lg:hidden">{{ text }}</p>
          <div
            class="bg-white lg:bg-grey-2 lg:bg-opacity-10 rounded-2xl px-4 py-2"
          >
            <div
              v-for="(d, index) of displayData"
              :key="index"
              class="py-2"
              :class="
                vertical ? 'text-left' : 'flex justify-between items-center'
              "
            >
              <p class="text-grey-2 text-sm text-opacity-80">
                {{ d.label }}
              </p>
              <p class="text-grey-2 text-sm font-semibold">{{ d.value }}</p>
            </div>
          </div>
        </div>
        <div class="mx-auto mt-6">
          <GpButton class="mb-4" color="white" @click="discard">
            {{ $t('wording.discard') }}
          </GpButton>
          <GpButton @click="edit">
            {{ $t('wording.confirm') }}
          </GpButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GpButton from '@/components/general/GpButton.vue'
import { getCurrentInstance } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: {
    GpButton
  },
  props: {
    displayData: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    },
    text: {
      type: String,
      default: ''
    },
    vertical: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    strategyId: {
      type: Number,
      default: -1
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const { emit } = getCurrentInstance()

    const discard = () => {
      emit('discard')
    }

    const edit = () => {
      if (props.strategyId !== -1)
        store
          .dispatch('updateStrategy', {
            id: props.strategyId,
            data: props.data
          })
          .then(() => {
            router.go(-1)
          })
          .catch((err) => {
            store.dispatch('setError', err)
          })
    }

    return {
      edit,
      discard
    }
  }
}
</script>

<style lang="scss" scoped></style>
