import i18n from "@/i18n";
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import { Types } from "@/assets/ts/enums/form/types";
const order = ['0_percent', '0_to_10', 'more_10'];
const time_frame_order = ['1_3_years', '3_5_years', '5_8_years'];

const professional:PageForm =
  {
    name: i18n.global.t('ca_legislation.suitability.side_panel.names.percent'),
    title: i18n.global.t('ca_legislation.suitability.side_panel.titles.percent'),
    form: [
      {
        title: i18n.global.t('ca_legislation.suitability.percent.title'),
        subtitle:  i18n.global.t('ca_legislation.suitability.percent.subtitle'),
        fields: [
          {
            key: "portfolio_percentage",
            value: "",
            options: order.reduce((obj, key) => {
                // @ts-ignore
                return {...obj, [key]: i18n.global.tm("ca_legislation.suitability.percent.options")[key]};
            }, {}),
            rules: "required",
            type: Types.RADIO
          },
          {
            key: "investment_term",
            value: "",
            title: i18n.global.t('ca_legislation.suitability.investment_term.title'),
            subtitle:  i18n.global.t('ca_legislation.suitability.investment_term.subtitle'),
            options: time_frame_order.reduce((obj, key) => {
              // @ts-ignore
              return {...obj, [key]: i18n.global.tm("ca_legislation.suitability.investment_term.options")[key]};
            }, {}),
            rules: "required",
            type: Types.RADIO
          }
        ],
        next: true,
        back: true
      }
    ]
  }

export default professional
