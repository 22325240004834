import i18n from "@/i18n";
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import { Types } from "@/assets/ts/enums/form/types";
import {get_type} from "@/assets/ts/suitability/callbacks/update_suitability";
import {add_instruments_details} from "@/assets/ts/suitability/callbacks/update_suitability";

const checks:PageForm =
  {
    name: i18n.global.t('ca_legislation.suitability.side_panel.names.check'),
    title: i18n.global.t('ca_legislation.suitability.side_panel.titles.check'),
    form: [
      {
        title: i18n.global.t("ca_legislation.suitability.check.title"),
        subtitle:  i18n.global.t('ca_legislation.suitability.check.subtitle'),
        fields: [
            {
              key: "no_borrowed",
              subtitle: i18n.global.t("ca_legislation.suitability.no_borrowed.subtitle"),
              label: i18n.global.t("ca_legislation.suitability.check.confirm"),
              type: Types.CHECKBOX,
              rules: 'required'
            },
            {
              key: "other_primary_income",
              subtitle: i18n.global.t("ca_legislation.suitability.other_primary_income.subtitle"),
              type: Types.CHECKBOX,
              label: i18n.global.t("ca_legislation.suitability.check.confirm"),
              rules: 'required'
            },
            {
              key: "self_info",
              subtitle: i18n.global.t("ca_legislation.suitability.self_info.subtitle"),
              type: Types.CHECKBOX,
              label: i18n.global.t("ca_legislation.suitability.check.confirm"),
              rules: 'required'
            },
            {
              key: "tcp_form",
              subtitle: i18n.global.t("ca_legislation.suitability.tcp.subtitle"),
              type: Types.CHECKBOX,
              label: i18n.global.t("ca_legislation.suitability.check.confirm")
            },
            {
              key: "not_insider",
              subtitle: i18n.global.t("ca_legislation.suitability.not_insider.subtitle"),
              type: Types.CHECKBOX,
              label: i18n.global.t("ca_legislation.suitability.check.confirm"),
              condition: {
                key: 'type',
                value: 'particular'
              }
            },{
              key: "not_insider",
              subtitle: i18n.global.t("ca_legislation.suitability.not_insider.subtitle_org"),
              type: Types.CHECKBOX,
              label: i18n.global.t("ca_legislation.suitability.check.confirm"),
              condition: {
                key: 'type',
                value: 'company'
              }
            },
            {
              key: "prospectus",
              title: i18n.global.t("ca_legislation.suitability.prospectus.title"),
              subtitle: i18n.global.t("ca_legislation.suitability.prospectus.subtitle"),
              type: Types.CHECKBOX,
              label: i18n.global.t("ca_legislation.suitability.check.confirm"),
              condition: {
                key: 'type',
                value: 'company'
              }
            },
            {
              key: "created_gp",
              title: i18n.global.t("ca_legislation.suitability.created.title"),
              subtitle: i18n.global.t("ca_legislation.suitability.created.subtitle"),
              type: Types.CHECKBOX,
              label: i18n.global.t("ca_legislation.suitability.check.confirm"),
              condition: {
                key: 'type',
                value: 'company'
              }
            }
        ],
        next: true,
        mountedCallback: get_type,
        nextCallback: add_instruments_details,
        back: true,
        confirmations:[
            {
              label:i18n.global.t('ca_legislation.suitability.om.subtitle', {field: process.env.VUE_APP_OM_LINK}),
              required: true
            }
        ]
      }
    ]
  }

export default checks
