<template>
  <div :class="{ 'steps flex flex-col': isWithdraw }">
    <div v-if="profile && isWithdraw" class="steps__title--mobile ml-5 md:ml-0">
      {{ $tm('settings.add_phone_number.verification_code.title') }}
    </div>
    <div
      v-if="profile && profile.phone && profile.country_code"
      class="text-left text-grey-2 lg:text-grey-3 my-6 text-sm"
    >
      {{
        $t('settings.add_phone_number.verification_code.sms_code') +
        '+' +
        profile.country_code +
        maskPhoneNumber(profile.phone) +
        '.'
      }}
      <div class="text-left text-grey-2 lg:text-grey-3 mb-6 text-sm">
        {{ $t('settings.add_phone_number.verification_code.how_to') }}
      </div>
      <div>
        <Form class="form" ref="form" v-slot="{ errors, meta }">
          <GeneralInput
            v-model:data="code"
            type="number"
            name="code"
            @keydown.enter.prevent
            :label="$t('settings.add_phone_number.verification_code.code')"
            rules="required|exactLength:6"
            :error="errors.code"
          />
          <p :class="'lg:flex'" class="text-xs text-grey-4">
            {{
              $t('settings.add_phone_number.verification_code.fifteen minutes')
            }}
          </p>

          <div class="py-12 flex flex-row">
            <div
              class="hidden lg:block text-left text-grey-3 my-6 text-sm mr-2"
            >
              {{
                $t(
                  'settings.add_phone_number.verification_code.new_code_question'
                )
              }}
            </div>

            <div
              @click="sendSmsCode"
              class="text-left lg:text-grey-3 my-6 text-sm orange-link"
            >
              {{ $t('settings.add_phone_number.verification_code.send_new') }}
            </div>
            <transition name="toast-fade">
              <div class="flex justify-center" v-if="show">
                <div class="absolute hidden md:block">
                  <div class="flex bg-grey-1 text-white px-4 py-2 rounded-xl">
                    <img src="@/assets/svg/copy_inverted.svg" alt="" />
                    <div class="ml-3 opacity-80 font-semibold text-13">
                      {{ $t('wording.sent') }}
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div
            class="
              absolute
              bottom-0
              w-screen
              container
              left-1/2
              -translate-x-1/2
              transform
            "
          >
            <div class="w-full relative">
              <GpButton
                :class="isWithdraw ? 'lg:bottom-8 bottom-24' : ' bottom-8'"
                :disabled="!meta.valid || loading"
                @click="checkCode"
                class="absolute right-0"
              >
                {{ $t('wording.confirm') }}
              </GpButton>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form } from 'vee-validate'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { useI18n } from 'vue-i18n'
import GpButton from '@/components/general/GpButton.vue'
import GeneralInput from '@/components/general/Input'

export default {
  props: {
    isWithdraw: { type: Boolean, default: false }
  },
  components: {
    GpButton,
    Form,
    GeneralInput
  },
  emits: ['finish'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const i18n = useI18n({ useScope: 'global' })
    const { maskPhoneNumber } = useGlobalHelpers()
    const code = ref(null)
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const show = ref(null)

    const loading = computed(() => {
      return store.state.general.loading
    })

    function checkCode() {
      store.dispatch('setLoading', true)
      store
        .dispatch('checkVerificationCode', { pin: code.value })
        .then(() => {
          store.dispatch('setLoading', false)
          if (props.isWithdraw) emit('finish')
          else {
            const popup = {
              title: i18n.t(
                'settings.add_phone_number.verification_code.success_popup.title'
              ),
              align: 'center',
              side: false,
              iconBg: 'bg-green-5',
              icon: '/img/done_step.svg',
              content: i18n.t(
                'settings.add_phone_number.verification_code.success_popup.description'
              ),
              primaryButton: i18n.t(
                'settings.add_phone_number.verification_code.success_popup.btn'
              )
            }
            store.dispatch('setPopup', popup)
            router.push({name: 'dashboard'})
          }
        })
        .catch((e) => {
          store.dispatch('setError', e)
          store.dispatch('setLoading', false)
        })
    }
    function sendSmsCode() {
      store.dispatch('setLoading', true)

      store
        .dispatch('sendSms')
        .then(() => {
          store.dispatch('setLoading', false)

          show.value = true
          setTimeout(function () {
            show.value = null
          }, 500)
        })
        .catch((e) => {
          store.dispatch('setError', e)
          store.dispatch('setLoading', false)
        })
    }

    return {
      maskPhoneNumber,
      checkCode,
      sendSmsCode,
      loading,
      code,
      profile,
      show
    }
  }
}
</script>
