<template>
  <div class="page h-screen flex items-center overflow-y-hidden container">
    <a href="https://goparity.ca" >
      <img
        src="@/assets/img/logo/goparity.svg"
        alt=""
        class="
          hidden
          lg:block
          absolute
          top-0
          left-0
          ml-4
          mt-4
          w-28
          sm:ml-10 sm:mt-10 sm:w-40
          z-50
        "
      />
    </a>

    <GreenBall
      start="bottom"
      offset="2rem"
      cutout="0"
      class="opacity-10 absolute hidden md:block z-0"
    />
    <GreenBall
      start="top"
      offset="2rem"
      cutout="0"
      class="opacity-10 scale-50 transform hidden md:block z-0"
      side="right"
    />
    <div
      class="
        w-full
        max-w-2xl
        mx-auto
        md:px-16 md:py-12
        flex flex-col
        h-full
        lg:h-5/6 lg:bg-white lg:bg-opacity-80
        z-10
        rounded-3xl
        relative
      "

    >
      <div  class="h-full block flex-col justify-between">
        <router-link class="lg:hidden" to="login">
          <img
            src="@/assets/svg/arrows/left_grey_arrow.svg"
            alt="left_arrow"
            class="absolute top-6"
          />
        </router-link>
        <div class="my-4 text-xl text-grey-2 font-bold text-left ml-6 md:ml-0">
          {{ $t('passwordReset.title_underline') }}
        </div>
        <div class="mb-6 text-sm text-grey-2 text-left">
          {{ $t('passwordReset.subtitle') }}
        </div>
        <Form @submit="definePassword" v-slot="{ errors, meta }">
          <GeneralInput
            v-model:data="password"
            type="password"
            name="password"
            label="Password"
            :bgColor="'#f4f6f5'"
            :signupPassword="true"
            :error="errors.password"
            rules="required|minLength:8|hasUpperLower|hasNumberSymbol|maxLength:24"
          />
          <div class="absolute bottom-0 lg:bottom-12 width-button  left-1/2 transform -translate-x-1/2 w-full lg:w-3/5">
            <GpButton
              :disabled="!meta.valid || loading"
              desktopWidth="w-full"
              class=" lg:mb-12"
              @click="definePassword"
            >
              {{ $t('passwordReset.btn_text') }}
            </GpButton>

          </div>

        </Form>

        <span class="hidden lg:block text-grey-4 mt-10 text-sm absolute bottom-8 left-1/2 transform -translate-x-1/2">
          {{ $t('wording.back_to') }}
          <router-link to="login" class="orange-link">
            {{ $t('wording.signin') }}
          </router-link>
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import GreenBall from '@/components/general/GreenBall.vue'
import GeneralInput from '@/components/general/Input.vue'
import { Form } from 'vee-validate'
import GpButton from '@/components/general/GpButton.vue'
import { ref, computed } from 'vue'
import { useI18n } from "vue-i18n";

export default {
  components: {
    GpButton,
    GreenBall,
    GeneralInput,
    Form
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const password = ref('')
    const loading = computed(()=>{
      return store.state.general.loading
    })
    const i18n = useI18n({ useScope: 'global' })

    const definePassword = () => {
      store.dispatch('setLoading', true)
      store
        .dispatch('resetPassword', {
          code: route.query.reset,
          password: password.value,
          password_confirmation: password.value
        })
        .then(() => {
          store.dispatch('setLoading', false)
          const popup = {
            title: i18n.t('passwordReset.success_title'),
            align: 'center',
            side: false,
            icon: '/img/popup/check.svg',
            content: i18n.t('passwordReset.success_text'),
            primaryButton: i18n.t('passwordReset.success_btn'),
            primaryLink: '/login',
            iconBg: 'bg-green-4'
          }
          store.dispatch('setPopup', popup)
        }).catch((e)=>{
          store.dispatch('setLoading', false)
          store.dispatch('setError',e)
      })
    }

    return {
      loading,
      definePassword,
      password
    }
  }
}
</script>

<style lang="scss" scoped></style>
