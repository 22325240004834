<template>
  <div
    v-if="!isBorrower && currentState && isKyc"
   class="animate-pulse bg-white rounded-2xl w-full flex flex-col justify-between p-4"
  >
    <onboarding-progress-bar
      :current="currentStep"
      :goal="5"
      class="mb-5"
    />
    <div class="flex justify-between">
      <div class="flex">
        <div class="flex items-start w-8 mr-1">
          <img class="w-full" src="@/assets/svg/info.svg" alt="info" />
        </div>
        <div>
          <p class="text-left text-grey-2 text-sm font-semibold mb-2">
            {{ $t('settings.suitability.' + currentState.key + '.title') }}
          </p>
          <p class="text-left text-grey-2 text-sm text-opacity-50">
            <slot name="content">
              {{ $t('settings.suitability.' + currentState.key + '.description') }}
            </slot>
          </p>
        </div>
      </div>
    </div>
    <router-link
      :to="currentState.link"
      class="orange-link text-right text-13 text-transform: uppercase">
      {{ $t('settings.suitability.' + currentState.key + '.link') }}
    </router-link>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import OnboardingProgressBar from '@/components/general/OnboardingProgressBar.vue'

export default {
  components:{OnboardingProgressBar},
  setup() {
    const store = useStore()
    const profile = computed(() => {
      return store.getters.getProfile()
    })

    const isBorrower = computed(() => {
      return (profile.value && profile.value.sub_type === 'borrower') ? true : false
    })

    const isKyc = computed(() =>{
      return profile.value.kyc_level === 'regular' ? true : false
    })

    const states = [
      { key: 'missing', condition: profile.value.suitability.risk_level == null, link: '/suitability' },
      { key: 'incomplete', condition: profile.value.suitability.assets_value == 0, link: '/investor-classification' },
      { key: 'unsuitable', condition: profile.value.sub_type == 'unsuitable_pending', link: '/settings/suitability' }
    ]

    const currentState = computed(() => {
      return states.find(state => state.condition)
    })

    const currentStep = computed(() => {
      return profile.value.suitability.risk_level == null ? 3 : 
        (profile.value.suitability.assets_value == 0 ? 4 : 5
      ) 
    })

    return {
      isBorrower,
      isKyc,
      currentState,
      currentStep
    }
  }
}
</script>
