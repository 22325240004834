<template>
  <div class="steps">
    <p class="steps__title--mobile ml-5 md:ml-0">{{$t('auto_investment.setup.project_min.title')}}</p>
    <p class="text-sm text-grey-2 font-semibold lg:font-normal text-opacity-80 text-left mb-12">{{$t('auto_investment.setup.project_min.subtitle')}}</p>
    <Form @submit="next" class="form" ref="form" v-slot="{ errors, meta }">
      <GeneralInput
        v-model:data="projectMin"
        type="dollar"
        name="projectMin"
        :error="errors.projectMin"
        :locked="checkProjectMinCheck()"
        :label="checkProjectMinCheck() ? '' : $t('invest.amount.title')"
        :rules="projectMinCheck ? '' : projectMax ? 'minValue:500|maxValue:' + projectMax + ',1' : 'minValue:500|maxValue:2499900,1'"
        prefix="$"
      />
      <div class="flex mb-4 justify-between items-start">
        <div
          v-for="el in amounts"
          v-bind:key="el"
          class="
            text-13 text-orange-1
            font-bold
            p-2
            bg-white
            rounded-xl
            mr-3
            cursor-pointer
          "
          @click="checkProjectMinCheck() ? null : projectMin = dollarFormatting(el)"
        >
          $ {{ numberFormatting(dollarFormatting(el)) }} 
        </div>
      </div>
      <GeneralInput
        v-model:data="projectMinCheck"
        class="custom-checkbox"
        type="checkbox"
        name="noProjectMin"
      >
        <template #label>
          {{ $t('auto_investment.setup.project_min.info_box') }}
        </template>
      </GeneralInput>
      <div class="absolute md:bottom-8 bottom-24 w-4/5 md:w-3/4">
        <GpButton
          mobileWidth="w-full"
          @click="next"
          :disabled="!formFilled || !meta.valid"
          class="md:absolute right-0 bottom-0"
        >
          {{ $t('wording.continue') }}
        </GpButton>
      </div>
    </Form>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import GpButton from '@/components/general/GpButton.vue'
import GeneralInput from '@/components/general/Input.vue'
import { Form } from 'vee-validate'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'

export default {
  components: {
    GpButton,
    GeneralInput,
    Form
  },
  setup(props, { emit }) {
    const store = useStore()
    const form = ref(null)
    const projectMin = ref(null)
    const projectMinToStore = ref(null)
    const projectMinCheck = ref(false)
    const amounts = ref([5000, 7500, 15000, 25000])
    const { dollarFormatting, numberFormatting, dollarToNumberFormatting } = useGlobalHelpers()

    async function next() {
      if(projectMin.value || projectMinCheck.value) {
        if(projectMinCheck.value) projectMinToStore.value = false 
        else projectMinToStore.value = dollarToNumberFormatting(parseInt(projectMin.value))
        store.dispatch('updateNewAutoInvestment', {
          project_min: projectMinToStore.value
        })
        emit('next')
      }
      return false
    }

    const projectMax = computed(() => {
      return store.state.autoInvestment.autoInvestment.project_max ?? null
    })

    const checkProjectMinCheck = () => {
      if(projectMinCheck.value === true) {
        projectMin.value = null
        return true
      }
      return false
    }

    const setPredefinedAmount = (value) => {
			if (!projectMinCheck.value) {
				projectMin.value = value
			}
		}

    const formFilled = computed(() => {
      return projectMin.value || projectMinCheck.value 
    })

    return {
      next,
      projectMin,
      dollarFormatting,
      projectMinCheck,
      numberFormatting,
      amounts,
      form,
      checkProjectMinCheck,
      formFilled,
      setPredefinedAmount,
      projectMax,
      dollarToNumberFormatting,
      projectMinToStore
    }
  }
}
</script>
