<template>
  <div>
    <div
      v-if="error"
      class="
        fixed
        top-0
        left-0
        w-full
        h-screen
        bg-grey-2 bg-opacity-50
        z-50
        flex
        items-center
        justify-center
      "
    >
      <div
        class="
          bg-white
          w-80
          h-auto
          rounded-2xl
          flex
          items-center
          justify-center
          px-6
        "
      >
        <div class="flex-grow flex flex-col justify-between py-6 h-full">
          <div v-if="typeof message === 'string'" class="mb-4">
            <p class="mb-6 text-grey-2 font-bold text-lg">
              {{ $t('errors.api.' + message + '.title') }}
            </p>
            <p class="text-grey-2 text-sm">
              {{
                $t('errors.api.' + message + '.description', {
                  value: error.response.data.value
                })
              }}
            </p>
          </div>
          <div v-else-if="message && message.length" class="mb-4">
            <p class="mb-6 text-grey-2 font-bold text-lg">
              {{ $t('errors.api.general_error.title') }}
            </p>
            <p
              class="text-grey-2 text-sm"
              v-for="error in message"
              v-bind:key="error.code"
            >
              {{
                $t('errors.api.' + error.code + '.description', {
                  value: error.value
                })
              }}
            </p>
          </div>
          <div
            v-else-if="
              typeof message === 'object' &&
              !Array.isArray(message) &&
              message !== null
            "
            class="mb-4"
          >
            <p class="mb-6 text-grey-2 font-bold text-lg">
              {{ $t('errors.api.' + message.code + '.title') }}
            </p>
            <p class="text-grey-2 text-sm">
              {{
                $t('errors.api.' + message.code + '.description', {
                  value: message.value
                })
              }}
            </p>
          </div>
          <button class="fat-btn-orange-full" @click="close">
            {{ $t('errors.try_again') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useError from '@/mixins/useError.js'
export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const { serverError, errorMessage } = useError()

    // Adds final data to simulation so it can also be sent in email
    watch(store.state.error, () => {
      if (serverError(store.state.error.error)) {
        router.push('/error')
      }
    })

    const redirect = computed(() => {
      return store.state.error.redirect
    })

    const error = computed(() => {
      return serverError(store.state.error.error)
        ? null
        : store.state.error.error
    })

    const message = computed(() => {
      return errorMessage()
    })

    const close = () => {
      const dest = redirect.value
      store.dispatch('clearError')
      if (dest) router.push(dest)
    }

    return {
      error,
      close,
      message,
      redirect
    }
  }
}
</script>

<style lang="scss" scoped></style>
