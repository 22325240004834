import info from "@/assets/ts/onboarding/steps/info";
import types from "@/assets/ts/onboarding/steps/types";
import questions_company from "@/assets/ts/onboarding/steps/questions_company";
import questions_company2 from "@/assets/ts/onboarding/steps/questions_company2";
import questions_company3 from "@/assets/ts/onboarding/steps/questions_company3";

const form =
  {
      info,
      types,
      questions_company,
      questions_company2,
      questions_company3
  }

export default form
