<template>
  <div v-if="walletData">
    <SideBar>
      <div class="h-screen relative">
        <img
          src="@/assets/img/mountain-sidebar.png"
          alt="mountain background"
          class="absolute bottom-28 opacity-80 z-negative"
        />
        <div class="text-left w-3/4 mx-auto">
          <div class="text-3xl font-medium">
            <div class="text-grey-3">
              {{ $t('withdraw.sidebar.title') }}
            </div>
            <div class="text-grey-2 mt-1" v-if="!walletData.name">
              {{ $t('withdraw.sidebar.main_wallet') }}
            </div>
            <div class="text-grey-2 mt-1" v-else>
              {{ walletData.name }}
            </div>
          </div>

          <div class="mt-10 text-grey-3 text-sm">
            {{ $t('withdraw.sidebar.wallet_balance') }}
            <transition name="fade">
              <p class="text-3xl font-medium">
                ${{
                  walletData.balance
                    ? dollarFormatting(walletData.balance)
                    : '0.00'
                }}
              </p>
            </transition>
          </div>
        </div>
      </div>
    </SideBar>
    <div
      class="
        w-full
        lg:w-2/3
        ml-auto
        min-h-screen
        relative
        text-left
        lg:pt-28
        z-0
      "
    >
      <div
        class="
          md:w-3/4
          w-4/5
          m-auto
          absolute
          md:relative md:left-0
          left-9
          top-2
          z-50
        "
      >
        <transition name="toast-fade">
          <div
            class="
              back
              text-left text-grey-2
              md:flex
              absolute
              top-5
              items-center
              cursor-pointer
              md:mb-12
            "
            @click="previousSlide"
          >
            <img
              src="@/assets/svg/chev-left.svg"
              alt=""
              class="w-5 md:w-auto"
            />

            <span class="hidden md:block">{{ $t('wording.back') }}</span>
          </div>
        </transition>
      </div>
      <router-link to="/dashboard">
        <div class="block md:hidden absolute right-9 top-6 z-50">
          <img src="@/assets/svg/cross.svg" alt="" />
        </div>
      </router-link>
      <swiper
        class="w-full"
        :loop="false"
        @swiper="setSwiper"
        :slides-per-view="1"
        :space-between="50"
        :pagination="false"
        :allowTouchMove="false"
        :speed="1000"
        :autoplay="false"
        :autoHeight="true"
      >
        <swiper-slide class="flex flex-col">
          <ChooseBankAccount
            class="pb-20 w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
            @next="nextSlide"
          />
        </swiper-slide>
        <swiper-slide class="flex flex-col" v-slot="{ isActive }">
          <Amount
            class="pb-20 w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
            :balance="'$' + dollarFormatting(walletData && walletData.balance ? walletData.balance : 0)"
            @next="nextSlide"
            :walletName="
              walletData.name
                ? walletData.name
                : $t('withdraw.sidebar.main_wallet')
            "
            :walletBalance="walletData.balance"
            :wallet="walletData"
            :presetAmount="dollarFormatting(parseInt(propsAmount))"
            :isActive="isActive"
          />
        </swiper-slide>
        <swiper-slide v-slot="{ isActive }" class="flex flex-col">
          <Confirmation
            class="pb-20 w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
            @next="nextSlide"
            :wallet="walletData"
            :isActive="isActive"
          />
        </swiper-slide>
        <swiper-slide class="overflow-x-hidden flex flex-col">
          <VerificationCode
            class="pb-20 w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
            @finish="finish"
            :isWithdraw="true"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/general/SideBar.vue'
import Amount from '@/components/withdraw/Amount.vue'
import ChooseBankAccount from '@/components/withdraw/ChooseBankAccount.vue'
import VerificationCode from '@/components/general/VerificationCode.vue'
import Confirmation from '@/components/withdraw/Confirmation.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { computed, ref, onMounted } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  components: {
    SideBar,
    Swiper,
    Amount,
    ChooseBankAccount,
    Confirmation,
    SwiperSlide,
    VerificationCode
  },
  props: {
    wallet: String,
    propsAmount: String
  },
  setup(props) {
    const swiper = ref(null)
    const store = useStore()
    const router = useRouter()
    const { dollarFormatting } = useGlobalHelpers()
    // const i18n = useI18n({ useScope: 'global' })
    
    const walletData = computed(() => {
      return props.propsWallet ? JSON.parse(props.propWallet) : store.state.wallets.wallet
    })

    onMounted(async () => {
      if (!props.propWallet) {
        await store.dispatch('fetchWallets')
      }
    })

    const activeIndex = computed(() => {
      return swiper.value ? swiper.value.activeIndex : 0
    })

    // Initialize Swiper
    function setSwiper(value) {
      swiper.value = value
    }

    /** COMPUTED **/
    const amount = computed(() => {
      return store.state.withdraw.amount
    })

    const bankAccountId = computed(() => {
      return store.state.withdraw.bankAccount.id
    })

    // Swipe Next
    async function nextSlide() {
      swiper.value.slideTo(swiper.value.activeIndex + 1)
    }

    // Swipe Previous
    function previousSlide() {
      if (swiper.value.activeIndex > 0) {
        if (swiper.value.activeIndex === 3) {
          swiper.value.slideTo(swiper.value.activeIndex - 2)
        } else {
          swiper.value.slideTo(swiper.value.activeIndex - 1)
        }
      } else {
        router.push({ name: 'dashboard' })
      }
    }

    //Post Withdraw
    async function finish() {
      store.dispatch('setLoading', true)
      store.dispatch('withdraw', {
          wallet_id: walletData.value.id,
          params: {
            amount: amount.value,
            bank_account_id: bankAccountId.value
          }
        })
        .then(() => {
          const popup = {
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            component: 'GoodPayout'
          }
          store.dispatch('setPopup', popup)
          store.dispatch('setLoading', false)
        })
        .catch(() => {
          store.dispatch('setLoading', false)
        })
    }

    return {
      walletData,
      setSwiper,
      nextSlide,
      dollarFormatting,
      finish,
      previousSlide,
      activeIndex,
      amount,
      bankAccountId
    }
  }
}
</script>
<style scoped>
@media (min-width: 1024px) {
  .steps {
    padding-bottom: max(3.5rem, 0%);
  }
}
</style>