<template>
  <div></div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onBeforeMount } from '@vue/runtime-core'
import useFlows from '@/mixins/useFlows.js'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
export default {
  props: {
    project: {
      type: String,
      default: ''
    },
    simulationAmount: {
      type: String,
      default: ''
    },
    editReservation: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const { resetInvestFlow } = useFlows()
    const { isInt } = useGlobalHelpers()

    resetInvestFlow(-1)

    const projectId = computed(() => {
      return parseInt(props.project)
    })

    onBeforeMount(async () => {
      await store.dispatch('fetchWallets')
      
      // Check for project
      if (isInt(projectId.value)) {
        if (store.state.projects.project.id !== projectId.value) {
          await store.dispatch('getProject', projectId.value)
        }
      } else {
        store.dispatch('setError', {
          response: {
            custom: true,
            status: 500,
            data: {
              code: 'invalid_project'
            }
          }
        })
      }

      router.push({
        name: 'invest-amount',
        params: {
          projectId: projectId.value,
          simulationAmount: props.simulationAmount,
          editReservation: props.editReservation
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped></style>
