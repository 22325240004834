<template>
  <div
    class=" py-6 px-6 rounded-3xl overflow-hidden relative text-center"
    :class="{'shadow-2xl' : !noShadow, 'bg-green-base': template == 'christmas', 'bg-grey-giftcard': template !== 'christmas'}"
    style="height: inherit"
  >
    <div v-if="christmas && template === 'christmas'">
      <div
        class="christmas-ball christmas-ball--star right-6 w-16 top-8 opacity-40"
      >
        <img src="@/assets/svg/star.svg" alt="star">
      </div>
      <div class="christmas-ball  left-6 w-12 top-16 opacity-40">
        <img src="@/assets/svg/white-ball.svg" alt="green ball">
      </div>
    </div>
    <div v-else>
      <img src="@/assets/svg/yellow-ball-2.svg" alt="" class="absolute -left-6 -top-6 w-28 opacity-50">
      <img src="@/assets/svg/birds.svg" alt="" class="absolute right-8 bottom-8 w-28 opacity-50">

    </div>

    <div class=" text-sm mb-8" :class="{'top-1/3 transform -translate-y-1/2 absolute left-1/2 -translate-x-1/2': !code, 'text-white' : template === 'christmas', 'text-blue-light': template !== 'christmas'}">
      {{ message ? message : $t('giftcards.card_back.title_no_code') }}
    </div>
    <img src="@/assets/img/logo/horizontal_white.svg" alt="" class="absolute left-8 bottom-6" v-if="template === 'christmas'">
    <img src="@/assets/img/logo/goparity.svg" alt="" class="absolute left-8 bottom-6 w-24" v-else>

  </div>
</template>

<script>
import { ref } from 'vue'

export default {
	components: {
	},
	props: {
		code: {
			type: String,
			default: null
		},
		message: {
			type: String,
			default: null
		},
    template: {
      type: String,
      default: null
    },
    noShadow: {
      type: Boolean,
      default: false
    },
	},
	setup () {
		const christmas = ref(true)
    const frontendURL = ref(process.env.FRONTEND_URL)
		return { christmas, frontendURL }
	}
}
</script>

<style lang="postcss" scoped>
.giftcard__code {
	color: transparent;
	text-shadow: 0 0 24px #5f4b5c;
}

.christmas-ball {
	@apply absolute;
}

.christmas-ball img {
	@apply w-full;
}

.christmas-ball::before {
	/* @apply bg-grey-input; */
	content: '';
	width: 1px;
	height: 20rem;
	top: -20rem;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.christmas-ball--star::before {
	margin-top: 2px;
	margin-left: 1px;
}

.christmas-ball--star img{
	filter: drop-shadow(0px 0px 20px rgb(255 255 255 / 0.8));
}

.christmas-ball--yellow::before {
	margin-left: 0px;
	margin-top: 6px;
}
</style>
