import axios from 'axios'

export default {
  state: {
    kycs: null,
    conditions: null,
    settingsSubtitles: {}
  },
  mutations: {
    SET_KYCS(state, kycs) {
      state.kycs = kycs
    },
    SET_KYCS_CONDITIONS(state, conditions) {
      state.conditions = conditions
    },
    SET_SETTINGS_SUBTITLES(state, subtitles) {
      state.settingsSubtitles = subtitles
    }
  },
  actions: {
    getKycs({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/profiles/kyc')
        .then(({ data }) => {
          if (Object.keys(data).length !== 0) {
            commit('SET_KYCS', data)
          }
        })
        .catch((e) => {
          commit('SET_ERROR', e)
        })
    },
    setKycsConditions({ commit }, data) {
      commit('SET_KYCS_CONDITIONS', data)
    },
    setUnderlineSettings({ commit }, data) {
      commit('SET_SETTINGS_SUBTITLES', data)
    }
  },
  getters: {
    getKycsConditions: (state, getters) => () => {
      let conditions = {}
      const profile = getters.getProfile()
      // don't show popup if kyc is done
      if (profile.type == 'particular' && profile.kyc_level == 'regular') {
        conditions = {
          showPopup: false,
          localesPath: 'validated',
          description: 'home.kyc_popup.validated.settings_subtitle',
          color: 'text-orange-1'
        }
      }
      else if (profile.valid_id === 'true') {
        conditions = {
          showPopup: false,
          localesPath: 'validated',
          description: 'home.kyc_popup.validated.settings_subtitle',
          color: 'text-orange-1'
        }
      }
      // otherwise show popup
      else {
        // if they have already submitted
        if (state.kycs) {
          conditions = {
            showPopup: true,
            localesPath: 'validation_asked',
            description: 'home.kyc_popup.validation_asked.settings_subtitle',
            color: ''
          }
          // individual
          if (profile.type === 'particular') {
            // Refused conditions
            if (state.kycs.status === 'REFUSED') {
              conditions = {
                showPopup: true,
                iconColor: 'exclamation_red',
                localesPath: 'document_failed',
                description: 'home.kyc_popup.document_failed.settings_subtitle',
                color: 'text-red-primary'
              }
              if (state.kycs.data === 'DOCUMENT_DO_NOT_MATCH_USER_DATA') {
                conditions = {
                  showPopup: true,
                  iconColor: 'exclamation_orange',
                  localesPath: 'document_do_not_match_user_data',
                  description: 'home.kyc_popup.document_do_not_match_user_data.settings_subtitle',
                  color: 'text-orange-1'
                }
              } else if ( state.kycs.data === 'DOCUMENT_DO_NOT_ACCEPTED' || state.kycs.data === 'DOCUMENT_INCOMPLETE'
              ) {
                conditions = {
                  showPopup: true,
                  iconColor: 'exclamation_orange',
                  localesPath: 'general',
                  description: 'home.kyc_popup.general.settings_subtitle',
                  color: 'text-red-primary'
                }
              }
              // VALIDATION ASKED
            } 
            // pending status
            else if (state.kycs.status === 'VALIDATION_ASKED') {
              conditions = {
                localesPath: 'validation_asked',
                description: 'home.kyc_popup.validation_asked.settings_subtitle',
                color: ''
              }
            }
          } 
          //organization
          else {
            // Company
            const kycsUnderValidation = state.kycs.every(
              (kyc) =>
                kyc.status === 'VALIDATION_ASKED' || kyc.status === 'VALIDATED'
            )
            const kycRefused = state.kycs.some(
              (kyc) => kyc.status === 'REFUSED'
            )
            if (kycsUnderValidation) {
              conditions = {
                showPopup: false,
                localesPath: 'validation_asked',
                description:
                  'home.kyc_popup.validation_asked.settings_subtitle',
                color: ''
              }
            } else if (kycRefused) {
              conditions = {
                showPopup: true,
                iconColor: 'exclamation_red',
                localesPath: 'document_failed',
                description: 'home.kyc_popup.document_failed.settings_subtitle',
                color: 'text-red-primary'
              }
            }
          }
        }
        //KYC not submitted (Particular and Company)
        else {
          conditions = {
            showPopup: true,
            iconColor: 'exclamation_grey',
            localesPath: 'kyc_not_submitted',
            description: 'home.kyc_popup.kyc_not_submitted.settings_subtitle',
            color: 'text-orange-1'
          }
        }
      } 
      return conditions
    }
  }
}
