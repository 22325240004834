<template>
  <div class="page flex">
    <div class="lg:pt-20 container flex-grow relative min-p-button">
      <transition name="fade" mode="out-in">
        <div>
          <Back
            :to="'/settings'"
            :title="$t('settings.change_password.title')"
          />
          <div class="lg:pt-6">
            <Form @submit="definePassword" v-slot="{ errors, meta }">
              <GeneralInput
                v-model:data="password.current_password"
                type="password"
                name="currentpw"
                :label="$t('settings.change_password.current_password')"
              />
              <GeneralInput
                ref="newpw"
                v-model:data="password.new_password"
                name="newpw"
                :label="$t('settings.change_password.new_password')"
                type="password"
                :signupPassword="true"
                :error="errors.newpw"
                :rules="
                  'required|minLength:8|hasUpperLower|hasNumberSymbol|maxLength:24|newPw:' +
                  password.current_password
                "
              />

              <div
                class="
                  absolute
                  bottom-0
                  w-screen
                  container
                  left-1/2
                  -translate-x-1/2
                  transform
                "
              >
                <div class="w-full relative">
                  <GpButton
                    :disabled="
                      password.current_password === '' || !meta.valid || loading
                    "
                    @click="changePassword"
                    class="absolute bottom-8 right-0"
                  >
                    {{ $t('settings.change_password.btn') }}
                  </GpButton>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import GeneralInput from '@/components/general/Input'
import { Form } from 'vee-validate'
import { useI18n } from 'vue-i18n'

import { ref, computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  components: {
    Back,
    GpButton,
    Form,
    GeneralInput
  },
  setup() {
    const newpw = ref(null)
    const store = useStore()
    const router = useRouter()
    const password = ref({ current_password: '', new_password: '' })
    const error = ref(null)
    const cookies = inject('vue-cookie')
    const i18n = useI18n()

    const loading = computed(() => {
      return store.state.general.loading
    })

    function changePassword() {
      store.dispatch('setLoading', true)
      error.value = null
      store
        .dispatch('changePassword', password.value)
        .then(() => {
          router.push('/login')
          store.dispatch('logout', cookies.getCookie('token')).then(()=>{
            store.dispatch('resetToasts')
          })
          store.dispatch('setLoading', false)
          const popup = {
            title: i18n.t('settings.change_password.success.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            primaryButton: i18n.t('settings.change_password.success.btn')
          }
          store.dispatch('setPopup', popup)
        })
        .catch((e) => {
          store.dispatch('setError', e)
          store.dispatch('setLoading', false)
        })
    }

    return { password, newpw, loading, changePassword, error }
  }
}
</script>

<style lang="sass" scoped></style>
