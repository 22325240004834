<template>
  <div>
    <div
        class="
                    rounded-t-2xl
                    bg-white bg-opacity-60
                    flex
                    items-center
                    justify-between
                    p-4
                  "
    >
      <p class="text-grey-2 text-sm font-bold">
        {{ $t('projects.details.updates.self') }}
      </p>
      <Link
          :link="'/projects/' + projectId + '/updates'"
          :text="$t('projects.details.updates.all')"
          textStyle="text-xs font-semibold mr-1 text-grey-2"
          router
          id="gp-link-invest-about-project-all-updates-19"
      >
        <template #singleImage>
          <img
              src="@/assets/svg/arrows/right_grey_arrow_dark.svg"
              alt="right"
          />
        </template>
      </Link>
    </div>
    <div v-if="lastUpdate" class="bg-white rounded-b-2xl p-4 flex">
      <div class="h-2 w-2 relative mr-3 mt-1">
        <div
            class="
                        animate-ping
                        absolute
                        h-full
                        w-full
                        rounded-full
                        bg-green-2
                        opacity-75
                      "
        />
        <div class="relative rounded-full h-2 w-2 bg-green-2" />
      </div>
      <div class="w-full">
        <div class="flex items-center justify-between mb-1">
          <p class="text-13 text-grey-1 font-semibold text-left">
            {{ lastUpdate.title }}
          </p>
          <p
              class="
                          text-grey-2 text-opacity-50 text-11 text-right
                          whitespace-nowrap
                        "
          >
            {{ lastUpdate.date }}
          </p>
        </div>
        <p
            class="
                        text-left text-grey-2 text-sm text-opacity-80
                        indented-custom-bullet
                      "
            v-html="lastUpdate.description"
        ></p>
      </div>
    </div>
  </div>
</template>

<script type="ts">
import {defineComponent} from 'vue'
import Link from '@/components/general/Link.vue'

export default defineComponent({
  props: {
    lastUpdate:{
      type: Object,
      default: () => {}
    },
    projectId:{
      type: Number,
      default: 0
    }
  },
  components: {
    Link
  },
  emits: [],
  setup() {
    return {}
  }
})
</script>

<style lang="sass" scoped></style>
