<template>
  <div class="">
    <swiper
      class="w-full project-slide"
      :loop="false"
      :slides-per-view="1.2"
      :space-between="16"
      :pagination="false"
      :allowTouchMove="true"
      :centered-slides="true"
      :speed="1000"
      :autoplay="false"
      :breakpoints="{
        // when window width is >= 320px
        640: {
          slidesPerView: 2,
          centeredSlides: false
        },
        1800: {
          slidesPerView: 3,
          centeredSlides: false
        },
        2200: {
          slidesPerView: 4,
          centeredSlides: false
        },
        2700: {
          slidesPerView: 5,
          centeredSlides: false
        }
      }"
    >
      <swiper-slide
        v-for="project in projects"
        v-bind:key="'project-' + project.id"
      >
        <ProjectCard :project="project" :animated="false" />
      </swiper-slide>
      <template v-if="skeleton">
        <swiper-slide
          v-for="index in 7"
          v-bind:key="'project-skeleton-' + index"
        >
          <ProjectSkeleton />
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import ProjectCard from '@/components/projects/Card'
import ProjectSkeleton from '@/components/projects/Skeleton'
export default {
  components: {
    Swiper,
    ProjectCard,
    ProjectSkeleton,
    SwiperSlide
  },
  props: {
    projects: Array,
    skeleton: {
      type: Boolean,
      default: false
    }
  },
  setup() {}
}
</script>
<style>
.project-slide .swiper-slide {
  min-height: 22rem;
}
</style>
