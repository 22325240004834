<template>
  <div class="bg-white status-banner rounded-3xl relative overflow-hidden" style=" box-shadow: 0 30px 20px -29px rgba(0, 231, 99, 0.1); background-image: linear-gradient(112deg, #d0f2df -6%, white 111%);">
    <div class="status__header py-6 px-5 overflow-hidden">
      <div class="flex">
        <div>
          <div class="text-sm text-left text-grey-2 mb-2">
            {{ $t('giftcards.sub_title') }}
          </div>
          <div class="lg:flex relative justify-between items-center">
            <div class="text-grey-2 text-xl md:text-3xl font-medium text-left w-4/5 md:w-1/2">
              {{ $t('giftcards.title') }}
            </div>
          </div>
        </div>
        <!--Gift box-->
        <div class="w-2/3 -mb-2 max-w-giftcard min-h-giftcard -mr-1 relative">
          <div class="w-full absolute h-8 lid z-20">

          </div>

          <div class=" box absolute bottom-0 left-1/2 transform -translate-x-1/2 z-10">

          </div>

          <div class="stripe z-30">
            <div class="w-2 h-2 rounded-full" style="background:#3f3d56; margin-top:6px; margin-left:4px">

            </div>
            <div class="w-1 h-1 rounded-full" style="background:#3f3d56; margin-top:4px; margin-left:12px">

            </div>
            <div class="w-1 h-1 rounded-full" style="background:#3f3d56; margin-top:7px; margin-left:3px">

            </div>

          </div>

          <div class="leaf z-40">
            <div class="w-1 h-1 rounded-full" style="background:#3f3d56; margin-top:20px; margin-left:20px">

            </div>
            <div class="w-2 h-2 rounded-full" style="background:#3f3d56; margin-top:-16px; margin-left:9px">

            </div>
          </div>
          <div class="transform rotate-90">
            <div class="leaf z-40" style="left: 60%; top: -30px; animation-delay: 2.4s; transform-origin: right">
              <div class="w-1 h-1 rounded-full" style="background:#3f3d56; margin-top:7px; margin-left:7px">

              </div>
            </div>
          </div>
          <div class="absolute w-32 h-1/2 giftcard">
            <GiftcardCard/>
          </div>
        </div>
      </div>
    </div>

    <router-link to="/giftcards" id="gp-button-offer-sustainable-investment-4">
      <div class="bg-grey-1 px-6 py-3 rounded-xl absolute right-6 bottom-6 z-40">
        <img src="@/assets/svg/chev-right-white.svg" alt="" class="w-4">
      </div>
    </router-link>
  </div>
</template>
<script>
import { ref } from '@vue/runtime-core'
import { format } from 'date-fns'
import useGlobalHelpers from "@/mixins/useGlobalHelpers";
import GiftcardCard from "@/components/giftcards/GiftcardCard";

export default {
  components: {GiftcardCard},
  setup() {
    const { dollarFormatting } = useGlobalHelpers()

    const right = ref([
      '4',
      '8',
      '12',
      '20',
      '16',
      '14',
      '24',
      '32',
      '56',
      '64',
      '72',
      '80',
      '96'
    ])

    function formatDate(date) {
      return format(date, 'dd/MM/Y')
    }

    return {
      right,
      dollarFormatting,
      formatDate
    }
  }
}
</script>
<style scoped>
.box{
  background: #f8eccf;
  height: 65%;
  width: 85%;
}

.lid{
  background: #f9e2ad;
  top:28px;
  transform: rotate(0deg);
  transform-origin: right;
  animation: lid-close 3s forwards;
}

.stripe{
  background: #66f1a0;
  width:20%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(0);
  height: 80%;
  bottom: 0;
  animation: strip-top 0.3s forwards;
  animation-delay: 2.3s;
}

.leaf{
  background: #66f1a0;
  border-radius: 0 22px;
  height:2rem;
  width: 2rem;
  position: absolute;
  left: calc(50% - 1rem);
  transform: translateX(-50%) scale(0);
  transform-origin: bottom;
  top: -5px;
  animation: leaf-appear 0.6s forwards;
  animation-delay: 2.3s;
}

.giftcard{
  top: -45%;
  left: -20%;
  transform: rotate(45deg) translate(-80%, 50%) scale(0.8);
  z-index: 0;
  animation: card-inside 1s forwards;
  animation-delay: 1s;
}

@keyframes lid-close {
  10% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(70deg);
  }
  90% {
    transform: rotate(0deg);
  }
}

@keyframes card-inside {
  100% {
    transform: rotate(90deg) translate(80%, -50%) scale(0.7);
  }
}

@keyframes strip-top {
  100% {
    transform: translateX(-50%) scaleY(1);
  }
}

@keyframes leaf-appear {
  100% {
    transform: translateX(-50%) scale(1);
  }
}

</style>
