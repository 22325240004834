<template>
  <div :class="isStrategies ? 'page flex flex-col items-end' : 'page flex'">
    <SideBar v-if="isStrategies">
      <FlowProgress step="4" copy="strategies.progress" flow="strategies" />
    </SideBar>
    <img
      v-if="isStrategies"
      src="/img/signup/loved_one.png"
      alt="loved one picture"
      class="hidden lg:block fixed bottom-0 left-40 w-72 z-0 h-72"
    />
    <div
      v-if="!loading"
      :class="
        isStrategies
          ? 'strategies__desktop-container'
          : 'lg:pt-20 container flex-grow'
      "
    >
      <div :class="{ 'lg:w-3/4 xl:w-2/3 2xl:w-1/2': isStrategies }">
        <div>
          <Back
            :to="isStrategies ? '/strategies/max-amount' : '/settings'"
            :title="$t('settings.bank_accounts.title')"
          />
        </div>
        <ProgressBar
          class="lg:hidden"
          v-if="isStrategies"
          :step="6"
          :total="7"
        />
        <p
          v-if="isStrategies"
          class="text-sm lg:text-base text-grey-2 text-opacity-80 my-4 text-left"
        >
          {{ $t('settings.bank_accounts.strategies_description') }}
        </p>
        <GpButton
          color="grey"
          class="mb-6"
          @click="goToTopup"
          :disabled="!account || !profile.activated || !profile.can_check || !profile.lend_ack || profile.sub_type ==='unsuitable' || profile.sub_type ==='unsuitable_pending'"
          v-if="!isBorrower" 
        >
          {{ '+ ' + $t('home.topup_long') }}
        </GpButton>
        <div class="pb-6 lg:pb-0">
          <BankAccountKyc/>        
        </div>
        <BankAccountElement
          v-if="account"
          :account="account"
          :index="index"
          :isStrategies="isStrategies"
        />
        <div :class="{ 'lg:pt-6': !account }">
          <!-- get token -->
          <div
            @click="addBankAccount"
            class="flex items-center justify-between text-left text-sm text-grey-2 font-semibold rounded-3xl p-6 cursor-pointer"
            :class="
              !account
                ? 'lg:p-4 bg-white shadow-add-account'
                : 'p-7 lg:p-4 bg-green-7'
            "
          >
            <div class="flex items-center justify-between">
              <img
                src="@/assets/svg/settings/bank_accounts_border.svg"
                alt=""
                class="hidden lg:block mr-4"
              />
              <p v-if="!account">
                {{ $t('settings.bank_accounts.add_btn_first') }}
              </p>
              <p v-else>{{ $t('settings.bank_accounts.add_btn_more') }}</p>
            </div>

            <div>
              <img
                v-if="!account"
                class="lg:hidden"
                src="@/assets/svg/arrows/right_arrow_yellow_border.svg"
                alt=""
              />
              <img
                :class="!account ? 'hidden lg:block lg:mr-2' : 'lg:mr-2'"
                class=""
                src="@/assets/svg/arrows/right_grey_arrow.svg"
                alt=""
              />
            </div>
          </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import BankAccountElement from '@/components/settings/BankAccountElement.vue'
import Back from '@/components/general/Back.vue'
import SideBar from '@/components/general/SideBar.vue'
import GpButton from '@/components/general/GpButton.vue'
import ProgressBar from '@/components/general/ProgressBar.vue'
import FlowProgress from '@/components/general/FlowProgress.vue'
import BankAccountKyc from '@/components/general/BankAccountKyc.vue'

export default {
  components: {
    BankAccountElement,
    Back,
    ProgressBar,
    GpButton,
    FlowProgress,
    SideBar,
    BankAccountKyc
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isStrategies = ref(route.query.strategies)
    const loading = computed(() => {
      return store.state.general.loading
    })

    const connectBank=()=>{
      store
          .dispatch('getVPBankAccounts')
          .then((data) => {
            store
              .dispatch('createBankAccount', {
                ...data,
                token: route.query.Token
              })
              .then(() => {
                store.dispatch('setLoading', false)
                const popup = {
                  align: 'center',
                  iconBg: 'bg-grey-8',
                  icon: '/img/popup/exclamation_orange.svg',
                  side: false,
                  component: 'ConnectBank',
                  props: {isBorrower,account}

                }
                store.dispatch('setPopup', popup)
                router.replace('/settings/bank-accounts')
              })
              .catch((e) => {
                store.dispatch('setLoading', false)
                store.dispatch('setError', e)
              })
          })
          .catch((e) => {
            store.dispatch('setLoading', false)
            store.dispatch('setError', e)
          })
    }

    onBeforeMount(() => {
      if (route.query.Token) {
        store.dispatch('setLoading', true)
        connectBank()
      }
    })

    onMounted(async () => {
      await store.dispatch('getBankAccount')
    })

    const account = computed(() => {
      return store.state.bankAccounts.account
    })

    const profile = computed(() => {
      return store.getters.getProfile()
    })

    const isBorrower = computed(() => {
      return profile.value && profile.value.sub_type === 'borrower'
    })

    function goToTopup() {
      router.push({
        name: 'topup'
      })
    }

    const addBankAccount = () => {
      const popup = {
        side: false,
        component: 'ReplaceBankAccount',
        props: {account}
      }
      store.dispatch('setPopup', popup)
    }

    return {
      account,
      profile,
      isBorrower,
      isStrategies,
      addBankAccount,
      goToTopup,
      loading
    }
  }
}
</script>

<style lang="postcss" scoped>
.shadow-add-account {
  box-shadow: 0px 3px 8px rgba(79, 89, 99, 0.03);
}
</style>
