<template>
  <div
    class="
      border
      p-4
      rounded-2xl
      max-w-md
      text-left
      z-50
      animate-toast
      transition
      duration-150
      ease-out
      transform
    "
    :class="bg[type] + ' ' + positions[position]"
  >
    <div class="flex">
      <div class="mr-2">
        <img
          :src="require('@/assets/svg/toast/' + type + '.svg')"
          alt=""
          class="w-10"
        />
      </div>
      <div
        class="text-grey-3 text-sm"
        v-html="$t('toasts.' + toast + '.content', vars)"
      ></div>
      <div class="ml-2">
        <img
          src="@/assets/svg/cross.svg"
          alt=""
          class="w-8 cursor-pointer"
          @click="dismiss"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      default: 'warning'
    },
    toast: {
      type: String,
      default: 'arrears'
    },
    vars: {
      type: Object,
      default: () => {}
    },
    position: {
      type: String,
      default: 'top-right'
    }
  },
  setup(props) {
    const store = useStore()
    const dismiss = () => {
      store.dispatch('hideToast', props.toast)
    }
    const bg = ref({
      success: 'bg-green-7 border-green-3',
      warning: 'bg-orange-7 border-orange-3',
      error: 'bg-red-50 border-red-500'
    })
    const positions = ref({
      'top-right': 'top-8 md:top-28 mx-8 md:mx-0 md:right-8',
      'top-left': 'top-8 md:top-28 mx-8 md:mx-0 md:left-8',
      'bottom-right': 'bottom-8 right-8',
      'bottom-left': 'bottom-8 left-8'
    })
    return { dismiss, bg, positions }
  }
}
</script>
<style>
.animate-toast {
  animation: appear-toast ease-out 0.3s forwards;
}
@keyframes appear-toast {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
