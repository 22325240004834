import axios from 'axios'

export default {
  state: {
    contracts: null,
    contract: null,
    filters: null,
    offerPreview: null,
    search: null
  },
  mutations: {
    SET_CONTRACTS(state, contracts) {
      state.contracts = contracts
    },
    SET_CONTRACT(state, contract) {
      state.contract = contract
    },
    SET_SEARCH(state, search) {
      state.search = search
    },
    SET_CONTRACT_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_OFFER_PREVIEW(state, offer) {
      state.offerPreview = offer
    }
  },
  actions: {
    fetchContracts({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/contracts')
        .then((data) => {
          commit('SET_CONTRACTS', data.data)
        })
        .catch((e) => {
          commit('SET_ERROR', e)
        })
    },
    fetchContract({ commit }, id) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/contracts/' + id)
        .then((data) => {
          commit('SET_CONTRACT', data.data)
        })
        .catch((e) => {
          commit('SET_ERROR', e)
        })
    },
    storeContract({ commit }, contract) {
      commit('SET_CONTRACT', contract)
    },
    setContractFilters({ commit }, filters) {
      commit('SET_CONTRACT_FILTERS', filters)
    },
    setSearch({ commit }, search) {
      commit('SET_SEARCH', search)
    },
    previewOffer({ commit }, contractId) {
      return axios
        .get(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/contracts/' +
            contractId +
            '/offer'
        )
        .then((data) => {
          commit('SET_OFFER_PREVIEW', data.data)
        })
        .catch((e) => {
          commit('SET_ERROR', e)
        })
    }
  },
  getters: {
    getContracts:
      (state) =>
      (settled = false, sortBy = null) => {
        if (state.contracts) {
          let filtered = null

          // Settled filter
          if (settled) {
            filtered = state.contracts.filter((c) => {
              return c.state === 'settled'
            })
          } else {
            filtered = state.contracts.filter((c) => {
              return c.state !== 'settled'
            })
          }
          if (state.filters) {
            // Status filter
            if (state.filters.paymentStatus) {
              if (state.filters.paymentStatus === 'arrears') {
                filtered = filtered.filter((c) => {
                  return c.payment_in_arrears
                })
              } else if (state.filters.paymentStatus === 'on_time') {
                filtered = filtered.filter((c) => {
                  return !c.payment_in_arrears
                })
              }
            }
            // Risk filter
            if (
              state.filters.risk &&
              Object.values(state.filters.risk).includes(true)
            ) {
              filtered = filtered.filter((c) => {
                return state.filters.risk[c.project_risk]
              })
            }
            // Category filter
            if (
              state.filters.categories &&
              Object.values(state.filters.categories).includes(true)
            ) {
              filtered = filtered.filter((c) => {
                return state.filters.categories[c.project_category]
              })
            }
            // Wallet filter
            if (
              state.filters.wallets &&
              Object.values(state.filters.wallets).includes(true)
            ) {
              filtered = filtered.filter((c) => {
                return state.filters.wallets[c.vp_wallet]
              })
            }
          }
          if (sortBy)
            return filtered.sort((a, b) => {
              switch (sortBy) {
                case 'next_payment':
                  if (
                    new Date(a.upcoming_payments.next_payment.schedule_date) <=
                    new Date(b.upcoming_payments.next_payment.schedule_date)
                  )
                    return -1
                  else return 1
                case 'outstanding_low':
                  if (
                    a.upcoming_payments.next_payment.capital_outstanding <=
                    b.upcoming_payments.next_payment.capital_outstanding
                  )
                    return -1
                  else return 1
                case 'outstanding_high':
                  if (
                    a.upcoming_payments.next_payment.capital_outstanding >=
                    b.upcoming_payments.next_payment.capital_outstanding
                  )
                    return -1
                  else return 1
                case 'end_recent_first':
                  if (new Date(a.end_date) <= new Date(b.end_date)) return -1
                  else return 1
                case 'end_recent_last':
                  if (new Date(a.end_date) >= new Date(b.end_date)) return -1
                  else return 1
                default:
                  return filtered
              }
            })
          else return filtered
        } else return null
      },
    getPaymentsPreview: (state) => () => {
      if (state.contract) {
        if (state.contract.upcoming_payments.upcoming_payments_count >= 4) {
          let all = state.contract.all_payments.filter((p) => {
            return (
              parseInt(p.payment_n) >=
              parseInt(state.contract.upcoming_payments.next_payment.payment_n)
            )
          })
          return all.slice(0, 4)
        } else
          return state.contract.all_payments
            ? state.contract.all_payments.slice(-4)
            : null
      } else return null
    }
  }
}
