<template>
  <div class="home">
    <SideBar>
      <div class="h-screen relative">
        <img
          src="@/assets/img/mountain-sidebar.png"
          alt=""
          class="absolute bottom-28 opacity-80 z-negative"
        />
        <div class="text-left w-3/4 mx-auto">
          <div class="text-3xl font-medium" v-if="numberOfWallets > 1">
            <div class="text-grey-3">
              {{
                $t(
                  'topup.sidebar.title',
                  { method: $t('topup.sidebar.' + method) },
                  activeIndex + 1
                )
              }}
            </div>
            <div class="text-grey-2 mt-1">
              {{ name }}
            </div>
          </div>
          <div class="text-3xl font-medium" v-else>
            <div class="text-grey-3">
              {{
                $t(
                  'topup.sidebar.title_2',
                  { method: $t('topup.sidebar.' + method) },
                  activeIndex + 1
                )
              }}
            </div>
          </div>
          <div class="mt-10 text-grey-3 text-sm">
            {{ $t('topup.sidebar.wallet_balance') }}
            <transition name="fade">
              <p class="text-3xl font-medium" v-if="wallet">
                ${{
                  wallet.balance ? dollarFormatting(wallet.balance) : '0.00'
                }}
              </p>
            </transition>
          </div>
          <div class="mt-24 text-grey-4 text-sm" v-if="lastTopup && !method">
            {{ $t('topup.sidebar.last_topup') }}
            <span class="font-semibold">{{ lastTopup }}</span>
          </div>
        </div>
      </div>
    </SideBar>
    <div
      class="w-full lg:w-2/3 ml-auto min-h-screen relative text-left lg:pt-28 z-0"
    >
      <div
        class="md:w-3/4 w-4/5 m-auto absolute md:relative md:left-0 left-9 top-2 z-50"
      >
        <transition name="toast-fade">
          <div
            class="back text-left text-grey-2 md:flex absolute top-5 items-center cursor-pointer md:mb-12"
            :class="{ 'opacity-0': activeIndex === 0 }"
            @click="previousSlide"
          >
            <img
              src="@/assets/svg/chev-left.svg"
              alt=""
              class="w-5 md:w-auto"
            />

            <span class="hidden md:block">{{ $t('wording.back') }}</span>
          </div>
        </transition>
      </div>
      <router-link to="/dashboard">
        <div class="block md:hidden absolute right-9 top-6 z-50">
          <img src="@/assets/svg/cross.svg" alt="" />
        </div>
      </router-link>
      <swiper
        class="w-full"
        :loop="false"
        @swiper="setSwiper"
        @slidePrevTransitionEnd="checkMethod"
        :slides-per-view="1"
        :space-between="50"
        :pagination="false"
        :allowTouchMove="false"
        :speed="1000"
        :autoplay="false"
      >
        <swiper-slide class="flex flex-col">
          <Method
            class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
            @next="nextSlide"
            :project="propProject"
            @quit="quit"
          />
        </swiper-slide>
        <swiper-slide class="flex flex-col" v-slot="{ isActive }">
          <Amount
            class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
            :balance="
              wallet && wallet.balance
                ? '$' + dollarFormatting(wallet.balance)
                : '0.00'
            "
            @next="nextSlide"
            :walletName="name"
            :isActive="isActive"
            :method="method"
            :presetAmount="dollarFormatting(parseInt(propsAmount))"
          />
        </swiper-slide>
        <swiper-slide class="flex flex-col" v-slot="{ isActive }">
          <Confirmation
            class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
            @finish="finish(account)"
            :wallet="wallet"
            :isActive="isActive"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/general/SideBar.vue'
import Method from '@/components/topup/Method.vue'
import Amount from '@/components/topup/Amount.vue'
import Confirmation from '@/components/topup/Confirmation.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  components: {
    SideBar,
    Swiper,
    Confirmation,
    Method,
    SwiperSlide,
    Amount
  },
  props: {
    propWallet: String,
    propsAmount: String,
    propProject: String
  },
  name: 'home',
  setup(props) {
    const swiper = ref(null)
    const notAgain = ref(null)
    const i18n = useI18n({ useScope: 'global' })
    const { dollarFormatting, ordinalSuffix, slugIt } = useGlobalHelpers()
    const store = useStore()
    const router = useRouter()
    const { locale } = useI18n({ useScope: 'global' })

    const lastTopup = computed(() => {
      if (
        store.state.transactions.lastTopup &&
        store.state.transactions.lastTopup.length
      ) {
        const date = new Date(
          store.state.transactions.lastTopup[0].date.replace(/-/g, '/')
        )
        switch (locale.value) {
          case 'en':
            return (
              date.toLocaleString(locale.value, { month: 'long' }) +
              ' ' +
              ordinalSuffix(date.getDate()) +
              ' ' +
              date.getFullYear()
            )
          case 'es':
          case 'pt':
            return (
              date.getDate() +
              ' de ' +
              date.toLocaleString(locale.value, { month: 'long' }) +
              ' de ' +
              date.getFullYear()
            )
        }
      }
      return null
    })

    const activeIndex = computed(() => {
      return swiper.value ? swiper.value.activeIndex : 0
    })

    const method = computed(() => {
      return store.state.topup.method
    })

    const numberOfWallets = computed(() => {
      return store.state.wallets.wallets
        ? store.state.wallets.wallets.length
        : 0
    })

    // Name of the wallet (if no saving_model found, its the main wallet)
    const name = computed(() => {
      return wallet.value && wallet.value.saving_model
        ? wallet.value.saving_model.name
        : i18n.t('transactions.filters.main_wallet')
    })

    // Call VOPAY endpoint
    async function finish(account) {
      store.dispatch('setLoading', true)
      store.dispatch('topup', {
          wallet_id: wallet.value.id,
          account: account
        })
        .then(() => {
          const popup = {
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            component: 'GoodPayin',
            props: { method: method.value },
            disableClose: true
          }
          store.dispatch('setPopup', popup)
          store.dispatch('setLoading', false)
        })
        .catch((e) => {
          store.dispatch('setLoading', false)
          store.dispatch('setError', e)
        })
    }

    // Swipe Next
    async function nextSlide() {
      swiper.value.slideTo(swiper.value.activeIndex + 1)
    }

    // Swipe Previous
    function previousSlide() {
      if (swiper.value.activeIndex > 0) {
        swiper.value.slideTo(swiper.value.activeIndex - 1)
      } else router.push({ name: 'dashboard' })
    }

    // Check if go back to first slide
    function checkMethod() {
      if (swiper.value.activeIndex === 0) {
        store.dispatch('setMethod', null)
      }
    }

    // Initialize Swiper
    function setSwiper(value) {
      swiper.value = value
    }

    const wallet = computed(() => {
      return props.propsWallet ? JSON.parse(props.propWallet) : store.state.wallets.wallet
    })

    // Get wallets
    onMounted(async () => {
      notAgain.value =
        localStorage.getItem('hide_topup_confirmation') === 'true'

      if (!props.propWallet) {
        await store.dispatch('fetchWallets')
      }

      // Fetch last topup
      await store.dispatch('fetchTransactions', {
        categories: ['topup'],
        wallet: wallet.value.id,
        group: false,
        order: '-date',
        total: 1,
        lastTopup: true
      })
    })

    const quit = async () => {
      const project = JSON.parse(props.propProject)
      const popup = {
        title: i18n.t('invest.confirmation.success_card.title_reserved', {
          project: project.name
        }),
        content: i18n.t('invest.confirmation.success_card.text_topup'),
        align: 'center',
        side: false,
        iconBg: 'bg-green-5',
        icon: '/img/done_step.svg',
        primaryButton: i18n.t('wording.understood')
      }
      await store.dispatch('setPopup', popup)
      router.push('/projects/' + slugIt(project.name) + '-' + project.id)
    }

    return {
      name,
      wallet,
      dollarFormatting,
      checkMethod,
      setSwiper,
      nextSlide,
      lastTopup,
      finish,
      previousSlide,
      activeIndex,
      notAgain,
      method,
      numberOfWallets,
      quit
    }
  }
}
</script>