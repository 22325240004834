<template>
  <div class="steps">
    <p class="steps__title--mobile ml-5 md:ml-0">{{$t('auto_investment.setup.max_term.title')}}</p>
    <p class="text-sm text-grey-2 font-semibold lg:font-normal text-opacity-80 text-left mb-12">{{$t('auto_investment.setup.max_term.subtitle')}}</p>
    <Slider
      v-model="maxTerm"
      :min="0"
      :max="10"
      :step="1"
      :tooltips="true"
      :format="formatTooltip"
      class="slider-auto-investment"
    />
    <div class="flex justify-between mt-6">
      <p class="font-bold text-grey-2 text-base">0{{ $t('wording.year') }}</p>
      <p class="font-bold text-grey-2 text-base">{{ $t('wording.year', 10) }}</p>
    </div>
    <p v-if="avgTerm > 0" class="text-sm text-grey-3 bg-grey-7 p-4 mt-8 rounded-3xl">
      {{ $t('auto_investment.setup.max_term.info_box_1') }} {{ $t('wording.year', {n:replaceDotByComma(avgTerm)}, avgTerm ) }} {{ $t('auto_investment.setup.max_term.info_box_2') }}
    </p>
    <GpButton
      mobileWidth="w-4/5"
      class="absolute bottom-20 lg:bottom-8 lg:right-24 mx-auto lg:m-0"
      @click="next"
      :disabled="maxTerm === 0"
    >
      {{$t('onboarding.continue')}}
    </GpButton>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import GpButton from '@/components/general/GpButton.vue'
import Slider from '@vueform/slider'
// import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'

export default {
  components: {
    GpButton,
    Slider
  },
  setup(props, { emit }) {
    const store = useStore()
    const maxTerm = ref(5)
    const i18n = useI18n({ useScope: 'global' })
    // const { monthFormat } = useGlobalHelpers()


    const avgTerm = computed(() => {
			return store.state.metrics.metrics ? Math.round(store.state.metrics.metrics.avg_term) : 0
		})

    async function next() {
      store.dispatch('updateNewAutoInvestment', {
        max_term: maxTerm.value,
      })
      emit('next')
    }

    const formatTooltip = (value) => {
      return i18n.t('wording.year', value)
    }

     const replaceDotByComma = (value) => {
      value = parseFloat(value/12).toFixed(1).toString().replace('.', ',')
      return value
    }

    return {
      next,
      formatTooltip,
      maxTerm,
      avgTerm,
      replaceDotByComma
    }
  }
}
</script>
