import info from "@/assets/ts/suitability/steps/info";
import education_market from "@/assets/ts/suitability/steps/education_market";
import risk from "@/assets/ts/suitability/steps/risk";
import checks from "@/assets/ts/suitability/steps/checks";

const form =
  {
    info,
    education_market,
    risk,
    checks
  }


export default form
