import i18n from "@/i18n";
import PageForm from "@/assets/ts/interfaces/form/pageForm";
import { Types } from "@/assets/ts/enums/form/types";
import handle_suitability from "@/assets/ts/investor_type/callbacks/handle_suitability";

const order_worth = ['_0', '_500000', '_2000000', '_5000000', '_10000000', '_40000000', '_100000000', '_500000000'];
const order_assets = ['_10000', '_100000', '_250000', '_500000', '_1500000', '_2500000', '_5000000', '_10000000', '_50000000', '_100000000'];

const questions_company:PageForm =
  {
    title: i18n.global.t('ca_legislation.investor_classification.sidebar_title'),
    image: 'img/lamp-sidebar.png',
    form: [
      {
        title: i18n.global.t("ca_legislation.investor_classification.questions_company.title"),
        fields: [
          {
            key: "net_worth",
            title: i18n.global.t("ca_legislation.investor_classification.questions.net_worth_title"),
            subtitle: i18n.global.t("ca_legislation.investor_classification.questions.net_worth_text"),
            options: order_worth.reduce((obj, key) => {
              // @ts-ignore
              return {...obj, [key]: i18n.global.tm("ca_legislation.investor_classification.questions.net_worth_options")[key]};
            }, {}),
            type: Types.RADIO,
            rules: 'required'
          },
          {
            key: "assets_value",
            title: i18n.global.t("ca_legislation.investor_classification.questions.assets_value_title"),
            subtitle: i18n.global.t("ca_legislation.investor_classification.questions.assets_value_text"),
            options: order_assets.reduce((obj, key) => {
              // @ts-ignore
              return {...obj, [key]: i18n.global.tm("ca_legislation.investor_classification.questions.assets_value_options")[key]};
            }, {}),
            type: Types.RADIO,
            rules: 'required'
          },
          {
              key: "income_1_years",
              title: i18n.global.t("ca_legislation.investor_classification.questions_company.income_1_years_title"),
              subtitle: i18n.global.t("ca_legislation.investor_classification.questions_company.income_1_years_text"),
              type: Types.NUMBER,
              rules: 'required'
          },
          {
              key: "income_2_years",
              title: i18n.global.t("ca_legislation.investor_classification.questions_company.income_2_years_title"),
              subtitle: i18n.global.t("ca_legislation.investor_classification.questions_company.income_2_years_text"),
              type: Types.NUMBER,
              rules: 'required'
          },
          {
              key: "income_3_years",
              title: i18n.global.t("ca_legislation.investor_classification.questions_company.income_3_years_title"),
              subtitle: i18n.global.t("ca_legislation.investor_classification.questions_company.income_3_years_text"),
              type: Types.NUMBER,
          },
          {
              key: "rp_income_1_years",
              title: i18n.global.t("ca_legislation.investor_classification.questions_company.rp_income_1_years_title"),
              subtitle: i18n.global.t("ca_legislation.investor_classification.questions_company.rp_income_1_years_text"),
              type: Types.NUMBER,
              rules: 'required'
          },
          {
              key: "rp_income_2_years",
              title: i18n.global.t("ca_legislation.investor_classification.questions_company.rp_income_2_years_title"),
              subtitle: i18n.global.t("ca_legislation.investor_classification.questions_company.rp_income_2_years_text"),
              type: Types.NUMBER,
          },
          {
              key: "rp_income_3_years",
              title: i18n.global.t("ca_legislation.investor_classification.questions_company.rp_income_3_years_title"),
              subtitle: i18n.global.t("ca_legislation.investor_classification.questions_company.rp_income_3_years_text"),
              type: Types.NUMBER,
          }
        ],
        next: true,
        nextCallback: handle_suitability,
        back: true,
        confirmations:[
            {
              label:i18n.global.t('ca_legislation.investor_classification.questions.past_year'),
              required: true
            }
        ]
      }
    ]
  }

export default questions_company
