<template>
  <div>
    <div
      v-if="transaction.type === 'empty'"
      class="
        flex
        px-4
        lg:bg-white lg:p-4 lg:rounded-2xl lg:mb-1 lg:bg-opacity-60
        cursor-pointer
      "
    >
      <img
        :src="require('@/assets/svg/transactions/empty.svg')"
        alt="transaction icon"
        class="mr-2 mt-1 lg:hidden"
      >
      <img
        :src="require('@/assets/svg/transactions/empty_desktop.svg')"
        alt="transaction icon"
        class="mr-2 hidden lg:block"
      >
      <div class="flex flex-col justify-center">
        <p class="text-sm text-grey-2 text-left">
          {{ title }}
        </p>
        <p class="text-13 text-grey-4 text-left">
          {{ description }}
        </p>
      </div>
    </div>
    <div
      v-else
      class="
        flex
        items-center
        justify-between
        px-4
        lg:bg-white lg:py-4 lg:rounded-2xl lg:mb-2 lg:bg-opacity-60
        cursor-pointer
      "
      :class="{
        'lg:rounded-b-none': showDetails
      }"
      @click="selectTransaction"
    >
      <div class="flex items-start ">
        <div class="flex items-center mt-px md:mt-0">
          <!-- Mobile icon -->
          <img
            v-if="
              transaction.state === 'failed' || transaction.state === 'pending'
            "
            :src="
              require('@/assets/svg/transactions/' +
                failed_icon +
                '_failed.svg')
            "
            alt="transaction icon"
            class="lg:hidden mr-2"
          >
          <img
            v-else
            :src="require('@/assets/svg/transactions/' + icon + '.svg')"
            alt="transaction icon"
            class="lg:hidden mr-2 mt-1"
          >
          <!-- Desktop icon -->
          <img
            v-if="
              transaction.state === 'failed' || transaction.state === 'pending'
            "
            :src="
              require('@/assets/svg/transactions/' +
                failed_icon +
                '_desktop_failed.svg')
            "
            alt="transaction icon"
            class="hidden lg:block mr-2 mt-1"
          >
          <img
            v-else
            :src="require('@/assets/svg/transactions/' + icon + '_desktop.svg')"
            alt="transaction icon"
            class="hidden lg:block mr-2 mt-1"
          >
        </div>
        <div class="flex flex-col justify-center">
          <p 
            v-if="transaction.type === 'voucher' && transaction.transfer_voucher"
            class="text-sm text-grey-2 text-left"
            :class="{ 'text-grey-4': transaction.state === 'pending' }"
          >
            <span v-if="transaction.transfer_voucher.type === 'giftcard'"> {{ $t('transactions.voucher_transaction.giftcard_voucher_transaction') }}</span>
            <span v-else-if="transaction.transfer_voucher.sub_type === 'referral_sender' && transaction.transfer_voucher.pair_voucher"> {{ $t('transactions.voucher_transaction.sender_voucher', { name: transaction.transfer_voucher.pair_voucher.profile.user.name}) }}</span>
            <span v-else-if="transaction.transfer_voucher.sub_type === 'referral_receiver'"> {{ $t('transactions.voucher_transaction.gift_from', { name: transaction.transfer_voucher.sender_profile.user.name}) }}</span>
            <span v-else> {{ $t('wording.voucher') }}</span>
          </p>
          <p
            v-else
            class="text-sm text-grey-2 text-left"
            :class="{ 'text-grey-4': transaction.state === 'pending' }"
          >
            {{ title }}
          </p>
          
          <p class="text-sm text-grey-4 text-left">
            {{ description }}
          </p>
        </div>
      </div>
      <div class="flex items-center">
        <div>
          <p
            class="font-semibold text-right"
            :class="{
              'text-green-1': isGreen && !privacyMode,
              'text-grey-2': !isGreen,
              'text-grey-4':
                transaction.state === 'failed' ||
                transaction.state === 'pending',
              'line-through': transaction.state === 'failed'
            }"
          >
            <span v-if="isGreen && !privacyMode">+</span>
            <span v-else-if="!privacyMode">-</span>
            <span
              :class="{'blur-md filter': privacyMode}"
            >
              {{ '$' + (dollarFormatting (transaction.amount ?? 0)) }}
            </span>
          </p>
          <p class="text-13 text-grey-2 text-opacity-50 text-right">
            {{ date }}
          </p>
        </div>
        <img
          class="hidden lg:block w-4 h-4 mx-4"
          :class="{ 'transform rotate-180': showDetails }"
          src="@/assets/svg/arrows/down_grey_arrow.svg"
          alt="down arrow"
        >
      </div>
    </div>
    <div
      v-if="!isLast"
      class="bg-grey-ghost h-0.5 w-full -px-4 my-4 lg:hidden"
    />
    <TransactionDetailsComponent
      v-if="showDetails"
      :transaction="transaction"
      :privacy-mode="privacyMode"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import TransactionDetailsComponent from '@/components/transactions/TransactionDetailsComponent.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import useTransactions from '@/mixins/useTransactions.js'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: {
    TransactionDetailsComponent
  },
  props: {
    transaction: {
      type: Object,
      default: () => {}
    },
    isLast: {
      type: Boolean,
      default: false
    },
    isVoucher: {
      type: Boolean,
      default: false
    },
    privacyMode: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { dollarFormatting  } = useGlobalHelpers()
    const { description, isGreen, title, icon, failed_icon } = useTransactions()
    const i18n = useI18n({ useScope: 'global' })
    const store = useStore()
    const router = useRouter()
    const showDetails = ref(false)

    const date = computed(() => {
      let onlyDate = props.transaction.date.split(' ')[0].split('-')
      let month = i18n.t(`wording.months.${onlyDate[1]}`).slice(0, 3)
      let day = onlyDate[2]
      return day + ' ' + month
    })

    const transactionsDescription = computed(() => {
      return description(props.transaction)
    })

    const selectTransaction = async () => {
      await store.dispatch('setTransaction', props.transaction)
      if (window.innerWidth < 1024) {
        router.push('/transactions/details')
      } else {
        showDetails.value = !showDetails.value
      }
    }

    return {
      dollarFormatting ,
      isGreen: isGreen(props.transaction),
      date,
      title: title(props.transaction),
      icon: icon(props.transaction),
      failed_icon: failed_icon(props.transaction),
      description: transactionsDescription,
      selectTransaction,
      showDetails
    }
  }
}
</script>

<style lang="scss" scoped></style>
