<template>
  <div>
    <SideBar>
      <div
        v-if="form"
        class="h-screen relative text-left px-14 text-grey-giftcard-title"
      >
        <div
          v-for="(obj, key, index) in form"
          :key="'el-' + key"
        >
          <transition
            name="list-title"
            appear
            mode="out-in"
          >
            <div
              class="text-3xl top-0 absolute"
              v-html="currentKey === key ? obj.title : obj.sub_steps && currentKey in obj.sub_steps ? obj.sub_steps[currentKey].title : null"
            />
          </transition>
          <transition
            name="list"
            appear
          >
            <div
              class="mb-5"
              :style="'transition-delay: ' + (0.2 + (0.1 * index)) + 's'"
               v-if="obj.name"
            >
              <div
                v-if="index === 0"
                class="pt-24"
              />
              <span
                class="flex items-center"
                :class="{
                  'font-bold text-grey-2': (currentKey === key) || (obj.sub_steps && currentKey in obj.sub_steps),
                  'text-grey-9': keys.indexOf(key) > keys.indexOf(currentKey)
                }"
              >
                <div
                  v-if="keys.indexOf(key) >= keys.indexOf(currentKey) || (obj.sub_steps && currentKey in obj.sub_steps)"
                  class="steps__bullet"
                  :class="{'steps__bullet--active' : (currentKey === key) || (obj.sub_steps && currentKey in obj.sub_steps)}"
                  style="font-size: 0.625rem"
                >
                  {{ (index + 1).toString().padStart(2, '0') }}
                </div>
                <div v-if="keys.indexOf(key) < keys.indexOf(currentKey) && !(obj.sub_steps && currentKey in obj.sub_steps)"><img
                  src="@/assets/svg/check-2.svg"
                  alt=""
                  class="w-4"
                ></div>
                <div :class="keys.indexOf(key) >= keys.indexOf(currentKey) || (obj.sub_steps && currentKey in obj.sub_steps) ? 'ml-10': 'ml-6'">
                  {{ obj.name }}
                </div>
              </span>

              <template v-if="obj.sub_steps && currentKey in obj.sub_steps">
                <transition-group
                  name="list"
                  tag="ul"
                  appear
                >
                  <div
                    v-for="(sub, k, i) in obj.sub_steps"
                    :key="'el-'+ key + '-' + k"
                    :style="'transition-delay: ' + (0.2 + (0.1 * i)) + 's'"
                    class="ml-16 mb-5 mt-5 flex items-center"
                    :class="{'font-bold': currentKey === k, 'text-grey-9': keys.indexOf(k) > keys.indexOf(currentKey)}"
                  >
                    <div
                      v-if="keys.indexOf(k) < keys.indexOf(currentKey)"
                      class="mr-1 -ml-1"
                    >
                      <img
                        src="@/assets/svg/check-2.svg"
                        alt=""
                        class="w-4"
                      >
                    </div>
                    <div
                      v-if="currentKey === k"
                      class="mr-1 -ml-1"
                    >
                      <img
                        src="@/assets/svg/chev-right-4.svg"
                        alt=""
                        class="w-4"
                      >
                    </div>
                    {{ sub.name }}
                  </div>
                </transition-group>
              </template>
            </div>
          </transition>
          <img
              class="left-8 xl:left-18 absolute bottom-28 h-96 2xl:h-2/3"
              :src="obj.image"
              alt="lamp"
              v-if="obj.image"
          />
        </div>
      </div>
    </SideBar>

    <div
      v-if="slides.length"
      class="
        w-full
        lg:w-2/3
        ml-auto
        min-h-screen
        relative
        text-left
        lg:pt-28
        z-0
        form
        "
    >
      <swiper
        :allow-touch-move="false"
        :auto-height="true"
        :observer="true"
        :observe-parents="true"
        @swiper="setSwiper"
        @slideChange="slideChange"
      >
        <swiper-slide
          v-for="(slide, index) in slides"
          :key="'slide-form-' + index"
          v-slot="{ isActive }"
          class="flex flex-col no-scrollbar"
        >
          <Form 
            :data="slide"
            class="w-4/5 mx-auto mt-0 h-full flex flex-col"
            :progress="progress"
            :name="name"
            :index="index"
            :is-active="isActive"
            @next="next"
            @back="back"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import Form from '@/components/general/Form';
import { Swiper, SwiperSlide } from 'swiper/vue'
import {computed, ref, watch, defineComponent, onMounted} from "vue";
import SideBar from "@/components/general/SideBar";
import { useStore } from "vuex";
import {useRouter} from "vue-router";

export default defineComponent({
  components: {
    SideBar,
    Form,
    Swiper,
    SwiperSlide
  },
  props:{
    name:{
      type: String,
      default: '',
  }
},
  setup() {
    const slides = ref([])
    const swiper = ref(null)
    const store = useStore()
    const keys = ref([])
    const initialized = ref(0)

    const form = computed(()=>{
      return store.state.general.form
    })

    const router = useRouter()

    const currentIndex = computed(()=>{
      return swiper.value ? swiper.value.activeIndex : -1
    })

    const progress = computed(()=>{
      return currentKey.value ? ((keys.value.indexOf(currentKey.value) + 1 === keys.value.length ? 140 :
        (((keys.value.indexOf(currentKey.value) + 1) /
          keys.value.length) *
        100))) : 0
    })

    const currentKey = computed(()=>{
      return slides.value && slides.value[currentIndex.value] ? slides.value[currentIndex.value].key : null
    })

    const currentSlide = computed(()=>{
      return slides.value ? slides.value[currentIndex.value] : null
    })

    function setSwiper(value) {
      swiper.value = value
      //First step of the flow depending on the device size.
      var ro = new ResizeObserver(() => {
        swiper.value.update()
      })
      // Observe one or multiple elements
      ro.observe(swiper.value.slides[0])
    }

    function slideChange() {
      var ro = new ResizeObserver(() => {
        swiper.value.update()
      });

      // Observe one or multiple elements
      ro.observe(swiper.value.slides[swiper.value.activeIndex]);
    }

    function next(){
      if(currentSlide.value.nextLink)
        router.push(currentSlide.value.nextLink)
      else
        swiper.value.slideTo(Math.min(swiper.value.activeIndex + 1, swiper.value.slides.length - 1))
    }

    function back(){
      swiper.value.slideTo(Math.max(swiper.value.activeIndex - 1, 0))
    }

    function buildSlides(obj) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(obj)) {
        if(!keys.value.includes(key))
          keys.value.push(key)
        if('form' in value)
          slides.value = slides.value.concat(value["form"].map(f => ({...f, key: key, subkey: f.key})));
        if('sub_steps' in value)
          buildSlides(value['sub_steps'])
      }
    }

    onMounted(()=>{
      slides.value = []
      if(form.value)
        buildSlides(form.value)
    })

    watch(
      () => form.value,
      () => {
        if(initialized.value > 0) {
          slides.value = []
          if (form.value)
            buildSlides(form.value)
        }
        initialized.value++
      },
      { deep: true }
    )

    return { slides, slideChange, setSwiper, next, back, form, currentIndex, keys, currentKey, progress }
  }
})
</script>

<style>
  .form .swiper-slide{
    height: 100%;
    min-height: calc(100vh - 9rem);
  }

  @media (max-width: 768px) {
    .form .swiper-slide{
        height: 100%;
        min-height: 100vh;
    }
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 

  }
  .no-scrollbar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
</style>
