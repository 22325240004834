<template>
  <div class="card signin">
    <div class="m-auto h-5/6 w-100">
      <div class="card__title">{{ $t('login.title') }}</div>
      <!-- {{ Vue3GoogleOauth.isInit }} -->
      <Form @submit="login" class="relative" v-slot="{ errors }">
        <GeneralInput
          v-model:data="email"
          :triedToSubmit="triedToSubmit"
          type="email"
          name="email"
          label="Email"
          rules="email|required"
          :error="errors.email"
        />

        <GeneralInput
          v-model:data="password"
          :triedToSubmit="triedToSubmit"
          type="password"
          name="password"
          label="Password"
          :error="errors.password"
          rules="required"
        />

        <div class="card__form-item__forgot hidden sm:block">
          <router-link :to="'password-forgot'">{{
            $t('login.forgot_password')
          }}</router-link>
        </div>
        <GpButton submit desktopWidth="w-full" class="mb-8 sm:mt-10 sm:mb-6" :disabled="loading">
          {{ $t('login.sign_in') }}
        </GpButton>
        <div class="card__form-item__forgot block sm:hidden">
          <router-link :to="'password-forgot'">
            {{ $t('login.forgot_password') }}
          </router-link>
        </div>
      </Form>
      <div class="mt-5 text-sm text-grey-2">
        {{ $t('login.no_account') }}

        <router-link class="orange-link" :to="'signup'">
          {{ $t('login.register') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import GeneralInput from '../general/Input.vue'
import GpButton from '@/components/general/GpButton.vue'
import { Form } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { event } from 'vue-gtag'

export default {
  components: {
    Form,
    GeneralInput,
    GpButton
  },
  setup() {
    const Vue3GoogleOauth = inject('Vue3GoogleOauth')
    const cookies = inject('vue-cookie')

    let password = ref('')
    let email = ref('')
    let triedToSubmit = ref(false)
    let error = ref(null)
    const profile = computed(() => {
      return store.getters.getProfile()
    })
    const loading = computed(()=>{
      return store.state.general.loading
    })
    const router = useRouter()
    const store = useStore()
    const { locale } = useI18n({ useScope: 'global' })

    window.gonative_secret_callback = function (data) {
      if (data && data.success && data.secret) {
        let credentials = JSON.parse(data.secret)
        email.value = credentials.username
        password.value = credentials.password

        // Use username and password to do login here,
        // e.g. an http post or ajax request
        login()
      } else {
        // Allow manual entry
      }
    }

    window.gonative_status_afterlogin = function (data) {
      if (data && data.hasTouchId) {
        let secret = JSON.stringify({
          username: email.value,
          password: password.value
        })

        window.location.href =
          'gonative://auth/save?secret=' + encodeURIComponent(secret)
      }
    }

    window.gonative_status_beforelogin = function (data) {
      if (data && data.hasTouchId && data.hasSecret) {
        // Prompt the user to use the fingerprint to log in
        window.location.href =
          'gonative://auth/get?callbackFunction=gonative_secret_callback'
      }
    }

    onMounted(() => {
      if (navigator.userAgent.indexOf('gonative') > -1) {
        window.location.href =
          'gonative://auth/status?callbackFunction=gonative_status_beforelogin'
      }
    })

    function login() {
      triedToSubmit.value = true
      error.value = null
      store.dispatch('setLoading', true)
      store
        .dispatch('login', {
          email: email.value,
          password: password.value
        })
        .then(() => {
          event('login', { method: 'traditional' })
          if(cookies.isCookieAvailable('showed_welcome'))
          cookies.setCookie('showed_welcome', false)
          
          store
            .dispatch('fetchProfile')
            .then(() => {
              store.dispatch('setLoading', false)

              const lang = profile.value.language
              if (navigator.userAgent.indexOf('gonative') > -1) {
                window.location.href =
                    'gonative://auth/status?callbackFunction=gonative_status_afterlogin'
              }
              locale.value = lang === 'en-US' ? 'en' : lang
              router.push({name: 'dashboard'})
              const path = window.localStorage.getItem('path')
              if (path) {
                window.localStorage.removeItem('path')
                router.push(path)
              } else router.push({name: 'dashboard'})
            })
            .catch((err) => {
              store.dispatch('warnSlack', err.response)

              store.dispatch('setLoading', false)
              if (
                err.response &&
                err.response.status === 404
              ) {
                router.push('/onboarding')
              }
            })
        })
        .catch((err) => {
          if(err.response && ((err.response.data && err.response.data.error !== 'invalid_credentials') || !err.response.data))
            store.dispatch('warnSlack', err.response)

          store.dispatch('setLoading', false)
          error.value = err
          store.dispatch('setError', err)
        })
    }

    return {
      email,
      login,
      password,
      Vue3GoogleOauth,
      loading,
      error,
      triedToSubmit
    }
  }
}
</script>
