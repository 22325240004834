<template>
  <div
    class="circle-big w-14 bg-white rounded-full shadow-green4"
    :style="
      '--percentage: ' +
      circlePercentage +
      '% 262%;--arrearsPercentage: ' +
      arrearsPercentage +
      '% 262%; --arrearsDegree: rotate(' +
      (-90 + arrearsDegree) +
      'deg)'
    "
  >
    <div
      class="
        absolute
        left-1/2
        top-1/2
        transform
        -translate-x-1/2 -translate-y-1/2
        z-50
      "
    >
      <div class="text-grey-1 font-bold text-11">{{ percentage }}%</div>
    </div>
    <svg>
      <circle class="bg" cx="50%" cy="50%" r="22px"></circle>
      <circle class="progress" cx="50%" cy="50%" r="22px"></circle>
      <circle
        class="progress progress--orange"
        cx="50%"
        cy="50%"
        r="22px"
      ></circle>
    </svg>
  </div>
</template>
<script>
import useGlobalHelpers from '@/mixins/useGlobalHelpers'
import { computed, ref } from 'vue'
export default {
  props: {
    amount: {
      type: Number,
      default: 0
    },
    arrears: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const tooltip = ref({ x: '0px', y: '0px' })
    const { dollarFormatting } = useGlobalHelpers()
    const percentage = computed(() => {
      return Math.round((props.amount / props.total) * 100)
    })
    const circlePercentage = computed(() => {
      return Math.round((props.amount / props.total) * 261)
    })
    const arrearsPercentage = computed(() => {
      return Math.round((props.arrears / props.total) * 100)
    })
    const arrearsCirclePercentage = computed(() => {
      return Math.round((props.arrears / props.total) * 261)
    })
    const arrearsDegree = computed(() => {
      return Math.round((percentage.value * 180) / 50)
    })

    return {
      dollarFormatting,
      percentage,
      circlePercentage,
      tooltip,
      arrearsPercentage,
      arrearsDegree,
      arrearsCirclePercentage
    }
  }
}
</script>
<style>
.circle-big {
  position: relative;
  height: 0px;
  padding-bottom: 3.5rem;
}

.circle-big svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.circle-big .bg {
  fill: rgba(255, 255, 255, 0.8);
  stroke-width: 4px;
  stroke: #263340;
}

.circle-big .progress {
  fill: none;
  stroke-width: 5px;
  stroke: #38c867;
  stroke-linecap: unset;
  animation: big 1s ease-in-out;
  stroke-dasharray: var(--percentage);
  transform: rotate(-90deg);
  transform-origin: center;
}

.circle-big .progress--orange {
  stroke: #ffb600;
  transform: var(--arrearsDegree);
  stroke-dasharray: var(--arrearsPercentage);
  animation: big-arrears 1s ease-in-out;
}

@keyframes big-arrears {
  from {
    stroke-dasharray: 0 262%;
  }
  to {
    stroke-dasharray: var(--arrearsPercentage);
  }
}

@keyframes big {
  from {
    stroke-dasharray: 0 262%;
  }
  to {
    stroke-dasharray: var(--percentage);
  }
}
</style>
