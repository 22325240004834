<template>
  <div class="">
    <div class="container min-h-screen pb-1" v-if="campaignId == null">
      <span class="md:block md:pt-28">
        <Back />
      </span>
      <div
        class="
          text-lg text-grey-2
          md:text-xl md:text-grey-1
          font-bold
          text-left
          absolute
          md:relative md:top-auto md:left-auto
          top-5
          left-12
        "
      >
        {{ $t('promoter.campaigns.title') }}
      </div>

      <div class="text-right"><ProjectOrder class="mb-2" /></div>
      <Projects campaigns :type="type" />

    </div>
    <div v-else>
      <Suspense>
        <template #default>
          <ProjectDetails campaign />
        </template>
        <template #fallback>
          <div class="grid grid-cols-2 gap-x-6 gap-y-8">
            <ProjectSkeletons />
          </div>
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, onMounted, ref } from "vue";
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ProjectDetails from '@/views/projects/ProjectDetails'
export default {
  components: {
    ProjectDetails,
    Projects: defineAsyncComponent(() =>
      import('@/components/projects/Projects.vue')
    ),
    ProjectSkeletons: defineAsyncComponent(() =>
      import('@/components/projects/ProjectSkeletons.vue')
    ),
    ProjectOrder: defineAsyncComponent(() =>
      import('@/components/projects/Order.vue')
    ),
    Back: defineAsyncComponent(() => import('@/components/general/Back.vue'))
  },

  setup() {
    const showPopup = ref(true)
    const store = useStore()
    const route = useRoute()
    const left = ref(0)
    const barParent = ref(null)
    const type = computed(() => {
      return store.state.projects.type
    })

    const campaignId = computed(() => {
      return route.params.id
    })

    onMounted(()=>{
      store.dispatch('setOrder', 'created_at')
    })

    async function changeType(type) {
      store.dispatch('setType', type)
      const timer = (ms) => new Promise((res) => setTimeout(res, ms))
      await timer(1)
      left.value =
        document.getElementById(type).offsetLeft - barParent.value.offsetLeft
    }
    return { type, changeType, showPopup, left, barParent, campaignId }
  }
}
</script>
