<template>
	<div class="page flex flex-col items-end pb-32 lg:pb-0">
		<SideBar>
			<div class="h-screen relative">
				<GreenBall
					class="steps__ball w-full"
					start="bottom"
					offset="-2rem"
					cutout="0"
				/>
				<img
					src="@/assets/img/windmill-sidebar.png"
					alt=""
					class="absolute bottom-28 h-96 2xl:h-2/3 z-0"
				/>
				<div class="text-left w-3/4 mx-auto absolute z-10 left-24">
					<div class="text-3xl">
						<div class="text-grey-3 font-normal">
							{{$t('auto_investment.setup.title_sidebar_1')}}
						</div>
						<div class="text-grey-3 font-medium">
							{{
								$t(
									'auto_investment.self'
								)
							}}
						</div>
					</div>
					<ul class="ml-8 mt-8 text-grey-2">
						<transition name="fade" 
							v-for="(item) in stepsData"
            	v-bind:key="item">
							<li class="mb-4">
								 <img
                    src="@/assets/img/step_done.png"
                    alt=""
                    class="w-6 absolute"
                  />
								<span class="ml-9 text-smfont-bold">
									{{ item }}
								</span>
							</li>
						</transition>
					</ul>
				</div>
			</div>
		</SideBar>
		<div
			class="
				strategies__desktop-container
			"
		>	
			<div
				v-if="autoInvestmentToEdit"
				class="
					flex-grow flex flex-col
          justify-between
          lg:w-3/4
          xl:w-2/3
          2xl:w-1/2
					text-left
				"
			>
				<Back :to="'/auto-investment'"  :title="$t('auto_investment.edit.title')"/>
				<p class="hidden lg:block text-base text-grey-3 mb-8">{{$t('auto_investment.edit.subtitle')}}</p>
				<div class="mb-6">
					<p class="text-base font-semibold text-grey-2 mb-12">{{$t('auto_investment.setup.interest_rate.title')}}</p>
					<Slider
						v-model="interestRate"
						:min="2"
						:max="8"
						:step="1"
						:tooltips="true"
						@update="sliderModified()"
						showTooltip="always"
						:format="formatPercentageTooltip"
						class="slider-auto-investment"
					/>
					<div class="flex justify-between mt-6">
						<p class="font-bold text-grey-2 text-base">2%</p>
						<p class="font-bold text-grey-2 text-base">8%</p>
					</div>
				</div>
				<div class="mb-6">
					<p class="text-base font-semibold text-grey-2 mb-12">{{$t('auto_investment.setup.max_term.title')}}</p>
					<Slider
						v-model="maxTerm"
						:min="0"
						:max="10"
						:step="1"
						:tooltips="true"
						@update="sliderModified()"
						showTooltip="always"
						:format="formatMaxTermTooltip"
						class="slider-auto-investment"
					/>
					<div class="flex justify-between mt-6">
						<p class="font-bold text-grey-2 text-base">{{ $t('wording.year', 0) }}</p>
						<p class="font-bold text-grey-2 text-base">{{ $t('wording.year', 10) }}</p>
					</div>
				</div>
				<div class="mb-6">
					<p class="text-base font-semibold text-grey-2 mb-12">{{$t('auto_investment.setup.wallet_balance.title')}}</p>
					<Slider
						v-model="walletBalance"
						:min="10"
						:max="100"
						:step="10"
						:tooltips="true"
						@update="sliderModified()"
						showTooltip="always"
						:format="formatPercentageTooltip"
						class="slider-auto-investment"
					/>
					<div class="flex justify-between mt-6">
						<p class="font-bold text-grey-2 text-base">10%</p>
						<p class="font-bold text-grey-2 text-base">100%</p>
					</div>
				</div>
				<div class="mb-6">
					<p class="text-base font-semibold text-grey-2 mb-12">{{$t('auto_investment.setup.risk_rating.title')}}</p>
					<Slider
						v-model="riskRating"
						:min="0"
						:max="66.6"
						@update="sliderModified()"
						:step="11.1"
						:tooltips="true"
						:format="formatRiskTooltip"
						class="slider-auto-investment"
					/>
					<div class="flex justify-between mt-6">
						<p class="font-bold text-grey-2 text-base">A+</p>
						<p class="font-bold text-grey-2 text-base">C+</p>
					</div>
				</div>
				
				<div class="mb-6">
					 <Form @submit="next" class="form" ref="form" v-slot="{ errors, meta }">
						<p class="text-base font-semibold text-grey-2 mb-4 lg:mb-6">{{$t('auto_investment.setup.project_min.title')}}</p>
						<GeneralInput
							v-model:data="projectMin"
							type="dollar"
							name="projectMin"
							:error="errors.projectMin"
							:locked="checkAmountCheck('projectMin')"
							:label="checkAmountCheck('projectMin')? '' : $t('invest.amount.title')"
							:rules="projectMinCheck ? '' : projectMax ? 'minValue:500|maxValue:' + dollarToNumberFormatting(projectMax) + ',1' : 'minValue:500|maxValue:1000000,1'"
							prefix="$"
						/>
						<GeneralInput
							v-model:data="projectMinCheck"
							class="custom-checkbox"
							type="checkbox"
							name="projectMinCheck"
						>
							<template #label>
								{{ $t('auto_investment.setup.project_min.info_box') }}
							</template>
						</GeneralInput>
						<p class="text-base font-semibold text-grey-2 my-4 lg:my-6">{{$t('auto_investment.setup.project_max.title')}}</p>
						<GeneralInput
							v-model:data="projectMax"
							type="dollar"
							name="projectMax"
							:error="errors.projectMax"
							:locked="checkAmountCheck('projectMax')"
							:label="checkAmountCheck('projectMax')? '' : $t('invest.amount.title')"
							:rules="projectMaxCheck ? '' : projectMin ? 'minValue:'+dollarToNumberFormatting(projectMin)+'|maxValue:1000000,1' + ',1' : 'minValue:500|maxValue:1000000,1'"
							prefix="$"
						/>
						<GeneralInput
							v-model:data="projectMaxCheck"
							class="custom-checkbox"
							type="checkbox"
							name="projectMaxCheck"
						>
							<template #label>
								{{ $t('auto_investment.setup.project_max.info_box') }}
							</template>
						</GeneralInput>
						<div class="flex flex-col lg:flex-row lg:items-center mt-10 text-center lg:text-left">
							<router-link to="/auto-investment" class="text-orange-1 font-semibold text-base uppercase lg:flex-grow lg:pl-20 mb-8 lg:mb-0">{{ capitalize($t('wording.cancel'))}}</router-link>
							<GpButton
								@click="updateAutoInvestment()"
								:disabled="!formChanged || !meta.valid || maxTerm === 0">
								{{ $t('wording.confirm') }}
							</GpButton>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SideBar from '@/components/general/SideBar.vue'
import Back from '@/components/general/Back.vue'
import GreenBall from '@/components/general/GreenBall.vue'
import GpButton from '@/components/general/GpButton.vue'
import Slider from '@vueform/slider'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { Form } from 'vee-validate'
import GeneralInput from '@/components/general/Input.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
	components: {
		SideBar,
		GreenBall,
		Slider,
		Back,
		Form,
		GeneralInput,
		GpButton
	},
	props: {
		autoInvestment: () => {}
	},
	name: 'edit-auto-investment',
	setup(props) {
		const i18n = useI18n({ useScope: 'global' })
		const { dollarFormatting, capitalize, dollarToNumberFormatting } = useGlobalHelpers()
		const store = useStore()
		const autoInvestmentToEdit = ref(null)
		const interestRate = ref(null)
		const maxTerm = ref(null)
		const walletBalance = ref(null)
		const riskRating = ref(null)
		const projectMin = ref(null)
		const sliderHasChanged = ref(false)
		const projectMax = ref(null)
		const projectMinCheck = ref(false)
		const projectMaxCheck = ref(false)

		const router = useRouter()
		// const { locale } = useI18n({ useScope: 'global' })	

		const stepsData = computed(() => {
			return i18n.tm('auto_investment.steps')
		})

		const checkAmountCheck = (value) => {
			if((value === 'projectMin' && projectMinCheck.value === true) || (value === 'projectMax' && projectMaxCheck.value === true)) {
				if(value === 'projectMin')  projectMin.value = null
				if(value === 'projectMax')  projectMax.value = null
				return true
			}
		}

		const sliderModified = () => {
			sliderHasChanged.value = true
		}

		const formatPercentageTooltip = (value) => {
			return `${Math.round(value)}%`
		}

		const formatMaxTermTooltip = (value) => {
			return i18n.t('wording.year', value)
		}

		const activeAutoInvestment = computed(() => {
			return store.getters.getActiveAutoInvestment
		})

		const formatRiskTooltip = (value) => {
			return riskLevel(value)
		}

		const amountInputsFilled = computed(() => {
			return ( projectMin.value || projectMinCheck.value ) && ( projectMax.value || projectMaxCheck.value )
		})
		

		const formChanged = computed(() => {
			if(sliderHasChanged.value === true || (amountInputsFilled.value && (autoInvestmentToEdit.value.project_min && dollarToNumberFormatting(parseInt(projectMin.value)) !== autoInvestmentToEdit.value.project_min) || 
				(autoInvestmentToEdit.value.project_max && dollarToNumberFormatting(projectMax.value) != autoInvestmentToEdit.value.project_max) || (!autoInvestmentToEdit.value.project_max && projectMax.value) || (!autoInvestmentToEdit.value.project_min && projectMin.value))) {
				return true
			} else {
				return false
			}
		})

		const riskLevel = (value) => {
		if (value === 0) return 'A+'
		else if (value === 11.1) return 'A'
		else if (value === 22.2) return 'A-'
		else if (value === 33.3) return 'B+'
		else if (value === 44.4) return 'B'
		else if (value === 55.5) return 'B-'
		else if (value === 66.6) return 'C+'
		else return 'D'
		}

		const riskConversion = (value, findKey = false) => {
		const rates = {
			0: 10,
			11.1: 9,
			22.2: 8,
			33.3: 7, 
			44.4: 6,
			55.5: 5,
			66.6: 4
		}
				if(findKey) return Object.keys(rates).find(key => rates[key] === value)
		else return rates[value] ? rates[value] : null
		}

		const updateAutoInvestment = () => {
			formChanged.value = false
			if(projectMinCheck.value) projectMin.value = false
			else projectMin.value = parseInt(dollarToNumberFormatting(projectMin.value)) 
			if(projectMaxCheck.value) projectMax.value = false 
			else projectMax.value = parseInt(dollarToNumberFormatting(projectMax.value))
			const data = {
				min_interest: interestRate.value[0],
				max_interest: interestRate.value[1],
				max_term: maxTerm.value,
				balance_percentage: walletBalance.value,
				max_risk: riskConversion(riskRating.value),
				project_min: projectMin.value,
				project_max: projectMax.value
			}
			
			if(autoInvestmentToEdit.value.id) {
				data['id'] = autoInvestmentToEdit.value.id
				store.dispatch('updateAutoInvestment', data)
				 .then(async () => {
          const popup = {
            title: i18n.t('auto_investment.setup.confirmation.popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            primaryButton: i18n.t('auto_investment.setup.confirmation.popup.btn')
          }
          await store.dispatch('setPopup', popup)
          router.push('/auto-investment')
        })
				.catch((e) => {
          store.dispatch('setError', e)
          store.dispatch('forceRedirect', '/auto-investment')
					formChanged.value = true
        })
			} else {
				store.dispatch('createAutoInvestment', data)
				.then(async () => {
          const popup = {
            title: i18n.t('auto_investment.setup.confirmation.popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            primaryButton: i18n.t('auto_investment.setup.confirmation.popup.btn')
          }
          await store.dispatch('setPopup', popup)
          router.push('/auto-investment')
        })
				.catch((e) => {
          store.dispatch('setError', e)
          store.dispatch('forceRedirect', '/auto-investment')
					formChanged.value = true
        })
			}
		}


		onMounted(async () => {
			if (!props.autoInvestment) {
				router.push({
				name: 'auto-investment'
				})
			} else {
				autoInvestmentToEdit.value = JSON.parse(props.autoInvestment)
						interestRate.value = [autoInvestmentToEdit.value.min_interest, autoInvestmentToEdit.value.max_interest]
						maxTerm.value = parseInt(autoInvestmentToEdit.value.max_term)
						walletBalance.value = parseInt(autoInvestmentToEdit.value.balance_percentage)
						riskRating.value = riskConversion(parseInt(autoInvestmentToEdit.value.max_risk), true)
						if(!autoInvestmentToEdit.value.project_min) projectMinCheck.value = true
						else projectMin.value = dollarFormatting(parseInt(autoInvestmentToEdit.value.project_min))
						if(!autoInvestmentToEdit.value.project_max) projectMaxCheck.value = true
						else projectMax.value = dollarFormatting(parseInt(autoInvestmentToEdit.value.project_max))
			}
		})

		return {
			stepsData,
			interestRate,
			dollarFormatting,
			formatPercentageTooltip,
			formatMaxTermTooltip,
			formatRiskTooltip,
			riskLevel,
			riskConversion,
			autoInvestmentToEdit,
			maxTerm,
			walletBalance,
			riskRating,
			projectMin, 
			projectMax,
			checkAmountCheck,
			projectMaxCheck,
			projectMinCheck,
			capitalize,
			activeAutoInvestment,
			formChanged,
			sliderModified,
			amountInputsFilled,
			updateAutoInvestment,
			dollarToNumberFormatting
		}
	}
}
</script>

<style>
.slider-auto-investment {
  --slider-connect-bg: #b3dabb;
  --slider-bg: #4f59631a;
  --slider-handle-ring-color: #ffffff;
  --slider-handle-bg: #b3dabb;
  --slider-handle-ring-width: 6px;
  --slider-handle-radius: 1000px;
	--slider-tooltip-bg: #b3dabb;
	--slider-tooltip-py: 0.25rem;
	--slider-tooltip-px: 0.25rem;
}

.slider-horizontal .slider-handle {
  right: -16px;
}
</style>