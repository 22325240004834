<template>
  <Form
    @submit="next"
    ref="form"
    v-slot="{ errors, meta }"
    class="steps form flex-grow flex flex-col justify-between"
  >
    <div>
      <div class="steps__title--mobile ml-5 md:ml-0">
        {{ $tm('withdraw.amount.title') }}
        <p v-if="!walletBalance || walletBalance == 0" class="absolute -top-1 -right-5">
          <InfoTooltip 
            :copy="'home.no_balance'" 
            :positionXAxisMobile="'-left-72'"
            />
        </p>
      </div>
      <div class="steps__subtitle">
        {{
          $t('withdraw.amount.subtitle', {
            wallet_name: walletName,
            name: profile.name
          })
        }}
      </div>
      <GeneralInput
        v-model:data="amount"
        type="dollar"
        name="amount"
        :error="errors.amount"
        :label="$t('withdraw.amount.placeholder')"
        :rules="'required|minValue:' + minTransfer + '|maxValue:' + maxInvestment"
      />
    </div>

    <div>
      <div class="block lg:hidden mb-6 flex justify-between">
        {{ $t('home.balance') }}
        <div class="font-semibold">
          {{ balance }}
        </div>
      </div>
      <GpButton
        mobileWidth="w-full"
        type="submit"
        :disabled="!meta.valid || loading || !profile.can_check || !profile.lend_ack"
        class="ml-auto"
      >
        {{ $t('wording.continue') }}
      </GpButton>
    </div>
  </Form>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { Form } from 'vee-validate'
import GpButton from '@/components/general/GpButton.vue'
import { useI18n } from 'vue-i18n'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import GeneralInput from '@/components/general/Input.vue'
import { useStore } from 'vuex'

export default {
  components: {
    Form,
    GpButton,
    GeneralInput
  },
  props: {
    balance: String,
    walletName: String,
    walletBalance: Number,
    wallet: Object,
    presetAmount: String,
    isActive: Boolean
  },
  emits: ['next'],

  setup(props, { emit }) {
    const form = ref(null)
    const i18n = useI18n({ useScope: 'global' })
    const store = useStore()
    const { dollarFormatting, dollarToNumberFormatting } = useGlobalHelpers()
    const settingsConfig = require("@/config/settings.config.js");

    onMounted(async () => {
      await store.dispatch('setRequestSettings', settingsConfig.toString())
      await store.dispatch('fetchSuitability')
    })

    const settings = computed(() => {
      return store.state.general.settings
    })

    const amount = ref(
      props.presetAmount && props.presetAmount !== ''
        ? props.presetAmount
        : null
    )

    const minTransfer = computed(() => {
      if(settings.value && settings.value.min_investment){
        return settings.value.min_investment 
      }
      return 2000
    })

    const profile = computed(() => {
      return store.getters.getProfile()
    })
        
    const bankAccountId = computed(() => {
      return store.state.withdraw.bankAccount.id
    })

    const loading = computed(() => {
      return store.state.general.loading
    })
    const isBorrower = computed(() => {
      return profile.value && profile.value.sub_type === 'borrower'
    })

    // per transaction limit
    const transactionLimits = () => {
      let limit = 10000000

      // cant withdraw if
      if(!profile.value.activated || 
        !profile.value.can_check || 
        !profile.value.lend_ack || 
        isBorrower.value || 
        ['unsuitable', 'unsuitable_pending'].includes(profile.value.sub_type)
      ) return 0;

      if(settings.value  && settings.value.vp_indv_limit && settings.value.vp_org_limit){
        if(profile.value.type === 'particular') limit = settings.value.vp_indv_limit
        else if(profile.value.type === 'company') limit = settings.value.vp_org_limit
      }
      return limit;
    }

    // final limit
    const maxInvestment = computed(() => {
      return Math.min(
        props.walletBalance ?? 0, 
        transactionLimits()
      )
    })

    async function next() {
      store.dispatch('setLoading', true)
      await store.dispatch('setAmount', dollarToNumberFormatting(amount.value))
        .then(() => {
          store.dispatch('withdrawDetails', {
            wallet_id: props.wallet.id,
            params: {
              amount: dollarToNumberFormatting(amount.value),
              bank_account_id: bankAccountId.value
            }
          })
          .then(() => {
            store.dispatch('setLoading', false)
          })
          .catch((e) => {
            store.dispatch('setError', e)
            store.dispatch('setLoading', false)
          })
        })
        .catch((e) => {
          store.dispatch('setLoading', false)
          store.dispatch('setError', e)
        })
      emit('next')
    }

    watch(
      () => props.isActive,
      async () => {
        if (props.isActive && (!profile.value.can_check || !profile.value.lend_ack)) {
        const popup = {
          title: i18n.t('promoter.topup.popup_invalid_user.title'),
          align: 'center',
          side: false,
          iconBg: 'bg-grey-8',
          icon: '/img/popup/exclamation_orange.svg',
          content: i18n.t('promoter.topup.popup_invalid_user.content'),
          primaryButton: i18n.t('promoter.topup.popup_invalid_user.button')
        }
        await store.dispatch('setPopup', popup)
      }
      }
    )

    return {
      form,
      next,
      amount,
      loading,
      settings,
      minTransfer,
      dollarFormatting,
      maxInvestment,
      profile
    }
  }
}
</script>
