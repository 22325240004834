<template>
  <div class="topup">
    <SideBar>
      <div class="h-screen relative">
        <img
          src="@/assets/img/mountain-sidebar.png"
          alt=""
          class="absolute bottom-28 opacity-80 z-negative"
        />
        <div v-if="loan && walletName" class="mt-8 text-left w-3/4 mx-auto">
          <div class="text-3xl font-medium">
            <div v-if="isPayNow" class="text-grey-3">
              {{ $t('topup.sidebar.pay_now_title') }}
            </div>
            <div v-else class="text-grey-3">
              {{ $t('topup.sidebar.title') }}
            </div>
            <div class="text-grey-2 mt-1">
              {{ walletName }}
            </div>
            <div class="text-grey-2 mt-1">
              {{ $t('topup.sidebar.wallet') }}
            </div>
            <div class="mt-10 text-grey-3 text-sm">
              {{ $t('topup.sidebar.wallet_balance') }}
              <transition name="fade">
                <p class="text-3xl font-medium" v-if="loan">
                  ${{
                    loan.project_wallet.balance
                      ? dollarFormatting(loan.project_wallet.balance)
                      : '0,00'
                  }}
                </p>
              </transition>
            </div>
          </div>
        </div>
        <!-- Method -->
        <div v-else class="mt-8 text-left w-3/4 mx-auto text-3xl font-medium">
          <div v-if="method" class="text-grey-3">
            {{
              $t(
                'topup.sidebar.title_2',
                { method: $t('topup.sidebar.' + method) },
                activeIndex + 1
              )
            }}
          </div>
        </div>
        <div
          class="mt-12 text-grey-4 text-sm text-left w-3/4 mx-auto"
          v-if="nextPayment && !method"
        >
          {{ $t('topup.sidebar.next_payment') }}
          <span class="font-semibold">{{ nextPayment }}</span>
        </div>
        <div
          class="mt-24 text-grey-4 text-sm text-left w-3/4 mx-auto"
          v-if="lastTopup && method === 'creditcard'"
        >
          {{ $t('topup.sidebar.last_topup') }}
          <span class="font-semibold">{{ lastTopup }}</span>
        </div>
      </div>
    </SideBar>
    <div
      class="
        w-full
        lg:w-2/3
        ml-auto
        min-h-screen
        relative
        text-left
        lg:pt-20
        z-0
      "
    >
      <div
        class="
          md:w-3/4
          w-4/5
          m-auto
          absolute
          md:relative md:left-0
          left-9
          top-2
          z-50
        "
      >
        <transition name="toast-fade">
          <div
            class="
              back
              text-left text-grey-2
              lg:flex
              absolute
              top-5
              items-center
              cursor-pointer
              md:mb-12
            "
            @click="previousSlide"
          >
            <img
              src="@/assets/svg/chev-left.svg"
              alt=""
              class="w-5 md:w-auto"
            />

            <span class="hidden md:block">{{ $t('wording.back') }}</span>
          </div>
        </transition>
      </div>
      <router-link :to="'/loan/' + loan.id">
        <div class="block md:hidden absolute right-9 top-6 z-50">
          <img src="@/assets/svg/cross.svg" alt="" />
        </div>
      </router-link>

      <swiper
        v-if="loan"
        class="w-full"
        :loop="false"
        @swiper="setSwiper"
        @slidePrevTransitionEnd="checkMethod"
        :slides-per-view="1"
        :space-between="50"
        :pagination="false"
        :allowTouchMove="false"
        :speed="1000"
        :autoplay="false"
        :autoHeight="true"
      >
        <swiper-slide v-if="isPayNow" class="flex flex-col">
          <AmountToPay
            class="pb-20 w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
            @next="nextSlide"
            :loan="loan"
          />
        </swiper-slide>
        <swiper-slide class="flex flex-col">
          <Method
            class="pb-20 w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
            @next="nextSlide"
            :isPromoter="isPromoter"
          />
        </swiper-slide>
        <swiper-slide v-if="method === 'creditcard'" class="flex flex-col">
          <Amount
            class="pb-20 w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
            :balance="'$' + 
              (loan && loan.project_wallet.balance
                ? dollarFormatting(loan.project_wallet.balance)
                : '0,00')
            "
            @next="nextSlide"
            :walletName="walletName"
            :isPromoter="true"
            :amortizationAmount="loan.next_payment.amount"
            :isPayNow="Boolean(Number(isPayNow))"
          />
        </swiper-slide>
        <swiper-slide class="flex flex-col" v-slot="{ isActive }">
          <Confirmation
            class="pb-20 w-4/5 md:w-3/4 m-auto mt-6 md:mt-0"
            @finish="finish"
            :wallet="loan.project_wallet"
            :isActive="isActive"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { computed, ref, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import SideBar from '@/components/general/SideBar.vue'
import AmountToPay from '@/components/topup/AmountToPay.vue'
import Method from '@/components/topup/Method.vue'
import Amount from '@/components/topup/Amount.vue'
import Confirmation from '@/components/topup/Confirmation.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'

export default {
  components: {
    SideBar,
    Swiper,
    Confirmation,
    Method,
    SwiperSlide,
    Amount,
    AmountToPay
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { locale } = useI18n({ useScope: 'global' })
    const i18n = useI18n({ useScope: 'global' })
    const { dollarFormatting, ordinalSuffix } = useGlobalHelpers()
    const swiper = ref(null)
    const notAgain = ref(null)
    const walletName = ref(null)
    const isPayNow = ref(route.query.paynow)

    const profile = computed(() => {
      return store.getters.getProfile()
    })

    const loan = computed(() => {
      return store.state.promoters.loan
    })

    const isPromoter = computed(() => {
      if (profile.value) {
        return profile.value.promoter_id
      }
      return false    
    })

    onBeforeMount(async () => {
      notAgain.value =
        localStorage.getItem('hide_topup_confirmation') === 'true'
      if (!loan.value && route.params.id)
        await store.dispatch('fetchLoan', route.params.id.split('-').pop())

      // Name of the wallet
      walletName.value = loan.value.name

      // Fetch last topup
      await store.dispatch('fetchTransactions', {
        categories: ['topup'],
        wallet: loan.value.project_wallet.id,
        group: false,
        order: '-date',
        total: 1,
        lastTopup: true
      })
    })

    const activeIndex = computed(() => {
      return swiper.value ? swiper.value.activeIndex : 0
    })

    const method = computed(() => {
      return store.state.topup.method
    })

    const lastTopup = computed(() => {
      if (
        store.state.transactions.lastTopup &&
        store.state.transactions.lastTopup.length
      ) {
        const date = new Date(store.state.transactions.lastTopup[0].date.replace(/-/g, "/"))
        switch (locale.value) {
          case 'en':
            return (
              date.toLocaleString(locale.value, { month: 'long' }) +
              ' ' +
              ordinalSuffix(date.getDate()) +
              ' ' +
              date.getFullYear()
            )
          case 'es':
          case 'pt':
            return (
              date.getDate() +
              ' de ' +
              date.toLocaleString(locale.value, { month: 'long' }) +
              ' de ' +
              date.getFullYear()
            )
        }
      }
      return null
    })

    const nextPayment = computed(() => {
      if (store.state.promoters.loan) {
        const date = new Date(
          store.state.promoters.loan.next_payment.next_payment_date
        )
        switch (locale.value) {
          case 'en':
            return (
              date.toLocaleString(locale.value, { month: 'long' }) +
              ' ' +
              ordinalSuffix(date.getDate()) +
              ' ' +
              date.getFullYear()
            )
          case 'es':
          case 'pt':
            return (
              date.getDate() +
              ' de ' +
              date.toLocaleString(locale.value, { month: 'long' }) +
              ' de ' +
              date.getFullYear()
            )
        }
      }
      return null
    })

    // Call MANGOPAY endpoint
    async function finish() {
      store.dispatch('setLoading', true)
      store
        .dispatch('topup', { wallet_id: loan.value.project_wallet.id })
        .then((url) => {
          store.dispatch('setLoading', false)
          window.location.replace(url)
        })
        .catch((e) => {
          store.dispatch('setError', e)
          store.dispatch('setLoading', false)
        })
    }

    // Swipe Next
    async function nextSlide() {
      if (
        swiper.value.activeIndex === 2 &&
        notAgain.value &&
        store.state.topup.method === 'creditcard'
      ) {
        finish()
      } else if (
        store.state.topup.method === 'creditcard' &&
        store.state.topup.amount > 499999
      ) {
        const popup = {
          title: i18n.t('promoter.topup.popup_amount_not_allowed.title'),
          align: 'center',
          side: false,
          iconBg: 'bg-grey-8',
          icon: '/img/popup/exclamation_orange.svg',
          content: i18n.t('promoter.topup.popup_amount_not_allowed.content'),
          primaryButton: i18n.t(
            'promoter.topup.popup_amount_not_allowed.button'
          )
        }
        store.dispatch('setPopup', popup)
      } else {
        if (store.state.topup.method === 'bankwire') {
          store.dispatch('setLoading', true)
          await store
            .dispatch('topup', { wallet_id: loan.value.project_wallet.id })
            .then(() => {
              store.dispatch('setLoading', false)
              swiper.value.slideTo(swiper.value.activeIndex + 1)
            })
            .catch(() => {
              store.dispatch('setLoading', false)
            })
        } else {
          swiper.value.slideTo(swiper.value.activeIndex + 1)
        }
      }
    }

    // Swipe Previous
    async function previousSlide() {
      if (swiper.value.activeIndex > 0) {
        if (store.state.topup.amountNotEnoughConfirmation === true) {
          await store.dispatch('setConfirmation', false)
          swiper.value.slideTo(swiper.value.activeIndex - 1)
        } else {
          swiper.value.slideTo(swiper.value.activeIndex - 1)
        }
      } else {
        router.push({
          path: '/loan/' + loan.value.id
        })
      }
    }

    // Check if go back to first slide
    function checkMethod() {
      if (swiper.value.activeIndex === 0) {
        store.dispatch('setMethod', null)
      }
      if (isPayNow.value && swiper.value.activeIndex === 1) {
        store.dispatch('setMethod', null)
      }
    }

    // Initialize Swiper
    function setSwiper(value) {
      swiper.value = value
    }

    return {
      walletName,
      dollarFormatting,
      checkMethod,
      setSwiper,
      nextSlide,
      finish,
      previousSlide,
      activeIndex,
      notAgain,
      method,
      isPayNow,
      loan,
      lastTopup,
      nextPayment,
      isPromoter
    }
  }
}
</script>
<style scoped>
@media (min-width: 1024px) {
  .steps {
    padding-bottom: max(3.5rem, 0%);
  }
}
</style>

