import axios from 'axios'

export default {
  state: {
    account: null
  },
  mutations: {
    DELETE_ACCOUNT(state) {
      state.account = null
    },
    SET_ACCOUNT(state, account) {
      state.account = account
    },
  },
  actions: {
    getBankAccount({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/bank-accounts')
        .then(({ data }) => {
          commit('SET_ACCOUNT', data)
          return data
        })
    },
    // eslint-disable-next-line no-unused-vars
    getVPBankAccounts({ commit }) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/bank-accounts/vp')
        .then(( data ) => {
          return data.data
        })
    },
    // eslint-disable-next-line no-empty-pattern
    tokenizeiFrame({}, redirectURL) {

      return axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/iframe',
        {
          params: {
            RedirectURL: redirectURL
          }
        }
      )
      .then(({ data }) => {
        return data
      })
      .catch((e) => {
        return e
      })
    },
    // eslint-disable-next-line no-empty-pattern
    createBankAccount({ commit }, account_data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/bank-accounts',
            account_data
          )
          .then(({ data }) => {
            commit('SET_ACCOUNT', data)
            resolve(data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    deleteBankAccount({ commit }, bankAccountID) {
      return axios
        .delete(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/bank-accounts/' +
            bankAccountID
        )
        .then((data) => {
          commit('DELETE_ACCOUNT', data.data.id)
        })
    },
    // eslint-disable-next-line no-empty-pattern
    saveBankAccount({ commit }, bankAccount) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/bank-accounts/rename',
              bankAccount
          )
          .then((data) => {
            commit('SET_ACCOUNT', data.data)
            resolve(data)
          })
          .catch((e) => {
            commit('SET_ERROR', e)
            reject(e)
          })
      })
    }
  },
  getters: {
  }
}
