<template>
  <div class="page">
    <div class="md:pt-24">
      <div class=" container">
        <div>
          <div class="flex items-center">
            <div class="relative text-lg font-bold w-16 h-16 border-3 rounded-3xl mr-4"
                 :class="profile && profile.is_shareholder ? 'text-green-base border-green-base' : 'text-orange-3 border-orange-3'"
                 :style="profile && profile.is_shareholder ? 'background-color: #F6FAFD' : 'background-color: rgba(244, 178, 14, 0.1)'">
              <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                {{initials}}
              </div>
              <div class="absolute w-6 -bottom-2 -right-2">
                <img v-if="profile && profile.is_shareholder" src="@/assets/svg/badge.svg" alt="">
              </div>
            </div>
            <div class="text-grey-2 font-semibold text-xl text-left">
              <div>
                {{profile.name}} {{profile.surname}}
              </div>
              <div class="text-xs font-medium mt-1" style="color: #717980">
                <div>{{$t('profile.investor_since')}} <span class="font-bold">{{profile.investor_since}}</span></div>
                <div v-if="profile && profile.is_shareholder">{{$t('profile.shareholder_since')}} <span class="font-bold">{{profile.shareholder_since}}</span></div>
              </div>
            </div>

          </div>
          <router-link to="/settings" >
            <div class="bg-white p-4 rounded-xl absolute top-8 right-6">
              <img src="@/assets/svg/navigation/mobile/settings.svg" alt="" class="w-5"/></div
            >
          </router-link>
        </div>
        <InviteBanner class="mt-4"/>
        <GiftcardsBanner class="mt-4" v-if="isKycRegular"/>
        <div class="mt-6 mb-8">
          <div class="block md:hidden text-left text-grey-2 text-base opacity-60">
            {{$t('voucher_mobile.have_voucher')}}
          </div>
          <router-link :to="'/vouchers/redeem'">
            <div class="bg-white w-full p-4 mt-3 rounded-2xl text-left text-grey-3 font-semibold text-sm flex justify-between">
              <div>
              {{$t('voucher_mobile.redeem')}}
              </div>
              <img
                v-if="!white"
                src="@/assets/svg/arrows/right_grey_arrow.svg"
                alt=""
              />
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { useStore } from "vuex";
import { computed,ref , onMounted } from "vue";
import InviteBanner from '@/components/invite/InviteBanner'
import GiftcardsBanner from "@/components/giftcards/GiftcardsBanner";
// import WalletListElementAccordion from "@/components/dashboard/WalletListElementAccordion";
export default {
  components: {
    GiftcardsBanner,
    InviteBanner,
    // WalletListElementAccordion
  },
  setup() {
    const store = useStore()

    const profile = computed(()=>{
      return store.getters.getProfile()
    })

    const isKycRegular = computed(() => {
      return profile.value.kyc_level === 'regular'
    })

    const wallets = computed(() => {
      return store.state.wallets.wallets
    })

    const fetchingWallets = ref(true)

    const initials = computed(()=>{
      const first = profile.value.name ? profile.value.name[0] : ''
      let second = profile.value.surname ? profile.value.surname.split(' ') : ''
      if(second)
        second = second.length > 1 ? second[second.length - 1] : second[0]
      return first + second[0]
    })

    onMounted(async ()=>{
      if(!wallets.value)
        await store.dispatch('fetchWallets')
      fetchingWallets.value = false
    })

    return {
      profile,
      wallets,
      fetchingWallets,
      initials,
      isKycRegular
    }
  }
}
</script>
