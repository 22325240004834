<template>
  <div class="w-full mb-8 lg:mb-0">
    <div class="w-full flex justify-between lg:mb-4">
      <!-- Download -->
      <div
        @click="
          () => {
            if (downloadable) exportTransactionsXLS()
          }
        "
        class="hidden lg:flex bg-white rounded-2xl px-8 py-4 w-max"
        :class="downloadable ? 'cursor-pointer' : 'cursor-not-allowed'"
      >
        <img
          src="@/assets/svg/transactions/download.svg"
          alt="download"
          class="w-5 h-5 mr-2"
        />
        <p class="text-grey-2 text-sm font-semibold text-opacity-80">
          {{ $t('transactions.filters.export') }}
        </p>
      </div>
      <!-- lg -->
      <div
        class="
          hidden
          lg:flex
          bg-white
          rounded-2xl
          px-8
          py-4
          w-max
          cursor-pointer
        "
        @click="showFiltersPopup"
      >
        <img
          src="@/assets/svg/transactions/filters_desktop.svg"
          alt="cross"
          class="mr-2"
        />
        <p class="text-grey-2 text-sm font-semibold text-opacity-80">
          {{ $t('transactions.filters.title') }}
        </p>
      </div>
    </div>
    <!-- Mobile -->
    <div class="lg:hidden relative">
      <img
        src="@/assets/svg/transactions/filters.svg"
        alt="filters"
        @click="openFilters"
      />
      <div v-if="active" class="absolute top-2 left-5">
        <img src="@/assets/svg/transactions/active_filters.svg" alt="active" />
        <p
          class="text-grey-2 text-xxs absolute top-0 left-1 leading-3 font-bold"
        >
          {{ active }}
        </p>
      </div>
    </div>
    <Drawer v-if="showDrawer" minHeight="h-5/6" @close="close(false)">
      <template v-slot:content>
        <TransactionsFiltersDrawer @close="close" />
      </template>
    </Drawer>
  </div>
</template>

<script>
import Drawer from '@/components/general/Drawer.vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import TransactionsFiltersDrawer from '@/components/transactions/TransactionsFiltersDrawer.vue'
import { getCurrentInstance, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  components: { Drawer, TransactionsFiltersDrawer },
  props: {
    active: {
      type: Number,
      default: 0
    },
    downloadable: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const { emit } = getCurrentInstance()
    const showDrawer = ref(false)
    const { dateFormatting } = useGlobalHelpers()
    const store = useStore()

    const close = (filter = true) => {
      showDrawer.value = false
      emit('setFilters', filter)
    }

    const openFilters = () => {
      showDrawer.value = true
      emit('opened')
    }

    const exportTransactionsXLS = async () => {
      emit('export')
    }

    const showFiltersPopup = () => {
      const popup = {
        side: true,
        component: 'TransactionsFiltersDrawer'
      }
      store.dispatch('setPopup', popup)
    }

    return {
      showDrawer,
      close,
      openFilters,
      dateFormatting,
      exportTransactionsXLS,
      showFiltersPopup
    }
  }
}
</script>

<style lang="scss" scoped></style>
