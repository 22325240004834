<template>
  <div>
    <div class="flex-grow" id="popup-content-amount-not-enough">
      <p
        class="text-grey-2 mb-10 mt-6 md:mt-0 text-xl font-medium text-center"
        v-html="$t('popups.activate.title', {email: email})"
      />
      <p
        class="text-sm text-grey-3"
        v-html="$t('popups.activate.content', {email: email})"
      />
      <div class="flex mt-10 gap-4">
        <GpButton @click="next(false)" type="submit" color="white">
          {{ $t('popups.activate.sec_button') }}
        </GpButton>
        <GpButton @click="next(true)" type="submit">
          {{ $t('popups.activate.button') }}
        </GpButton><br/><br/><br/>.
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import GpButton from '@/components/general/GpButton.vue'
import axios from 'axios'
import { VueCookieNext } from 'vue-cookie-next'

export default {
  components: {
    GpButton
  },
  props: {
    email: {
      type: String,
      default: ''
    }
  },
  setup() {
    const store = useStore()

    async function next(value) {
      if (value) {
        store.dispatch('setLoading', true);
        axios.get(
          process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/profiles/activation'
        ).then(() => {
          store.dispatch('setLoading', false);
          store.dispatch('setToast', {
            type: 'success',
            toast: 'email_sent',
            position: 'top-right'
          })
          store.dispatch('setShowPopup', false)

          setTimeout(() => {
            store.dispatch('logout', VueCookieNext.getCookie('token')).then(()=>{
              store.dispatch('resetToasts')
            })
          }, 5000)
        }).catch((e) => {
          store.dispatch('setLoading', false);
          store.dispatch('setError', e)
        })
      }
      else{
        store.dispatch('setShowPopup', false)
      }
    }

    return { next }
  }
}
</script>
