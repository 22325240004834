<template>
  <div>
    <div class="text-center">
      <p class="font-bold text-lg text-grey-2 mb-9 mt-6">
        {{ $t('invest.amount.length_disclaimer.popup_title') }} 
      </p>
    </div>
    <a class="text-sm text-grey-2 text-opacity-60">
      {{ $t('invest.amount.length_disclaimer.popup_description') }} 
    </a><br/><br/>
    <GpButton
        @click="confirm()" >
        {{ $t('risk_popup.back') }}
    </GpButton>
    <br/><br/><br/>
  </div>
</template>
<script>
import GpButton from '@/components/general/GpButton.vue'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { useStore } from 'vuex'


export default {
    components: {
    GpButton
  },
  setup(){
    const router = useRouter()
    const store = useStore()
    const clicked = ref(false)

    async function confirm () {
      clicked.value=!clicked.value
      if(clicked.value){
      store.dispatch('setShowPopup', false)
      router.push({
        name: 'projects'
      })
      }
    }
    return {
      confirm,
      clicked
    }
  }
}
</script>
