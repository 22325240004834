import axios from 'axios'
import { VueCookieNext } from 'vue-cookie-next'

export default function () {
  const updateProfile = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .put(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/profiles', params)
        .then((data) => {
          VueCookieNext.setCookie('profile', data.data, {
            path: '/',
            domain: '',
            secure: true
          })
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
  return {
    updateProfile
  }
}
