<template>
  <div class="page ">
    <div class="md:pt-24">
    <Kyc
      v-if="profile.type === 'particular'"
      :name="profile.name"
      :surname="profile.surname"
      :redirectLink="true"
    />
    <KycOrganization
      v-else
      :name="profile.name"
      :surname="profile.surname"
      @done="kycsSubmitted"
    />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  components: {
    Kyc: defineAsyncComponent(() => import('@/components/general/Kyc.vue')),
    KycOrganization: defineAsyncComponent(() =>
      import('@/components/general/KycOrganization.vue')
    )
  },

  setup() {
    const route = useRouter()
    const store = useStore()
    const profile = computed(() => {
      return store.getters.getProfile()
    })

    onMounted(()=>{
      store.dispatch('add',{
        type: profile.value.type
      })
    })

    const kycsSubmitted = async () => {
      await store.dispatch('setToast', {
        type: 'success',
        toast: 'kyc_submitted',
        position: 'top-right'
      })
      route.push({name: 'dashboard'})
    }

    return {
      profile, 
      kycsSubmitted
    }
  }
}
</script>
