<template>
  <div class="page flex flex-col">
    <div
      v-if="contract && offerPreview"
      class="lg:pt-20 container flex-grow flex flex-col justify-between"
    >
      <div>
        <Back :to="'/contract-details/' + slug" :title="$t('mkt.sell.title')" />
        <p class="mt-8 mb-4 text-left text-sm text-grey-3">
          {{ $t('mkt.sell.text') }}
        </p>
        <!-- Table top -->
        <div class="pt-6 pb-4 px-4 bg-white rounded-t-2xl">
          <p class="text-left text-sm font-semibold text-grey-3 mb-4">
            {{ contract.project_name }}
          </p>
          <div class="flex items-center justify-between text-sm mb-4">
            <p class="text-grey-3">{{ $t('mkt.sell.outstanding') }}</p>
            <p class="text-grey-2 font-semibold">
              ${{ dollarFormatting(offerPreview.outstanding_capital) }}
            </p>
          </div>
          <div class="flex items-center justify-between text-sm mb-2">
            <p class="text-grey-3">{{ $t('mkt.sell.fee') }}</p>
            <p class="text-grey-2 font-semibold">
              ${{ dollarFormatting(offerPreview.offer_fee) }}
            </p>
          </div>
          <p class="text-left text-grey-4 text-xs">
            {{ $t('mkt.sell.fee_description') }}
          </p>
        </div>
        <!-- Table bottom -->
        <div class="pb-6 pt-4 px-4 bg-white rounded-b-2xl bg-opacity-60 mb-4">
          <div class="flex items-center justify-between text-sm mb-4">
            <p class="text-grey-3">{{ $t('wording.wallet') }}</p>
            <p class="text-grey-2 font-semibold">
              {{ walletName }}
            </p>
          </div>
          <div class="flex items-center justify-between text-sm">
            <p class="text-grey-3">{{ $t('mkt.sell.receive') }}</p>
            <p class="text-grey-2 font-semibold">
              ${{
                dollarFormatting(
                  offerPreview.outstanding_capital - offerPreview.offer_fee
                )
              }}
            </p>
          </div>
        </div>
        <GeneralInput
          v-model:data="transferAgreement"
          type="checkbox"
          name="transferAgreement"
        >
          <template #label>
            {{ $t('mkt.sell.transfer_agreement_label') }}
            <a @click="downloadConfirmationPDF" class="orange-link">
              {{ $t('mkt.sell.transfer_agreement_link') }}
            </a>
          </template>
        </GeneralInput>
      </div>
      <div class="w-full flex justify-end">
        <GpButton
          @click="sellContract"
          :disabled="!transferAgreement || pressed"
        >
          {{ $t('wording.confirm') }}
        </GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { onBeforeMount, computed, ref } from 'vue'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import Back from '@/components/general/Back.vue'
import GeneralInput from '@/components/general/Input.vue'
import GpButton from '@/components/general/GpButton.vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

export default {
  components: {
    Back,
    GeneralInput,
    GpButton
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const { slugIt, dollarFormatting } = useGlobalHelpers()
    const i18n = useI18n({ useScope: 'global' })
    const transferAgreement = ref(false)
    const pressed = ref(false)

    onBeforeMount(async () => {
      if (!contract.value)
        await store.dispatch('fetchContract', contractId.value)
      await store.dispatch('previewOffer', contractId.value)
    })

    const contractId = computed(() => {
      return route.params.id.split('-').pop()
    })

    const downloadConfirmationPDF = () => {
      if(contractId.value && contract.value) {
        axios
          .get(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/contracts/' +
              parseInt(contractId.value) +
              '/draft-offer',
            { responseType: 'arraybuffer' }
          )
          .then((response) => {
            let blob = new Blob([response.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = contract.value.project_name + 'draft_offer.pdf'
            link.click()
          })
          .catch((e) => {
            store.dispatch('setError', e)
          })
      }
    }

    const contract = computed(() => {
      return store.state.contracts.contract
    })

    const offerPreview = computed(() => {
      return store.state.contracts.offerPreview
    })

    const slug = computed(() => {
      return contract.value.project_name
        ? slugIt(contract.value.project_name) + '-' + contract.value.id
        : contract.value.id
    })

    const walletName = computed(() => {
      if (!offerPreview.value.contract_wallet)
        return i18n.t('transactions.filters.no_wallet')
      if (offerPreview.value.contract_wallet === 'main_wallet')
        return i18n.t('transactions.filters.main_wallet')
      else return offerPreview.value.contract_wallet
    })

    const sellContract = () => {
      pressed.value = true
      const params = {
        contract_id: contractId.value,
        term_agreement_checkbox: transferAgreement.value
      }
      axios
        .post(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/offers', params)
        .then(() => {
          router.push('/contract-details/' + slug.value)
        })
        .catch((e) => {
          pressed.value = false
          store.dispatch('setError', e)
        })
    }

    return {
      contract,
      slug,
      walletName,
      offerPreview,
      dollarFormatting,
      transferAgreement,
      sellContract,
      pressed,
      downloadConfirmationPDF
    }
  }
}
</script>

<style lang="scss" scoped></style>
