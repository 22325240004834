<template>
  <div class="relative">
    <div
      class="relative rounded w-max rounded flex items-center"
      :class="[bgColor, { 'px-2 py-px': daysToExpire >= 1 }]"
    >
      <div v-if="daysToExpire < 1" class="flex h-3.5 w-3.5 relative mr-2">
        <span
          class="
            animate-ping
            absolute
            inline-flex
            h-full
            w-full
            rounded-full
            bg-orange-3
            opacity-75
          "
        ></span>
        <span class="rounded-full h-1.5 w-1.5 bg-orange-3 m-auto"></span>
      </div>
      <p class="text-white text-xs">
        {{ text }}
      </p>
    </div>
    <div
      v-if="daysToExpire === 1"
      class="
        absolute
        top-0
        left-0
        px-2
        py-px
        rounded
        w-max
        rounded
        animate-ping
        opacity-75
      "
      :class="bgColor"
    >
      <p class="text-white text-11 text-orange-2">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
export default {
  props: {
    daysToExpire: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const i18n = useI18n({ useScope: 'global' })

    const bgColor = computed(() => {
      if (props.daysToExpire === 1) return 'bg-orange-2'
      else if (props.daysToExpire > 1) return 'bg-orange-1'
      else return ''
    })

    const text = computed(() => {
      if (props.daysToExpire === 1) return i18n.t('mkt.expires_tomorrow')
      else if (props.daysToExpire > 1)
        return i18n.t(
          'mkt.expires_in',
          { n: props.daysToExpire },
          props.daysToExpire
        )
      else return i18n.t('mkt.expires_today')
    })

    return {
      text,
      bgColor
    }
  }
}
</script>

<style lang="scss" scoped></style>
