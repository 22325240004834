<template>
  <div class="steps">
    <p class="steps__title--mobile ml-5 md:ml-0">{{$t('auto_investment.setup.wallet_balance.title')}}</p>
    <p class="text-sm text-grey-2 font-semibold lg:font-normal text-opacity-80 text-left mb-12">{{$t('auto_investment.setup.wallet_balance.subtitle')}}</p>
    <Slider
      v-model="walletPercentage"
      :min="10"
      :max="100"
      :step="10"
      :tooltips="true"
      :format="formatTooltip"
      class="slider-auto-investment"
    />
    <div class="flex justify-between mt-6">
      <p class="font-bold text-grey-2 text-base">10%</p>
      <p class="font-bold text-grey-2 text-base">100%</p>
    </div>
    <p class="text-sm text-grey-3 bg-grey-7 p-4 mt-8 rounded-3xl">
      {{ $t('auto_investment.setup.wallet_balance.info_box', { n: walletPercentage } ) }}
    </p>
    <GpButton
      mobileWidth="w-4/5"
      class="absolute bottom-20 lg:bottom-8 lg:right-24 mx-auto lg:m-0"
      @click="next"
    >
      {{$t('onboarding.continue')}}
    </GpButton>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref } from 'vue'
import GpButton from '@/components/general/GpButton.vue'
import Slider from '@vueform/slider'

export default {
  components: {
    GpButton,
    Slider
  },
  props: {
    loading: Boolean
  },
  setup(props, { emit }) {
    const store = useStore()
    const walletPercentage = ref(50)

    async function next() {
      store.dispatch('updateNewAutoInvestment', {
        balance_percentage: walletPercentage.value
      })
      emit('next')
    }

    const formatTooltip = (value) => {
      return `${Math.round(value)}%`
    }

    return {
      next,
      formatTooltip,
      walletPercentage
    }
  }
}
</script>
