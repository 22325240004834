<template>
  <div class="card signup sm:shadow-card">
    <div class="m-auto h-5/6 w-100">
      <div class="text-2xl font-bold text-grey-2 text-left flex mb-6">
        {{ $t('signup.title') }}
      </div>
      <div
        class="text-grey-2 font-medium text-sm text-left -mt-4 mb-6"
      >
        <span class="mr-1">
          {{ $t('signup.already_have_account') }}
        </span>
        <router-link
          class="orange-link"
          :to="voucher ? '/vouchers/redeem/' + voucher : '/login'"
          >{{ $t('wording.login') }}</router-link
        >.
      </div>
      <div class="relative">
        <Form @submit="register" v-slot="{ errors, meta }" class="relative">
          <GeneralInput
            v-model:data="email"
            :triedToSubmit="triedToSubmit"
            type="email"
            name="email"
            :label="company ? $t('signup.work_email') : 'Email'"
            bgColor= 'bg-grey-ghost'
            rules="required|email"
            :error="errors.email"
          />
          <GeneralInput
            v-model:data="password"
            :triedToSubmit="triedToSubmit"
            type="password"
            name="password"
            label="Password"
            bgColor='bg-grey-ghost'
            :signupPassword="true"
            :error="errors.password"
            rules="required|minLength:8|hasUpperLower|hasNumberSymbol|maxLength:24"
          />
          <GpButton 
            id="gp-button-welcome-register-with-email-2"
            submit
            :disabled="loading || !meta.valid"
            class="mb-2 text-13"
            desktopWidth="w-full"
          >
            {{ !company ? $t('signup.button') : $t('signup.button_company') }}
          </GpButton>

          <div class="text-center mt-4 text-grey-3 text-sm relative">
            <span class="mr-1"
              >{{
                !company
                  ? $t('signup.by_signing_up')
                  : $t('signup.by_signing_up_company')
              }}
            </span>
            <br class="block sm:hidden" />
            <a
              class="orange-link mr-1"
              :href='terms'
              target="_blank"
              >{{ $t('signup.terms') }}</a
            >
            <span class="mr-1"> {{ $t('wording.and') }} </span>
            <a
              class="orange-link"
              :href='privacy'
              target="_blank"
              >{{ $t('signup.privacy') }}</a
            >.
          </div>
        </Form>
      </div>
      <div class="text-grey-2">
        <span class="opacity-60 mr-1">
          {{ $t('signup.already_have_account') }}
        </span>
        <router-link class="orange-link" :to="'login'">{{
          $t('wording.login')
        }}</router-link>.
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import GeneralInput from '../general/Input.vue'
import GpButton from '@/components/general/GpButton.vue'
import { Form } from 'vee-validate'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    GeneralInput,
    Form,
    GpButton,
  },
  props: {
    company: {
      type: Boolean,
      default: false
    },
    voucher: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const i18n = useI18n({ useScope: 'global' })
    const { locale } = useI18n({ useScope: 'global' })

    let password = ref('')
    let email = ref('')
    let triedToSubmit = ref(false)
    let error = ref(null)

    const loading = computed(() => {
      return store.state.general.loading
    })

    const serverUrl = computed(() => {
			return process.env.VUE_APP_SERVER_BASE_URL ?? null
		})

    function register() {
      triedToSubmit.value = true
      error.value = null
      store.dispatch('setLoading', true)
      store
        .dispatch('signup', {
          email: email.value,
          password: password.value,
          password_confirmation: password.value,
          name: route.params.voucher + '@' + locale.value,
          surname:
            (props.company ? 'company@' : 'particular@') +
            route.query.click_id
        })
        .then(() => {
          store.dispatch('setLoading', false)
          router.push({
            name: 'onboarding',
            query: { step: 'registration' }
          })
        const popup = {
          title: i18n.t('signup.popup.title'),
          align: 'center',
          side: false,
          drawer: false,
          icon: '/img/signup/email.svg',
          content: i18n.t('signup.popup.content'),
          primaryButton: i18n.t('signup.popup.btn')
        }
        store.dispatch('setPopup', popup)
      })
      .catch((err) => {
        store.dispatch('setLoading', false)
        error.value = err
        store.dispatch('setError', err)
      })
    
    }

    return {
      register,
      email,
      serverUrl,
      password,
      error,
      triedToSubmit,
      locale,
      loading,
      terms: process.env.VUE_APP_TERMS_LINK,
      privacy: process.env.VUE_APP_PRIVACY_LINK
    }
  }
}
</script>
