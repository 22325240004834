<template>
  <div>
    <div class="text-left" id="popup-auto-investment-cancellation">
      <div class="flex-grow">
        <p
          class="text-sm text-grey-3"
          v-html="$t('auto_investment.cancellation.popup_content')"
        />
        <br>
        <p
          class="text-sm text-grey-3"
          v-html="$t('auto_investment.cancellation.popup_subcontent')"
        />
      </div>
      <div class="mt-24 lg:mt-10 gap-4 text-center lg:flex lg:justify-center lg:items-center">
        <a href="/auto-investment" class="text-sm text-orange-1 font-semibold mr-2.5 lg:w-1/2 uppercase">
          {{$t('auto_investment.cancellation.popup_cancel') }}
        </a>
        <GpButton
          :disabled="deleted"
          @click="cancelAutoInvestment()"
          class="mx-auto lg:w-1/2 mt-6 lg:mt-0"
        >
          {{$t('auto_investment.cancellation.popup_btn')}}
        </GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import GpButton from '@/components/general/GpButton.vue'
import { ref } from 'vue'

export default {
  components: {
		GpButton
	},
  props: {
    autoInvestmentId: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const i18n = useI18n({ useScope: 'global' })
    const deleted = ref(false)

    const cancelAutoInvestment = () => {
      if(props.autoInvestmentId) {
        deleted.value = true
        store.dispatch('cancelAutoInvestment', props.autoInvestmentId)
          .then(() => {
            const popup = {
              title: i18n.t('auto_investment.cancellation.popup_confirmation'),
              align: 'center',
              side: false,
              iconBg: 'bg-green-5',
              icon: '/img/done_step.svg',
              
            }
            store.dispatch('setPopup', popup)
            router.go()
          })
          .catch((e) => {
            store.dispatch('setError', e)
            store.dispatch('forceRedirect', '/dashboard')
            deleted.value = false
          })
      }
      else {
        router.push('/auto-investment')
      }
    }
    return {
      cancelAutoInvestment,
      deleted
    }
  }
}
</script>
