<template>
  <div class="z-50">
    <transition name="fade">
      <div
        class="
          bg-grey-4
          top-0
          left-0
          w-screen
          h-screen
          fixed
          z-60
          hidden
          md:block
        "
        @click="hidePopup"
        v-if="impactShowPopup"
      ></div>
    </transition>
    <div ref="cardbig" style="width: fit-content" class="hidden">
      <card-big share />
    </div>
    <card-big @share="shareImage" />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import CardBig from '@/components/impact/impact/CardBig'
export default {
  props: {},
  components: { CardBig },
  setup() {
    const store = useStore()
    const cardbig = ref(null)

    const impactPopup = computed(() => {
      return store.state.general.impactPopup
    })

    const impactShowPopup = computed(() => {
      return store.state.general.impactShowPopup
    })

    function shareImage() {
      store.dispatch('setShare', cardbig.value)
    }

    function hidePopup() {
      store.dispatch('setImpactShowPopup', false)
    }

    return { impactShowPopup, hidePopup, impactPopup, shareImage, cardbig }
  }
}
</script>
