<template>
  <div class="z-60">
    <transition name="fade" mode="out-in">
      <div
        class="fixed bg-grey-3 w-screen h-screen top-0 left-0 z-60"
        @click="exit"
        v-if="showShare"
      ></div>
    </transition>

    <div
      class="
        container
        z-60
        fixed
        top-1/2
        left-1/2
        transform
        -translate-y-1/2 -translate-x-1/2
        scale-0
        transition-all
        max-w-screen
        md:w-screen-2/5
        bg-white
        p-14
        rounded-5xl
      "
      :class="{ 'scale-100': showShare }"
    >
      <img
        src="@/assets/svg/cross.svg"
        alt=""
        class="absolute right-6 top-6 cursor-pointer"
        @click="exit"
      />
      <img :src="output" alt="" v-if="!loading" />
      <Loading v-else center />
      <div class="mt-6 text-sm text-grey-3">{{ $t('share.title') }}</div>
      <div class="flex justify-center mt-4">
        <div
          v-for="(social, index) in socials"
          v-bind:key="social"
          class="bg-grey-ghost rounded-2xl w-14 h-14 relative cursor-pointer"
          :class="{ ' mr-8': index < socials.length - 1 }"
          @click="share(social)"
        >
          <div
            class="
              absolute
              top-1/2
              left-1/2
              transform
              -translate-x-1/2 -translate-y-1/2
            "
          >
            <img
              :src="require('@/assets/svg/social/' + social[0] + '.svg')"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="mt-8 text-sm">
        <span class="text-grey-3 mr-1">
          {{ $t('wording.or') }}
        </span>
        <a :href="output" download="my_impact">
          <span class="orange-link">{{ $t('share.save') }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/general/Loading'
export default {
  components: { Loading },

  props: {
    type: {
      type: String,
      default: 'login'
    }
  },
  data() {
    return {
      output: null,
      loading: false,
      socials: ['facebook', 'twitter', 'instagram', 'email']
    }
  },
  computed: {
    el() {
      return this.$store.state.general.share
    },
    showShare() {
      return this.$store.state.general.showShare
    }
  },
  watch: {
    async el() {
      this.el.classList.remove('hidden')
      await this.print()
    }
  },
  methods: {
    share(method) {
      // t=document.title;
      switch (method) {
        case 'facebook':
          window.open(
            'http://www.facebook.com/sharer.php?u=' + '',
            'sharer',
            'toolbar=0,status=0,width=626,height=436'
          )
      }
    },

    async print() {
      this.loading = true
      const options = {
        type: 'dataURL'
      }
      this.output = await this.$html2canvas(this.el, options)
      this.el.classList.add('hidden')
      this.loading = false
    },
    exit() {
      this.$store.dispatch('setShare', null)
      this.$store.dispatch('setShowShare', false)
    }
  }
}
</script>
