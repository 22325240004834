<template>
  <!-- GROUND -->
  <div>
    <div
      class="hoover-animation"
    >
      <!-- RABBIT -->
      <div
        class="transform scale-40"
        style="position: absolute; bottom: 30px; z-index: 34; left: 20px"
      >
        <div
          class="bottom-5 absolute z-50 bunny-run"
        >
          <div class="rabbit rabbit-jump" ></div>
        </div>
      </div>

      <!-- BUTTERFLY -->
      <transition name="bounce">
        <div
          class="z-50 absolute -right-12 bottom-24 origin-center"
        >
          <div
            class="butterfly-animation z-50 absolute right-0 transform scale-15 h-2"
            style="bottom: 124px"
          >
            <div
              class="
                  transform
                  h-8
                  transition-all
                  ease-out
                  duration-2000
                  absolute

                  -translate-x-96 translate-y-96
                "

            >
              <div
                class="butterfly butterfly-hover"
                :style="
                    '--flap-speed:' +
                     700  +
                    'ms'
                  "
              >
                <div
                  class="wing wing-animation"
                >
                  <div class="bit"></div>
                  <div class="bit"></div>
                </div>
                <div
                  class="wing wing-animation"
                >
                  <div class="bit"></div>
                  <div class="bit"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <!-- SOIL -->
      <div class="w-40">
        <img
          src="@/assets/svg/impact/gamification/soil.svg"
          alt=""
          :class="'appear'"
        />
      </div>

      <!-- INITIAL TREE -->
      <div class="absolute -right-6 w-20 bottom-2 z-30">

        <!-- MAIN LOG -->
        <img
          src="@/assets/svg/impact/gamification/components/main-log.svg"
          alt=""
          class="absolute bottom-7 left-5 transition-all ease-out z-negative"
          :style="`height: ${stalkData.height}px; width: ${stalkData.width}px; transition-delay: ${stalkData.delay}s`"
        />

        <!-- BRANCHES -->
        <div
          v-for="(branch, index) in stalkData.branches"
          v-bind:key="'branch-' + index"
          :style="`--tw-rotate: ${branch.deg};`"
          class="transform"
        >
          <div
            :style="`animation-delay: ${branch.delay}s ; --tw-scale-x: ${
                branch.mirror ? -1 : 1
              }; --var-height:${stalkData.height}px;  right:${
                branch.right
              }px; left:${branch.left}px; bottom: ${branch.bottom}px`"
            class="
                branch-animation
                w-24
                overflow-hidden
                absolute
                z-30
                transform
                transition-all
                ease-in-out
              "
          >
            <img
              :src="
                  require(`@/assets/svg/impact/gamification/components/${
                    branch.sub ? 'sub-branch' : 'branch'
                  }.svg`)
                "
              alt=""
              class="absolute w-4"
              :style="`bottom: 12px; width: 40px; right: 0px; ${
                  branch.reverse ? 'transform: scaleX(-1)' : ''
                }; ${branch.scale ? `transform: scale(${branch.scale});` : ''}`"
            />
          </div>
        </div>

        <!-- BALLS -->
        <span
          v-for="(ball, index) in stalkData.balls"
          v-bind:key="'ball-' + index"
        >
            <div
              class="transform w-8 absolute transition-all ease-out"
              :style="`left: ${ball.left}px; bottom: ${
                ball.bottom
              }px;--tw-scale-x: ${ball.scale ? ball.scale : 1}; --tw-scale-y: ${
                ball.scale ? ball.scale : 1
              };z-index: ${ball.z};`"
            >
              <img
                :src="
                  require(`@/assets/svg/impact/gamification/components/${
                    ball.light
                      ? 'ball-light'
                      : ball.dark
                      ? 'ball-dark'
                      : 'ball-regular'
                  }.svg`)
                "
                alt=""
                class="
                  absolute
                  w-6
                  bottom-18
                  leaf-animation
                  transition-all
                  ease-in-out
                  duration-100
                  z-30
                "
                :style="`left: 17px; animation-delay: ${
                  ball.delay ? ball.delay : 0
                }ms`"
              />
            </div>
          </span>

        <div
          :class="{ 'transform scale-0': stalkData.hideTop }"
          style="transition-delay: 1.6s"
        >
          <img
            src="@/assets/svg/impact/gamification/components/stalk-top.svg"
            alt=""
            class="absolute w-6 bottom-7 left-3 transition-all ease-in-out"
            :style="'bottom:' + stalkData.bottom + 'px;'"
          />
        </div>
      </div>

      <!-- BUSHES -->
      <div
        class="absolute w-12 z-40 ground-appear"
        style="
                --height-final: 2.1rem;
                --height-top: 2.2rem;
                left: 99px;
                bottom: 32px;
              "
      >
        <div
          style="animation-delay: 0.8s"
          class="jiggle-animation"
        >
          <img src="@/assets/svg/impact/gamification/bush.svg" alt="" />
        </div>
        <div>
          <div
            style="
                background: #f75e5e;
                left: 10px;
                bottom: 19px;
                width: 4px;
                height: 4px;
                animation-delay: 1.2s;
              "
            class="absolute rounded-full berry"
          ></div>
          <div
            style="
                background: #f75e5e;
                left: 16px;
                bottom: 9px;
                width: 4px;
                height: 4px;
                animation-delay: 1.4s;
              "
            class="absolute rounded-full berry"
          ></div>
          <div
            style="
                background: #f75e5e;
                left: 5px;
                bottom: 2px;
                width: 4px;
                height: 4px;
                animation-delay: 1.8s;
              "
            class="absolute rounded-full berry"
          ></div>

          <div
            style="
                background: #f75e5e;
                right: 14px;
                bottom: 20px;
                width: 4px;
                height: 4px;
                animation-delay: 1.6s;
              "
            class="absolute rounded-full berry"
          ></div>
          <div
            style="
                background: #f75e5e;
                right: 8px;
                bottom: 10px;
                width: 4px;
                height: 4px;
                animation-delay: 2s;
              "
            class="absolute rounded-full berry"
          ></div>
          <div
            style="
                background: #f75e5e;
                right: 15px;
                bottom: 2px;
                width: 4px;
                height: 4px;
                animation-delay: 2.2s;
              "
            class="absolute rounded-full berry"
          ></div>
        </div>
      </div>
      <div
        class="absolute w-10 z-40 ground-appear"
        style="
            --height-final: 1.6rem;
            --height-top: 1.7rem;
            left: 72px;
            bottom: 35px;
            z-index: 35;
          "
      >
        <div class="jiggle-animation">
          <img src="@/assets/svg/impact/gamification/bush-light.svg" alt="" />
        </div>
        <div>
          <div
            style="
                background: #f75e5e;
                left: 10px;
                bottom: 17px;
                width: 4px;
                height: 4px;
                animation-delay: 1.3s;
              "
            class="absolute rounded-full berry"
          ></div>
          <div
            style="
                background: #f75e5e;
                left: 14px;
                bottom: 9px;
                width: 4px;
                height: 4px;
                animation-delay: 1.5s;
              "
            class="absolute rounded-full berry"
          ></div>
          <div
            style="
                background: #f75e5e;
                left: 5px;
                bottom: 2px;
                width: 4px;
                height: 4px;
                animation-delay: 1.9s;
              "
            class="absolute rounded-full berry"
          ></div>

          <div
            style="
                background: #f75e5e;
                right: 14px;
                bottom: 19px;
                width: 4px;
                height: 4px;
                animation-delay: 1.7s;
              "
            class="absolute rounded-full berry"
          ></div>
          <div
            style="
                background: #f75e5e;
                right: 8px;
                bottom: 10px;
                width: 4px;
                height: 4px;
                animation-delay: 2.1s;
              "
            class="absolute rounded-full berry"
          ></div>
          <div
            style="
                background: #f75e5e;
                right: 15px;
                bottom: 2px;
                width: 4px;
                height: 4px;
                animation-delay: 2.3s;
              "
            class="absolute rounded-full berry"
          ></div>
        </div>
      </div>
      <div
        class="absolute w-8 z-40 ground-appear"
        style="
            --height-final: 1.6rem;
            --height-top: 1.7rem;
            left: 42px;
            bottom: 35px;
            z-index: 5;
          "
      >
        <div class="jiggle-animation">
          <img src="@/assets/svg/impact/gamification/bush.svg" alt="" />
        </div>
      </div>
      <div
        class="absolute w-6 z-40 ground-appear"
        style="
            --height-final: 1.6rem;
            --height-top: 1.7rem;
            left: 22px;
            bottom: 28px;
          "
      >
        <div class="jiggle-animation">
          <img src="@/assets/svg/impact/gamification/bush-light.svg" alt="" />
        </div>
      </div>

      <!-- BERRY TREE -->
      <div
        class="
            absolute
            left-8
            bottom-10
            z-20
            transform
            transition-all
            ease-out
            scale-100
          "
        style="transition-delay: 1400ms"
      >
        <BerryTree berries/>
      </div>

      <!-- BROWN TREE -->
      <div
        class="
            absolute
            left-20
            bottom-10
            z-10
            transform
            transition-all
            ease-out
            scale-100
          "
        style="transition-delay: 1800ms"
      >
        <BrownTree />
      </div>

      <!-- TWO BROWN LEAVES -->
      <div class="absolute left-2 bottom-0 z-negative">
        <TwoLeaves/>
      </div>

      <!-- TWO BROWN LEAVES BLUE -->
      <div
        class="absolute z-60 transform"
        style="--tw-scale-x: -1; left: 98px; bottom: -9px"
      >
        <TwoLeaves blue/>
      </div>
    </div>

  </div>
</template>

<script>
import BerryTree from '@/components/impact/gamification/BerryTree'
import BrownTree from '@/components/impact/gamification/BrownTree'
import TwoLeaves from '@/components/impact/gamification/TwoLeaves'
import { ref } from 'vue'
export default {
  components:{
    BerryTree,
    BrownTree,
    TwoLeaves
  },
  setup(){
    const stalkData = ref(      // Sprout w/ Leaves (No more leaves)
      {
        height: 100,
        width: 90,
        min: 20,
        bottom: 58,
        wait: 1200,
        branches: [
          {
            right: 50,
            bottom: 73
          },
          {
            deg: '-5deg',
            left: 30,
            bottom: 68,
            reverse: true
          },
          {
            right: 56,
            bottom: 54
          },
          {
            right: 41,
            bottom: 38,
            deg: '-10deg',
            sub: true,
            scale: 0.2
          },
          {
            left: 27,
            bottom: 53,
            reverse: true,
          },
          {
            left: 23,
            bottom: 51,
            sub: true,
            mirror: true,
            scale: 0.2
          }
        ],
        balls: [
          {
            left: -31,
            bottom: -3,
            dark: true,
            scale: 1.6,
            z: 0,
            delay: 50
          },
          {
            left: -6,
            bottom: 28,
            scale: 1.2,
            z: 1,
            delay: 100
          },
          {
            left: -38,
            bottom: -32,
            light: true,
            scale: 1.7,
            z: -1,
            delay: 150
          },
          {
            left: -8,
            bottom: 3,
            light: true,
            scale: 1.7,
            z: 0,
            delay: 200
          },
          {
            left: -22,
            bottom: -61,
            scale: 2.5,
            z: -4,
            delay: 250
          },
          {
            left: 14,
            bottom: 20,
            scale: 1.2,
            light: true,
            z: -1,
            delay: 300
          },
          {
            left: 24,
            bottom: 4,
            scale: 1.2,
            z: -2,
            delay: 350
          },
          {
            left: 24,
            bottom: -16,
            scale: 1.7,
            dark: true,
            z: -2,
            delay: 400
          }
        ],
        hideTop: true,
        topLeaf: 'scale(1)'
      }
      )
    return{stalkData}
  }
}
</script>

<style>
.hoover-animation {
  animation: hoover 6s ease-in-out infinite;
}

.appear {
  animation: sun 0.4s ease-out forwards;
}

.ground-appear {
  overflow: hidden;
  height: 0px;
  animation: ground 0.4s ease-out forwards;
}

.branch-animation {
  width: 0;
  height: var(--var-height);
  transform-origin: bottom;
  animation: stalk-w 0.3s ease-out forwards;
}

.leaf-animation {
  transform: scale(0);
  animation: leaf 0.5s forwards;
}


.rabbit {
  width: 5em;
  height: 3em;
  background: #ffffff;
  border-radius: 70% 90% 60% 50%;
  position: relative;
  transform: rotate(0deg) translate(-2em, 0);
  z-index: 1;
}

.rabbit-jump {
  animation: hop 1s infinite linear;
}

.no-flexbox .rabbit {
  margin: 10em auto 0;
}

.rabbit:before {
  content: '';
  position: absolute;
  width: 1em;
  height: 1em;
  background: white;
  border-radius: 100%;
  top: 0.5em;
  left: -0.3em;
  box-shadow: 4em 0.4em 0 -0.35em #3f3334, 0.5em 1em 0 white,
  4em 1em 0 -0.3em white, 4em 1em 0 -0.3em white, 4em 1em 0 -0.4em white;
}

.rabbit-jump:before {
  animation: kick 1s infinite linear;
}

.rabbit:after {
  content: '';
  position: absolute;
  width: 0.75em;
  height: 2em;
  background: white;
  border-radius: 50% 100% 0 0;
  transform: rotate(-30deg);
  right: 1em;
  top: -1em;
  border-top: 1px solid #f7f5f4;
  border-left: 1px solid #f7f5f4;
  box-shadow: -0.5em 0em 0 -0.1em white;
}

.bunny-run {
  animation: run 6s infinite linear;
}

.butterfly-animation {
  animation: butterfly 5s ease-in-out infinite;
  animation-delay: 1s;
}

.butterfly {
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateY(0px);
  width: 30px;
}

.butterfly-hover {
  animation: hover 250ms cubic-bezier(0.48, 0.01, 0.54, 1) infinite;
  animation-direction: alternate;
  animation-fill-mode: reverse;
}

.butterfly::before {
  background: #23455b;
  border-radius: 50%;
  content: '';
  display: block;
  height: 110px;
  left: 50%;
  margin-left: -10px;
  outline: 1px solid transparent;
  position: absolute;
  top: -15px;
  transform: rotatey(100deg);
  width: 20px;
  z-index: 2;
}

.wing {
  background: #888;
  display: block;
  opacity: 0.7;
  outline: 1px solid transparent;
  position: absolute;
  top: 0;
}

.wing:first-child {
  height: 1px;
  left: 0;
  transform: rotateY(-20deg);
  transform-origin: 700% 50%;
  width: 1px;
  z-index: 3;
}
.wing-animation:first-child {
  animation: leftflap var(--flap-speed) cubic-bezier(0.48, 0.01, 0.54, 1)
  infinite;
  animation-direction: alternate;
  animation-fill-mode: reverse;
}

.wing-animation:last-child {
  animation: rightflap var(--flap-speed) cubic-bezier(0.48, 0.01, 0.54, 1)
  infinite;
  animation-direction: alternate;
  animation-fill-mode: reverse;
}
.wing:last-child {
  right: 0;
  transform: rotateY(200deg);
  z-index: 1;
}
.wing .bit {
  background: #ffb600;
}
.wing .bit::after {
  background: #f4b20e;
}
.wing .bit,
.wing .bit::after {
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: 100% 50%;
}
.wing .bit:first-child {
  height: 70px;
  text-align: center;
  top: 15px;
  transform: rotateZ(40deg);
  width: 130px;
}
.wing .bit:first-child::after {
  content: '';
  display: inline-block;
  height: 60px;
  left: -30px;
  top: 5px;
  width: 100px;
}
.wing .bit:last-child {
  height: 55px;
  transform: rotateZ(-40deg);
  width: 100px;
}
.wing .bit:last-child::after {
  content: '';
  display: inline-block;
  height: 45px;
  left: -24px;
  top: 5px;
  width: 60px;
  z-index: 1;
}

@keyframes butterfly {
  0% {
    transform: translateY(0%) scale(0.15);
  }
  50% {
    transform: translateY(250%) scale(0.15);
  }
  100% {
    transform: translateY(0%) scale(0.15);
  }
}

@keyframes hover {
  0% {
    transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(0px);
  }
  100% {
    transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateZ(-3px);
  }
}

@keyframes leftflap {
  0% {
    transform: rotateY(-20deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}

@keyframes rightflap {
  0% {
    transform: rotateY(200deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}

@keyframes run {
  0% {
    transform: translateX(0) scaleX(1);
  }
  55% {
    transform: translateX(300%);
  }
  60% {
    transform: translateX(300%) scaleX(-1);
  }
  95% {
    transform: translateX(0) scaleX(-1);
  }
  100% {
    transform: translateX(0) scaleX(1);
  }
}

@keyframes hop {
  20% {
    transform: rotate(-10deg) translate(0em, -2em);
  }
  40% {
    transform: rotate(10deg) translate(0em, -4em);
  }
  60%,
  75% {
    transform: rotate(0) translate(0em, 0);
  }
}
@keyframes kick {
  20%,
  50% {
    box-shadow: 4em 0.4em 0 -0.35em #3f3334, 0.5em 1.5em 0 white,
    4em 1.75em 0 -0.3em white, 4em 1.75em 0 -0.3em white,
    4em 1.9em 0 -0.4em white;
  }
  40% {
    box-shadow: 4em 0.4em 0 -0.35em #3f3334, 0.5em 2em 0 white,
    4em 1.75em 0 -0.3em white, 4.2em 1.75em 0 -0.2em white,
    4.4em 1.9em 0 -0.2em white;
  }
}

@keyframes leaf {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes stalk-w {
  0% {
    width: 0;
  }
  100% {
    width: 24px;
  }
}

@keyframes sun {
  0% {
    transform: translateY(350%);
  }
  70% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes hoover {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes ground {
  0% {
    height: 0px;
  }
  70% {
    height: var(--height-top);
  }
  99% {
    height: var(--height-final);
    overflow: hidden;
  }
  100% {
    height: var(--height-final);
    overflow: visible;
  }
}

</style>